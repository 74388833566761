import React from 'react'

const CancelNegIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
    >
      <path
        fill={fill}
        d='M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0Zm7,18.2L18.2,21,14,16.8,9.8,21,7,18.2,11.2,14,7,9.8,9.8,7,14,11.2,18.2,7,21,9.8,16.8,14Z'
      />
    </svg>
  )
}

export default CancelNegIcon
