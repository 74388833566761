import React from 'react'
import classnames from 'classnames'
import styles from './textinput.scss'

/**
 * A simple input field. No wrapper element - just a naked <input>
 *
 * @param {Object} props
 * @param {String} [props.name]
 * @param {String} [props.type]
 * @param {String} [props.placeholder]
 * @param {String} [props.value]
 * @param {Function} [props.change]
 * @param {React.Ref} [getRef]
 */
const Input = (props) => {
  const {
    name,
    type,
    placeholder,
    value,
    change,
    styling,
    getRef,
    disabled,
    small,
    ...rest
  } = props

  return (
    <input
      className={classnames(styles.input, styling, {
        [styles.small]: small,
      })}
      id={name}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={change}
      ref={getRef}
      disabled={disabled}
      {...rest}
    />
  )
}

export default Input
