const cache = {}

const svgLoader = (name) => {
  if (cache[name]) return cache[name]

  return fetch(name)
    .then(response => response.text())
    .then(response => {
      cache[name] = response

      return response
    })
}

export default svgLoader
