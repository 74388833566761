import React from 'react'

import { useTranslation } from 'react-i18next'

// import Row from '_/components/layout/Row'
import Column from '_/components/layout/Column'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Button from '_/components/element/Button'

import { sanitizeDateString } from '@ticknovate/frontend-shared/libs/dateFormatter'

import isSameDay from 'date-fns/is_same_day'
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'

import styles from './day.scss'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const formatDateTitle = (date, t) => {
  const today = new Date()

  if (isSameDay(date, today)) return t('meta.today')
  if (isSameDay(date, addDays(today, 1))) return t('meta.tomorrow')

  const [
    num,
    day,
  ] = format(sanitizeDateString(date), 'D_dddd').split('_')

  return `${num} ${t(`day.short.${day.toLowerCase()}`, day)}`
}

const Day = ({
  date,
  price,
  selected,
  disabled,
  change,
}) => {
  const { t } = useTranslation()

  return (
    <Button change={change} fullFlex padding={0} gridAlign={'stretch'}>
      <Column
        spread={false}
        padding={'0.5rem'}
        crossAxis={'flex-start'}
        className={styleCombine(styles.layout, selected && styles.selected, disabled && styles.disabled)}
      >
        <InlineTextBox size={1.25} margin={'0 0 0.25rem 0'} rawStyle={{ color: 'inherit' }}>{formatDateTitle(date, t)}</InlineTextBox>
        <InlineTextBox rawStyle={{ color: 'inherit' }}>{price === null ? '-' : price}</InlineTextBox>
      </Column>
    </Button>
  )
}

export default Day
