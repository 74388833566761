
import React, { Fragment, useEffect, useState } from 'react'
import * as cartApi from '../../../../../api/cart'
import Notification from '../../../../molecules/Notification'
import Container from '../../../../containers/Container'
import styles from '../../../../organisms/Form-Billing/formbilling.scss'
import { useTranslation } from 'react-i18next'
import retry from 'async-retry'

// List from https://tech.dibspayment.com/easy/integration-guide
const allowedLanguages = [
  'en-GB',
  'da-DK',
  'sv-SE',
  'nb-NO',
  'de-DE',
  'pl-PL',
  'fr-FR',
  'es-ES',
  'nl-NL',
  'fi-FI',
]

/**
 * If the cart locale is "sv", the Nets form will error.
 * Nets needs the string "sv-SE", or it will be spiteful.
 */
const getValidLanguage = (locale) => {
  if (allowedLanguages.includes(locale)) {
    return locale
  }

  const filtered = allowedLanguages.filter((lang) => {
    return lang.startsWith(locale)
  })

  if (filtered.length) {
    return filtered[0]
  }

  return allowedLanguages[0]
}

export default function NetsPaymentForm ({
  cart,
  redirectToConfirmation,
  termsAndConditions,
  config,
}) {
  const [error, setError] = useState()
  // const validatorContext = useContext(FormContext)
  const { t } = useTranslation()

  useEffect(() => {
    let unmounted = false
    let checkout = null

    getPaymentId(cart)
      .then((paymentId) => {
        if (unmounted) return

        checkout = new window.Dibs.Checkout({
          checkoutKey: config.checkoutKey,
          paymentId,
          containerId: 'dibs-complete-checkout',
          language: getValidLanguage(cart.locale),
          theme: getNetsTheme(),
        })

        // This triggers when payment is complete. There may have been a redirect away
        // from the Ticknovate site to a vendor page to authorise the payment with the
        // bank (3DSecure). In that case, the iframe loads upon successful redirect back
        // and then triggers this "payment-completed" event. The magic is in the query
        // params we get redirected back with (?paymentId=foo).
        checkout.on('payment-completed', async (response) => {
          let isRetrying = false
          await retry(
            // If anything in the first function throws, we retry until the max amount of retries is completed
            async () => {
              console.log('Nets: payment-completed', response)

              /**
               * Do this once, due to the webhook _also_ triying to add the payment to the DB
               * by calling `syncPayment` directly.
               *
               * Repeat calls can cause an SQL clash if both webhook and this call happen at the same time.
               */
              if (!isRetrying) {
                const paymentResult = await cartApi.addPayment(cart, {
                  method: 'nets_easy',
                  paymentId: response.paymentId,
                })
                console.log('Nets: Payment saved', paymentResult)
              }

              const orderResult = await cartApi.placeOrder(cart)
              console.log('Nets: Order placed', orderResult)

              redirectToConfirmation(orderResult)
            },
            {
              // Number of retries before giving up
              retries: 3,
              // The time between retries, in milliseconds
              minTimeout: 2000,
              // Triggered on retry
              onRetry: (error) => {
                console.error('Place order request failed. Retrying..', error)
                isRetrying = true
              },
            },
          )
        })

        // TODO: Add this check back
        // checkout.on('pay-initialized', () => {
        //   const valid = validatorContext.isFormValid({ displayAllErrors: true })

        //   // If "valid" is "false", checkout won't take any action.
        //   // If "valid" is "true", Nets script redirects to take payment.
        //   checkout.send('payment-order-finalized', valid)

        //   if (!valid) {
        //     triggerToastMessage({
        //       status: 'error',
        //       message: t('billing.errors.NOT-ACCEPTED-TERMS.long', 'You must accept the terms and conditions to continue.')
        //     })
        //   }
        // })
      })
      .catch((err) => {
        console.error('NetsPaymentForm error', err)
        setError(err)
      })

    return () => {
      unmounted = true
      if (checkout) {
        checkout.cleanup()
      }
    }
  }, [])

  if (error) {
    return (
      <Notification status={'error'}>
        {t([
          // Do we human-readable text for the error message code?
          `server-errors.${error.message}`,
          // If not, show generic message
          'billing.errors.CANT-LOAD-PAYMENT-FORM.long',
          // Last resort fallback value
          'The payment form could not load.',
        ])}
      </Notification>
    )
  }

  const isReturningFromExternalAuthPage = new URLSearchParams(
    document.location.search,
  ).has('paymentId')

  return (
    <Fragment>
      {/*
        Hide terms when redirecting to the page after seeing the vendor's 3D secure page.
        Upon return, terms and conditions checkboxes become un-ticked. This page is just
        showing "success" messaging on return.
       */}
      {termsAndConditions && !isReturningFromExternalAuthPage && (
        <Container styling={styles.paddingTop}>{termsAndConditions}</Container>
      )}
      <div id='dibs-complete-checkout' />
    </Fragment>
  )
}

const getPaymentId = async (cart) => {
  const paymentIdParam = new URLSearchParams(window.location.search).get(
    'paymentId',
  )

  if (paymentIdParam) {
    console.log(`Nets: Got payment ID from URL: ${paymentIdParam}`)
    return paymentIdParam
  }

  console.log('Nets: No payment ID in URL. Creating new payment...')
  const { paymentId } = await cartApi.initNetsPayment(cart)
  console.log(`Nets: Created new payment with ID: ${paymentId}`)

  return paymentId
}

const getNetsTheme = () => ({
  // [Optional] as are all values within
  textColor: window.TICKNOVATE_CONFIG.colors.text, // any css color
  primaryColor: window.TICKNOVATE_CONFIG.colors.button, // any css color
  buttonRadius: '13px',
  buttonTextColor: window.TICKNOVATE_CONFIG.colors.button_inner_text, // any css color
  linkColor: window.TICKNOVATE_CONFIG.colors.text_link, // any css color
  footerBackgroundColor: 'transparent', // any css color
  footerOutlineColor: 'transparent', // any css color
  footerTextColor: window.TICKNOVATE_CONFIG.colors.text_mid, // any css color
  backgroundColor: 'transparent', // any css color
  // panelColor: window.TICKNOVATE_CONFIG.colors.button, // any css color
  outlineColor: window.TICKNOVATE_CONFIG.colors.border_thin, // any css color
  primaryOutlineColor: window.TICKNOVATE_CONFIG.colors.border_thin, // any css color
  panelTextColor: window.TICKNOVATE_CONFIG.colors.text, // any css color
  panelLinkColor: window.TICKNOVATE_CONFIG.colors.text_link, // any css color
  fontFamily: window.TICKNOVATE_CONFIG.theme.fontMain, // any google font
  buttonFontWeight: 500, // number or string
  // buttonFontStyle: "italic", // oblique, italic, etc. any valid css value
  buttonTextTransform: 'none', // any valid css text-transform
  placeholderColor: window.TICKNOVATE_CONFIG.colors.text_placeholder, // any css color
  useLightIcons: false, // boolean
  useLightFooterIcons: false, // boolean
})
