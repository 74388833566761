import { v4 as uuidV4 } from 'uuid'

const reducer = (state = uuidV4(), action = {}) => {
  switch (action.type) {
    case 'START_SESSION':
    case 'END_SESSION':
      return uuidV4()

    default:
      return state
  }
}

export default reducer
