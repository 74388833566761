const isBrowserSupported = () => (
  window.CSS &&
  window.CSS.supports &&
  // We support all browsers that support CSS grid.
  // This isn't perfect, but it's better than a blanket
  // "we support all except Internet Explorer" rule.
  //
  // If grid works, chances are, the browser has the modern
  // features we need.
  //
  // If grid doesn't work, the whole app will be useless.
  window.CSS.supports('display', 'grid')
)

export default isBrowserSupported
