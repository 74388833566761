import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import isEqualWith from 'lodash/fp/isEqualWith'

/**
 * A comparer function to use with `React.memo`.
 *
 * If a prop has a `getDataForMemoComparison` method, the result of that is
 * compared instead of the raw object for performance reasons.
 */
const arePropsEqual = isEqualWith((a, b) => {
  const keys = uniq([
    ...Object.keys(a),
    ...Object.keys(b),
  ])

  for (const key of keys) {
    if (a[key] && a[key].getDataForMemoComparison) {
      if (!isEqual(
        a[key].getDataForMemoComparison(),
        b[key].getDataForMemoComparison(),
      )) return false
    } else if (!isEqual(a[key], b[key])) {
      return false
    }
  }

  return true
})

export default arePropsEqual
