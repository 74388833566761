const EMBED_READY = 'EMBED_READY'

const initialState = {
  ready: false,
}

const ete = (state = initialState, action = {}) => {
  switch (action.type) {
    case EMBED_READY: {
      return Object.assign({}, state, {
        ready: action.ready,
      })
    }

    default: return state
  }
}

export default ete
