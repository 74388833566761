import React from 'react'

import Area from '_/components/layout/Area'
import Button from '_/components/element/Button'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Icon from '_/components/element/Icon'

const ActionRadioGroup = ({
  field,
  value: selected,
  options,
  change,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const handleChange = (value) => () => change(field, value)

  return (
    <Area
      columns={3}
      baseColUnit={1}
      colgap={0.25}
      rowgap={0.25}
    >
      {options.map(({ label, value, icon: name }) => {
        const active = value === selected

        const style = {
          background: `RGB(var(--theme_radio_${active ? 'selected' : 'default'}))`,
          border: `1px solid RGB(var(--theme_radio_${active ? 'selected' : 'default'}_border))`,
          padding: '0.5rem 0',
          width: '100%',
        }

        const color = active ? 'radio_selected_text' : 'radio_default_text'

        return (
          <Button rawStyle={style} change={handleChange(value)} key={value}>
            <Icon name={name} size={24} fill={theme[color]} />
            <InlineTextBox strong color={color} margin={'0 0 0 0.25rem'}>{label}</InlineTextBox>
          </Button>
        )
      })}
    </Area>
  )
}

export default ActionRadioGroup
