import React from 'react'

import Button from '_/components/element/Button'
import Row from '_/components/layout/Row'
import Icon from '_/components/element/Icon'
import InlineTextBox from '_/components/layout/InlineTextBox'

const ActionLink = ({
  label,
  change,
  icon: iconName,
  color = 'button',
  ...styling
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Button change={change} color={color} {...styling}>
      <Row>
        <Icon name={iconName} size={24} fill={theme[color]} />
        <InlineTextBox strong color={'button'}>{label}</InlineTextBox>
      </Row>
    </Button>
  )
}

export default ActionLink
