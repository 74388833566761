import React, { Fragment, useEffect } from 'react'

import { useQueryClient } from 'react-query'

import {
  useDispatch,
} from 'react-redux'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'
import { ResponsiveView as View } from '_/components/layout/View'

import Heading from '_/components/layout/Heading'
import NavTitle from '_/components/navigation/NavTitle'

import ActionCard from '_/components/action/ActionCard'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useOverView from '_/hooks/useOverview'

import { triggerEndSession } from '_/hooks/useSessionTimeout'
import { usePageView } from '_/hooks/usePageView'

const mobileLayout = [
  'title',
  'route',
  'event',
]

const desktopLayout = [
  'title',
  'route/2 .',
  'event/2 .',
]

const Explore = ({
  history,
}) => {
  const queryClient = useQueryClient()
  const reduxDispatch = useDispatch()

  queryClient.removeQueries('cart')
  queryClient.removeQueries('customer')

  const {
    products,
  } = useOverView()

  const {
    t,
  } = useTranslation()

  usePageView('EXPLORE', {})

  useEffect(() => {
    triggerEndSession()

    reduxDispatch({
      type: 'END_SESSION',
    })
  }, [reduxDispatch])

  const hasRoute = products.some(product => product.type === 'route')
  const hasEvent = products.some(product => product.type === 'event')

  const hideTitle = (!hasRoute || !hasEvent)

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Fragment>
            <NavTitle mobile={mobile} title={t('explore.section-title')} />
            <View mobile={mobile} fullFlex>
              <Area
                areas={mobile ? mobileLayout : desktopLayout}
                columns={6}
                rowgap={1}
                baseColUnit={1}
              >
                {!hideTitle && (
                  <Heading level={2} title={t('explore.sub-title')} area={'title'} />
                )}
                {hasRoute && (
                  <ActionCard
                    area={'route'}
                    label={t('explore.option-route')}
                    change={() => history.push('/ticket/route')}
                  />
                )}
                {hasEvent && (
                  <ActionCard
                    area={'event'}
                    label={t('explore.option-event')}
                    change={() => history.push('/event')}
                  />
                )}
              </Area>
            </View>
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

export default Explore
