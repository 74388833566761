import React from 'react'

import styles from './headerrow.scss'

import Row from '../../containers/Row'
import InlineTextBox from '../../containers/InlineTextBox'

const HeaderRow = (props) => {
  const {
    headings,
    styling,
  } = props

  return (
    <Row
      type={'spaced'}
      styling={[styles.layout, styling].join(' ')}
    >
      {headings.map((heading, index) => {
        return (
          <InlineTextBox
            key={index}
            styling={styles.heading}
          >
            {heading}
          </InlineTextBox>
        )
      })}
    </Row>
  )
}

export default HeaderRow
