import { useEffect, useCallback } from 'react'

const useOnClickOutside = (wrapperRef, callback) => {
  const listener = useCallback(event => {
    const wrapped = wrapperRef.current

    if (wrapped.contains(event.target)) return

    callback(event)
  }, [wrapperRef, callback])

  useEffect(() => {
    window.addEventListener('mousedown', listener, false)

    return () => {
      window.removeEventListener('mousedown', listener, false)
    }
  })
}

export default useOnClickOutside
