import React, { useState, useLayoutEffect, useMemo, useRef } from 'react'

import isMatch from 'lodash/isMatch'
import sortBy from 'lodash/sortBy'

import StepLayout from '_/templates/StepLayout'
import Summary from '_/templates/Summary'
import LoadIndicator from '_/templates/LoadIndicator'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import EditorModal from '_/templates/EditorModal'

import Form from './Form'

import useForm from '_/hooks/useForm'

import {
  getSchema,
  valid_spec,
  reset_values,
} from './context'

import useOverview from '_/hooks/useOverview'

import {
  filterTravellers,
  filterNonTravellers,
} from '_/libs/dependantData'

import useBookings from '_/hooks/useBookings'

import useBasket from '_/hooks/useBasket'
import useCart from '_/hooks/useCart'

import {
  syncBasketToCart,
  syncCartToBasket,
} from '_/models/cart'

import layouts from './layouts'

import makeSerialID from '_/libs/makeSerialID'

import EmbedTransport from '_/libs/EmbedTransport'

const enhancedValidity = ({
  meta,
  layout,
  current,
  valid,
}) => {
  const enhanced = {
    travel: (() => {
      const non_travellers = filterNonTravellers(meta, current.ticket, layout)

      return non_travellers.some(ticket => ticket.qty > 0)
    })(),
    travellers_group: (() => {
      return filterTravellers(meta, current.ticket, layout)
        .some(ticket => ticket.qty > 0)
    })(),
  }

  return {
    ...valid,
    ...enhanced,
  }
}

const checkTicketValuesMatch = (existing, changed) => {
  const isSameLength = changed.length === existing.length
  const hasSameIds = sortBy(changed, ['id']).map(({ id }) => id).join(',') === sortBy(existing, ['id']).map(({ id }) => id).join(',')

  if (isSameLength && hasSameIds) {
    return isMatch(sortBy(existing, ['id']), sortBy(changed, ['id']))
  } else {
    return false
  }
}

/*
  Alter cart items if they are no longer valid or have tickets changed
*/
const setBasketState = ({
  current,
  update,
  options,
  basket,
  cart,
}) => {
  if (!basket) return

  const {
    outbound_time,
    outbound_date,
    inbound_time,
  } = options

  const {
    outbound,
    inbound,
  } = basket.state.items

  if (!outbound_time.isLoaded || !outbound_date.isLoaded) return

  if (outbound !== null) {
    const outboundValid = outbound_time.data.options
      .map(makeSerialID).includes(outbound.pick_id)

    const inboundValid = inbound !== null && inbound_time.data.options
      .map(makeSerialID).includes(inbound.pick_id)

    // If outbound is wrong then inbound is as well
    // Clear them both
    if (!outboundValid) {
      basket.emptyBasket()
      cart.reset()

      update([
        {
          field: 'outbound_date',
          value: null,
        },
        {
          field: 'outbound_time',
          value: null,
        },
        {
          field: 'inbound_date',
          value: null,
        },
        {
          field: 'inbound_time',
          value: null,
        },
      ])
    } else {
      // Get the filter tickets list
      const currentTickets = current.ticket
        .filter(ticket => ticket.qty > 0)

      // Check if our tickets match
      const ticketsMatch = checkTicketValuesMatch(outbound.ticket, currentTickets)

      if (!ticketsMatch) { // No match, so update outbound
        const payload = {
          ...outbound,
          ticket: currentTickets,
        }

        basket.editBasket(payload, 'outbound')

        if (inboundValid) { // Inbound journey is still valid, update accordingly
          const payload = {
            ...inbound,
            ticket: currentTickets,
          }

          basket.editBasket(payload, 'inbound')
        } else { // Inbound journey is invalid, remove
          basket.removeFromBasket('inbound')

          update([
            {
              field: 'inbound_date',
              value: null,
            },
            {
              field: 'inbound_time',
              value: null,
            },
          ])
        }
      }
    }
  }
}

const Amend = ({
  match,
  meta,
  current,
  valid,
  update,
  layout,
  dateField,
}) => {
  const basket = useBasket()
  const cart = useCart(basket.state)

  const [
    loading,
    setLoading,
  ] = useState(false)

  const options = useBookings({ current, layout })

  // Do something here if current basket options do not sync with ticket selection
  useLayoutEffect(() => {
    setBasketState({
      current,
      update,
      options,
      basket,
      cart,
    })
  }, [current, options])

  const handleUpdate = (payload, reset = []) => {
    update([
      ...payload,
      ...reset
        .map(field => ({
          field,
          value: reset_values[field] ? reset_values[field](current[field]) : null,
        })),
    ])
  }

  const discard = async () => {
    console.log('DISCARDING CART')
    await cart.discard(match.params.id)
    console.log('DISCARDED CART')

    EmbedTransport.send({
      type: 'DESTROY',
      value: null,
    })
  }

  const sendCart = async () => {
    setLoading(true)
    const uploadState = syncBasketToCart(basket.state, cart.data)

    console.log('SAVING CART', basket, uploadState)
    await cart.update(uploadState)
    console.log('CART SAVED')

    EmbedTransport.send({
      type: 'SUBMIT',
      value: uploadState,
    })
  }

  const modal_props = {
    data: meta,
    layout,
    current,
    options,
    update: handleUpdate,
    dateField,
    amending: true,
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <EditorModal
            mobile={mobile}
            modalProps={modal_props}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <Form
                    current={current}
                    valid={enhancedValidity({
                      meta,
                      layout,
                      current,
                      valid,
                    })}
                    dateField={dateField}
                    mobile={mobile}
                    match={match}
                    mount={mount}
                    data={meta}
                    layout={layout}
                    submit={sendCart}
                    discard={discard}
                    loading={loading || cart.isFetching}
                  />
                  {!mobile && (
                    <Summary
                      area={'basket'}
                      title
                    />
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

const filterOutTicketObject = (ticket_type) => {
  if (ticket_type.hasOwnProperty('capacity_units')) {
    return ticket_type.id
  } else {
    return ticket_type
  }
}

const getInitialTickets = (meta, cart) => {
  const firstBooking = cart.items[0]
  const type = firstBooking.product.type

  if (type === 'event') {
    const filtered = meta.products
      .filter(product => product.id === firstBooking.product.id)
      .reduce((acc, { ticket_types }) => {
        ticket_types.map(filterOutTicketObject).forEach(type => {
          const exists = firstBooking.ticket_types.find(item => item.id === type)

          acc.push({
            id: type,
            qty: exists ? exists.qty : 0,
          })
        })

        return acc
      }, [])

    return filtered
  } else {
    return meta.tickets
      .map(ticket => {
        const exists = firstBooking.ticket_types.find(item => item.id === ticket.value)

        return {
          id: ticket.value,
          qty: exists ? exists.qty : 0,
        }
      })
  }
}

const Loader = ({
  match,
}) => {
  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  const nonce = useRef(false)

  const {
    resetBasket,
    setPersistID,
  } = useBasket()

  const cart = useCart()

  const meta = useOverview()

  const [dateField, setDateField] = useState('outbound')

  const {
    current,
    valid,
    update,
    reset,
  } = useForm(getSchema, valid_spec, {}, {
    unique: {},
    onUpdate: null,
  })

  useLayoutEffect(() => {
    const getCartInternal = async () => {
      const cartData = await cart.amend(match.params.id, match.params.item)

      const {
        data,
      } = cartData

      const index = data.items.findIndex(item => item.id === match.params.item)

      resetBasket(
        syncCartToBasket({
          ...data,
        }),
      )

      const ticket = getInitialTickets(meta, data)

      reset({
        itemId: match.params.item,
        ...data,
        ticket,
      })

      const persist = {
        outbound: data.items[0].id,
        inbound: data.items[1] ? data.items[1].id : null,
      }

      setPersistID(persist)

      setDateField(index === 0 ? 'outbound' : 'inbound')

      nonce.current = true
    }

    if (meta.isLoaded) {
      getCartInternal()
    }
  }, [meta.isLoaded])

  const layout = useMemo(() => {
    if (explore?.[current.type]) {
      const {
        selector,
      } = explore[current.type]

      return {
        ...layouts[current.type][selector],
        ...explore[current.type],
      }
    }

    return {
      selector: 'derived',
      ...layouts.route.derived,
    }
  }, [cart.isLoaded, current.type])

  if (!meta.isLoaded && !cart.isLoaded && !nonce.current) {
    return (
      <MediaQuery media={media.mobile}>
        {mobile => {
          return (
            <StepLayout mobile={mobile}>
              <LoadIndicator />
            </StepLayout>
          )
        }}
      </MediaQuery>
    )
  }

  return (
    <Amend
      match={match}
      meta={meta}
      current={current}
      update={update}
      valid={valid}
      layout={layout}
      dateField={dateField}
    />
  )
}

export default Loader
