import i18next from 'i18next'

import base from './en-gb'

const fetch = window.fetch

const SUPPORTED_LANGS = ['en', 'en-GB', 'sv', 'sv-SE', 'da', 'da-DK']

const toLoad = [
  'en-gb',
  'sv-se',
  'da-dk',
]

class LocaleHolder {
  constructor () {
    this._locales = {}
    this.instance = null
  }

  initLocales = async (endpoint, app) => {
    return Promise.all(toLoad.map(lang => {
      console.log(`Fetching language file ${lang} from ${endpoint} with ${app}`)
      return getRemoteLocale(lang, endpoint, app)
    }))
      .then(response => {
        response.forEach((item, index) => {
          if (item !== null) this._locales[toLoad[index]] = item
        })
      })
  }

  setContext = async (initLang) => {
    const instance = i18next.createInstance()

    const resources = {
      en: {
        translation: base,
      },
    }

    for (const key in this._locales) {
      resources[setToUpper(key)] = {
        translation: this._locales[key],
      }
    }

    await instance
      .init({
        debug: Boolean(process.env.NODE_ENV === 'development'),
        interpolation: {
          escapeValue: false,
        },
        lng: 'en-GB',
        resources,
        supportedLngs: SUPPORTED_LANGS,
        fallbackLng: {
          'sv': ['sv-SE', 'en'],
          'da': ['da-DK', 'en'],
          'en': ['en-GB', 'en'],
          'default': ['en'],
        },
        lowerCaseLng: false,
      })

    instance
      .on('languageChanged', (lng) => {
        console.log('Captured lng change in context', lng)
      })

    console.log('Created new context')

    instance.changeLanguage(initLang)

    this.instance = instance

    return {
      instance: this.instance,
      translate: (...args) => instance.t(...args),
      getLanguage: () => instance.language,
      changeLanguage: lang => instance.changeLanguage(lang),
    }
  }
}

const getRemoteLocale = async (lang, endpoint, app) => {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json' },
  }

  try {
    const response = await fetch(`${endpoint}/locales/${app}/${lang}.json`, options)

    const locale_data = await response.json()

    return locale_data
  } catch (e) {
    console.log('Get data remove local fail', e.message)

    return null
  }
}

const setToUpper = lang => {
  const [
    locale,
    language,
  ] = lang.split('-')

  return `${locale}-${language.toUpperCase()}`
}

const createLocales = new LocaleHolder()

const setLocalesContext = async () => createLocales.setContext()
const initLocales = (endpoint, app) => createLocales.initLocales(endpoint, app)
const getContext = () => createLocales.instance
const getLanguage = () => createLocales.instance.language
const changeLanguage = (lang) => createLocales.instance.changeLanguage(lang)
const translate = (...args) => createLocales.instance.t(...args)

export {
  translate,
  getContext,
  setLocalesContext,
  initLocales,
  getLanguage,
  changeLanguage,
}
