import getStore from '_/store'
import isPast from 'date-fns/is_past'
import * as loginApi from '../api/login'
import * as userActions from '../actions/user'

let currentRefresh = null

const getFreshAuthToken = async () => {
  // Get current user state from redux store.
  const store = await getStore()
  const { user } = store.getState()

  // Customer-facing site will not have a user. It will send no Authorization headers.
  if (!user || !user.authenticated) return

  // Refresh token has not expired!
  if (!isPast(user.expiryTime)) {
    return user.accessToken
  }

  // Refresh token has expired and there is not already a refresh in
  // progress. Let's get a new token!
  if (!currentRefresh) {
    const { refreshToken, data } = user

    currentRefresh = loginApi.login({
      username: data['cognito:username'],
      refreshToken,
    })

    // Sync result with redux store.
    currentRefresh
      .then(response => {
        currentRefresh = null
        store.dispatch(userActions.userLogin(response))
      })
      .catch(err => {
        currentRefresh = null
        console.error(err)
        console.error('Failed to refresh user\'s token. Logging out')
        store.dispatch(userActions.logout())
      })
  }

  // Wait for the refresh to complete.
  await currentRefresh

  // Return NEW access token from redux store.
  return store.getState().user.accessToken
}

export default getFreshAuthToken
