import React from 'react'

const UpIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 20.01'
    >
      <polygon
        fill={fill}
        points='28 20 32 16 16 0 0 16 4 20.01 16 8 28 20'
      />
    </svg>
  )
}

export default UpIcon
