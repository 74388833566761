import React, { Fragment } from 'react'

import sortBy from 'lodash/sortBy'

import Day from './Day'
import Ticket from './Ticket'
import TextBox from '_/components/layout/TextBox'

import styles from './datetile.scss'

import stylingHandler from '@ticknovate/frontend-shared/style/stylingHandler'

const chunkTimes = (times) => {
  const groups = {}

  times.sort((a, b) => a.start_time > b.start_time || -(a.start_time < b.start_time))

  times.forEach(time => {
    const slot = Math.floor(time.start_time.slice(4, 6) / 60)
    const key = time.start_time.slice(0, 3) + (slot ? '60' : '00')

    groups[key] ? groups[key].push(time) : groups[key] = [time]
  })

  return groups
}

const DateTile = ({
  selected,
  date,
  picked,
  comboPicked,
  tickets = [],
  style = {},
  isLoading,
  disabled = false,
  change,
  comboFirst = false,
  direction,
}) => {
  const TicketList = comboFirst ? Combo : Normal

  return (
    <div
      className={styles.layout}
      style={style}
    >
      <Day
        date={date}
        selected={selected}
        disabled={disabled}
      />
      <div className={styles.list}>
        <TicketList
          tickets={tickets}
          picked={picked}
          change={change}
          comboPicked={comboPicked}
          direction={direction}
        />
      </div>
    </div>
  )
}

const Combo = ({
  tickets,
  picked,
  change,
  comboPicked,
  direction,
  showTime = false,
  products,
  ...style
}) => {
  if (tickets.length === 0) return null

  const flattened = tickets
    .reduce((acc, cur) => {
      const {
        combos,
        ...rest
      } = cur

      if (combos.length > 0) {
        acc.push(...combos)
      } else {
        acc.push(rest)
      }

      return acc
    }, [])

  return (
    <div className={styles.list} style={stylingHandler(style)}>
      {sortBy(flattened, ['start_time', 'pricing.total']).map(ticket => {
        if (ticket.combo_pick_id) {
          return (
            <Ticket
              key={ticket.combo_pick_id}
              selected={comboPicked === ticket.combo_pick_id && picked === ticket.selection.pick_id}
              combo
              data={ticket}
              change={() => change(ticket.selection.pick_id, ticket.combo_pick_id)}
              icon={'return'}
              showDiscount={direction === 'outbound'}
              showTime={showTime}
            />
          )
        } else {
          return (
            <Ticket
              key={ticket.pick_id}
              selected={picked === ticket.pick_id}
              data={ticket}
              change={() => change(ticket.pick_id)}
              icon={direction === 'outbound' ? 'arrow_right' : 'arrow_left'}
              showTime={showTime}
            />
          )
        }
      })}
    </div>
  )
}

const Normal = ({
  tickets,
  picked,
  change,
  comboPicked,
  direction,
  showTime = false,
  products,
  ...style
}) => {
  if (tickets.length === 0) return null

  return (
    <div className={styles.list} style={stylingHandler(style)}>
      {sortBy(tickets, ['start_time', 'pricing.total']).map((ticket, offer) => {
        if (ticket.combos.length > 0) {
          let ticketPicked = picked === ticket.pick_id

          // Are any combos under this ticket picked
          const comboSelected = ticket.combos.some(combo => comboPicked === combo.combo_pick_id)

          if (ticketPicked && comboSelected) {
            ticketPicked = false
          }

          const curProduct = products?.find((prod) => prod.id === ticket.product_id)

          return (
            <Fragment>
              <Ticket
                key={`${ticket.pick_id}_${offer}`}
                selected={ticketPicked}
                data={{ ...ticket, short_description: curProduct?.short_description, category: curProduct?.category }}
                change={() => change(ticket.pick_id)}
                icon={direction === 'outbound' ? 'arrow_right' : 'arrow_left'}
                showTime={showTime}
              />
              {sortBy(ticket.combos, ['start_time', 'pricing.total']).map((combo, index) => {
                return (
                  <Ticket
                    key={`${ticket.pick_id}:${combo.combo_pick_id}_${index}`}
                    selected={picked === ticket.pick_id && comboPicked === combo.combo_pick_id}
                    combo
                    data={{ ...combo, short_description: combo.short_description, category: curProduct?.category }}
                    change={() => change(ticket.pick_id, combo.combo_pick_id)}
                    icon={direction === 'outbound' ? 'arrow_right' : 'return'}
                    showDiscount={direction === 'outbound'}
                  />
                )
              })}
            </Fragment>
          )
        } else {
          return (
            <Ticket
              key={ticket.pick_id}
              selected={picked === ticket.pick_id}
              data={ticket}
              change={() => change(ticket.pick_id)}
              showTime={showTime}
            />
          )
        }
      })}
    </div>
  )
}

const Timed = ({
  tickets,
  picked,
  change,
  comboPicked,
  direction,
  ...style
}) => {
  const chunked = chunkTimes(tickets)

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const titleStyle = {
    color: theme.text_light,
    backgroundColor: theme.background_nav,
  }

  if (tickets.length === 0) return null

  return (
    <div className={styles.list} style={stylingHandler(style)}>
      {Object.keys(chunked).map(time => {
        const chunk = chunked[time]

        return (
          <Fragment>
            <TextBox textAlign={'center'} uppercase strong margin={'0 0 0.25rem 0'} padding={'0.25rem 0'} rawStyle={titleStyle}>{time}</TextBox>
            {chunk.map(ticket => {
              if (ticket.combos.length > 0) {
                let ticketPicked = picked === ticket.pick_id

                // Are any combos under this ticket picked
                const comboSelected = ticket.combos.some(combo => comboPicked === combo.combo_pick_id)

                if (ticketPicked && comboSelected) {
                  ticketPicked = false
                }

                return (
                  <Fragment>
                    <Ticket
                      key={ticket.pick_id}
                      selected={ticketPicked}
                      data={ticket}
                      change={() => change(ticket.pick_id)}
                      icon={direction === 'outbound' ? 'arrow_right' : 'arrow_left'}
                    />
                    {sortBy(ticket.combos, ['start_time', 'pricing.total']).map((combo, index) => {
                      return (
                        <Ticket
                          key={`${ticket.pick_id}:${combo.combo_pick_id}`}
                          selected={picked === ticket.pick_id && comboPicked === combo.combo_pick_id}
                          combo
                          data={combo}
                          change={() => change(ticket.pick_id, combo.combo_pick_id)}
                          icon={direction === 'outbound' ? 'arrow_right' : 'return'}
                          showDiscount={direction === 'outbound'}
                        />
                      )
                    })}
                  </Fragment>
                )
              } else {
                return (
                  <Ticket
                    key={ticket.pick_id}
                    selected={picked === ticket.pick_id}
                    data={ticket}
                    change={() => change(ticket.pick_id)}
                  />
                )
              }
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export default DateTile

export {
  Combo,
  Normal,
  Timed,
}
