import React from 'react'

import styles from './inlinetextbox.scss'

const InlineTextBox = (props) => {
  const {
    children,
    styling,
    ...rest
  } = props

  return (
    <span
      className={[styles.layout, styling].join(' ')}
      {...rest}
    >
      {children}
    </span>
  )
}

export default InlineTextBox
