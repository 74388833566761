import { createSchema } from 'morphism'

import {
  mapDefaults,
  mapToArraySubSchema,
} from '_/libs/mapToSchema'

import {
  notEmptyOrNull,
} from '_/libs/validityChecks'

import {
  stampToDateISO,
  getTimeNow,
} from '_/libs/date'

import isToday from 'date-fns/is_today'

const getTicketSchema = () => {
  return createSchema(
    {
      'id': 'id',
      'qty': 'qty',
    },
    mapDefaults({
      'qty': 0,
    }),
  )
}

const getSchema = () => {
  return createSchema(
    {
      'type': (cart) => {
        if (!cart.items) return 'route'

        return cart.items[0].product.type
      },
      'product_id': (cart) => {
        if (!cart.items) return null

        const type = cart.items[0].product.type

        return type !== 'route' ? cart.items[0].product.id : null
      },
      'location': (cart) => {
        if (!cart.items) return null

        return cart.items[0].location.id
      },
      'end_location': (cart) => {
        if (!cart.items) return null

        const type = cart.items[0].product.type

        return type === 'route' ? cart.items[0].end_location.id : null
      },
      'ticket': {
        path: 'ticket',
        fn: (ticket) => {
          if (!ticket) return []

          return mapToArraySubSchema(getTicketSchema)(ticket)
        },
      },
      'outbound_date': (cart) => {
        if (!cart.items) return null

        return cart.items[0].booked_unit.start_date
      },
      'outbound_date_selection': (cart) => {
        if (!cart.items) return null

        const date = cart.items[0].booked_unit.start_date

        if (!date) {
          return stampToDateISO(new Date())
        } else {
          return date
        }
      },
      'inbound_date': (cart) => {
        if (!cart.items) return null

        return cart.items[1] ? cart.items[1].booked_unit.start_date : null
      },
      'inbound_date_selection': (cart) => {
        if (!cart.items) return null

        const date = cart.items[1] ? cart.items[1].booked_unit.start_date : null

        if (!date) {
          return stampToDateISO(new Date())
        } else {
          return date
        }
      },
      'outbound_time_selection': (cart) => {
        if (!cart.items) return null

        const date = cart.items[0].booked_unit.start_date

        if (!date) {
          return '09:00'
        } else {
          return isToday(date) ? getTimeNow(0) : '09:00'
        }
      },
      'inbound_time_selection': (cart) => {
        if (!cart.items) return null

        const date = cart.items[1] ? cart.items[1].booked_unit.start_date : null

        if (!date) {
          return '09:00'
        } else {
          return isToday(date) ? getTimeNow(0) : '09:00'
        }
      },
      'outbound_time_type': 'outbound_time_type',
      'inbound_time_type': 'inbound_time_type',
      'outbound_time': 'outbound_time',
      'inbound_time': 'inbound_time',
    },
    mapDefaults({
      'type': 'route',
      'outbound_date_selection': stampToDateISO(new Date()),
      'inbound_date_selection': stampToDateISO(new Date()),
      'outbound_time_selection': getTimeNow(0),
      'inbound_time_selection': getTimeNow(0),
      'outbound_time_type': 'depart',
      'inbound_time_type': 'depart',
    }),
  )
}

const valid_spec = {
  'location': notEmptyOrNull,
  'end_location': notEmptyOrNull,
  'outbound_date': notEmptyOrNull,
  'inbound_date': notEmptyOrNull,
  'ticket': (record, value) => {
    return value
      .some(ticket => ticket.qty > 0)
  },
}

const reset_values = {
  'ticket': (tickets) => tickets
    .map(ticket => ({
      ...ticket,
      qty: 0,
    })),
}

export {
  getSchema,
  valid_spec,
  reset_values,
}
