import React from 'react'
import classnames from 'classnames'

import styles from './heading.scss'

const getLevelTag = (level) => {
  if (level === 'section') return 'h1'
  if (typeof level === 'number') return `h${level}`
  return level
}

const Heading = (props) => {
  const {
    level,
    levelVisual: rawLevelVisual,
    title,
    styling,
    brand,
    noMargin,
  } = props
  const Tag = getLevelTag(level)
  const levelVisual = rawLevelVisual || level
  const className = styles[`heading-${levelVisual}`]

  return (
    <Tag className={classnames(className, styling, {
      [styles.brand]: brand,
      [styles.noMargin]: noMargin,
    })}>
      {title}
    </Tag>
  )
}

export default Heading
export { styles }
