import upperFirst from 'lodash/upperFirst'
import uniq from 'lodash/uniq'

export const paymentMethods = {
  /** "Free" payment method, where payment has already been taken. For voucher redemption. */
  NONE: 'none',
  /** "Free" payment via credit. For trade partner's being billed via invoice. */
  CREDIT: 'internal_credit',
  CASH: 'internal_cash',
  GENERIC: 'internal_generic',
  /** Standard credit card payment. */
  CARD: 'sagepay_card',
  NETS_EASY: 'nets_easy',
  WORLDPAY_ONLINE: 'worldpay_online',
  /** Refund */
  REFUND: 'refund',
  /** Deferred payment via email */
  PAY_LATER: 'pay_later',
  INVOICE: 'internal_invoice',
  ARRIVAL: 'internal_pay_on_arrival',
}

export const paymentMethodMeta = {
  [paymentMethods.CREDIT]: {
    displayName: 'Invoice',
    ctaText: {
      text: 'Complete and request invoice',
      translationKey: 'button-confirm-invoice',
    },
  },
  [paymentMethods.NONE]: {
    displayName: 'Pre-paid',
    ctaText: {
      text: 'Complete order',
      translationKey: 'button-confirm-none',
    },
  },
  [paymentMethods.CARD]: {
    displayName: 'Card',
    ctaText: {
      text: 'Complete payment',
      translationKey: 'button-confirm-card',
    },
  },
  [paymentMethods.NETS_EASY]: {
    displayName: 'Card',
    ctaText: {
      text: 'Complete payment',
      translationKey: 'button-confirm-card',
    },
  },
  [paymentMethods.WORLDPAY_ONLINE]: {
    displayName: 'Card',
    ctaText: {
      text: 'Complete payment',
      translationKey: 'button-confirm-card',
    },
  },
  // FRONTEND PAYMENT METHOD ONLY:
  [paymentMethods.REFUND]: {
    displayName: 'Refund',
    ctaText: {
      text: 'Confirm refund',
      translationKey: 'button-confirm-refund',
    },
  },
}

export const getPaymentCtaText = (method) => {
  return paymentMethodMeta[method].ctaText || {
    text: 'Complete payment',
    translationKey: 'button-confirm-card',
  }
}

export const channels = {
  /** Point of Sale - android app in Ticket booths */
  POS_DIRECT: 1,
  /** Consumer-facing web site */
  WEB_DIRECT: 2,
  /** Reservation portal direct bookings (not voucher or trade partner) */
  RESERVATIONS_DIRECT: 3,
  /** Online Travel Agency - 3rd party reseller (e.g. TourCMS) */
  OTA: 4,
  /**
   * Voucher booking - Bookings made using none voucher codes purchased with
   * a 3rd party.
   */
  VOUCHER: 5,
  /**
   * Trade partner booking - bookings made on behalf of a 3rd party ticket
   * reseller, on either the reservations portal, or trade partner portal.
   */
  TRADE_PARTNER: 6,
}

// TODO: `channel` and `channelMeta` don't need to be separate objects. Same for payment methods
// TODO: These channels can probably be populated on the backend. Inventory has a hardcoded "channel_title" value.
export const channelMeta = {
  [channels.POS_DIRECT]: { displayName: 'Point of Sale' },
  [channels.WEB_DIRECT]: { displayName: 'Web direct' },
  [channels.RESERVATIONS_DIRECT]: { displayName: 'Reservations direct' },
  [channels.OTA]: { displayName: 'Online travel agency' },
  [channels.VOUCHER]: { displayName: 'Voucher' },
  [channels.TRADE_PARTNER]: { displayName: 'Trade partner' },
}

/**
 * Condense a list of payments into a single string of unique, generic
 * payments types used.
 */
export const getPaymentsSummaryText = (payments = []) => {
  const paymentTypes = payments
    .map(({ method }) => {
      // `method` may be "internal_cash", "sagepay_card", "ocius_card", etc...
      return upperFirst(method.split('_').pop())
    })
    .sort()

  return uniq(paymentTypes).join(' & ')
}
