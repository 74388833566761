import { useEffect } from 'react'
import EmbedTransport from '_/libs/EmbedTransport'

// Expose a page view event for tracking purposes
export const usePageView = (pageType, getState) => {
  useEffect(
    () => {
      try {
        const state = typeof getState === 'function' ? getState() : getState
        EmbedTransport.send({
          type: 'PAGE_VIEW',
          value: { page: pageType, state },
        })
      } catch (err) {
        console.error('Error when trying to send PAGE_VIEW', err)
      }
    },
    // Only trigger once on view load
    [],
  )
}
