import { useEffect } from 'react'

const useEventListener = (element, event, callback, options) => {
  useEffect(() => {
    element.addEventListener(event, callback, options)

    return () => {
      element.removeEventListener(event, callback, options)
    }
  }, [element, event, callback, options])
}

export default useEventListener
