import React from 'react'
import { NavLink } from 'react-router-dom'

const EXTERNAL_HREF_REGEX = /^https?:\/\//

/**
 * Like react-router's `NavLink` component, except it can accept external URLs.
 */
const NavLinkEnhanced = ({ to, children, activeClassName, ...rest }) => {
  if (EXTERNAL_HREF_REGEX.test(to)) {
    return <a href={to} {...rest}>{children}</a>
  }

  return <NavLink to={to} activeClassName={activeClassName} {...rest}>{children}</NavLink>
}

export default NavLinkEnhanced
