import React, { forwardRef } from 'react'
import MediaQuery from '../renderProp/MediaQuery'
export { default as media } from 'styles/mediaQueries.scss'

const withMediaQuery = media => BaseComponent => forwardRef((ownProps, forwardedRef) => (
  <MediaQuery media={media}>
    {media => (
      <BaseComponent
        ref={forwardedRef}
        mediaQuery={media}
        {...ownProps}
      />
    )}
  </MediaQuery>
))

export default withMediaQuery
