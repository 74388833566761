import addMonths from 'date-fns/add_months'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/start_of_week'
import startOfISOWeek from 'date-fns/start_of_iso_week'
import endOfISOWeek from 'date-fns/end_of_iso_week'
import eachDay from 'date-fns/each_day'
import endOfWeek from 'date-fns/end_of_week'
import addDays from 'date-fns/add_days'
import getISODay from 'date-fns/get_iso_day'
import differenceInCalendarISOWeeks from 'date-fns/difference_in_calendar_iso_weeks'
import addWeeks from 'date-fns/add_weeks'

import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'
import getTime from 'date-fns/get_time'

import {
  splitDate,
  distanceBetween,
  stampToDateISO,
} from './date'

const firstAndLastDay = (year, month) => {
  year = Number(year)
  month = Number(month)

  const lastDay = new Date(year, month + 1, 0).getDate()

  const firstDay = new Date(year, month, 1).getDay()

  return {
    firstDay,
    lastDay,
  }
}

const arrayRange = (start, length) => {
  return Array.from({length: length}, (e, i) => start + i)
}

const decorateMonth = (ghosted, month, year, start, length) => {
  return arrayRange(start, length).map(date => {
    return {
      date: date,
      stamp: getTime(new Date(year, month, date)),
      ghosted,
    }
  })
}

// Expects month variable to be 0 - 11 based rather than 1 - 12
const dateRange = (startsOnMonday, month, year) => {
  const maxLength = 7 * 6

  const currentMonth = firstAndLastDay(year, month)

  const monthList = decorateMonth(false, month, year, 1, currentMonth.lastDay)

  let output

  const previous = {
    month: ((month - 1) < 0) ? 11 : month - 1,
    year: ((month - 1) < 0) ? year - 1 : year,
  }

  const previousMonth = firstAndLastDay(previous.year, previous.month)

  if (startsOnMonday) {
    let start = 0
    let length = 0

    if (currentMonth.firstDay === 0) {
      start = previousMonth.lastDay - 5
      length = 6
    }

    if (currentMonth.firstDay > 1) {
      start = previousMonth.lastDay - (currentMonth.firstDay - 1)
      length = currentMonth.firstDay - 1
    }

    output = [
      ...decorateMonth(true, previous.month, previous.year, start, length),
      ...monthList,
    ]
  } else {
    if (currentMonth.firstDay > 0) {
      output = [
        ...decorateMonth(true, previous.month, previous.year, previousMonth.lastDay - currentMonth.firstDay, currentMonth.firstDay),
        ...monthList,
      ]
    } else {
      output = [
        ...monthList,
      ]
    }
  }

  if (output.length < maxLength) {
    const next = {
      month: ((month + 1) > 11) ? 0 : (month + 1),
      year: ((month + 1) > 11) ? year + 1 : year,
    }

    output = [
      ...output,
      ...decorateMonth(true, next.month, next.year, 1, maxLength - output.length),
    ]
  }

  return output
}

const multiDateRange = (startsOnMonday, start, end, rows) => {
  const amount = distanceBetween(start, end)

  const startSplit = splitDate(start, true)
  const endSplit = splitDate(end, true)

  const currentMonth = firstAndLastDay(startSplit.year, startSplit.month)

  const previous = {
    month: ((startSplit.month - 1) < 0) ? 11 : startSplit.month - 1,
    year: ((startSplit.month - 1) < 0) ? startSplit.year - 1 : startSplit.year,
  }

  const previousMonth = firstAndLastDay(previous.year, previous.month)

  let output = []

  if (startsOnMonday) {
    let begins = 0
    let length = 0

    if (currentMonth.firstDay === 0) {
      begins = previousMonth.lastDay - 5
      length = 6
    }

    if (currentMonth.firstDay > 1) {
      begins = previousMonth.lastDay - (currentMonth.firstDay - 1)
      length = currentMonth.firstDay - 1
    }

    output = [
      ...decorateMonth(true, previous.month, previous.year, begins, length),
    ]
  } else {
    if (currentMonth.firstDay > 0) {
      output = [
        ...decorateMonth(true, previous.month, previous.year, previousMonth.lastDay - currentMonth.firstDay, currentMonth.firstDay),
      ]
    }
  }

  for (let x = 0; x <= amount; x++) {
    const current = splitDate(addMonths(parse(start), x), true)

    output = [
      ...output,
      ...decorateMonth(false, current.month, current.year, 1, currentMonth.lastDay),
    ]
  }

  const round = Math.max((Math.ceil(output.length / 7) * 7), 7 * rows)

  if (output.length < round) {
    const next = {
      month: ((endSplit.month + 1) > 11) ? 0 : (endSplit.month + 1),
      year: ((endSplit.month + 1) > 11) ? endSplit.year + 1 : endSplit.year,
    }

    output = [
      ...output,
      ...decorateMonth(true, next.month, next.year, 1, round - output.length),
    ]
  }

  return output
}

const paddedMonthWeekBlock = (startsOnMonday, start, end, minRows) => {
  const weeksInRange = differenceInCalendarISOWeeks(endOfMonth(end), startOfMonth(start))

  const output = []

  for (let x = 0; x <= weeksInRange; x++) {
    const startDay = startOfISOWeek(addWeeks(start, x))
    const endDay = endOfISOWeek(addWeeks(start, x))

    const week = eachDay(startDay, endDay).map(generateStamps)

    output.push(week)
  }

  return output
}

const generateMonthBlock = date => {
  const pad = (6 * 7)

  const [
    year,
    month,
  ] = date.split('-')

  const lastDay = new Date(Number(year), Number(month - 1) + 1, 0)

  const firstDay = new Date(Number(year), Number(month - 1), 1)

  let dates = eachDay(startOfISOWeek(firstDay), endOfISOWeek(lastDay)).map(generateStamps)

  if (dates.length < pad) {
    const padStart = addDays(endOfISOWeek(lastDay), 1)
    const padEnd = addDays(endOfISOWeek(lastDay), (pad - dates.length))

    const added = eachDay(padStart, padEnd).map(generateStamps)

    dates = [
      ...dates,
      ...added,
    ]
  }

  return dates
}

const generateStamps = (date) => {
  return {
    date,
    day: getISODay(date),
    stamp: getTime(date),
    ISO: stampToDateISO(date),
  }
}

const paddedMonthBlock = (startsOnMonday, start, end, pad) => {
  const startDate = splitDate(start, true)
  const endDate = splitDate(end, true)

  const boundary = firstAndLastDay(endDate.year, endDate.month)

  let startDay
  let endDay

  if (startsOnMonday) {
    startDay = startOfISOWeek(new Date(startDate.year, startDate.month, 1))
    endDay = endOfISOWeek(new Date(endDate.year, endDate.month, boundary.lastDay))
  } else {
    startDay = startOfWeek(new Date(startDate.year, startDate.month, 1))
    endDay = endOfWeek(new Date(endDate.year, endDate.month, boundary.lastDay))
  }

  let dates = eachDay(startDay, endDay).map(generateStamps)

  if (pad && dates.length < pad) {
    const tempStart = addDays(endDay, 1)
    endDay = addDays(endDay, (pad - dates.length))

    const added = eachDay(tempStart, endDay).map(generateStamps)

    dates = [
      ...dates,
      ...added,
    ]
  }

  return {
    dates,
    startDay,
    endDay,
  }
}

export {
  dateRange,
  multiDateRange,
  paddedMonthBlock,
  paddedMonthWeekBlock,
  generateMonthBlock,
}
