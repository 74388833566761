const NAMESPACE = 'TICKNOVATE'

// E-Commerce flow
export const PRODUCT_VIEW = `${NAMESPACE}:PRODUCT_VIEW`
export const ADD_TO_CART = `${NAMESPACE}:ADD_TO_CART`
export const REMOVE_FROM_CART = `${NAMESPACE}:REMOVE_FROM_CART`
export const PAYMENT_METHOD_SELECTED = `${NAMESPACE}:PAYMENT_METHOD_SELECTED`
export const PURCHASE = `${NAMESPACE}:PURCHASE`

// Misc.
export const TOASTER_MESSAGE = `${NAMESPACE}:TOASTER_MESSAGE`
export const CART_CHANGE_CONFIRMATION_NEEDED = `${NAMESPACE}:CART_CHANGE_CONFIRMATION_NEEDED`
export const KEY_META_UPDATED = `${NAMESPACE}:KEY_META_UPDATED`
export const PAGE_NAVIGATION = `${NAMESPACE}:PAGE_NAVIGATION`
export const WIDGET_LOAD = `${NAMESPACE}:WIDGET_LOAD`
export const WIDGET_TAB_SELECT = `${NAMESPACE}:WIDGET_TAB_SELECT`
export const WIDGET_SUB_TAB_SELECT = `${NAMESPACE}:WIDGET_SUB_TAB_SELECT`
export const WIDGET_TICKET_SELECT = `${NAMESPACE}:WIDGET_TICKET_SELECT`
export const WIDGET_LOCATION_SELECT = `${NAMESPACE}:WIDGET_LOCATION_SELECT`
export const WIDGET_FORM_SUBMIT = `${NAMESPACE}:WIDGET_FORM_SUBMIT`
export const APP_RENDERED = `${NAMESPACE}:APP_RENDERED`
export const SESSION_ENDED = `${NAMESPACE}:SESSION_ENDED`
export const COMPONENT_ERROR_BOUNDARY = `${NAMESPACE}:COMPONENT_ERROR_BOUNDARY`

// Helper functions
export const trigger = (eventName, detail, target = document) => {
  console.debug(`Event "${eventName}" fired.`, detail)
  target.dispatchEvent(
    new window.CustomEvent(eventName, { detail }),
  )
}

export const listen = (eventName, callback, target = document) => {
  const wrappedCallback = event => {
    try {
      callback(event.detail, event)
    } catch (err) {
      console.error(`Error handling event ${eventName}`)
      console.error(err)
    }
  }

  target.addEventListener(eventName, wrappedCallback)

  return () => {
    target.removeEventListener(eventName, wrappedCallback)
  }
}

listen(APP_RENDERED, () => {
  // Global variable for external tools to determine if app is running
  window.TICKNOVATE_REACT_APP_RENDERED = true
})
