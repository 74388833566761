import React, { Fragment } from 'react'

import isToday from 'date-fns/is_today'

import MultiLegTicketPicker from '_/components/navigation/MultiLegTicketPicker'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useBasket from '_/hooks/useBasket'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'

import ActionCard from '_/components/action/ActionCard'

import differenceInDays from 'date-fns/difference_in_days'

import LoadIndicator from '_/templates/LoadIndicator'

import {
  getBookingOptionSchema,
} from '_/models/bookingOptions'

import {
  getTimeNow,
} from '_/libs/date'

const getAvailable = (options, limitToDate = null) => {
  return options
    .filter(option => {
      if (limitToDate === null) return true

      return differenceInDays(option.start_date, limitToDate) > -1
    })
    .filter(option => option.status === 'available')
    .reduce((list, option) => {
      if (!list[option.start_date]) {
        list[option.start_date] = [
          {
            ...option,
          },
        ]
      } else {
        list[option.start_date].push(option)
      }

      return list
    }, {})
}

const TicketMultiLegSelector = ({
  field,
  data,
  current,
  startLimitDate = null,
  update,
  hideCombos = false,
  options,
  layout,
}) => {
  const {
    state: basket,
    editBasket,
  } = useBasket()

  const booking = options[`${field}_time`].data

  const {
    options: mapped,
  } = getBookingOptionSchema(booking, data)

  let combo_cleaned = mapped

  if (hideCombos) {
    combo_cleaned = mapped
      .map(option => {
        return {
          ...option,
          combos: [],
        }
      })
  }

  const available_map = getAvailable(combo_cleaned)

  const filtered = combo_cleaned
    .filter(option => {
      if (startLimitDate === null) return true

      return differenceInDays(option.start_date, startLimitDate) > -1
    })

  const handleChange = (pick_id, combo_pick_id = null) => {
    const item = filtered.find(item => item.pick_id === pick_id)

    const {
      product_id,
      instance_id,
      start_date,
      start_time,
      pricing,
      legs,
    } = item

    const basket_item = {
      pick_id,
      product_id,
      combo_id: combo_pick_id,
      instance_id,
      start_date,
      start_time,
      ticket: current.ticket
        .filter(ticket => ticket.qty > 0),
      location: field === 'inbound' ? current.end_location : current.location,
      end_location: field === 'inbound' ? current.location : current.end_location,
      pricing,
      legs,
    }

    editBasket(basket_item, field)

    if (field === 'inbound' && combo_pick_id === null) {
      const {
        combo_id,
        ...rest
      } = basket.items[field]

      editBasket({
        ...rest,
        combo_id: null,
      }, field)
    }

    update([
      {
        field: `${field}_time`,
        value: start_time,
      },
      {
        field: `${field}_date_selection`,
        value: start_date,
      },
      {
        field: `${field}_date`,
        value: start_date,
      },
      {
        field: `${field}_combo`,
        value: combo_pick_id,
      },
    ])
  }

  const handleSelectionChange = (value) => {
    update(
      [
        {
          field: `${field}_date_selection`,
          value,
        },
        {
          field: `${field}_time_selection`,
          value: isToday(value) ? getTimeNow(0) : '09:00',
        },
      ],
    )
  }

  const handleSetTime = (type, value) => {
    update(
      [
        {
          field: `${field}_time_${type}`,
          value,
        },
      ],
    )
  }

  return (
    <Fragment>
      <MultiLegTicketPicker
        startLimitDate={startLimitDate}
        selected={basket.items[field] ? basket.items[field].pick_id : null}
        comboOnly={field === 'inbound' && basket.items.outbound?.combo_id != null}
        comboPicked={basket.items[field] ? basket.items[field].combo_id : null}
        displayDate={current[`${field}_date_selection`]}
        displayTime={current[`${field}_time_selection`]}
        displayTimeType={current[`${field}_time_type`]}
        options={available_map}
        change={handleChange}
        setDisplayDate={handleSelectionChange}
        setDisplayTime={handleSetTime}
        showTypeOption={layout.showTimeType}
        isFetching={options[`${field}_time`].isFetching}
      />
      {options[`${field}_time`].isFetching && (
        <LoadIndicator />
      )}
    </Fragment>
  )
}

const Holder = ({
  title,
  children,
  noSave,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Column height={'100%'} spread={false}>
            <Heading
              level={3}
              title={title}
              noFlex
              textAlign={'left'}
              width={'100%'}
              margin={'0 0 0.5rem 0'}
              padding={'0.75rem'}
            />
            <Column rawStyle={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'hidden' }} margin={mobile ? null : '1rem 0 0 0'}>
              {children}
            </Column>
            {!noSave && (
              <Row type={'end'} noFlex padding={'0 0 1rem 0'}>
                <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
              </Row>
            )}
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default TicketMultiLegSelector

export {
  Holder,
}
