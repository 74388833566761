import React from 'react'

import styles from './tabnav.scss'

import InlineTextBox from '_/components/layout/InlineTextBox'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const TabNav = ({
  change,
  selected,
  tabs,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            <ul>
              {tabs.map(({ id, label }) => {
                const active = id === selected

                return (
                  <li
                    key={id}
                    onClick={() => change(id)}
                    className={styleCombine(active && styles.active)}
                  >
                    <InlineTextBox strong uppercase>{label}</InlineTextBox>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }}
    />
  )
}

TabNav.styleable = true

export default TabNav
