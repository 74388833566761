import React, { Fragment, useState } from 'react'

import styles from '_/components/layout/ModalColumn/modalcolumn.scss'

import TicketPicker from '_/components/navigation/TicketPicker'

import useBookingOptions from '_/hooks/useBookingOptions'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import {
  generatePaddedBlock,
} from '_/libs/generateMonthBlock'

import {
  stampToDateISO,
} from '_/libs/date'

import useBasket from '_/hooks/useBasket'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'

import ActionCard from '_/components/action/ActionCard'

import differenceInDays from 'date-fns/difference_in_days'

import LoadIndicator from '_/templates/LoadIndicator'

import {
  getBookingSearch,
} from '_/libs/dependantData'

import {
  getBookingOptionSchema,
} from '_/models/bookingOptions'

const getAvailable = (options, limitToDate = null) => {
  return options
    .filter(option => {
      if (limitToDate === null) return true

      return differenceInDays(option.start_date, limitToDate) > -1
    })
    .reduce((list, option) => {
      if (!list[option.start_date]) {
        list[option.start_date] = [
          {
            ...option,
          },
        ]
      } else {
        list[option.start_date].push(option)
      }

      return list
    }, {})
}

const decorateOutboundProduct = (field, basket, meta) => (option) => {
  return {
    ...option,
    outbound_product: basket.items.outbound && field === 'inbound' ? meta.product_map[basket.items.outbound.product_id] : null,
    outbound_product_category: basket.items.outbound && field === 'inbound' ? meta.product_info_map[basket.items.outbound.product_id].category : null,
  }
}

const Ticket = ({
  field,
  data, // TODO: Rename as meta
  current,
  update,
  itemId = null,
  options,
}) => {
  const {
    state: basket,
    editBasket,
  } = useBasket()

  const booking = options[`${field}_time`].data

  const limitToDate = field === 'inbound' ? current.outbound_date : null

  const {
    options: mapped,
  } = getBookingOptionSchema(booking, data)

  const decorate = decorateOutboundProduct(field, basket, data)

  const available_map = getAvailable(mapped.map(decorate))

  const handleChange = (pick_id, combo_pick_id = null) => {
    const item = mapped.find(item => item.pick_id === pick_id)

    const {
      product_id,
      instance_id,
      start_date,
      start_time,
      pricing,
      legs,
    } = item

    const basket_item = {
      ...(itemId !== null && { id: itemId }),
      pick_id,
      product_id,
      combo_id: combo_pick_id,
      instance_id,
      start_date,
      start_time,
      ticket: current.ticket // Remove this and decorate elsewhere or rename
        .filter(ticket => ticket.qty > 0),
      location: field === 'inbound' ? current.end_location : current.location,
      end_location: field === 'inbound' ? current.location : current.end_location,
      pricing, // No place for this here decorate in from cart,
      legs,
    }

    editBasket(basket_item, field)

    if (field === 'inbound' && combo_pick_id === null) {
      const {
        combo_id,
        ...rest
      } = basket.items[field]

      editBasket({
        ...rest,
        combo_id: null,
      }, field)
    }

    update([
      {
        field: `${field}_time`,
        value: start_time,
      },
      {
        field: `${field}_date_selection`,
        value: start_date,
      },
      {
        field: `${field}_date`,
        value: start_date,
      },
      {
        field: `${field}_combo`,
        value: combo_pick_id,
      },
    ])
  }

  const handleSelectionChange = (value) => {
    update(
      [
        {
          field: `${field}_date_selection`,
          value,
        },
      ],
    )
  }

  return (
    <Fragment>
      <TicketPicker
        comboFirst={Boolean(field === 'inbound')}
        selected={current[`${field}_date_selection`]}
        displayDate={current[`${field}_date_selection`]}
        pickedDate={current[`${field}_date`]}
        notBefore={limitToDate}
        picked={basket.items[field] ? basket.items[field].pick_id : null}
        comboPicked={basket.items[field] ? basket.items[field].combo_id : null}
        options={available_map}
        change={handleChange}
        setDisplayDate={handleSelectionChange}
        direction={field}
      />
      {options[`${field}_time`].isFetching && (
        <LoadIndicator />
      )}
    </Fragment>
  )
}

/*
  ONLY USE THIS SELECTOR FOR AMEND PATH
*/
const TicketAmend = ({
  data,
  current,
  startLimitDate = null,
  update,
  itemId = null,
}) => {
  const {
    state: basket,
    editBasket,
  } = useBasket()

  const [
    currentDate,
    setCurrentDate,
  ] = useState(startLimitDate || current.amend_date || stampToDateISO(new Date()))

  const limitToDate = startLimitDate

  console.log('Do we have limit?', limitToDate)

  const {
    isFetching,
    data: booking,
  } = useBookingOptions(
    getBookingSearch({
      field: 'amend',
      data: current,
      dates: generatePaddedBlock(currentDate, 3, limitToDate),
    }),
  )

  const {
    options,
  } = getBookingOptionSchema(booking, data, current.restrict_combo)

  const decorate = decorateOutboundProduct('amend', basket, data)

  const available_map = getAvailable(options.map(decorate))

  const handleChange = (pick_id, combo_pick_id = null) => {
    const item = options.find(item => item.pick_id === pick_id)

    const {
      product_id,
      instance_id,
      start_date,
      start_time,
      pricing,
      legs,
    } = item

    const basket_item = {
      ...(itemId !== null && { id: itemId }),
      pick_id,
      product_id,
      combo_id: combo_pick_id,
      instance_id,
      start_date,
      start_time,
      ticket: current.ticket
        .filter(ticket => ticket.qty > 0),
      location: current.location,
      end_location: current.end_location,
      pricing,
      legs,
    }

    editBasket(basket_item, 'amend')

    update([
      {
        field: 'amend_time',
        value: start_time,
      },
      {
        field: 'amend_date',
        value: start_date,
      },
      {
        field: 'amend_combo',
        value: combo_pick_id,
      },
    ])
  }

  console.log('AVAILABLE', available_map, current.restrict_combo)

  return (
    <Fragment>
      <TicketPicker
        comboFirst={false}
        selected={currentDate}
        displayDate={currentDate}
        pickedDate={current.amend_date}
        notBefore={limitToDate}
        picked={basket.items.amend ? basket.items.amend.pick_id : null}
        comboPicked={basket.items.amend ? basket.items.amend.combo_id : null}
        options={available_map}
        change={handleChange}
        setDisplayDate={setCurrentDate}
        direction={'outbound'}
        products={data.products}
      />
      {isFetching && (
        <LoadIndicator />
      )}
    </Fragment>
  )
}

const desktopLayout = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  width: 'auto',
  zIndex: 1,
}

const mobileLayout = {
  padding: '0.75rem',
  width: '100%',
}

const Holder = ({
  title,
  children,
  noSave,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Column height={'100%'} spread={false}>
            <Heading
              level={3}
              title={title}
              noFlex
              textAlign={'left'}
              width={'auto'}
              margin={'0 0 0.5rem 0'}
              rawStyle={mobile ? mobileLayout : desktopLayout}
            />
            <Column rawStyle={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'hidden' }} margin={mobile ? null : '1rem 0 0 0'}>
              {children}
            </Column>
            {!noSave && (
              <Row type={'end'} noFlex className={styles.cta}>
                <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
              </Row>
            )}
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default Ticket

export {
  Holder,
  TicketAmend,
}
