import React, { Fragment } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'

import styles from './tablecollapsed.scss'

import UpIcon from '../../../icons/KeyboardUp'
import DownIcon from '../../../icons/KeyboardDown'

const expandColumns = columns => columns
  .filter(column => column.display !== false)
  .map(column => {
    return typeof column === 'string'
      ? { key: column, label: column }
      : column
  })

/**
 * A component that accepts all the same props as the <Table /> component,
 * but displays the data as a list, instead of tabular format.
 *
 * This is useful for displaying tables with many columns on mobile, where
 * they would not usually fit due to the reduced screen width.
 */
const TableCollapsed = (props) => {
  const {
    columns: rawColumns,
    data,
    rowClick,
    shadeAlternateRows,
    noLines,
    noRowPadding,
    lightLines,
    styling,
    detailed = null,
    isDetailedView = false,
  } = props

  const columns = expandColumns(rawColumns)

  return (
    <div className={classnames(styles.layout, styling, {
      [styles.shadeAlternateRows]: shadeAlternateRows,
      [styles.noLines]: noLines,
      [styles.noRowPadding]: noRowPadding,
      [styles.lightLines]: lightLines,
    })}>
      {data && data.map((row, i) => {
        const nextRow = data[i + 1]
        const onClick = rowClick
          ? rowClick.bind(this, row)
          : row.onClick
        const isDetailsActive = isDetailedView && row.details && detailed.includes(row.id)
        const sharedRowStyling = classnames({
          [styles.selectedRow]: row.isSelected,
          [styles.beforeSelectedRow]: nextRow && nextRow.isSelected,
          [styles.shadyRow]: shadeAlternateRows && i % 2 === 0,
        })

        return (
          <Fragment key={row.id}>
            <div onClick={onClick} className={classnames(styles.row, row.styling, sharedRowStyling, {
              [styles.noBorderBottom]: isDetailsActive,
              [styles.interactiveRow]: onClick,
            })}>
              {
                isDetailedView && (
                  row.icon
                    ? <div className={styles.rowIconWrapper}>{row.icon}</div>
                    : (
                      <div className={styles.rowIconWrapper}>
                        {row.details && (
                          isDetailsActive
                            ? <UpIcon size={24} fill={window.TICKNOVATE_CONFIG.theme.button_cta} />
                            : <DownIcon size={24} fill={window.TICKNOVATE_CONFIG.theme.button_cta} />
                        )}
                      </div>
                    )
                )
              }
              <div className={styles.rowContent}>
                {columns.map((column) => {
                  let content = null

                  try {
                    content = column.getter
                      ? column.getter(row)
                      : get(row, column.key)
                  } catch (error) {
                    console.error('Failed to get data for table column', error)
                  }

                  return (
                    <div key={column.key}>
                      {column.label && (
                        <span className={styles.label}>
                          {`${column.label}: `}
                        </span>
                      )}
                      {content}
                    </div>
                  )
                })}
              </div>
            </div>
            {isDetailsActive && row.details && (
              <div className={classnames(sharedRowStyling, styles.detailsRow, row.detailsStyling)}>
                {row.details}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default TableCollapsed
