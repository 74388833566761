import { createAction } from '../../../libs/actionHelpers'
import { trigger, SESSION_ENDED } from '../../../libs/events'

export default {
  startSession: createAction('START_SESSION'),
  endSession: () => async (dispatch, getState) => {
    const reduxStore = getState()
    dispatch({ type: 'END_SESSION' })
    // This is convoluted to get around a circular dependency issue.
    trigger(SESSION_ENDED, { reduxStore })
  },
}
