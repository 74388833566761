import React, { Fragment, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  useSelector,
} from 'react-redux'

import ModalRouter from '_/components/navigation/ModalRouter'

import NavFlow from '_/components/navigation/NavFlow'
import StepLayout from '_/templates/StepLayout'
import Summary from '_/templates/Summary'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import ActionCard from '_/components/action/ActionCard'
import { ResponsiveView as View } from '_/components/layout/View'

import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'

import { ActionBasketCart } from '_/components/action/ActionBasket'
import FooterWrapper from '_/components/layout/FooterWrapper'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'
import ActionRadioGroup from '_/components/action/ActionRadioGroup'

import Basket from '_/modals/BasketCart'

import Message from './Message'
import ReducedForm from './ReducedForm'
import BillingForm from './BillingForm'
import Options from './Options'

import useForm from '_/hooks/useForm'
import useOverView from '_/hooks/useOverview'
import useCart from '_/hooks/useCart'

import history from '@ticknovate/frontend-shared/libs/history'

import EmbedTransport from '_/libs/EmbedTransport'

import {
  getSchema,
  valid_spec,
} from './context'
import { usePageView } from '_/hooks/usePageView'

import { makeInvoicePayment } from '@ticknovate/frontend-shared/components/templates/PaymentPage/modules/PaymentSection'

const layout = {
  mobile: [
    'title',
    'message',
    'form',
    'options',
    'submit',
  ],
  tablet: [
    'title .',
    'message .',
    'form options',
    'form submit',
  ],
  desktop: [
    'title .',
    'message .',
    'form options',
    'form submit',
  ],
}

const billing_address = {
  'line_1': '',
  'line_2': '',
  'post_code': '',
  'country': '',
  'city': '',
  'state': '',
}

const setUserForm = (user) => {
  if (user.authenticated) {
    const {
      email = '',
      firstname = '',
      lastname = '',
      telephone = '',
    } = user

    return {
      authenticated: true,
      customer: {
        email,
        firstname,
        lastname,
        telephone,
      },
      meta: {
        email_confirm: email,
      },
    }
  } else {
    return {
      authenticated: false,
    }
  }
}

const getPaymentOptions = (t, allowed) => {
  const options = [
    {
      label: t('details.form-option-card'),
      value: 'credit-card',
      icon: 'payment',
    },
    {
      label: t('details.form-option-email'),
      value: 'pay-later',
      icon: 'email',
    },
  ]

  if (allowed.includes('internal_invoice')) {
    options.push({
      label: t('details.form-option-invoice'),
      value: 'pay-invoice',
      icon: 'invoice',
    })
  }

  if (allowed.includes('internal_pay_on_arrival')) {
    options.push({
      label: t('details.form-option-on-day'),
      value: 'pay-on-day',
      icon: 'schedule',
    })
  }

  return options
}

const Details = ({
  match,
  location,
}) => {
  const { t } = useTranslation()

  const flow = location?.state?.flow || null

  const {
    app,
    details,
    payment,
    env,
  } = window.TICKNOVATE_CONFIG

  const paymentMethodOptions = getPaymentOptions(t, payment.enabledPaymentMethods)

  const [
    paymentMethod,
    setPaymentMethod,
  ] = useState(paymentMethodOptions[0].value)

  const cart = useCart()

  usePageView('CUSTOMER_DETAILS', { cart: cart.data })

  const data = useOverView()

  const user = useSelector(({ user }) => {
    return user
  })

  const [
    billing,
    setBilling,
  ] = useState({
    ...billing_address,
    country: app.defaultBillingCountry || '',
  })

  const handleUpdateBilling = (field, value) => {
    const state = { ...billing }

    if (field === 'country' && value !== 'US') {
      state.state = ''
    }

    state[field] = value

    setBilling(state)
  }

  const { embeddedMode } = env

  const showPayOptions = embeddedMode && EmbedTransport.getApp() === 'reservations' && app.enablePayLater

  let initial = {}

  if (cart.data && cart.data.customer) {
    initial = {
      customer: cart.data.customer,
    }
  }

  const exposeBilling = payment.enabledPaymentMethods.includes('sagepay_card')

  const authenticated = user.authenticated

  const {
    current,
    valid,
    allValid,
    changed,
    reset,
    update,
  } = useForm(getSchema, valid_spec, initial)

  useEffect(() => {
    if (authenticated === true) {
      const details = setUserForm(user)

      reset(details)
    }
  }, [authenticated])

  const change = (field, value) => update([{ field, value }])

  const handleSubmit = async () => {
    const {
      customer,
      delivery_method,
      booking_note,
    } = current

    const payload = {
      ...cart.data,
      customer,
      delivery_method,
    }

    if (app.storeBillingInCart) {
      payload.billing_address = billing
    }

    if (details.showNotes) {
      const items = cart.data.items
        .map((item) => ({
          ...item,
          booking_note,
        }))

      payload.items = items
    }

    const response = await cart.update(payload)

    if (paymentMethod === 'pay-invoice' || paymentMethod === 'pay-on-day') {
      try {
        const paymentsResponse = await makeInvoicePayment(response, paymentMethod === 'pay-invoice' ? 'INVOICE' : 'ARRIVAL')

        history.push('/confirmation', {
          ...paymentsResponse,
        })
      } catch (error) {
        console.error('Payment error', error)
      }
    } else {
      history.push({
        pathname: paymentMethod === 'pay-later' ? '/paylater' : '/payment',
        state: {
          flow,
          billing,
          complete: false,
        },
      })
    }
  }

  const valid_billing = Object.keys(billing)
    .reduce((acc, cur) => {
      if (cur === 'line_2') {
        acc[cur] = true
      } else {
        if (cur === 'state') {
          acc[cur] = billing.country === 'US' ? billing[cur] !== '' : true
        } else {
          acc[cur] = billing[cur] !== ''
        }
      }

      return acc
    }, {})

  let formValid = allValid

  if (exposeBilling) {
    formValid = allValid && Object.keys(valid_billing).every(key => valid_billing[key])
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <ModalRouter
            mobile={mobile}
            routes={{
              basket: {
                desktop: {},
                mobile: {
                  height: 'calc(100vh - 2rem)',
                  bottom: 0,
                },
                view: ({
                  ...props
                }) => {
                  return (
                    <Basket {...props} data={data} />
                  )
                },
              },
            }}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <NavFlow mobile={mobile} match={match} area={'nav'} mount={mount} flow={flow}>
                    {app.basketInMenu && (
                      <ActionBasketCart
                        type={'menu'}
                        change={() => mount('basket')}
                        area={'child'}
                        gridAlign={'stretch'}
                      />
                    )}
                  </NavFlow>
                  <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
                    <MediaQuery media={{ mobile: media.mobile, tablet: media.tablet, desktop: media.desktop }}>
                      {({ mobile, tablet }) => {
                        return (
                          <Area
                            areas={layout[mobile ? 'mobile' : tablet ? 'tablet' : 'desktop']}
                            columns={2}
                            rowgap={1}
                            colgap={mobile ? 0 : 1.5}
                          >
                            <Heading level={2} title={t('details.form-title')} area={'title'} />
                            {app.showLogin && (
                              <Message area={'message'} />
                            )}
                            <div area={'form'}>
                              <ReducedForm
                                mobile={tablet}
                                breakpoint={mobile ? 'mobile' : tablet ? 'tablet' : 'desktop'}
                                current={current}
                                valid={valid}
                                change={change}
                              >
                                {exposeBilling && (
                                  <BillingForm
                                    area={'billing'}
                                    breakpoint={mobile ? 'mobile' : tablet ? 'tablet' : 'desktop'}
                                    current={billing}
                                    valid={valid_billing}
                                    change={handleUpdateBilling}
                                  />
                                )}
                              </ReducedForm>
                            </div>
                            <div area={'options'}>
                              <Options current={current} change={change} />
                              {showPayOptions && (
                                <Fragment>
                                  <Heading level={3} title={'Select payment method'} margin={'1.5rem 0 0.75rem 0'} />
                                  <ActionRadioGroup
                                    field={'paymentMethod'}
                                    value={paymentMethod}
                                    options={paymentMethodOptions}
                                    change={(field, value) => setPaymentMethod(value)}
                                  />
                                </Fragment>
                              )}
                              <Submit
                                valid={formValid}
                                changed={changed}
                                action={handleSubmit}
                              />
                            </div>
                          </Area>
                        )
                      }}
                    </MediaQuery>
                  </View>
                  {!mobile && (
                    <Summary
                      key={'summary'}
                      area={'basket'}
                      title
                    />
                  )}
                  {mobile && !app.basketInMenu && (
                    <FooterWrapper>
                      <ActionBasketCart
                        change={() => mount('basket')}
                      />
                    </FooterWrapper>
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

const Submit = ({
  valid,
  changed,
  action,
}) => {
  const { t } = useTranslation()
  const [clicked, setClicked] = useState(false)
  const handleChange = () => {
    if (valid && !clicked) {
      setClicked(true)
      return action()
    }
  }
  return (
    <Container margin={'1.5rem 0 4rem 0'}>
      {changed && !valid && (
        <TextBox color={'text_error'}>{t('details.submit-error')}</TextBox>
      )}
      <ActionCard
        cta={valid && changed}
        label={t('meta.make-payment')}
        change={handleChange}
        width={'100%'}
        warning={!valid}
        disabled={clicked && changed}
        loading={clicked && changed}
      />
    </Container>
  )
}

export default Details
