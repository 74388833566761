import React from 'react'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'
import Column from '_/components/layout/Column'
import Icon from '_/components/element/Icon'
import InlineTextBox from '_/components/layout/InlineTextBox'

const InfoBlock = ({
  icon = 'travel',
  label = '',
  value,
  titleColor,
  textColor,
  fill = 'card_default_icon',
  ...style
}) => {
  const {
    t,
  } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Area
      areas={[
        'icon title',
        'icon value',
        '. value',
      ]}
      columns={['1.75rem', '1fr']}
      colgap={0}
      rowgap={0.25}
      textAlign={'left'}
      {...style}
    >
      <Icon name={icon} size={24} fill={theme[fill]} area={'icon'} />
      <InlineTextBox color={titleColor} strong block size={1} area={'title'} uppercase>{label}</InlineTextBox>
      {Array.isArray(value) && (
        <Column area={'value'} spread={false} crossAxis={'flex-start'}>
          {value.map((label, index) => {
            return (
              <InlineTextBox color={textColor} block key={index}>{label}</InlineTextBox>
            )
          })}
        </Column>
      )}
      {!Array.isArray(value) && (
        <InlineTextBox color={textColor} block area={'value'}>{value || t('meta.select')}</InlineTextBox>
      )}
    </Area>
  )
}

export default InfoBlock
