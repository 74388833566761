import useAsyncData from './useAsyncData'
import * as cartApi from '../../api/cart'

const useAllowedPaymentMethods = cart => useAsyncData({
  promiseCreator: cartApi.getAllowedPaymentMethods,
  promiseCreatorArgs: [cart],
  promiseCreatorShouldRun: cart != null,
})

export default useAllowedPaymentMethods
