import React, { Fragment, useState, useRef } from 'react'

// import { useTranslation } from 'react-i18next'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import Modal from '_/components/layout/Modal'
import ModalView from '_/components/layout/ModalView'
// import Area from '_/components/layout/Area'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'

import useOverView from '_/hooks/useOverview'

const getPosition = (position) => {
  const {
    left,
    top,
    width,
  } = position

  if (!left || !top || !width) {
    return {
      top: '10rem',
      left: '25vw',
    }
  } else {
    return {
      top: '10rem',
      left: `${Math.round(left + (width / 2)) - (10 * 16)}px`,
    }
  }
}

const ModalInfo = ({
  product = null,
  combo = null,
  render,
}) => {
  const position = useRef({})

  const data = useOverView()

  const [
    active,
    setActive,
  ] = useState(false)

  const [
    mounted,
    setMounted,
  ] = useState(false)

  const end = () => {
    setActive(false)
  }

  const mount = (bounds) => {
    position.current = bounds
    setActive(true)
    setMounted(true)
  }

  const close = () => {
    setMounted(false)
  }

  const {
    left,
    top,
  } = getPosition(position.current)

  return (
    <Fragment>
      {render(mount)}
      {active && (
        <MediaQuery media={media.mobile}>
          {mobile => {
            return (
              <Modal
                mounted={mounted}
                endHandler={end}
                portal={'widget'}
                rawStyle={{
                  left: mobile ? 'calc((100vw - 20rem) / 2)' : left,
                  top,
                  width: '20rem',
                  bottom: 'auto',
                  minHeight: '10rem',
                }}
              >
                <ModalView type={'dialogue'} close={close}>
                  {product !== null && data.product_info_map[product] && (
                    <Details
                      title={data.product_info_map[product].title}
                      description={data.product_info_map[product].description}
                    />
                  )}
                  {combo !== null && data.combo_info_map[combo] && (
                    <Details
                      title={data.combo_info_map[combo].title}
                      description={data.combo_info_map[combo].description}
                    />
                  )}
                </ModalView>
              </Modal>
            )
          }}
        </MediaQuery>
      )}
    </Fragment>
  )
}

const boxStyle = {
  marginBottom: '1rem',
  borderBottom: '1px solid rgb(var(--theme_border_thin))',
}

const Details = ({
  title,
  description,
}) => {
  return (
    <div style={boxStyle}>
      <Heading title={title} level={3} />
      <TextBox margin={'0.25rem 0 1rem 0'}>{description}</TextBox>
    </div>
  )
}

export default ModalInfo
