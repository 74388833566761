import React, { useCallback, useRef } from 'react'
import styles from './tabselect.scss'
import classnames from 'classnames'

import { getWrappedIndex } from '../../../libs/arrayHelpers'

/**
 * A simple tab navigation component.
 *
 * @param {Object} props
 * @param {Function} props.action
 * @param {Object[]} props.tabs
 * @param {*} props.selected - The ID of the selected tab
 * @param {React.Node} [props.title] - An optional title to render to the left of the tabs
 * @param {String} [props.align] - Where to position the tabs: 'left', 'center', or 'right'
 * @param {String} [props.fontClassName]
 * @param {String} [props.styling]
 */
const TabSelect = ({
  action,
  selected,
  styling,
  tabs,
  fontClassName,
  title,
  useIndex,
  align = 'center',
  fadeOutColor = window.TICKNOVATE_CONFIG.theme.background_ui,
  hideSingleTab,
}) => {
  const tabListRef = useRef(null)
  const safeActionByIndex = index => {
    index = getWrappedIndex(tabs, index)

    const tabElements = [...tabListRef.current.querySelectorAll('[role="tab"]')]

    if (tabElements[index]) {
      tabElements[index].focus()
    }

    if (useIndex) {
      action(index)
    } else {
      action(tabs[index].id)
    }
  }
  const selectedIndex = useIndex ? selected : tabs.findIndex(({ id }) => id === selected)
  const handleKeyDown = useCallback(event => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        safeActionByIndex(selectedIndex - 1)
        event.preventDefault()
        break

      case 'ArrowDown':
      case 'ArrowRight':
        safeActionByIndex(selectedIndex + 1)
        event.preventDefault()
        break
    }
  })

  if (hideSingleTab && tabs.length === 1) {
    return null
  }

  return (
    <div className={classnames(styles.wrapper, fontClassName, styling, {
      [styles.alignLeft]: align === 'left',
      [styles.alignRight]: align === 'right',
    })}>
      <div className={styles.fadeOutLeft} style={{ color: fadeOutColor }} />
      <ul
        role='tablist'
        className={styles.nav}
        onKeyDown={handleKeyDown}
        ref={tabListRef}
      >
        {title && (
          <li className={styles.title}>
            {title}
          </li>
        )}
        {tabs.map(({ id: rawId, label, disabled }, i) => {
          const id = useIndex ? i : rawId
          const isActive = id === selected

          return (
            <li
              key={id}
              role='tab'
              aria-selected={isActive}
              tabIndex={isActive ? 0 : -1}
              className={classnames(styles.item, {
                [styles.itemSelected]: isActive,
                [styles.itemDisabled]: disabled,
              })}
              onClick={!disabled && action && action.bind(this, id)}
            >
              {label}
            </li>
          )
        })}
      </ul>
      <div className={styles.fadeOutRight} style={{ color: fadeOutColor }} />
    </div>
  )
}

export default TabSelect
