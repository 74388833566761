import merge from 'lodash/merge'
import colorLightOrDark from '@ticknovate/frontend-shared/libs/colorLightOrDark'
import set from 'lodash/set'
import { padArrayStart } from '@ticknovate/frontend-shared/libs/arrayHelpers'

// const app = 'b2c_next'

const fetch = window.fetch

// These need to be set as they will be called as soon as we even import something
const defaults = {
  env: {
    domain: null,
    app: 'b2c',
    endpoint: null,
    tenant: null,
    market: null,
  },
  app: {},
  icons: {},
  explore: {},
  colors: {},
  theme: {},
  transforms: {},
  payment: {},
  checkout: {},
}

const createStyleElement = (id) => {
  const styleEl = document.createElement('style')

  styleEl.setAttribute('ID', id)

  styleEl.appendChild(document.createTextNode(''))

  document.head.appendChild(styleEl)

  return styleEl.sheet
}

const cssVariables = (theme) => {
  const {
    fontMain,
    fontSecondary,
    fontSectionWeight,
    palette,
    styling,
  } = theme

  const fontSheet = createStyleElement('font')

  const styleSheet = createStyleElement('theme')

  const rules = []
  const config = {}

  if (palette && styling) {
    for (let style in styling) {
      rules.push(`--theme_${style}: ${palette[styling[style]]};`)

      const alpha = styling[style].replace(' ', '').split(',').length === 4

      config[`${style}`] = `${alpha ? 'rgba' : 'rgb'}(${palette[styling[style]]})`
    }

    // Add ui_gradient
    rules.push(`--gradient: linear-gradient(to bottom, rgb(${palette.white}) 0%,rgb(${palette.grey_2}) 100%);`)
  }

  rules.push(`--font_main: '${fontMain}', sans-serif;`)
  rules.push(`--font_secondary: '${fontSecondary}', sans-serif;`)
  rules.push(`--font_section_weight: ${fontSectionWeight};`)

  if (fontMain === fontSecondary) {
    fontSheet.insertRule(`@import url('https://fonts.googleapis.com/css?family=${fontMain.replace(' ', '+')}:300,400,500,700);`, 0)
  } else {
    fontSheet.insertRule(`@import url('https://fonts.googleapis.com/css?family=${fontMain.replace(' ', '+')}:300,400,500,700|${fontSecondary.replace(' ', '+')}:300,400,500,700');`, 0)
  }

  styleSheet.insertRule(`:root { ${rules.join(' ')} }`)

  return config
}

// Placeholder for when we have the backend support to parse the URL
const parseEndpoint = (hostname = document.location.hostname) => {
  const [
    tenantAndGroupStr = '',
    appAndEnvStr = '',
    domain = '',
    tld = '',
  ] = padArrayStart(hostname.split('.'), 4)

  const searchParams = new URLSearchParams(document.location.search)

  const queryKeyMarket = searchParams.get('market')

  // Local dev fallback when not using etc/hosts
  if (hostname.startsWith('localhost') || hostname.startsWith('192.168.')) {
    return {
      endpoint: process.env.REACT_APP_LOCAL_CONFIG_URL_OVERWRITE || 'https://b2c-dev.ticknovate.com/config',
      tenant: process.env.REACT_APP_LOCAL_TENANT || searchParams.get('tenant') || 'forsea',
      market: queryKeyMarket || 'int',
    }
  }

  const tenantAndGroup = tenantAndGroupStr.split('-')
  const tenant = tenantAndGroup.shift()
  const market = queryKeyMarket || tenantAndGroup.join('')
  const endpoint = process.env.REACT_APP_LOCAL_CONFIG_URL_OVERWRITE || `https://${appAndEnvStr}.${domain}.${tld}/config`

  return {
    endpoint,
    tenant: tenant === '' ? process.env.REACT_APP_LOCAL_TENANT : tenant, // Safer for local development
    market: market === '' ? null : market,
  }
}

const getConfig = (endpoint) => fetch(
  endpoint,
  {
    method: 'GET',
    headers: { 'Accept': 'application/json' },
  },
)
  .then(response => {
    return response.json()
  })

const parseConfig = (defaultConfig, remoteConfig, embed = false) => {
  const {
    theme,
    ...remoteConfigRest
  } = remoteConfig

  const colors = cssVariables(theme)

  const localConfigOverwrite = process.env.REACT_APP_LOCAL_CONFIG_OVERWRITE_RESERVATIONS
    ? JSON.parse(process.env.REACT_APP_LOCAL_CONFIG_OVERWRITE_RESERVATIONS)
    : {}

  // If allowEmbed is set in config check to see if the url is calling it
  const embeddedMode = embed === 'true' && remoteConfigRest.app.allowEmbed

  console.log('CONFIG SETTING EMBED', embed, remoteConfigRest.app.allowEmbed, embed && remoteConfigRest.app.allowEmbed)

  return merge(
    {},
    defaultConfig,
    {
      env: {
        embeddedMode,
      },
    },
    remoteConfigRest,
    {
      colors,
      theme: colors,
      transforms: theme.transforms,
      app: {
        scheme: colorLightOrDark(colors.background_nav),
      },
    },
    localConfigOverwrite,
  )
}

class ConfigHolder {
  constructor () {
    this._config = defaults

    for (let key in defaults) {
      Object.defineProperty(this, key, {
        get: function () {
          return this._config[key]
        },
        set: function (new_value) {
          this._config[key] = new_value
        },
      })
    }
  }

  init ({ app, context }) {
    const {
      endpoint,
      tenant,
      market,
    } = parseEndpoint()

    this._config.env = {
      app,
      endpoint,
      tenant,
      market,
      context,
    }

    window.TICKNOVATE_CONFIG = this._config
  }

  loadConfig = async (embed) => {
    const {
      app,
      endpoint,
      tenant,
      market,
    } = this._config.env

    const cache = Date.now()

    try {
      const remoteConfig = await getConfig(`${endpoint}/${tenant}/${app}_next.json?cache=${cache}`)

      this._config = parseConfig(this._config, remoteConfig, embed)

      if (market === null && remoteConfig.app.defaultMarket) {
        this._config.env.market = remoteConfig.app.defaultMarket
      }

      window.TICKNOVATE_CONFIG = this._config

      return this._config
    } catch (error) {
      console.log('No config, setting to default', error)

      try {
        const remoteConfig = await getConfig(`${endpoint}/default/${app}_next.json?cache=${cache}`)

        this._config = parseConfig(this._config, remoteConfig)

        if (market === null && remoteConfig.app.defaultMarket) {
          this._config.env.market = remoteConfig.app.defaultMarket
        }

        window.TICKNOVATE_CONFIG = this._config

        return this._config
      } catch (error) {
        console.log('Config server unavailable', error)

        throw error
      }
    }
  }

  get config () {
    return this._config
  }

  set config (config) {
    this._config = config
  }

  setDeepValue = (field, value) => {
    set(this._config, field, value)

    window.TICKNOVATE_CONFIG = this._config

    console.log('CONFIG RESET', field, value, this._config)
  }
}

const Config = new ConfigHolder()

export default Config
