import React, { Fragment } from 'react'

import isBefore from 'date-fns/is_before'

import Row from '_/components/layout/Row'

import InlineTextBox from '_/components/layout/InlineTextBox'

import { DisplayDate } from '_/components/action/ActionDateChanger'

import Scroll from '_/components/layout/Scroll'

import TicketList from './TicketList'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import {
  generatePaddedBlock,
} from '_/libs/generateMonthBlock'

import {
  stampToDateISO,
} from '_/libs/date'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import Day from './Day'

const MultiLegTicketPicker = ({
  startLimitDate,
  selected,
  comboOnly,
  comboPicked,
  displayDate,
  displayTime,
  displayTimeType,
  options,
  change,
  setDisplayDate,
  setDisplayTime,
  showTypeOption,
  isFetching,
}) => {
  if (startLimitDate === null) startLimitDate = stampToDateISO(new Date())

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        const dates = generatePaddedBlock(displayDate, mobile ? 1 : 2)

        return (
          <Fragment>
            <DisplayDate
              area={'month'}
              date={displayDate || stampToDateISO(new Date())}
              format={'MMM YYYY'}
              rawStyle={{ flexGrow: 0, flexShrink: 0 }}
              margin={'0 0 0.5rem auto'}
              width={mobile ? '100%' : '50%'}
            />
            <Scroll background={'background_ui'} rawStyle={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch' }}>
              <Row spread={false} padding={mobile ? '0' : '0 2rem'} background={'background_module'}>
                {dates.map((date, index) => {
                  const invalidDate = isBefore(date, startLimitDate)

                  const disabled = invalidDate

                  let price = null

                  if (options[date]) {
                    price = options[date]
                      .reduce((best, option) => {
                        const {
                          status = 'available',
                          pricing_potential_best, // Should this be a different value as we aren't taking into account combos?
                        } = option

                        if (status === 'available' && pricing_potential_best.total < best) {
                          best = pricing_potential_best.total
                        } else {
                          best = pricing_potential_best.total
                        }

                        return best
                      }, 0)
                  }

                  return (
                    <Day
                      key={`day_${index}`}
                      date={date}
                      price={price === null ? '' : formatCurrency(price, { currencyCode: options[date][0].currency, small: false })}
                      selected={date === displayDate}
                      disabled={disabled}
                      change={disabled ? null : () => setDisplayDate(date)}
                    />
                  )
                })}
              </Row>
              <TicketList
                selected={selected}
                comboOnly={comboOnly}
                comboPicked={comboPicked}
                change={change}
                options={options[displayDate] || []}
                padding={mobile ? '1rem' : '1rem 2rem'}
                displayTime={displayTime}
                displayTimeType={displayTimeType}
                setDisplayTime={setDisplayTime}
                showTypeOption={showTypeOption}
                isFetching={isFetching}
              />
            </Scroll>
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

const TicketBoundary = ({
  label,
  ...style
}) => {
  return (
    <InlineTextBox textAlign={'center'} uppercase strong padding={'0.5rem 0 0 0'} {...style}>{label}</InlineTextBox>
  )
}

TicketBoundary.styleable = true

export default MultiLegTicketPicker
