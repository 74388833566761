import { useReducer, useCallback, useMemo } from 'react'

import has from 'lodash/has'
import get from 'lodash/get'
// import toPath from 'lodash/toPath'

import cloneDeep from 'lodash/cloneDeep'

import createHookReducer from '_/libs/createHookReducer'

import mapToSchema, { update } from '_/libs/mapToSchema'

const resetValidity = (record, spec, unique, unique_filter) => {
  if (record === null) return {}

  const unique_map = Object.keys(unique)
    .reduce((filter, key) => {
      filter[key] = unique[key].filter(value => value !== unique_filter)

      return filter
    }, {})

  const valid = Object.keys(spec)
    .reduce((validity, key) => {
      if (!has(record, key)) {
        return validity
      }

      const value = get(record, key)

      validity[key] = spec[key](record, value, unique_map)

      return validity
    }, {})

  return valid
}

const initialValid = (record, spec) => {
  return Object.keys(spec)
    .reduce((validity, key) => {
      if (!has(record, key)) {
        return validity
      }

      const value = get(record, key)

      validity[key] = spec[key](record, value)

      return validity
    }, {})
}

const getInitialState = (refreshData, valid_spec, data, unique) => ({
  current: {
    ...refreshData(data),
  }, // Holds the current form values
  valid: initialValid(data, valid_spec, unique),
  allValid: false,
  forceInvalid: false,
  changed: false,
  unique_filter: '',
})

const resetCurrent = (refreshData, valid_spec, unique, onUpdate) => (state, action) => {
  const {
    record,
  } = action

  const current = {
    ...refreshData(record),
  }

  const unique_filter = Object.keys(unique)
    .reduce((filter, key) => {
      if (record[key] && (record[key] !== null || record[key] !== '')) filter = record[key]

      return filter
    }, '')

  const valid = resetValidity(current, valid_spec, unique, unique_filter)
  const allValid = Object.keys(valid).every(key => valid[key] === true)

  onUpdate && onUpdate(current)

  return Object.assign(
    {},
    state,
    {
      current,
      valid,
      allValid,
      changed: true,
      unique_filter,
    },
  )
}

const setValidOverride = (state, action) => {
  const {
    validity,
  } = action

  return Object.assign({}, state, {
    forceInvalid: validity,
  })
}

const updateRecord = (valid_spec, unique, onUpdate) => (state, action) => {
  const {
    changes,
  } = action

  const current = cloneDeep(state.current)

  changes
    .forEach(change => update(current, change.field, change.value))

  const valid = resetValidity(current, valid_spec, unique, state.unique_filter)

  const allValid = Object
    .keys(valid)
    .every(key => valid[key] === true)

  onUpdate && onUpdate(current)

  return Object.assign(
    {},
    state,
    {
      current,
      valid,
      allValid,
      changed: true,
    },
  )
}

const reducer = (refreshData, valid_spec, unique, onUpdate) => createHookReducer({
  RESET_CURRENT: resetCurrent(refreshData, valid_spec, unique, onUpdate),
  OVERRIDE_VALID: setValidOverride,
  UPDATE: updateRecord(valid_spec, unique, onUpdate),
})

const useForm = (getSchema, valid_spec, initial = {}, options = {
  unique: {},
  onUpdate: null,
}) => {
  const valid_model = valid_spec

  const refreshData = mapToSchema(getSchema)

  const [
    formState,
    dispatch,
  ] = useReducer(
    reducer(refreshData, valid_model, options.unique, options.onUpdate),
    getInitialState(refreshData, valid_model, initial, options.unique),
  )

  const setOverride = useCallback((value) => dispatch({
    type: 'OVERRIDE_VALID',
    validity: Boolean(value),
  }), [])

  const reset = useCallback((record) => dispatch({
    type: 'RESET_CURRENT',
    record,
  }), [])

  const update = useCallback((changes) => dispatch({
    type: 'UPDATE',
    changes,
  }), [])

  return useMemo(() => {
    return {
      current: formState.current,
      valid: formState.valid,
      allValid: formState.allValid,
      changed: formState.changed,
      reset,
      update,
      setOverride,
    }
  }, [formState, reset, setOverride, update])
}

export default useForm
