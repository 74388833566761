import React from 'react'

import styles from '_/components/layout/ModalColumn/modalcolumn.scss'

import DateRange from '_/components/navigation/DateRange'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'
import Scroll from '_/components/layout/Scroll'

import ActionCard from '_/components/action/ActionCard'

const DateRangeSelector = ({
  current,
  earliest,
  update,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <DateRange
            from={current.from}
            to={current.to}
            earliest={earliest}
            change={update}
            margin={'0.75rem 0 0 0'}
            small={mobile}
          />
        )
      }}
    </MediaQuery>
  )
}

const desktopLayout = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  pointerEvents: 'none',
  zIndex: 1,
}

const mobileLayout = {
  padding: '0.75rem 0 0 0.75rem',
}

const Holder = ({
  title,
  children,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.tablet}>
      {tablet => {
        return (
          <Column height={'100%'} spread={false}>
            <Heading
              level={3}
              title={title}
              noFlex
              textAlign={'left'}
              width={'100%'}
              rawStyle={tablet ? mobileLayout : desktopLayout}
            />
            <Scroll rawStyle={{ flex: '1 1', alignSelf: 'stretch' }}>
              {children}
            </Scroll>
            <Row type={'end'} noFlex className={styles.cta}>
              <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
            </Row>
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default DateRangeSelector

export {
  Holder,
}
