import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfISOWeek from 'date-fns/start_of_iso_week'
import endOfISOWeek from 'date-fns/end_of_iso_week'
import eachDay from 'date-fns/each_day'
import isSameMonth from 'date-fns/is_same_month'
import isSameDay from 'date-fns/is_same_day'
import addDays from 'date-fns/add_days'
import subDays from 'date-fns/sub_days'
import getISODay from 'date-fns/get_iso_day'
import differenceInDays from 'date-fns/difference_in_days'
import getDaysInMonth from 'date-fns/get_days_in_month'
import { isBefore } from 'date-fns'

import {
  getStamp,
  stampToStrings,
  stampToDateISO,
} from '_/libs/date'

const setGhosted = (dates, month) => {
  return dates.map(date => {
    const ghosted = !isSameMonth(date.stamp, month)

    return {
      ...date,
      ghosted,
    }
  })
}

const generateStamps = (date) => {
  return {
    day: getISODay(date),
    stamp: getStamp(date),
    ISO: stampToDateISO(date),
    string: stampToStrings(date),
  }
}

const generateMonthBlock = (date) => {
  const pad = (6 * 7)

  const [
    year,
    month,
  ] = date.split('-')

  const lastDay = new Date(Number(year), Number(month - 1) + 1, 0)

  const firstDay = new Date(Number(year), Number(month - 1), 1)

  let dates = eachDay(startOfISOWeek(firstDay), endOfISOWeek(lastDay)).map(generateStamps)

  if (dates.length < pad) {
    const padStart = addDays(endOfISOWeek(lastDay), 1)
    const padEnd = addDays(endOfISOWeek(lastDay), (pad - dates.length))

    const added = eachDay(padStart, padEnd).map(generateStamps)

    dates = [
      ...dates,
      ...added,
    ]
  }

  return setGhosted(dates, date)
}

const generateMonthDates = (date) => {
  const today = new Date()

  const [
    year,
    month,
  ] = stampToDateISO(date).split('-')

  const lastDay = new Date(Number(year), Number(month - 1) + 1, 0)

  let firstDay = date

  if (isSameDay(today, lastDay)) {
    return [stampToDateISO(lastDay)]
  }

  if (isBefore(firstDay, today)) {
    firstDay = today
  }

  try {
    const output = eachDay(firstDay, lastDay).map(stampToDateISO)

    return output
  } catch (error) {
    console.log('Date generator', error)

    return [stampToDateISO(lastDay)]
  }
}

const generatePaddedBlock = (date, pad = 3, notBefore = null) => {
  let startPad = pad

  if (date === null) return []

  if (notBefore !== null) {
    const diff = differenceInDays(date, notBefore)

    startPad = diff < 0 || diff > pad ? startPad : diff
  }

  const firstDay = subDays(date, startPad)
  const lastDay = addDays(date, pad)

  try {
    const output = eachDay(firstDay, lastDay).map(stampToDateISO)

    return output
  } catch (error) {
    console.log('Date generator', error)

    return [stampToDateISO(lastDay)]
  }
}

const getDayPosition = (date) => {
  return Number(format(parse(date), 'E')) - 1
}

const getCalendarBounds = (date, pad = 6 * 7) => {
  const [
    year,
    month,
  ] = stampToDateISO(date).split('-')

  const firstDay = new Date(Number(year), Number(month - 1), 1)

  const lastDay = new Date(Number(year), Number(month - 1) + 1, 0)

  const firstPosition = getDayPosition(stampToDateISO(firstDay))

  const daysInMonth = getDaysInMonth(stampToDateISO(date))

  const amount = firstPosition + daysInMonth

  const firstDate = stampToDateISO(subDays(firstDay, firstPosition))
  const lastDate = stampToDateISO(addDays(lastDay, (pad - amount)))

  return {
    firstDate,
    lastDate,
  }
}

const generateCalendarDates = (date) => {
  if (date === null) return []

  const today = new Date()

  let {
    firstDate,
    lastDate,
  } = getCalendarBounds(date)

  if (isBefore(firstDate, today)) {
    firstDate = today
  }

  try {
    const output = eachDay(firstDate, lastDate).map(stampToDateISO)

    return output
  } catch (error) {
    console.log('Date generator', error)

    return [stampToDateISO(lastDate)]
  }
}

export default generateMonthBlock

export {
  generateMonthDates,
  generatePaddedBlock,
  generateCalendarDates,
}
