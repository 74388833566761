import { callApi } from './base'

export const getCommsHistory = salesOrderId =>
  callApi(`salesorder/${salesOrderId}/communications`, {})

export const getMailContent = id =>
  callApi(`communications/${id}/mail`, {
    responseFormat: 'text',
  })

export const getAttachment = (id, attachmentName) =>
  callApi(`communications/${id}/attachments/${attachmentName}`, {
    responseFormat: 'blob',
    asUrl: true,
  })

export const resendConfirmation = ({ orderId, email = null }) =>
  callApi(`orders/${orderId}/communications`, {
    method: 'POST',
    body: email ? { email } : {},
  })
