import React, { useState, useEffect } from 'react'
import { formatCurrency, moneyNumberToString } from '../../../libs/moneyFormatting'

import StatefulContainer from '../../atoms/StatefulContainer'
import Input from '../../atoms/Input'

const getStringValue = (value, currencyCode, fullCurrencyFormat = false) => {
  if (value === '' || value == null) {
    return ''
  }

  if (fullCurrencyFormat) {
    return formatCurrency(value, { currencyCode })
  }

  return moneyNumberToString(value)
}

const CurrencyField = props => {
  const {
    field,
    status,
    value,
    change,
    required,
    requiredText,
    messagePosition,
    shouldDisplayAllErrors,
    subtleValidation,
    currency,
    placeholder = formatCurrency(0, currency),
    allowEmpty,
    noNegative,
    styling,
    disabled,
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [textValue, setTextValue] = useState(getStringValue(value))
  const syncValues = (newValue = textValue) => {
    if (allowEmpty && newValue.trim() === '') {
      change(field, null)
      return
    }

    // We round the value to ensure that "75.32" becomes `7532`, and not `7531.999999999999`.
    // https://jira.digitalistgroup.com/browse/TICKETPLAT-1729
    let numberValue = Math.round(Number(newValue.replace(/[^-\d.]/g, '')) * 100)

    if (noNegative) {
      numberValue = Math.abs(numberValue)
    }

    if (
      numberValue !== value &&
      !Number.isNaN(numberValue)
    ) {
      change(field, numberValue)
    }
  }

  useEffect(() => {
    if (!isFocused) {
      setTextValue(getStringValue(value))
    }
  }, [value, isFocused])

  return (
    <StatefulContainer
      // TODO: These props are mostly for validation. They can come from formValidator's context.
      subtleValidation={subtleValidation}
      shouldDisplayAllErrors={shouldDisplayAllErrors}
      status={status}
      required={required}
      requiredText={requiredText}
      messagePosition={messagePosition}
      styling={styling}
    >
      <div style={{ width: '100%' }}>
        <Input
          data-test-handle={props['data-test-handle']}
          disabled={disabled}
          name={field}
          type={'text'}
          autoComplete={'off'}
          placeholder={placeholder}
          value={isFocused ? textValue : getStringValue(value, true)}
          change={event => {
            setTextValue(event.target.value)
            syncValues(event.target.value)
          }}
          onBlur={() => {
            setIsFocused(false)
            syncValues()
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
        />
      </div>
    </StatefulContainer>
  )
}

export default CurrencyField
