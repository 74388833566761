import once from 'lodash/once'

/**
 * Props to spread over a React component to make it:
 * 1. Focusable via keyboard
 * 2. Clickable via keyboard
 */
export const keyboardClickableProps = {
  role: 'button',
  tabIndex: 0,
  onKeyDown: event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      event.target.click()
    }
  },
}

/**
 * Register event listeners to apply a data attribute to the
 * document body for styling.
 *
 * This allows us to show focus rings for keyboard users, but
 * not for mouse users.
 */
export const registerAccessibilityHelpers = once(() => {
  const setLastTouched = value => {
    document.body.dataset.lastTouchedBy = value
  }

  // Register events
  document.addEventListener('mousedown', () => setLastTouched('mouse'))
  document.addEventListener('keydown', event => {
    if (
      !['INPUT', 'TEXTAREA'].includes(event.target.tagName) ||
      event.key === 'Tab'
    ) {
      setLastTouched('keyboard')
    }
  })

  // Set initial state
  setLastTouched('mouse')
})
