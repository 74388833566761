import React from 'react'

import styles from './optionlisthorizontal.scss'

import Icon from '_/components/element/Icon'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'
import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const OptionListHorizontal = ({
  field,
  value,
  options,
  change,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.list}>
            {options.map((option, index) => {
              const isSelected = option.value === value

              return (
                <div
                  key={option.value}
                  className={styleCombine(styles.row, isSelected && styles.active)}
                  onClick={() => change(option.value)}
                >
                  <Icon name={option.icon} size={56} fill={isSelected ? theme.text_light : theme.card_default_icon} />
                  <span>{option.label}</span>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

OptionListHorizontal.styleable = true

export default OptionListHorizontal
