import React from 'react'

import styles from './link.scss'

const Link = ({
  url,
  children,
}) => (
  <a className={styles.link} href={url}>
    {children}
  </a>
)

export default Link
