import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import { withTranslation } from 'react-i18next'

import styles from './toaster.scss'

import Container from '../../containers/Container'
import Toast from '../../molecules/Toast'
import { trigger, listen, TOASTER_MESSAGE } from '../../../libs/events'

let idCount = 0

/**
 * A wrapper for displaying toast notifications.
 *
 * It is expected that this component will only be used once in an app.
 *
 * Just dump the component on the page and use the exported
 * `triggerToastMessage` function to trigger a message.
 */
class Toaster extends Component {
  state = { messages: [] }

  removeMessage = idToRemove => {
    this.setState(({ messages }) => ({
      messages: messages.filter(({ id }) => id !== idToRemove),
    }))
  }

  handleMessageEvent = ({ status, message, getDismissPromise, uniqueId }) => {
    const id = idCount++
    const close = () => this.removeMessage(id)
    let timeout

    // A `uniqueId` may be passed to only show a maximum of 1 instance of the error message.
    // If a message exists with this `uniqueId`, do nothing.
    if (
      uniqueId != null &&
      this.state.messages.find(message => uniqueId === message.uniqueId)
    ) {
      return
    }

    // Rules for automatic message dismissal
    if (getDismissPromise) {
      setTimeout(() => {
        // `getDismissPromise` is defined as a getter function, so you can pass a promise
        // that may not be defined at the point when this toast was triggered.
        const promise = getDismissPromise()

        if (promise) promise.then(close)
      }, 0)
    } else if (status !== 'error') {
      timeout = setTimeout(
        close,
        window.TICKNOVATE_CONFIG.timeouts.TOAST_MESSAGE_DISPLAY_TIME_MILLISECONDS,
      )
    }

    this.setState(({ messages }) => ({
      messages: [
        {
          id,
          status,
          message,
          close,
          uniqueId,
          onMouseEnter: () => clearTimeout(timeout),
        },
        ...messages,
      ],
    }))
  }

  componentDidMount () {
    this.unListen = listen(TOASTER_MESSAGE, this.handleMessageEvent)
  }

  componentWillUnmount () {
    if (this.unListen) this.unListen()
  }

  render () {
    const { t } = this.props
    const { messages } = this.state

    const content = (
      <Container styling={styles.toaster}>
        {messages.map(({ message, id, ...rest }) => (
          <Toast key={id} {...rest}>
            {t(message)}
          </Toast>
        ))}
      </Container>
    )

    return createPortal(
      content,
      document.getElementById('toast-messages'),
    )
  }
}

export const triggerToastMessage = ({
  message,
  status,
  getDismissPromise,
  uniqueId,
}) => {
  trigger(TOASTER_MESSAGE, {
    message,
    status,
    getDismissPromise,
    uniqueId,
  })
}

export const triggerToastMessageForError = error => {
  if (error.message) {
    triggerToastMessage({
      status: 'error',
      message: error.message,
    })
  } else {
    triggerGenericToastError()
  }
}

export const triggerGenericToastError = () => {
  triggerToastMessage({
    status: 'error',
    message: 'Something went wrong...',
  })
}

export default withTranslation()(Toaster)
