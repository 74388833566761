const LOCAL_USER_LOGIN = 'LOCAL_USER_LOGIN'
const LOCAL_USER_LOGOUT = 'LOCAL_USER_LOGOUT'

/*
  This is purely for the ETE POC demo
  At some point we will have to add some user details when we go full login token
  As it is will always be empty and shouldn't have any impact
  Apologies for simplicity 🤢
*/
const initialState = {
  details: {
    username: '',
    email: '',
  },
  populated: false,
}

const userLocal = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOCAL_USER_LOGIN: {
      const {
        username = '',
        email = '',
      } = action.payload

      return Object.assign({}, state, {
        details: {
          username,
          email,
        },
        populated: true,
      })
    }

    case LOCAL_USER_LOGOUT: {
      return Object.assign({}, state, initialState)
    }

    default: return state
  }
}

export default userLocal
