import WarningIcon from '../icons/Warning'
import ErrorIcon from '../icons/Error'
import CheckCircleIcon from '../icons/CheckCircle'
import CheckIcon from '../icons/Check'
import InfoIcon from '../icons/Info'
import NotificationImportantIcon from '../icons/NotificationImportant'

export const getStatusMeta = status => {
  const statusMeta = {
    error: {
      Icon: ErrorIcon,
      IconAlternative: WarningIcon,
      color: window.TICKNOVATE_CONFIG.theme.text_error,
      colorText: window.TICKNOVATE_CONFIG.theme.text_error,
    },
    warning: {
      Icon: WarningIcon,
      IconAlternative: WarningIcon,
      color: window.TICKNOVATE_CONFIG.theme.text_warning,
      colorText: window.TICKNOVATE_CONFIG.theme.text_warning,
    },
    success: {
      Icon: CheckCircleIcon,
      IconAlternative: CheckIcon,
      color: window.TICKNOVATE_CONFIG.theme.text_success,
      colorText: window.TICKNOVATE_CONFIG.theme.text_success,
    },
    info: {
      Icon: InfoIcon,
      IconAlternative: InfoIcon,
      color: window.TICKNOVATE_CONFIG.theme.text,
      colorText: window.TICKNOVATE_CONFIG.theme.text,
    },
    default: {
      Icon: NotificationImportantIcon,
      IconAlternative: NotificationImportantIcon,
      color: window.TICKNOVATE_CONFIG.theme.text_success,
      colorText: window.TICKNOVATE_CONFIG.theme.text_success,
    },
  }

  return statusMeta[status] || statusMeta.default
}
