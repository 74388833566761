import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../../../../containers/Container'
import Heading from '../../../../atoms/Heading'
import TextBox from '../../../../containers/TextBox'
import Select from '../../../../atoms/Select'
import Button from '../../../../atoms/Button'
import styles from '../../../../organisms/Form-Billing/formbilling.scss'

import * as cartApi from '../../../../../api/cart'

const expiryOptions = [
  {
    value: 'PT2H',
    title: '2 hours',
  },
  {
    value: 'PT24H',
    title: '1 day',
  },
  {
    value: 'PT72H',
    title: '3 days',
  },
]

export default function PayLaterPaymentForm({
  cart,
  redirectToConfirmation,
  termsAndConditions,
}) {
  const [payLaterExpiry, setPayLaterExpiry] = useState(null)
  const { t } = useTranslation()

  return (
    <Container>
      <Heading level={1} title={t('billing.sections.pay-later.title', 'Pay via email')} />

      <TextBox>
        {t(
          'billing.sections.pay-later.expiry-dropdown-description',
          'An email will be sent to the customer to complete the payment in their browser.',
        )}
      </TextBox>

      <Container styling={styles.payLaterDropdownContainer}>
        <Select
          options={expiryOptions}
          placeholder={t('billing.sections.pay-later.expiry-dropdown-placeholder', 'Time to complete payment')}
          value={payLaterExpiry}
          onSelect={(i, expiry) => {
            setPayLaterExpiry(expiry)
          }}
          styling={styles.payLaterSelect}
        />

        <Button
          disabled={!payLaterExpiry}
          content={t('billing.sections.pay-later.send-email', 'Send Email')}
          onClick={async () => {
            const extendedCart = await cartApi.payLater(cart, {
              time_to_pay: payLaterExpiry,
            })

            console.log('Cart expiry extended ', extendedCart)

            redirectToConfirmation(extendedCart)
          }}
          styling={styles.payLaterEmailButton}
        />
      </Container>

      {termsAndConditions && (
        <Container styling={styles.paddingTop}>{termsAndConditions}</Container>
      )}
    </Container>
  )
}
