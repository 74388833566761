import makeSerialID from '_/libs/makeSerialID'

const getPickId = (data) => {
  const serial = {
    type: data.booked_unit.type,
    instance_id: data.booked_unit.instance_id,
    start_date: data.booked_unit.start_date,
    start_time: data.booked_unit.start_time,
    product_id: data.product.id,
  }

  return makeSerialID(serial)
}

export default getPickId
