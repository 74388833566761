import React from 'react'

import styles from './inlinetextbox.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const InlineTextBox = (props) => {
  const {
    children,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <span className={styles.layout} style={styling}>
            {children}
          </span>
        )
      }}
    />
  )
}

InlineTextBox.styleable = true

export default InlineTextBox
