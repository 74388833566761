import findIndex from 'lodash/findIndex'
import pullAt from 'lodash/pullAt'
import chunk from 'lodash/chunk'
import naturalCompare from 'natural-compare-lite'

/**
 * Ensure an item exists in an array.
 *
 * If it doesn't exist, return a new array with the provided
 * `defaultValue` appended. If no `defaultValue` is provided,
 * the `query` itself is used.
 *
 * @example
 * ensureItemExists(
 *   [{ id: 0 }, { id: 1 }],
 *   { id: 2 }
 * )
 * // returns [{ id: 0 }, { id: 1 }, { id: 2 }]
 *
 * ensureItemExists(
 *   [{ id: 0 }, { id: 1 }],
 *   { id: 1 }
 * )
 * // returns [{ id: 0 }, { id: 1 }]
 *
 * @param {Object[]} state
 * @param {*} query
 * @param {*} [defaultValue]
 * @returns {Object[]}
 */
export const ensureItemExists = (state, query, defaultValue = query) => {
  const i = findIndex(state, query)

  if (i === -1) {
    return [...state, defaultValue]
  }

  return state
}

/**
 * Grab everything before `index` and stick it on the end of `array`.
 *
 * @param {Array} array
 * @param {Number} index
 * @returns {Array}
 */
export const moveToEndAtIndex = (array, index) => {
  const start = array.slice(index)
  const end = array.slice(0, index)

  return [...start, ...end]
}

/**
 * Get a copy of an array, minus the value at at the specified `index`.
 *
 * @param {Array} array
 * @param {Number} index
 * @returns {Array}
 */
export const omitIndex = (array, index) => {
  const clone = [...array]
  pullAt(clone, index)
  return clone
}

/**
 * Like `Array.prototype.filter`, but if no items are filtered, the original
 * array is returned.
 *
 * This allows for shallow equality checks to work, instead of returning a new
 * array every time.
 *
 * @param {Array} original
 * @param {Array} callback
 * @returns {Array}
 */
export const immutableFilter = (original, callback) => {
  const filtered = original.filter(callback)

  if (filtered.length === original.length) {
    return original
  }

  return filtered
}

/**
 * "Wraps" the `index` value provided to be constrained to valid
 * keys of `array`.
 *
 * @param {Array} array
 * @param {Number} index
 * @returns {Number}
 */
export const getWrappedIndex = (array, index) => {
  if (index < 0) {
    return array.length - 1
  }

  if (index > (array.length - 1)) {
    return 0
  }

  return index
}

/**
 * Like `array[index]`, except `index` will "wrap".
 *
 * @param {Array} array
 * @param {Number} index
 */
export const atWrappedIndex = (array, index, filterBy) =>
  array[getWrappedIndex(array, index)]

/**
 * Get the next (or previous) item from an array that satisfies the `isValid`
 * criteria.
 *
 * Will loop back to the start / end of the array until a value is found.
 *
 * @param {Array} array
 * @param {Number} currentIndex
 * @param {-1|1} direction
 * @param {() => Boolean} [isValid]
 */
export const getNextOrPrev = (
  array,
  currentIndex,
  direction,
  isValid = () => true,
) => {
  if (direction === 0) return array[currentIndex]

  const stack = [
    ...array.slice(currentIndex + 1, array.length),
    ...array.slice(0, currentIndex),
  ]

  if (direction < 0) {
    stack.reverse()
  }

  while (stack.length) {
    const item = stack.shift()
    if (isValid(item)) return item
  }

  return null
}

/**
 * Lodash's "chunk" function, but in reverse.
 *
 * @param {Array} input
 * @param {Number} size
 */
export const chunkRight = (input, size) =>
  chunk([...input].reverse(), size)
    .map(item => item.reverse())
    .reverse()

/**
 * Pad the start of an `array` with the supplied `value` so that
 * it meets the minimum length requirement, `targetLength`.
 *
 * @param {*[]} array
 * @param {number} targetLength
 * @param {*} value
 */
export const padArrayStart = (array, targetLength, value) => {
  const padLength = targetLength - array.length

  if (padLength <= 0) {
    // Avoid creating new array if unnecessary
    return array
  }

  return [
    ...Array.from({ length: padLength }).fill(value),
    ...array,
  ]
}

/**
 * Sort values in an array based upon a natural sort algorithm, where
 * "foo10" sorts _after_ "foo6".
 */
export const naturalSortBy = (array, getterFn = x => x) => {
  return [...array].sort((a, b) => {
    return naturalCompare(getterFn(a), getterFn(b))
  })
}
