import React from 'react'
import styles from './textArea.scss'

const Area = (props) => {
  const {
    placeholder,
    value,
    change,
    blur,
    styling,
    readOnly,
  } = props

  return (
    <textarea
      className={[styles.area, styling].join(' ')}
      placeholder={placeholder}
      value={value}
      onChange={change}
      onBlur={blur}
      readOnly={readOnly}
    />
  )
}

export default Area
