import React from 'react'
import styles from './input.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const Input = (props) => {
  const {
    name,
    type,
    placeholder,
    value,
    change,
    blur,
    focus,
    disabled = false,
    getRef,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <input
            style={styling}
            className={styles.input}
            id={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={change}
            onBlur={blur}
            onFocus={focus}
            disabled={disabled}
            ref={getRef}
          />
        )
      }}
    />
  )
}

export default Input
