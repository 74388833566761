const USER_LOGIN = 'USER_LOGIN'

const initialState = {
  authenticated: false,
  permissions: [],
}

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_LOGIN: {
      const {
        accessToken,
        refreshToken,
        idTokenData,
        expiry,
        permissions = [],
        tradePartner = null,
        email = null,
        username = null,
        name,
        given_name = '',
        family_name = '',
        phone_number = '',
      } = action.payload

      let firstname = given_name
      let lastname = family_name
      let telephone = phone_number

      if (name) {
        [
          firstname,
          lastname,
        ] = name.split(' ')
      }

      return {
        authenticated: true,
        permissions,
        accessToken,
        data: idTokenData,
        // Refresh tokens are only issued on first login. On refreshes, it is empty.
        refreshToken: refreshToken || state.refreshToken,
        expiryTime: Date.now() + (expiry * 1000) - window.TICKNOVATE_CONFIG.timeouts.USER_REFRESH_TOKEN_EXPIRY_BUFFER_MILLISECONDS,
        tradePartner,
        email,
        username,
        firstname,
        lastname,
        telephone,
      }
    }

    default: return state
  }
}

export default user
