import React from 'react'

const CheckCircleIcon = ({
  size,
  fill,
  fillInner,
}) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24'>
    <path d='M0 0h24v24H0z' fill='none' />

    {/*
      This circle is not in the original SVG from Material Icons website.
      This is custom code to allow us to have a solid tick colour.
    */}
    {fillInner && <circle cx='12' cy='12' r='10' fill={fillInner} />}

    <path fill={fill} d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' />
  </svg>
)

export default CheckCircleIcon
