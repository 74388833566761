import React from 'react'

import InlineTextBox from '_/components/layout/InlineTextBox'
import CardButton from '_/components/element/CardButton'

const ActionCard = ({
  label,
  change,
  anchor = null,
  cta = false,
  warning = false,
  disabled = false,
  ...style
}) => {
  let text_color = null

  if (cta) {
    text_color = 'text_light'
  }

  if (warning) {
    text_color = 'text_error'
  }

  if (disabled) {
    text_color = 'text_disabled'
  }

  return (
    <CardButton
      change={change}
      anchor={anchor}
      minHeight={'2.5rem'}
      cta={cta}
      warning={warning}
      disabled={disabled}
      withBorder={false}
      {...style}
    >
      <InlineTextBox buttonLabel color={text_color}>{label}</InlineTextBox>
    </CardButton>
  )
}

ActionCard.styleable = true

export default ActionCard
