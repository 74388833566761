import React from 'react'
import sortBy from 'lodash/sortBy'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import Button from '_/components/element/Button'
import InlineTextBox from '_/components/layout/InlineTextBox'

import TicketButton from '_/components/element/TicketButton'

import TickIcon from '_/icons/Tick'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

const layoutDesktop = {
  areas: [
    'info tickets/2',
  ],
  columns: 3,
}

const layoutMobile = {
  areas: [
    'info',
    'tickets',
  ],
  columns: 2,
}

const getPickId = (option) => `${option.pick_id}|${option.combo_pick_id}`

const GroupedTicket = ({
  mobile = false,
  selected,
  options,
  change,
  children,
}) => {
  const layout = mobile ? layoutMobile : layoutDesktop

  // const expanded = options.some(option => getPickId(option) === selected)

  return (
    <TicketButton>
      <Area
        {...layout}
        colgap={1}
        rowgap={0.5}
      >
        <Container area={'info'}>
          {children}
        </Container>
        <Area
          area={'tickets'}
          columns={3}
          baseColUnit={1}
          colgap={0.5}
          rowgap={0.5}
          rawStyle={{ gridAutoRows: '1fr', alignSelf: 'stretch' }}
        >
          {sortBy(options, ['price']).map((option, index) => {
            const pickedHash = getPickId(option)

            return (
              <TicketOption
                key={index}
                mobile={mobile}
                {...option}
                price={formatCurrency(option.price, { currencyCode: option.currency, small: false })}
                change={change}
                selected={pickedHash === selected}
                expanded
              />
            )
          })}
        </Area>
      </Area>
    </TicketButton>
  )
}

const ticketLayoutMobile = {
  areas: [
    'price',
    'title',
    'description',
  ],
  columns: 1,
}

const ticketLayoutDesktop = {
  areas: [
    'title price',
    'description',
  ],
  columns: ['1fr auto'],
}

const TicketOption = ({
  mobile,
  selected,
  expanded,
  pick_id,
  combo_pick_id,
  price,
  combo_title,
  title,
  description,
  change,
  dark = false,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  let background = dark ? 'ticket_option_dark_background_default' : 'ticket_option_background_default'
  let text = dark ? 'ticket_option_dark_text_default' : 'ticket_option_text_default'

  if (selected) {
    background = dark ? 'ticket_option_dark_background_selected' : 'ticket_option_background_selected'
    text = dark ? 'ticket_option_dark_text_selected' : 'ticket_option_text_selected'
  }

  const layout = mobile ? ticketLayoutMobile : ticketLayoutDesktop

  return (
    <Button
      change={() => change(pick_id, combo_pick_id)}
      gridAlign={'stretch'}
      textAlign={'left'}
      background={background}
      padding={'1rem 0.5rem 0.5rem 0.5rem'}
      crossAxis={'flex-start'}
    >
      {selected && (
        <Container rawStyle={{ position: 'absolute', top: '0.25rem', right: '0.25rem' }}>
          <TickIcon size={12} fill={theme.text_light} />
        </Container>
      )}
      <Area
        {...layout}
        colgap={0.5}
        rowgap={0}
      >
        <InlineTextBox color={text} strong size={1.1} area={'price'}>
          {price}
        </InlineTextBox>
        <Container area={'title'}>
          {combo_title && (
            <InlineTextBox color={text} strong block>{combo_title}</InlineTextBox>
          )}
          <InlineTextBox color={text}>{title}</InlineTextBox>
        </Container>
        {expanded && (
          <InlineTextBox color={text} margin={'0.25rem 0 0 0'} size={0.75} area={'description'}>{description}</InlineTextBox>
        )}
      </Area>
    </Button>
  )
}

TicketOption.styleable = true

export default GroupedTicket

export {
  TicketOption,
}
