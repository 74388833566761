import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Heading from '_/components/layout/Heading'
import Row from '_/components/layout/Row'
import TextBox from '_/components/layout/TextBox'
import Container from '_/components/layout/Container'
import { Raw as InputText } from '_/components/input/InputText'
import ActionFlat from '_/components/action/ActionFlat'
import Card from './Card'
import Redemption from '_/views/CustomerHistory/Redemption'
import { formatUTCLocale } from '@ticknovate/frontend-shared/libs/dateFormatter'
import SmartphoneGeoIcon from '@ticknovate/frontend-shared/icons/SmartpchoneGeo'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import { resendConfirmation } from '@ticknovate/frontend-shared/api/communications'

import { triggerGenericToastError, triggerToastMessage } from '_/components/notification/Toaster'
import Area from '_/components/layout/Area'

import useOverView from '_/hooks/useOverview'
import useSalesOrder from '_/hooks/useSalesOrder'
import useTickets from '_/hooks/useTickets'

import LoadIndicator from '_/templates/LoadIndicator'

const layout = {
  mobile: {
    ticket: [
      'email',
      'ticket',
      'bookings',
    ],
    noTicket: [
      'email',
      'bookings',
    ],
  },
  desktop: {
    ticket: [
      'ticket/2 email',
      'bookings/2 .',
    ],
    noTicket: [
      'bookings/2 email',
    ],
  },
}

const emailIsEmail = (value) => /\S+@\S+\.\S+/.test(value)

const ResendingEmailForm = ({
  title,
  initialValue,
  id,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const [
    waiting,
    setWaiting,
  ] = useState(false)

  const [
    email,
    change,
  ] = useState(initialValue)

  const submit = async () => {
    try {
      setWaiting(true)
      await resendConfirmation({ orderId: id, email })
      triggerToastMessage({
        status: 'success',
        message: t('customer-history.message-send-success', { email }),
      })
      setWaiting(false)
    } catch (err) {
      console.log('EMAIL SEND ERROR', err)
      triggerGenericToastError()
      setWaiting(false)
    }
  }

  return (
    <Container >
      <Heading
        level={4}
        title={title}
        margin={'0 0 1rem 0'}
      />
      <Row type={'stretch'}>
        <InputText
          field={'resending-email'}
          value={email}
          change={(field, newValue) => change(newValue)}
          placeholder={'E.g. peter@gmail.com'}
        />
        <ActionFlat
          change={submit}
          label={'Send'}
          cta
          disabled={!emailIsEmail(email) && !waiting}
        />
      </Row>
    </Container>
  )
}

const getBookingTitle = (item, desktop) => {
  const {
    product,
    title,
    location,
    end_location,
  } = item

  if (product.type === 'route' && location && end_location) {
    return `${location.title} - ${end_location.title}`
  } else {
    return title
  }
}

const Tickets = ({
  id,
}) => {
  const { t } = useTranslation()

  const meta = useOverView()

  const {
    isLoaded: order_loaded,
    data: order_details,
  } = useSalesOrder(id)

  const {
    isLoaded: tickets_loaded,
    data: order_tickets,
  } = useTickets(id)

  if (!meta.isLoaded || !order_loaded || !tickets_loaded) {
    return (
      <LoadIndicator />
    )
  }

  let count = 0

  const incrementCount = () => {
    count += 1

    return count
  }

  return (
    <MediaQuery media={media.desktop}>
      {desktop => (
        <Area
          areas={desktop ? layout.desktop[order_details.is_cancelled ? 'noTicket' : 'ticket'] : layout.mobile[order_details.is_cancelled ? 'noTicket' : 'ticket']}
          columns={6}
          rowgap={1}
          baseColUnit={1}
          minHeight={'100vh'}
        >
          <Container area={'email'}>
            {desktop && (
              <Heading
                level={2}
                title={t('customer-history.message-send-email')}
                margin={'0 0 1.25rem 0'}
              />
            )}
            <ResendingEmailForm
              id={id}
              title={t('customer-history.message-send-email')}
              initialValue={order_details.customer.email || ''}
            />
          </Container>
          {!order_details.is_cancelled && (
            <Ticket order={order_details} />
          )}

          <Container area={'bookings'}>
            {order_details.items.map((item, index) => {
              const location = item.location ? meta.locations_raw.find(location => location.id === item.location.id) : null

              return (
                <Container key={item.id}>
                  <Heading
                    level={2}
                    title={t(`meta.${item.product.type}`)}
                    margin={'0 0 1.25rem 0'}
                  />
                  {location && !item.is_cancelled && (
                    <Navigate
                      getNumber={incrementCount}
                      title={item.location.title}
                      location={location}
                    />
                  )}
                  <Card getNumber={incrementCount}>
                    <Container width={'100%'}>
                      <Container rawStyle={{ borderBottom: '1px solid rgb(var(--theme_border_thin))' }}>
                        <Heading
                          level={3}
                          title={getBookingTitle(item, desktop)}
                          uppercase
                        />
                        <Heading
                          level={3}
                          title={item.combos.length > 0 ? `${item.combos[0].title}, ${item.title}` : item.title}
                          noMargin
                        />
                        <TextBox strong>
                          {formatUTCLocale(item.booked_unit.start_date, 'D MMMM YYYY')}
                        </TextBox>
                        {item.is_cancelled && (
                          <TextBox color={'text_error'} strong>
                            {t('meta.cancelled').toUpperCase()}
                          </TextBox>
                        )}
                      </Container>

                      <Container margin={'0 0 1rem 0'} rawStyle={{ borderBottom: '1px solid rgb(var(--theme_border_thin))' }}>
                        {item.ticket_types.map(ticket => (
                          <TextBox key={ticket.id}>
                            {`${ticket.qty}x ${ticket.title}`}
                          </TextBox>
                        ))}
                      </Container>

                      <Container>
                        <Redemption
                          tickets={order_tickets.filter(ticket => ticket.booking.id === item.id)}
                          dateFormat={'DD/MM/YYYY'}
                        />
                      </Container>
                    </Container>
                  </Card>
                </Container>
              )
            })}
          </Container>

        </Area>
      )}
    </MediaQuery>
  )
}

const Ticket = ({
  order,
}) => {
  const { t } = useTranslation()

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return (
    <MediaQuery media={media.mobile}>
      {mobile => (
        <Container area={'ticket'}>
          <Heading
            level={2}
            title={t('customer-history.section-title-ticket')}
            margin={'0 0 1.25rem 0'}
          />
          <Card>
            <Row type={'start'} spread={false} rawStyle={mobile ? { display: 'block' } : {}}>
              <img
                src={`${app.gatewayURL}/barcode/${order.id}.svg`}
                alt={order.id}
                style={{ width: '100%' }}
              />
              <Container noFlex width={mobile ? 'auto' : '10rem'} margin={mobile ? '1rem 0 0 0' : '0'}>
                <Heading
                  level={3}
                  title={t('customer-history.scan-title')}
                  margin={'0 0 1.25rem 0'}
                />
                <TextBox>
                  {t('customer-history.message-scan')}
                </TextBox>
              </Container>
            </Row>
          </Card>
        </Container>
      )}
    </MediaQuery>
  )
}

const Navigate = ({
  getNumber,
  title,
  location,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Card getNumber={getNumber}>
      <Row type={'start'}>
        <SmartphoneGeoIcon fill={theme.button} />

        <Container margin={'0 0 0 1rem'}>
          <Heading
            level={3}
            title={`${t('customer-history.message-navigate')} ${title}`}
            noMargin
          />
          {location && (
            <TextBox noMargin>{location.address}</TextBox>
          )}
        </Container>
      </Row>
    </Card>
  )
}

export default Tickets
