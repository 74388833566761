import React from 'react'

import sortBy from 'lodash/sortBy'

import Area from '_/components/layout/Area'

import useOverview from '_/hooks/useOverview'
import useBookingOptions from '_/hooks/useBookingOptions'

import { TicketOption } from '_/components/navigation/GroupedTicket'

import {
  getBookingOptionSchema,
} from '_/models/bookingOptions'

import getPickId from './getPickId'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import { getBookingSearch } from '_/libs/dependantData'

const getSelectableOptions = (options, data, picked) => {
  const sorted = sortBy(options, ['pricing.total']) // Should this filter make sure prices are unique?
    .filter(({ type, start_time }) => type === data.booked_unit.type && start_time === data.booked_unit.start_time)
    .filter(({ legs }) => legs[0].location.id === data.location.id)
    .reduce((list, option) => { // Sometimes duplicates come through. Until BE fixes this can stay
      const exists = list.find(item => item.pick_id === option.pick_id)

      if (!exists) {
        list.push(option)
      }

      return list
    }, [])

  const pickIndex = sorted.findIndex(option => option.pick_id === picked)

  if (pickIndex === -1) return []

  if (pickIndex === sorted.length - 1) {
    return [...sorted].slice((pickIndex - 2) > -1 ? pickIndex - 2 : 0)
  }

  if ((pickIndex - 1) > -1) {
    return [...sorted].slice(pickIndex - 1, pickIndex + 2)
  }

  if (pickIndex === 0) {
    return [...sorted].slice(pickIndex, pickIndex + 3)
  }
}

const TicketChooser = ({
  mobile,
  data,
  change,
  picked,
}) => {
  const meta = useOverview()

  const bookData = {
    type: 'route',
    location: data.location.id,
    end_location: data.end_location.id,
    ticket: data.ticket_types,
  }

  const {
    // isFetching,
    data: booking,
  } = useBookingOptions(
    getBookingSearch({
      data: bookData,
      dates: [data.booked_unit.start_date],
      time: data.booked_unit.start_time,
    }),
  )

  const {
    options,
  } = getBookingOptionSchema(booking, meta)

  const selectable = getSelectableOptions(options, data, picked)

  const current = getPickId(data)

  const selectedOption = selectable.find(option => current === option.pick_id)

  const selectedPrice = selectedOption ? selectedOption.pricing.total : 0

  if (selectable.length < 2) return null

  return (
    <Area
      columns={3}
      baseColUnit={1}
      colgap={0.5}
      rowgap={0.5}
    >
      {selectable.map((option, index) => {
        const {
          currency,
          pick_id,
          pricing,
          product_id,
          product_title,
          status,
        } = option

        const curProduct = meta.product_info_map[product_id]

        const details = {
          currency,
          pick_id,
          title: product_title,
          description: curProduct.short_description || curProduct.description,
          status,
        }

        const selected = current === pick_id

        const diffPrice = selected ? pricing.total : pricing.total - selectedPrice

        const price = `${selected ? '' : diffPrice < 0 ? '- ' : '+ '}${formatCurrency(Math.abs(diffPrice), { currencyCode: option.currency, small: false })}`

        return (
          <TicketOption
            key={index}
            mobile={mobile}
            {...details}
            price={price}
            change={() => change(option)}
            selected={selected}
            expanded
            dark
          />
        )
      })}
    </Area>
  )
}

export default TicketChooser
