import {
  changeLanguage,
  getLanguage,
} from '@ticknovate/frontend-shared/locales'

import debounce from 'lodash/debounce'

import getStore from '_/store'

import sessionActions from '@ticknovate/frontend-shared/reducers/session/localState/sessionActiveActions'

import history from '@ticknovate/frontend-shared/libs/history'

import {
  parseTicketsFromObject,
} from '_/libs/ticketDetailsURLUtils'

import {
  userLogin,
  logout as userLogout,
} from '@ticknovate/frontend-shared/actions/user'

import {
  externalAuth,
  externalMe,
} from '@ticknovate/frontend-shared/api/login'

import Config from '_/libs/Config'

import {
  getInitialMarketLocale,
} from '_/hooks/useOverview'

const setInitialLanguage = async () => {
  const marketLocale = await getInitialMarketLocale()

  changeLanguage(marketLocale)
}

const handlers = {
  'INIT_ACK': {
    action: async ({ app = null, token = null }) => {
      const store = await getStore()

      if (token) {
        await store.dispatch(userLogin({ ...token }))
      }

      await setInitialLanguage() // Reset the initial language after the init for embed mode

      await store.dispatch({
        type: 'EMBED_READY',
        ready: true,
      })

      if (app && app === 'reservations' && Config.env.app === 'reservations') {
        Config.setDeepValue('app.showConfirmLinks', false) // Bit of a hack to hide links if we are a reservations ETE
      }
    },
    set: (config) => ({
      ready: true,
      ...config,
    }),
    hasResponse: false,
  },
  'SET_LOGIN': {
    action: async (value) => {
      try {
        const auth = await externalAuth(value)

        const profile = await externalMe(auth.accessToken)

        const store = await getStore()

        await store.dispatch(userLogin({ ...auth, ...profile }))

        return {
          type: 'LOGIN_STATUS',
          value: {
            status: 'SUCCESS',
            type: 'LOGIN',
            payload: profile,
          },
        }
      } catch (error) {
        console.error(error)

        return {
          type: 'LOGIN_STATUS',
          value: {
            status: 'FAIL',
            type: 'LOGIN',
            payload: error,
          },
        }
      }
    },
    hasResponse: true,
  },
  'SET_LOGOUT': {
    action: async (value) => {
      const store = await getStore()

      await store.dispatch(userLogout())

      return {
        type: 'LOGIN_STATUS',
        value: {
          status: 'SUCCESS',
          type: 'LOGOUT',
          payload: null,
        },
      }
    },
    hasResponse: true,
  },
  'SET_LOCALE': {
    action: (value) => {
      changeLanguage(value)

      return {
        type: 'LOCALE_CHANGE',
        value: getLanguage(),
      }
    },
    hasResponse: true,
  },
  'GET_TICKET': {
    action: async (value) => {
      const {
        type = 'route',
        product = null,
        ...rest
      } = value

      let destination = `/ticket/${type}`

      if (type === 'event' && product !== null) {
        destination = `/ticket/${type}/${product}`
      }

      const store = await getStore()

      await store.dispatch({
        type: 'END_SESSION',
      })

      const search = parseTicketsFromObject(rest)

      history.push({
        pathname: destination,
        search,
      })
    },
    hasResponse: false,
  },
  'AMEND_ITEM': {
    action: async (value) => {
      const {
        id,
        item,
      } = value

      let destination = `/amend/${id}/${item}`

      const store = await getStore()

      await store.dispatch({
        type: 'END_SESSION',
      })

      history.push(destination)
    },
    hasResponse: false,
  },
  'GO_TO': {
    action: (value) => {
      console.log('REDIRECTING TO', value)
      history.replace(value)
    },
    hasResponse: false,
  },
  'END_SESSION': { // May be a bit ham fisted this one but it does clear everything
    action: async (value) => {
      const store = await getStore()

      await store.dispatch(sessionActions.endSession())
      history.replace('/')
    },
    hasResponse: false,
  },
  'RESIZE': {
    action: () => {
      return {
        type: 'RESIZE',
        value: {
          width: document.body.scrollWidth,
          height: document.body.scrollHeight,
        },
      }
    },
    hasResponse: true,
  },
}

class Transport {
  constructor () {
    this.target = null

    this.options = {}

    this.messages_sent = []
    this.messages_recieved = []

    this._config = {
      app: null,
      ready: false,
    }
  }

  init = async (target) => {
    this.target = target

    window.addEventListener('message', (event) => {
      this._config.ready ? this.parseMessage(event) : this.awaitAck(event.data)
    }, false)

    const {
      market,
      tenant,
    } = window.TICKNOVATE_CONFIG.env

    this.send({ // This call is just an acknowledgement of the ETE ready state
      type: 'ETE_LOADED',
      value: {
        market,
        tenant,
      },
    })
  }

  getApp = () => {
    return this._config.app
  }

  send = (message) => {
    const suppressed = !window.TICKNOVATE_CONFIG.env.embeddedMode
    console.log('EmbedTransport.send()', {
      suppressed,
      message,
    })

    if (!suppressed && this.target) {
      this.target.postMessage(message, '*')
      this.messages_sent.push(message)
    }
  }

  setConfig = (value) => {
    this._config = {
      ...this._config,
      ...value,
    }
  }

  // TODO: Find a better way of waiting for the parent to acknowledge transport
  awaitAck = async (data) => {
    const {
      type,
      value,
    } = data

    console.log('B2C: INITIAL MESSAGE RECIEVED', data)

    if (type === 'INIT_ACK') {
      this.setConfig(handlers['INIT_ACK'].set(value)) // Set the config

      const message = await handlers['INIT_ACK'].action(value) // Start the app

      this.send(message)

      this.send(handlers['RESIZE'].action())

      const resize = debounce(() => {
        const message = handlers['RESIZE'].action()
        this.send(message)
      }, 200, { trailing: true })

      window.addEventListener('resize', resize, { passive: true })
    }
  }

  parseMessage = async (event) => {
    const {
      type,
      value,
    } = event.data

    if (!handlers[type]) return

    this.messages_recieved.push(event.data)

    const message = await handlers[type].action(value)

    if (handlers[type].set) this.setConfig(handlers[type].set(value))

    if (handlers[type].hasResponse && message) this.send(message)
  }
}

const EmbedTransport = new Transport()

export default EmbedTransport
