import React from 'react'
import classnames from 'classnames'
import styles from './spinneroverlay.scss'
import IconSpinner from '../../../icons/Spinner'

const SpinnerOverlay = ({ fill = window.TICKNOVATE_CONFIG.theme.background_ui, size = 64, styling }) =>
  <div className={classnames(styles.overlay, styling)}>
    <IconSpinner fill={fill} size={size} />
  </div>

export default SpinnerOverlay
