import React from 'react'

import isSameMonth from 'date-fns/is_same_month'
import isPast from 'date-fns/is_past'
import endOfDay from 'date-fns/end_of_day'

import {
  generateMonthBlock,
} from '@ticknovate/frontend-shared/libs/generateCalendarRange'
import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import DateCell from './DateCell'

import styles from './calendar.scss'

const Calendar = ({
  selected,
  date,
  meta,
  change,
  showPrice,
  small,
}) => {
  const dates = generateMonthBlock(date)

  const handleChange = (value) => () => change(value)

  return (
    <div className={styles.layout}>
      {
        dates.map(item => {
          const active = selected === item.ISO
          const dateIsSameMonth = isSameMonth(item.ISO, date)
          const item_meta = meta?.[item.ISO] ? meta[item.ISO] : null

          /*
            STATUS (This now controls everything)
            available
            selected
            departure
            no_capacity
            before_outbound
            no_service
            empty
            ABILITY
            selectable - [available, selected, departure]
            MODIFIERS
            ghosted

            Styles map as:
            .avalable ['available']
            .selected ['selected']
            .departure ['departure']
            .warning ['no_capacity', 'before_outbound', 'no_service', 'sales_ended', 'sold_out', 'is_past]
          */

          let status = 'no_service' // Default status render no service

          if (item_meta !== null) status = item_meta.status // Presume that status maps from meta [available, sales_ended, sold_out, is_past]

          if (selected === item.ISO) status = 'selected'

          if (item_meta?.isOutbound) status = 'departure'

          if (item_meta === null && dateIsSameMonth && !isPast(endOfDay(item.ISO))) status = 'no_service'

          if (isPast(endOfDay(item.ISO)) || item_meta?.notSelectableBefore) status = 'before_outbound' // Set everything before today to before_outbound

          const selectable = ['available', 'selected', 'departure'].includes(status)

          const ghosted = status !== 'departure' && !dateIsSameMonth

          return (
            <DateCell
              key={item.ISO}
              date={item.ISO}
              status={status}
              label={formatLocal(item.date, 'D')}
              meta={item_meta === null ? {} : item_meta}
              active={active}
              ghosted={ghosted}
              selectable={selectable}
              change={handleChange(item.ISO)}
              showPrice={showPrice}
            />
          )
        })
      }
    </div>
  )
}

export default Calendar
