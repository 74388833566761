import React from 'react'
import classnames from 'classnames'

import styles from './view.scss'

const View = ({ styling, innerStyling, shaded, children, ...rest }) => (
  <div {...rest} className={classnames(styles.layout, styling, {
    [styles.layoutShaded]: shaded,
  })}>
    <div className={classnames(styles.inner, innerStyling)}>
      {children}
    </div>
  </div>
)

export default View
