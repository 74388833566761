import { flatMap, orderBy } from 'lodash'

const getRedemptionStatus = (tickets) => {
  const redemptions = orderBy(
    flatMap(tickets, ticket => ticket.redemptions),
    'scanned_at',
  )

  const status = tickets.some(ticket => ticket.status === 'EXPIRED')
    ? 'EXPIRED'
    : tickets.every(ticket => ticket.status === 'REDEEMED')
      ? 'REDEEMED'
      : redemptions.length > 0
        ? 'PARTIALLY_REDEEMED'
        : 'NOT_REDEEMED'

  return {
    status,
    redemptions,
  }
}

export default getRedemptionStatus
