import getUniqueAreas from './getUniqueAreas'

const getTracks = (areas, columns) => {
  const base_sub_cols = columns / areas.reduce((acc, cur) => {
    if (cur.length > acc) acc = cur.length

    return acc
  }, 0)

  const tracks = {}

  const unique = getUniqueAreas(areas)

  unique.forEach(area => {
    const cols = areas.reduce((acc, row) => {
      const amount = row.reduce((acc, item) => {
        if (item === area) acc += 1
        return acc
      }, 0)

      if (amount > acc) acc = amount

      return acc
    }, 0)

    const rows = areas.reduce((acc, cur) => {
      if (cur.includes(area)) acc += 1
      return acc
    }, 0)

    tracks[area] = {
      cols: cols * base_sub_cols,
      rows,
    }
  })

  return tracks
}

export default getTracks
