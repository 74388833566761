import React from 'react'

import { useTranslation } from 'react-i18next'

import Heading from '_/components/layout/Heading'
import NavWrapper from '_/components/layout/NavWrapper'

import Row from '_/components/layout/Row'
import Column from '_/components/layout/Column'
import InlineTextBox from '_/components/layout/InlineTextBox'

import ActionBack from '_/components/action/ActionBack'

import Area from '_/components/layout/Area'

import history from '@ticknovate/frontend-shared/libs/history'

const desktopStyle = {
  height: '5rem',
  padding: '0 1rem 0 3.75rem',
}

const mobileStyle = {
  height: '6.25rem',
  padding: '0 0 0 1.25rem',
}

const desktopLayout = [
  'title back steps',
]

const mobileLayout = [
  'back title/2 child',
  'steps',
]

const flows = {
  default: [
    {
      title: 'Add ticket',
      trans: 'ticket.flow-title',
      path: ['/ticket/:type?/:product_id?'],
    },
    {
      title: 'Your details',
      trans: 'details.flow-title',
      path: ['/details'],
    },
    {
      title: 'Make payment',
      trans: 'payment.flow-title',
      path: ['/payment', '/paylater'],
    },
  ],
  journey: [
    {
      title: 'Add ticket',
      trans: 'ticket.flow-title',
      path: ['/ticket/:type?/:product_id?'],
    },
    {
      title: 'Journey',
      trans: 'journey.flow-title',
      path: ['/journey'],
    },
    {
      title: 'Your details',
      trans: 'details.flow-title',
      path: ['/details'],
    },
    {
      title: 'Make payment',
      trans: 'payment.flow-title',
      path: ['/payment', '/paylater'],
    },
  ],
}

// const flowSteps = [
//   {
//     title: 'Add ticket',
//     trans: 'ticket.flow-title',
//     path: ['/ticket/:type?/:product_id?']
//   },
//   {
//     title: 'Your details',
//     trans: 'details.flow-title',
//     path: ['/details']
//   },
//   {
//     title: 'Make payment',
//     trans: 'payment.flow-title',
//     path: ['/payment', '/paylater']
//   }
// ]

const NavFlow = ({
  title = null,
  flow = null,
  options = flows.default,
  mobile,
  match,
  back = true,
  children,
}) => {
  const {
    t,
  } = useTranslation()

  if (flow !== null && flows[flow]) options = flows[flow]

  const {
    transforms,
  } = window.TICKNOVATE_CONFIG

  return (
    <NavWrapper rawStyle={mobile ? mobileStyle : desktopStyle}>
      <Area
        areas={mobile ? mobileLayout : desktopLayout}
        columns={mobile ? ['2rem', '1fr', '1fr', '1fr'] : ['1fr', '2rem', 'minmax(min-content, max-content)']}
        rowgap={0}
        colgap={1}
        baseColUnit={1}
      >
        <Heading level={mobile ? '2' : '1'} title={title || t('ticket.section-title')} color={'nav_title'} area={'title'} truncate textTransform={transforms.nav_title || 'normal'} />
        {back && (
          <ActionBack change={() => history.goBack()} area={'back'} color={'nav_icon'} />
        )}
        {mobile && children}
        <Row area={'steps'} spread={false}>
          {options.map((item, index) => {
            const selected = item.path.includes(match.path)

            return (
              <Step
                key={index}
                stage={index + 1}
                title={t(item.trans, item.title)}
                active={selected}
                mobile={mobile}
                margin={mobile ? '0 0.5rem 0 0' : '0 1.5rem 0 0'}
              />
            )
          })}
        </Row>
      </Area>

    </NavWrapper>
  )
}

const Step = ({
  stage,
  title,
  active,
  mobile,
  ...style
}) => {
  const parts = title.split(' ')

  const text_color = active ? 'nav_text_active' : 'nav_text'

  return (
    <Row spread={false} noFlex {...style}>
      <InlineTextBox color={text_color} strong size={2} margin={'0 0.25rem 0 0'}>{stage}</InlineTextBox>
      <Column noFlex type={'center'} spread={false} crossAxis={'flex-start'}>
        {parts.map((part, index) => {
          return (
            <InlineTextBox key={index} color={text_color} uppercase size={0.75}>{part}</InlineTextBox>
          )
        })}
      </Column>
    </Row>
  )
}

Step.styleable = true

export default NavFlow
