import merge from 'lodash/merge'
import { createSelector } from 'reselect'
import getRoutes from '_/config/routes'
import { channels } from '../libs/constants'
import Cart from '../libs/Cart'
import { getTradePartner, getTradePartnerId } from './session/localState/sessionUser'

export const getSessionUser = state =>
  state.session.localState.sessionUser

export const getSessionId = state =>
  state.session.localState.sessionId

export const getSessionTradePartner = createSelector(
  getSessionUser,
  getTradePartner,
)

export const getSessionTradePartnerId = createSelector(
  getSessionUser,
  getTradePartnerId,
)

export const getExploreForm = state =>
  state.session.localState.explore

export const getServiceGroupId = createSelector(
  getExploreForm,
  explore => explore.serviceGroupId,
)

export const getChannel = state => {
  // When editing a cart, use that cart's original channel.
  // This allows the Reservations Portal to edit an order
  // made via any channel, and maintain that channel's
  // pricing, cancellation fees, etc.
  const cart = getCart(state)
  if (cart && cart.channel != null) {
    return cart.channel
  }

  // If a trade partner is selected, use the trade partner channel.
  if (getSessionTradePartner(state)) {
    return channels.TRADE_PARTNER
  }

  return window.TICKNOVATE_CONFIG.app.defaultChannel
}

const getRawCart = state =>
  state.session.cart

const getLocalCartChanges = state =>
  state.session.localState.localCartChanges

export const getCart = createSelector(
  getRawCart,
  cart => new Cart(cart),
)

export const getDraftCart = createSelector(
  getRawCart,
  getLocalCartChanges,
  (cart, localCartChanges) => {
    const {
      quoteChanges,
      quoteItemByIdChanges,
    } = localCartChanges

    const combinedCart = merge({}, cart, quoteChanges)

    combinedCart.items = combinedCart.items.map(item => {
      const localItemChanges = quoteItemByIdChanges[item.item_id]

      if (!localCartChanges) {
        return item
      }

      return merge({}, item, localItemChanges)
    })

    return new Cart(combinedCart)
  },
)

export const getTradePartnerPortalUser = createSelector(
  [getSessionUser],
  sessionUser => (sessionUser && sessionUser.tradePartnerPortalUserUser)
    ? sessionUser
    : null,
)

export const selectGlobalSettings = ({ globalSettings }) => globalSettings

export const selectIsConciergeView = createSelector(
  [selectGlobalSettings],
  (settings = {}) => !!settings.isConciergeView,
)

export const selectIsSuperUser = createSelector(
  [getTradePartnerPortalUser],
  tradePartnerPortalUser => (
    window.TICKNOVATE_CONFIG.meta.portalName === 'reservations' && !tradePartnerPortalUser
  ),
)

export const selectUserPermissions = state => state.user
  ? state.user.permissions
  : []

export const selectUserCapabilities = createSelector(
  [selectUserPermissions],
  permissions => ({
    // Creating a booking is not something restricted - customers
    // can do this on the B2C portal. Restricting of creating bookings
    // in reservations is just cosmetic; there is no direct "order:create"
    // permission to map to.
    createBooking: (
      // Anyone can create a booking. However, we restrict this on the frontend
      // for reservations users with only "Viewer" type roles. The backend does
      // not enforce this, though the backend does lock down the harmful features,
      // so the user would only have the powers equivalent to a B2C user _if_ they
      // used the API directly.
      window.TICKNOVATE_CONFIG.meta.portalName !== 'reservations' ||
      // Check a permission related to basic order modification.
      permissions.includes('order:update')
    ),
    useVoucher: permissions.includes('voucher:use'),
    sendOrderEmail: permissions.includes('order:send_email'),
    overrideFees: permissions.includes('cart:override_fees'),
  }),
)

export const selectRoutes = createSelector(
  [getTradePartnerPortalUser, selectUserCapabilities],
  (tradePartnerPortalUser, userCapabilities) => getRoutes({
    tradePartnerPortalUser,
    userCapabilities,
  }),
)
