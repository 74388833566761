import fromPairs from 'lodash/fromPairs'
import keyBy from 'lodash/keyBy'
import * as duration from 'duration-fns'
import history from './history'
import global from './global'

export const TICKET_URL_PARAM = 'ticket'
const TICKET_PROPERTY_DELIMITER = '_'
const TICKET_DELIMITER = '__'
const COLLECTION_PROPERTY_DELIMITER = ':'
const COLLECTION_ITEM_DELIMITER = ','

/**
 * Create methods for converting a collection to and from a string.
 *
 * @example
 * const transformer = collectionTransformer('foo', Number, 'bar', Number)
 * const collection = [
 *   { foo: 1, bar: 22 },
 *   { foo: 2, bar: 40 }
 * ]
 * transformer.stringify(collection) // returns '1:22,2:40'
 * transformer.parse('1:22,2:40') // returns a new array, identical to `collection`
 *
 * @param {String} keyProp
 * @param {Function} castKey
 * @param {String} valueProp
 * @param {Function} castValue
 * @returns {{ stringify: Function, parse: Function }}
 */
const collectionTransformer = (keyProp, castKey, valueProp, castValue) => ({
  parse: stringified => stringified
    .split(COLLECTION_ITEM_DELIMITER)
    .map(pair => {
      const [key, value] = pair.split(COLLECTION_PROPERTY_DELIMITER)

      return {
        [keyProp]: castKey(key),
        [valueProp]: castValue(value),
      }
    }),

  stringify: collection => collection
    .map(item => `${item[keyProp]}${COLLECTION_PROPERTY_DELIMITER}${item[valueProp]}`)
    .join(COLLECTION_ITEM_DELIMITER),
})

const patronTransformer = collectionTransformer(
  'patron_type_id', Number,
  'qty', Number,
)

const seatingTransformer = collectionTransformer(
  'seating_type_id', Number,
  'qty', Number,
)

const booleanTransformer = {
  parse: value => {
    return !!Number(value)
  },
  stringify: value => {
    return value ? '1' : '0'
  },
}

const dateTransformer = {
  stringify: dateString => {
    const [date, time = ''] = dateString.split('T')
    const reducedDate = date.replace(/-/g, '')
    const reducedTime = time
      .replace(/[:.Z]/g, '')
      .replace(/0+$/, '')

    return `${reducedDate}${reducedTime}`
  },
  parse: dateString => {
    dateString = dateString.toUpperCase()

    // Naughty naughty.
    // This parsing is impure due to the side-effect `new Date()` call.
    // Expanding durations here is simplest for our current use cases.
    if (dateString.startsWith('P')) {
      return duration.apply(
        new Date(),
        duration.parse(dateString),
      ).toISOString()
    }

    const expandedDate = [
      dateString.substr(0, 4),
      dateString.substr(4, 2),
      dateString.substr(6, 2),
    ].join('-')
    const expandedTime = [
      dateString.substr(8, 2).padEnd(2, '0'),
      dateString.substr(10, 2).padEnd(2, '0'),
      dateString.substr(12, 2).padEnd(2, '0') + '.' + dateString.substr(14, 3).padEnd(3, '0'),
    ].join(':')

    return `${expandedDate}T${expandedTime}Z`
  },
  postProcessParams: ({ params, rawValue }) => {
    if (rawValue.toUpperCase().startsWith('P')) {
      return {
        ...params,
        find_closest_time: true,
      }
    }
    return params
  },
}

export const PARAMS_ARRAY = [
  // Careful when updating these "urlKey" values. You may break URLs that are
  // already existing in the wild.
  { urlKey: 's', fullKey: 'service_id', parse: Number },
  { urlKey: 'a', fullKey: 'ancillary_ticket_id', parse: Number },
  { urlKey: 'p', fullKey: 'pass_id', parse: Number },
  { urlKey: 'c', fullKey: 'combo_id', parse: Number },
  { urlKey: 'l', fullKey: 'location', parse: Number },
  { urlKey: 'm', fullKey: 'end_location', parse: Number },
  { urlKey: 't', fullKey: 'start_time', ...dateTransformer },
  { urlKey: 'd', fullKey: 'scroll_to', parse: String },
  { urlKey: 'e', fullKey: 'edit_item_id', parse: String },
  { urlKey: 'b', fullKey: 'patron', ...patronTransformer },
  { urlKey: 'u', fullKey: 'seating', ...seatingTransformer },
  { urlKey: 'f', fullKey: 'find_closest_time', ...booleanTransformer },
]
const PARAMS_BY_FULL_KEY = keyBy(PARAMS_ARRAY, 'fullKey')
const PARAMS_BY_URL_KEY = keyBy(PARAMS_ARRAY, 'urlKey')

/**
 * Stringify and compress ticket query objects.
 *
 * See tests for {@link appendTicketsToURL} for usage.
 *
 * @param {Object[]} tickets
 * @returns {String}
 */
const stringifyTickets = tickets => {
  return tickets
    .map(ticket => Object
      .keys(ticket)
      .filter(key => PARAMS_BY_FULL_KEY[key] != null && ticket[key] != null)
      .map(key => {
        const { urlKey, stringify = String } = PARAMS_BY_FULL_KEY[key]
        const value = stringify(ticket[key])

        if (value.includes(TICKET_PROPERTY_DELIMITER) || value.includes(TICKET_DELIMITER)) {
          throw new Error(`Value "${value}" cannot be stringified as it contains a delimiter value.`)
        }

        return `${urlKey}${value}`
      })
      .join(TICKET_PROPERTY_DELIMITER),
    )
    .join(TICKET_DELIMITER)
}

/**
 * Parse and expand a ticket string.
 *
 * See tests for {@link parseTicketsFromURL} for usage.
 *
 * @param {String} ticketsString
 * @returns {Object[]}
 */
const parseTickets = ticketsString => {
  return ticketsString
    .split(TICKET_DELIMITER)
    .map(ticketString => {
      // Parse values from URL format
      const params = ticketString
        .split(TICKET_PROPERTY_DELIMITER)
        .map(keyValue => {
          const urlKey = keyValue.substr(0, 1)
          const urlValue = keyValue.substr(1, keyValue.length - 1)
          const config = PARAMS_BY_URL_KEY[urlKey]

          if (!config) return null

          const { fullKey, parse } = config

          return {
            key: fullKey,
            value: parse(urlValue),
            rawValue: urlValue,
            postProcessParams: config.postProcessParams,
          }
        })
        .filter(entry => entry != null)

      // Make plain object with parsed values
      let paramsObj = fromPairs(params.map(
        ({ key, value }) => [key, value],
      ))

      // Do final processing for the value of one param may cause
      // another to change.
      for (const { postProcessParams, ...rest } of params) {
        if (postProcessParams) {
          paramsObj = postProcessParams({
            ...rest,
            params: paramsObj,
          })
        }
      }

      return paramsObj
    })
}

/**
 * Like `parseTickets`, but for parsing the value from a URL search string.
 *
 * See tests for usage.
 *
 * @param {String} search
 * @returns {Object}
 */
export const parseTicketsFromURL = search => {
  const searchParams = new URLSearchParams(search)

  if (!searchParams.has(TICKET_URL_PARAM)) {
    return []
  }

  const value = searchParams.get(TICKET_URL_PARAM)

  if (value.trim() === '') return []

  return parseTickets(value)
}

/**
 * Parse ticket details to use as the starting point for the ticket details form
 * state.
 *
 * The data shape will match the shape of actual items in the cart on the
 * backend.
 *
 * @param {String} search
 * @param {Object} cart
 * @returns {Object[]}
 */
export const parseTicketQueriesFromURL = (search, cart) => {
  return parseTicketsFromURL(search)
    .map(ticket => {
      // Editing existing item in the cart
      // ------------------------------
      if (ticket.edit_item_id != null) {
        try {
          const cartItem = cart.items.find(item => {
            return item.item_id === ticket.edit_item_id
          })

          // This item will have an "item_id". This is how to tell it apart from
          // an item not yet added to the cart.
          return {
            ...cartItem.toNormalizedObject(),
            scroll_to: ticket.scroll_to,
          }
        } catch (err) {
          console.error(err)
          // The cart may not be populated, or have a matching item.
          // It's OK to just show no form in this case.
          return null
        }
      }

      // Adding a new item to the cart
      // ------------------------------
      return ticket
    })
    .filter(ticket => ticket != null)
}

/**
 * Like `stringifyTickets`, but will append the new value to an
 * existing URL search string.
 *
 * See tests for usage.
 *
 * @param {String} search
 * @param {Object[]} tickets
 * @returns {String}
 */
export const appendTicketsToURL = (search = '', tickets = []) => {
  const searchParams = new URLSearchParams(search)

  if (tickets.length === 0) {
    searchParams.delete(TICKET_URL_PARAM)
  } else {
    searchParams.set(TICKET_URL_PARAM, stringifyTickets(tickets))
  }

  return searchParams.toString()
}

/**
 * Append the ticket query to the current page URL, which in turn will
 * trigger the ticket details form to open.
 *
 * @param {Object} query
 */
export const openTicketForm = ({
  urlQuery,
  internalQuery = null,
  pathname = window.location.pathname,
}) => {
  const tickets = parseTicketsFromURL(history.location.search).concat(urlQuery)
  const search = appendTicketsToURL(history.location.search, tickets)

  global.INTERNAL_PRODUCT_QUERY = internalQuery

  console.log('Opening tickets', urlQuery)

  history.push({
    pathname,
    search,
  })
}
