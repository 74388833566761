import React, { Fragment } from 'react'
import addDays from 'date-fns/add_days'
import isBefore from 'date-fns/is_before'

import Area from '_/components/layout/Area'

import InlineTextBox from '_/components/layout/InlineTextBox'

import ActionDateChanger from '_/components/action/ActionDateChanger'

import { Combo, Normal } from './DateTile'

import Scroll from '_/components/layout/Scroll'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import {
  generatePaddedBlock,
} from '_/libs/generateMonthBlock'

import {
  stampToDateISO,
} from '_/libs/date'

import {
  sanitizeDateString,
} from '@ticknovate/frontend-shared/libs/dateFormatter'

import Day from './Day'

const getDateShift = (date, pad, index) => {
  console.log('Calculate date shift', date, pad, index, stampToDateISO(addDays(sanitizeDateString(date), ((pad * 2) + 1) * index)))
  return stampToDateISO(addDays(sanitizeDateString(date), ((pad * 2) + 1) * index))
}

const generateAreas = (cols, options) => {
  const areas = []

  const hasOpen = Object.keys(options.open).length > 0
  const hasDates = Object.keys(options.date).length > 0
  const hasTimes = Object.keys(options.time).length > 0

  areas.push(Array.from({ length: cols }, (element, index) => `day_${index}`).join(' '))

  if (hasOpen) {
    areas.push('open_indicator')

    if (Object.keys(options.open).length === 1) {
      areas.push(Array.from({ length: cols }, () => 'open_0').join(' '))
    } else {
      areas.push(Array.from({ length: cols }, (element, index) => `open_${index}`).join(' '))
    }
  }

  if (hasDates) {
    areas.push('date_indicator')
    areas.push(Array.from({ length: cols }, (element, index) => `date_${index}`).join(' '))
  }

  if (hasTimes) {
    areas.push('time_indicator')
    areas.push(Array.from({ length: cols }, (element, index) => `time_${index}`).join(' '))
  }

  return areas
}

const showOpen = false

const TicketPicker = ({
  comboFirst,
  displayDate,
  pickedDate,
  picked,
  comboPicked,
  options,
  change,
  setDisplayDate,
  direction,
  products,
}) => {
  const today = stampToDateISO(new Date())

  const changeDate = (pad) => (next) => {
    setDisplayDate(getDateShift(displayDate, pad, next ? 1 : -1))
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        const dates = generatePaddedBlock(displayDate, mobile ? 1 : 2)

        const stripes = {
          minHeight: '100%',
          background: `
            repeating-linear-gradient(
              to right,
              RGBA(var(--theme_background_ui), 0.75),
              RGBA(var(--theme_background_ui), 0.75) ${mobile ? 33.33 : 20}%,
              RGBA(var(--theme_background_ui), 0.5) ${mobile ? 33.33 : 20}%,
              RGBA(var(--theme_background_ui), 0.5) ${mobile ? 66.66 : 40}%
            )
          `,
        }

        const validDates = dates.filter(date => !isBefore(date, today))

        // Filter options open, date, then timed
        const filtered = Object.keys(options)
          .reduce((acc, date) => {
            const current = validDates.includes(date) ? options[date] : []

            current
              .forEach(option => {
                if (option.type === 'open') {
                  acc.open[date] ? acc.open[date].push(option) : acc.open[date] = [option]
                }

                if (option.type === 'time') {
                  acc.time[date] ? acc.time[date].push(option) : acc.time[date] = [option]
                }

                if (option.type === 'date') {
                  acc.date[date] ? acc.date[date].push(option) : acc.date[date] = [option]
                }
              })

            return acc
          }, { open: {}, time: {}, date: {} })

        const hasOpenTickets = Object.keys(filtered.open).length > 0
        const hasDateTickets = Object.keys(filtered.date).length > 0
        const hasTimedTickets = Object.keys(filtered.time).length > 0

        return (
          <Fragment>
            <ActionDateChanger
              area={'month'}
              date={displayDate || stampToDateISO(new Date())}
              format={'MMM YYYY'}
              change={changeDate(mobile ? 1 : 2)}
              rawStyle={{ flexGrow: 0, flexShrink: 0 }}
              margin={'0 0 0.5rem auto'}
              width={mobile ? '100%' : '50%'}
            />
            <Scroll rawStyle={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch' }}>
              <Area
                areas={generateAreas(mobile ? 3 : 5, filtered)}
                columns={mobile ? 3 : 5}
                colgap={0}
                rowgap={0}
                rawStyle={stripes}
              >
                {dates.map((date, index) => { // Only need the date block here
                  const invalidDate = isBefore(date, today)
                  const disabled = invalidDate || !options[date] || options[date].length === 0

                  return (
                    <Day
                      key={`day_${index}`}
                      area={`day_${index}`}
                      date={date}
                      selected={date === pickedDate}
                      disabled={disabled}
                    />
                  )
                })}
                {showOpen && hasOpenTickets && (
                  <Fragment>
                    {(hasDateTickets || hasTimedTickets) && (
                      <TicketBoundary area={'open_indicator'} label={'Open tickets'} />
                    )}
                    {dates.map((date, index) => {
                      const invalidDate = isBefore(date, today)

                      const TicketList = comboFirst ? Combo : Normal

                      return (
                        <TicketList
                          key={date}
                          area={`open_${index}`}
                          tickets={!invalidDate ? filtered.open[date] || [] : []}
                          picked={picked}
                          change={change}
                          comboPicked={comboPicked}
                          direction={direction}
                          products={products}
                        />
                      )
                    })}
                  </Fragment>
                )}
                {hasDateTickets && (
                  <Fragment>
                    {(hasTimedTickets || (showOpen && hasOpenTickets)) && (
                      <TicketBoundary area={'date_indicator'} label={'Day tickets'} />
                    )}
                    {dates.map((date, index) => {
                      const invalidDate = isBefore(date, today)

                      const TicketList = comboFirst ? Combo : Normal

                      return (
                        <TicketList
                          key={`date_${date}_${index}`}
                          area={`date_${index}`}
                          tickets={!invalidDate ? filtered.date[date] || [] : []}
                          picked={picked}
                          change={change}
                          comboPicked={comboPicked}
                          direction={direction}
                          products={products}
                        />
                      )
                    })}
                  </Fragment>
                )}
                {hasTimedTickets && (
                  <Fragment>
                    {(hasDateTickets || (showOpen && hasOpenTickets)) && (
                      <TicketBoundary area={'time_indicator'} label={'Scheduled'} />
                    )}
                    {dates.map((date, index) => {
                      const invalidDate = isBefore(date, today)

                      const TicketList = comboFirst ? Combo : Normal

                      return (
                        <TicketList
                          key={`time_${date}_${index}`}
                          area={`time_${index}`}
                          tickets={!invalidDate ? filtered.time[date] || [] : []}
                          picked={picked}
                          change={change}
                          comboPicked={comboPicked}
                          direction={direction}
                          products={products}
                          showTime
                        />
                      )
                    })}
                  </Fragment>
                )}
              </Area>
            </Scroll>
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

const TicketBoundary = ({
  label,
  ...style
}) => {
  return (
    <InlineTextBox textAlign={'center'} uppercase strong padding={'0.5rem 0 0 0'} {...style}>{label}</InlineTextBox>
  )
}

TicketBoundary.styleable = true

export default TicketPicker
