import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import styles from './summary.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Button from '_/components/element/Button'
import InputText from '_/components/input/InputText'
import ActionDiscard from '_/components/action/ActionDiscard'

import { formatUTC } from '_/libs/dateFormatter'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import useBasket from '_/hooks/useBasket'
import useCart from '_/hooks/useCart'

import useOverview from '_/hooks/useOverview'

import LoadIndicator from '_/templates/LoadIndicator'

// {
//   'market': {
//     'id': 'se'
//   },
//   'items': [
//     {
//       'product': {
//         'id': 'JO-01'
//       },
//       'booked_unit': {
//         'instance_id': 'f9032a95-c00b-4ad2-9cac-777909728bd6',
//         'start_date': '2021-04-30',
//         'start_time': '10:00:00'
//       },
//       'ticket_types': [
//         {
//           'id': 'C6M',
//           'qty': 1
//         },
//         {
//           'id': 'AD',
//           'qty': 1
//         }
//       ],
//       'location': {
//         'id': 'EL'
//       },
//       'end_location': {
//         'id': 'HE'
//       }
//     }
//   ]
// }

import { createSchema } from 'morphism'

import mapToSchema, {
  mapToArraySubSchema,
} from '_/libs/mapToSchema'

import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Map in from data for group id
const getTicketSchema = () => {
  return createSchema(
    {
      'title': 'title',
      'qty': 'qty',
      'sub_total': 'pricing.subtotal',
    },
  )
}

const getItemSchema = () => {
  return createSchema(
    {
      'id': 'id',
      'title': (iteratee) => {
        const {
          location,
          end_location,
        } = iteratee

        if ((location && location.title) && (end_location && end_location.title)) {
          return `${location.title} - ${end_location.title}`
        }

        return null
      },
      'product': 'product.title',
      'booking_fee_refundable': 'product.booking_fee_refundable',
      'category': 'product.category',
      'product_location': (iteratee) => {
        const {
          location,
          product,
        } = iteratee

        if ((location && location.title) && product.type === 'event') {
          return location.title
        }

        return null
      },
      'combos': {
        path: 'combos',
        fn: (value) => {
          if (!value) return []

          return value.map(({ title, amount }) => ({
            title,
            value: amount,
          }))
        },
      },
      'promos': {
        path: 'promos',
        fn: (value) => {
          if (!value) return []

          return value.map(({ title, promo_code, amount }) => ({
            title: title === '' ? promo_code : title,
            value: amount,
          }))
        },
      },
      'date': 'booked_unit.start_date',
      'time': 'booked_unit.start_time',
      'type': 'booked_unit.type',
      'sub_total': 'pricing.total',
      'deposit': 'pricing.deposit',
      'booking_fee': 'pricing.booking_fee',
      'discount': 'pricing.discount',
      'minimum_spend': 'pricing.minimum_spend',
      'minimum_spend_enforced': 'pricing.minimum_spend_enforced',
      'tickets': {
        path: 'ticket_types',
        fn: mapToArraySubSchema(getTicketSchema),
      },
    },
  )
}

const totals_default = {
  total: 0,
  discount: 0,
  sub_total: 0,
  ticket_price: 0,
  deposit: 0,
  minimum_spend: 0,
  booking_fee: 0,
}

const getBasketSchema = () => {
  return createSchema(
    {
      'currency_code': 'currency',
      'totals': {
        path: 'items',
        fn: (value) => {
          if (!value) return { ...totals_default }
          const base = value.reduce((totals, item) => {
            const {
              total,
              subtotal,
              discount,
              deposit = 0,
              booking_fee,
              minimum_spend,
            } = item.pricing

            totals.total = totals.total + total
            totals.deposit = totals.deposit + deposit
            totals.sub_total = totals.sub_total + subtotal
            totals.discount = totals.discount + discount
            totals.booking_fee = totals.booking_fee + booking_fee
            totals.minimum_spend = totals.minimum_spend + minimum_spend
            totals.ticket_price = (totals.ticket_price - discount) + item.ticket_types
              .reduce((amount, { pricing }) => {
                return amount + pricing.subtotal
              }, 0)

            return totals
          }, { ...totals_default })

          const minimum_spend_enforced = value.some(({ pricing }) => pricing.minimum_spend_enforced)

          if (minimum_spend_enforced) base.ticket_price = base.minimum_spend

          return {
            ...base,
            minimum_spend_enforced,
          }
        },
      },
      'items': {
        path: 'items',
        fn: mapToArraySubSchema(getItemSchema),
      },
    },
  )
}

const Summary = ({
  title = false,
  data,
  ...style
}) => {
  const {
    t,
  } = useTranslation()

  const {
    state: internalCartState,
    setPromo,
  } = useBasket()

  const isTicketSelectionPage = !!useRouteMatch('/ticket/*')
  const isAmendSelectionPage = !!useRouteMatch('/amend/*')
  const isJourneySelectionPage = !!useRouteMatch('/journey')

  // Show preview of pending cart changes on ticket selection page,
  // but otherwise show the actual cart prices.
  const cartArgs = isTicketSelectionPage || isAmendSelectionPage || isJourneySelectionPage
    ? internalCartState
    : null

  const basket = useCart(cartArgs)

  const mapped = mapToSchema(getBasketSchema)(basket.data)

  if (mapped.items.length === 0) {
    return (
      <Wrapper
        title={title}
        {...style}
      >
        <InlineTextBox>{t('basket.empty-message')}</InlineTextBox>
      </Wrapper>
    )
  }

  const currencyCode = mapped.currency_code

  return (
    <Wrapper
      title={title}
      {...style}
    >
      <Totals
        totals={mapped.totals}
        currencyCode={currencyCode}
      />
      {mapped.items.map((item, index) => {
        return (
          <BasketItem
            key={item.id || index}
            value={item}
            currencyCode={currencyCode}
            meta={data}
          />
        )
      })}
      {isTicketSelectionPage && (
        <PromoBox
          setPromo={setPromo}
        />
      )}
      {basket.isFetching && (
        <LoadIndicator />
      )}
    </Wrapper>
  )
}

const DetailsBox = ({
  basket,
  restrictId = null,
  title = false,
  showTotals = true,
  ...style
}) => {
  const meta = useOverview()

  const mapped = mapToSchema(getBasketSchema)(basket)

  const currencyCode = mapped.currency_code

  let items = mapped.items

  if (restrictId) items = mapped.items.filter(item => item.id === restrictId)

  return (
    <Wrapper
      title={title}
      {...style}
    >
      {showTotals && (
        <Totals
          totals={mapped.totals}
          currencyCode={currencyCode}
        />
      )}
      {items.map((item, index) => {
        return (
          <BasketItem
            key={item.id || index}
            value={item}
            currencyCode={currencyCode}
            meta={meta}
          />
        )
      })}
    </Wrapper>
  )
}

DetailsBox.styleable = true

const Wrapper = ({
  title = false,
  children,
  ...style
}) => {
  const { t } = useTranslation()

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            {title && (
              <Heading color={'basket_text_title'} title={t('basket.section-title')} level={4} uppercase margin={'0 0 0.25rem 0'} />
            )}
            {children}
          </div>
        )
      }}
    />
  )
}

const Totals = ({
  totals,
  currencyCode,
}) => {
  const { t } = useTranslation()

  const {
    theme,
    transforms,
  } = window.TICKNOVATE_CONFIG

  return (
    <Container margin={'0 0 2rem 0'}>
      <Container
        padding={'0.25rem 0'}
        rawStyle={{
          borderTop: `2px solid ${theme.border_thick}`,
          borderBottom: `2px solid ${theme.border_thick}`,
        }}>
        <Row type={'spaced'}>
          <InlineTextBox strong textTransform={transforms.basket_text_total || 'normal'}>{t('meta.ticket-price')}</InlineTextBox>
          <InlineTextBox strong>{formatCurrency(totals.ticket_price, { currencyCode })}</InlineTextBox>
        </Row>
        {totals.minimum_spend_enforced && (
          <InlineTextBox color={'text_warning'}>{t('ticket.warning-minimum-spend', { count: formatCurrency(totals.minimum_spend, { currencyCode }) })}</InlineTextBox>
        )}
        {totals.deposit > 0 && (
          <Row type={'spaced'}>
            <InlineTextBox strong textTransform={transforms.basket_text_total || 'normal'}>{t('meta.deposit')}</InlineTextBox>
            <InlineTextBox strong>{formatCurrency(totals.deposit, { currencyCode })}</InlineTextBox>
          </Row>
        )}
        {totals.booking_fee > 0 && (
          <Row type={'spaced'}>
            <InlineTextBox strong textTransform={transforms.basket_text_total || 'normal'}>{t('meta.booking-fee')}</InlineTextBox>
            <InlineTextBox strong>{formatCurrency(totals.booking_fee, { currencyCode })}</InlineTextBox>
          </Row>
        )}
      </Container>
      <Row type={'spaced'} padding={'0.5rem 0'} rawStyle={{ borderBottom: `2px solid ${theme.border_thick}` }}>
        <InlineTextBox strong size={1} textTransform={transforms.basket_text_total || 'normal'} color={'basket_text_total'}>{t('meta.total')}</InlineTextBox>
        <InlineTextBox strong size={1} color={'basket_text_total'}>{formatCurrency(totals.total, { currencyCode })}</InlineTextBox>
      </Row>
      {totals.discount > 0 && (
        <Row type={'start'} margin={'0.25rem 0 0 0'}>
          <InlineTextBox strong color={'basket_text_savings'}>{t('meta.your-savings', { count: formatCurrency(totals.discount, { currencyCode }) })}</InlineTextBox>
        </Row>
      )}
    </Container>
  )
}

const PromoBox = ({
  setPromo,
}) => {
  const {
    t,
  } = useTranslation()

  const [
    promo,
    updatePromo,
  ] = useState('')

  const submitPromo = () => {
    setPromo(promo)
  }

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const submit = {
    border: `1px solid ${theme.outline_success}`,
    padding: '0.5rem 0.75rem',
    textTransform: 'uppercase',
  }

  const submitDisabled = {
    border: `1px solid ${theme.outline_disabled}`,
    padding: '0.5rem 0.75rem',
    textTransform: 'uppercase',
  }

  return (
    <Container margin={'2rem 0'}>
      <Row type={'start'} crossAxis={'flex-end'}>
        <InputText
          title={t('basket.promo-title')}
          placeholder={''}
          value={promo}
          change={(field, value) => updatePromo(value)}
        />
        <Button
          change={submitPromo}
          height={'2.5rem'}
          disabled={promo === ''}
          color={promo === '' ? 'text_disabled' : 'text'}
          rawStyle={promo === '' ? submitDisabled : submit}
        >
          {t('meta.add')}
        </Button>
      </Row>
    </Container>
  )
}

const getTime = time => {
  const [
    hour,
    minute,
  ] = time.split(':')

  return `${hour}:${minute}`
}

const BasketItem = ({
  value,
  currencyCode,
}) => {
  const { t } = useTranslation()

  const [ details, toggleDetails ] = useState(true)

  const [ isWaivingFees, setIsWaivingFees ] = useState(false)

  const cart = useCart()
  const user = useSelector((state) => state.user)

  const {
    theme,
    transforms,
  } = window.TICKNOVATE_CONFIG

  const {
    title = 'From - To',
    product,
    product_location,
    combos,
    promos,
    date,
    type,
    time,
    tickets,
    sub_total,
    booking_fee = 0,
    deposit = 0,
    discount = 0,
    minimum_spend = 0,
    minimum_spend_enforced = false,
    booking_fee_refundable,
    category,
  } = value

  let productTitle = combos.length > 0 ? `${combos.map(({ title }) => title).join(', ')}, ${product}` : product

  if (category) {
    productTitle = `${productTitle}, ${category}`
  }

  let ticket_price = tickets.reduce((amount, { sub_total }) => amount + sub_total, 0) - discount

  if (minimum_spend_enforced) ticket_price = minimum_spend

  return (
    <Container margin={'0 0 1rem 0'} padding={'0 0 0.5rem 0'} rawStyle={{ borderBottom: `1px solid ${theme.border_thin}` }}>
      <InlineTextBox strong size={1} block margin={'0 0 0.5rem 0'}>{title}</InlineTextBox>
      <InlineTextBox block strong>{productTitle}</InlineTextBox>
      {product_location && (
        <InlineTextBox block margin={'0.25rem 0'} color={'basket_text_info'} strong textTransform={transforms.basket_text_info || 'normal'}>{product_location}</InlineTextBox>
      )}
      <InlineTextBox block margin={'0.25rem 0'} color={'basket_text_info'} strong textTransform={transforms.basket_text_info || 'normal'}>
        {`${formatUTC(date, 'D MMMM YYYY')}${type === 'time' ? ` ${getTime(time)}` : ''}`}
      </InlineTextBox>
      {details && (
        <Container>
          <InlineTextBox strong>{t('meta.ticket-price')}</InlineTextBox>
          {tickets
            .map(({ title, qty, sub_total }) => (
              <Row type={'spaced'} key={title}>
                <InlineTextBox flex={'2'}>{title}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{`x${qty}`}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{formatCurrency(sub_total, { currencyCode })}</InlineTextBox>
              </Row>
            ))
          }
          {combos.map((combo, index) => {
            return (
              <Row type={'spaced'} key={`combo_${index}`}>
                <InlineTextBox flex={'2'}>{combo.title}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{''}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{formatCurrency(combo.value * -1, { currencyCode })}</InlineTextBox>
              </Row>
            )
          })}
          {promos.map((promo, index) => {
            return (
              <Row type={'spaced'} key={`promo_${index}`}>
                <InlineTextBox flex={'2'}>{promo.title}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{''}</InlineTextBox>
                <InlineTextBox flex={'1'} textAlign={'right'}>{formatCurrency(promo.value * -1, { currencyCode })}</InlineTextBox>
              </Row>
            )
          })}
          <Row type={'spaced'} margin={'0 0 1rem 0'} rawStyle={{ borderBottom: `1px solid ${theme.border_thin}` }}>
            <InlineTextBox strong textTransform={transforms.basket_text_total || 'normal'} color={'basket_text_total'}>{t('meta.sub-total')}</InlineTextBox>
            <InlineTextBox strong color={'basket_text_total'}>{formatCurrency(ticket_price, { currencyCode })}</InlineTextBox>
          </Row>
          {deposit > 0 && (
            <Row type={'spaced'} key={'deposit'}>
              <InlineTextBox flex={'2'}>{t('meta.deposit')}</InlineTextBox>
              <InlineTextBox flex={'1'} textAlign={'right'}>{''}</InlineTextBox>
              <InlineTextBox flex={'1'} textAlign={'right'}>{formatCurrency(deposit, { currencyCode })}</InlineTextBox>
            </Row>
          )}
          {booking_fee > 0 && (
            <Row type={'spaced'} key={'booking_fee'}>
              <InlineTextBox flex={'2'}>
                {t(booking_fee_refundable ? 'basket.booking-fee' : 'basket.booking-fee-non-refundable')}
                {cart.data.id != null && user.permissions.includes('cart:override_fees') && (
                  <ActionDiscard
                    label={t('basket.waive-fee', 'Waive fee')}
                    loading={isWaivingFees}
                    change={async () => {
                      setIsWaivingFees(true)
                      await cart.voidItemFees(cart.data.id, value.id)
                      setIsWaivingFees(false)
                    }}
                  />
                )}
              </InlineTextBox>
              <InlineTextBox flex={'1'} textAlign={'right'}>{''}</InlineTextBox>
              <InlineTextBox flex={'1'} textAlign={'right'}>{formatCurrency(booking_fee, { currencyCode })}</InlineTextBox>
            </Row>
          )}
        </Container>
      )}
      <Row type={'spaced'}>
        <InlineTextBox strong textTransform={transforms.basket_text_total || 'normal'} color={'basket_text_total'}>{t('meta.total')}</InlineTextBox>
        <InlineTextBox strong color={'basket_text_total'}>{formatCurrency(sub_total, { currencyCode })}</InlineTextBox>
      </Row>
      <Row type={'centered'} >
        <span
          className={styles.details}
          onClick={() => toggleDetails(!details)}
        >
          {details ? t('meta.show-less') : t('meta.show-more')}
        </span>
      </Row>
    </Container>
  )
}

Summary.styleable = true

export default Summary

export {
  Wrapper,
  Totals,
  BasketItem,
  DetailsBox,
}
