import React, { useCallback } from 'react'

import Row from '_/components/layout/Row'
import Input from '_/components/element/Input'

import FieldWrapper from '_/components/layout/FieldWrapper'

import FlexButton from '_/components/element/FlexButton'

import AddIcon from '_/icons/Add'
import MinusIcon from '_/icons/Remove'

const InputInteger = ({
  field,
  name,
  value,
  change,
  minHeight = '2.5rem',
  disabled = false,
  min = 0,
  max = 999,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const handleChange = useCallback((event) => {
    const changeValue = event.target.value

    if (changeValue === '') {
      change(field, 0)
    } else {
      let value = Number(event.target.value.replace(/[^0-9]/g, ''))

      if (value < min) value = Number(min)

      if (value > max) value = Number(max)

      change(field, value)
    }
  }, [change, field, max, min])

  const increment = useCallback(() => {
    let output = value + 1

    if (output < min) output = Number(min)

    if (output > max) output = Number(max)

    change(field, output)
  }, [change, field, max, min, value])

  const decrement = useCallback(() => {
    let output = value - 1

    if (output < min) output = Number(min)

    if (output > max) output = Number(max)

    change(field, output)
  }, [change, field, max, min, value])

  return (
    <Row minHeight={minHeight} {...style}>
      <FlexButton disabled={value === 0} background={value === 0 ? 'button_disabled' : 'button'} noFlex width={'2.5rem'} change={decrement}>
        <MinusIcon
          size={24}
          fill={theme.text_light}
        />
      </FlexButton>
      <FieldWrapper background={'row_shaded'} noFlex width={'2.5rem'}>
        <Input
          name={name || field}
          type={'text'}
          placeholder={0}
          value={value}
          change={handleChange}
          disabled={disabled}
          textAlign={'center'}
          size={1}
        />
      </FieldWrapper>
      <FlexButton disabled={disabled} background={disabled ? 'button_disabled' : 'button'} noFlex width={'2.5rem'} change={increment}>
        <AddIcon
          size={24}
          fill={theme.text_light}
        />
      </FlexButton>
    </Row>
  )
}

export default InputInteger
