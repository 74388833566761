import React from 'react'
import classnames from 'classnames'
import styles from './nudebutton.scss'
import NavLinkEnhanced from '../NavLinkEnhanced'

/**
 * A button with no styling.
 *
 * Useful for when an element should act like a button for
 * accessibility/ semantic reasons, but should not look like
 * a traditional box-shaped button.
 *
 * @param {Object} props
 * @param {React.Node} props.children
 * @param {String} [props.styling]
 */
const NudeButton = ({ children, styling, type = 'button', to, ...rest }) => {
  if (to) {
    return (
      <NavLinkEnhanced
        to={to}
        className={classnames(styles.btn, styling)}
        {...rest}
      >
        {children}
      </NavLinkEnhanced>
    )
  }

  return (
    <button
      type={type}
      className={classnames(styles.btn, styling)}
      {...rest}
    >
      {children}
    </button>
  )
}

export default NudeButton
