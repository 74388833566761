import React from 'react'

const AddIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z' fill={fill} />
    </svg>
  )
}

export default AddIcon
