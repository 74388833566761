import toPairs from 'lodash/toPairs'

/**
 * Stringify an object into a URL query string
 *
 * @param {Object} obj
 * @returns {String}
 */
const objectToQueryString = obj => {
  const query = toPairs(obj)
    // Filter out null values.
    // Even strings of "null" are to be filtered. `null` and `"null"` end up
    // the same in the end URL, and both can cause backend issues.
    .filter(([key, val]) => val != null && val !== 'null')
    .map(([key, val]) => {
      val = val instanceof Array
        ? val.join(',')
        : val

      return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    })
    .join('&')

  return query
    ? `?${query}`
    : ''
}

export default objectToQueryString
