import React, { useState, useCallback } from 'react'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styles from './statefulwrapper.scss'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

import useTouched from '_/hooks/useTouched'

const useStatus = () => {
  const [
    focused,
    setFocused,
  ] = useState(false)

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  return {
    focused,
    handleFocus,
    handleBlur,
  }
}

const allowed = {
  VALID: 'success',
  INVALID: 'error',
  FOCUSED: 'interacting',
}

const StatefulContainer = ({
  children,
  status = null,
  required,
  hideRequiredMessage = false,
  requiredText,
  focused,
  focus,
  blur,
  disabled = false,
  controlled = false,
  indicator,
  getRef,
  ...style
}) => {
  const {
    touched,
    click,
  } = useTouched(controlled)

  let state = status

  if (!touched && status !== 'VALID' && !disabled) state = null

  if (focused) state = 'FOCUSED'

  state = allowed[state] ? styles[allowed[state]] : styles.default

  if (disabled) state = styles.disabled

  const showRequired = required && status === 'INVALID' && !focused && touched && !hideRequiredMessage

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            className={styleCombine(styles.layout, state)}
            onFocus={focus}
            onBlur={blur}
            onClick={click}
            ref={getRef}
            style={styling}
          >
            {children}
            {indicator && !focused && (touched || status === 'VALID') && indicator(status)}
            {required && !disabled && (
              <div className={styleCombine(styles.indicator, state)}>{'*'}</div>
            )}
            {showRequired && !disabled && (
              <span className={styles.message}>{`* ${requiredText}`}</span>
            )}
          </div>
        )
      }}
    />
  )
}

const StatefulWrapper = (props) => {
  const {
    focused,
    handleFocus,
    handleBlur,
  } = useStatus()

  return (
    <StatefulContainer {...props} focused={focused} focus={handleFocus} blur={handleBlur} />
  )
}

export default StatefulWrapper

export {
  StatefulContainer,
}
