import React from 'react'

import Heading from '_/components/layout/Heading'
import Row from '_/components/layout/Row'

import Icon from '_/components/element/Icon'

const IconTitle = ({
  title,
  icon,
  size = 32,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Row spread={false} {...style}>
      <Icon name={icon} size={size} fill={theme.card_default_icon} />
      <Heading level={2} title={title} rawStyle={{ marginLeft: '0.25rem' }} />
    </Row>
  )
}

IconTitle.styleable = true

export default IconTitle
