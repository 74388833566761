import storage from '@ticknovate/frontend-shared/libs/asyncStorage'
import setUpReduxStore from '@ticknovate/frontend-shared/libs/reduxStoreSetup'

let store

const getStore = (initial = {}) => {
  if (!store) {
    store = setUpReduxStore({
      load: async () => {
        const session = await storage.get('session')

        return { session, ...initial }
      },
      save: store => {
        return storage.set('session', store.getState().session)
      },
    })
  }

  return store
}

export default getStore
