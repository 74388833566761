import React from 'react'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import UpIcon from '@ticknovate/frontend-shared/icons/Up'
import DownIcon from '@ticknovate/frontend-shared/icons/Down'

const VerticalArrowIndicator = ({
  open,
  size = 12,
  fill = 'card_default_icon',
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const Icon = open ? UpIcon : DownIcon

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div style={styling}>
            <Icon size={size} fill={theme[fill]} />
          </div>
        )
      }}
    />
  )
}

export default VerticalArrowIndicator
