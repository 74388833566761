import React, { Fragment, useState, useRef, useLayoutEffect } from 'react'

import parse, { attributesToProps, domToReact } from 'html-react-parser'

import svgLoader from '_/libs/svgLoader'

const Icon = ({
  name,
  size = 16,
  fill = '#000',
  style = {},
}) => {
  const {
    env,
    icons,
  } = window.TICKNOVATE_CONFIG

  const importRef = useRef(null)
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    if (icons[name]) {
      setLoading(true)
      const iconRef = `${env.endpoint}/${env.tenant}/icons/${icons[name]}`

      const importIcon = async () => {
        try {
          const imported = await svgLoader(iconRef)

          importRef.current = imported
        // eslint-disable-next-line no-useless-catch
        } catch (err) {
          throw err
        } finally {
          setLoading(false)
        }
      }

      importIcon()
    }
  }, [name, fill, size, icons, env.endpoint, env.tenant])

  if (!loading && importRef.current) {
    return (
      <Fragment>
        {parse(importRef.current, {
          replace: domNode => {
            if (domNode.name === 'svg') {
              const props = attributesToProps(domNode.attribs)
              return (
                <domNode.name {...props} width={size} height={size} fill={fill} style={style}>
                  {domToReact(domNode.children)}
                </domNode.name>
              )
            }
          },
        })}
      </Fragment>
    )
  }

  return null
}

export default Icon
