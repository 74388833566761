import { useRef } from 'react'

import {
  useQuery,
} from 'react-query'

import {
  isAfterOrSame,
} from '_/libs/date'

import apiCaller from '_/libs/apiCaller'

import {
  getBookingSearchSchema,
} from '_/models/bookingOptions'

const defaults = {
  isFetching: false,
  isLoaded: false,
  data: {
    currency: '',
    combos: [],
    options: [],
  },
}

const getOptions = (params) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return apiCaller('product-booking-options', app.auth)({ query: params })
}

const getSingularAvailability = async (params) => {
  const {
    env,
  } = window.TICKNOVATE_CONFIG

  const query = getBookingSearchSchema({
    ...params,
    market: env.market || 'de',
  })

  try {
    const data = await getOptions(query)

    const {
      options,
    } = data

    return options
  } catch (error) {
    console.log('GET OPTIONS ERROR', error)

    return []
  }
}

const verifyDates = async (params) => {
  const {
    env,
  } = window.TICKNOVATE_CONFIG

  let {
    outbound_date = null,
    inbound_date = null,
    ...rest
  } = params

  const hasTickets = rest.ticket
    .filter(ticket => ticket.qty > 0)
    .length > 0

  const dates = [outbound_date, inbound_date]
    .filter(date => Boolean(date))

  if (!hasTickets) {
    return {
      verify_outbound: false,
      verify_inbound: false,
    }
  }

  if (outbound_date !== null && inbound_date !== null && !isAfterOrSame(outbound_date, inbound_date)) {
    inbound_date = null
  }

  const options = getBookingSearchSchema({
    ...rest,
    dates,
    market: env.market || 'de',
  })

  try {
    const booking_options = await apiCaller('product-booking-options')({ query: options })

    if (booking_options.options.length === 0) {
      return {
        verify_outbound: false,
        verify_inbound: false,
      }
    }

    const outbound = booking_options.options.find(option => option.start_date === outbound_date)
    const inbound = booking_options.options.find(option => option.start_date === inbound_date)

    return {
      verify_outbound: Boolean(outbound),
      verify_inbound: Boolean(inbound),
    }
  } catch (error) {
    return {
      verify_outbound: false,
      verify_inbound: false,
    }
  }
}

const useBookingOptions = (params, cheapest = false) => {
  const errorSent = useRef(false)

  const {
    env,
  } = window.TICKNOVATE_CONFIG

  const options = getBookingSearchSchema({
    ...params,
    market: env.market || 'de',
  })

  if (cheapest) options.include = 'cheapest-per-day'

  // if (selection) options.selection = selection // Add selection param if we have a combo

  const {
    isFetching,
    isSuccess,
    isError,
    data,
  } = useQuery(['product-booking-options', options], () => getOptions(options), {
    retry: 0,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10, // Keep me at 10 minutes
  })

  if (isSuccess) {
    return {
      isFetching,
      isLoaded: isSuccess,
      data,
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      ...defaults,
      isFetching,
      isLoaded: isError,
    }
  }

  return {
    ...defaults,
    isLoaded: isSuccess,
    isFetching,
  }
}

export default useBookingOptions

export {
  verifyDates,
  getSingularAvailability,
}
