const calcMaxPax = (data, ticket, pax = []) => {
  const max = ticket
    .filter(ticket => !pax.includes(ticket.id) && ticket.qty > 0)
    .reduce((amount, ticket) => {
      const ticket_meta = data.tickets.find(item => item.value === ticket.id)

      const max = ticket_meta ? ticket_meta.max : 0

      return amount + max
    }, 0)

  if (pax.length === 0 || max === 0) return 999

  const current_pax = pax
    .filter(id => data.tickets_map.hasOwnProperty(id))
    .reduce((acc, cur) => {
      const value = ticket.find(ticket => ticket.id === cur)

      acc = acc + value.qty

      return acc
    }, 0)

  return (max - current_pax)
}

export default calcMaxPax
