import { useReducer, useEffect, useState, useLayoutEffect } from 'react'
import set from 'lodash/set'
import unset from 'lodash/unset'
import get from 'lodash/get'

import ErrorBanner from '../atoms/ErrorBanner'
import SpinnerPlaceholder from '../molecules/SpinnerPlaceholder'
import useDeepComparisonDeps from '../hooks/useDeepComparisonDeps'

const cache = {}
const NoopComponent = () => null

const asyncDataReducer = (state, { type, args, data, persistData, error }) => {
  switch (type) {
    case 'loading':
      return {
        ...state,
        args: persistData ? state.args : args,
        data: persistData ? state.data : null,
        error: null,
        status: state.status.startsWith('loading')
          ? state.status // e.g. "loading-long"
          : 'loading',
        aborted: false,
      }
    case 'loading-long':
      if (state.status === 'loading') {
        return {
          ...state,
          status: 'loading-long',
        }
      }

      return state
    case 'loaded':
      if (state.aborted) {
        return state
      }
      return {
        status: 'loaded',
        args,
        data,
        error: null,
      }
    case 'error':
      if (state.aborted) {
        return state
      }
      return {
        status: 'error',
        args,
        data: null,
        error,
      }
    case 'abort':
      return {
        ...state,
        status: state.data ? 'loaded' : 'inactive',
        aborted: true,
      }
    default:
      throw new Error(`Unknown action type "${type}"`)
  }
}

const expandState = (setTrigger, abort, { status, args, data, error }) => {
  const ready = status === 'loaded' || status === 'error'
  const loadingLong = status === 'loading-long'
  const inactive = status === 'inactive'

  return {
    status,
    args,
    data,
    error,
    /** "ready" doesn't necessarily mean "error-free", only that we are ready to
     * deal with the result. Always check and handle errors.
     */
    ready,
    loading: status.startsWith('loading'),
    loadingLong,
    inactive,
    /** A generic component to display to reflect loading and error states */
    NotReadyComponent: error
      ? ErrorBanner
      : loadingLong
        ? SpinnerPlaceholder
        : !ready && !inactive
          ? NoopComponent
          : null,
    trigger: (...promiseCreatorArgs) => setTrigger({
      shouldRun: true,
      promiseCreatorArgs,
    }),
    abort,
  }
}

const useAsyncData = ({
  promiseCreator,
  promiseCreatorArgs = [],
  promiseCreatorShouldRun = true,
  persistDataWhenLoading = true,
  // Specify a `cacheKey` value for things that need to be fetched only
  // once per page load. For things that very rarely change, like tags and
  // service groups.
  cacheKey,
}) => {
  let isFresh = true
  const [trigger, setTrigger] = useState({
    shouldRun: false,
  })
  const getCachePromise = () => cacheKey ? get(cache, cachePath, null) : null
  if (trigger.shouldRun) {
    promiseCreatorShouldRun = true
  }
  const args = trigger.promiseCreatorArgs && trigger.promiseCreatorArgs.length
    // If this request was triggered via the ".trigger()" method with args passed,
    // use those arguments...
    ? trigger.promiseCreatorArgs
    // ...otherwise, just use the arguments first passed to the hook.
    : promiseCreatorArgs
  const cachePath = [cacheKey, ...args.map(String)]
  const cachePromise = getCachePromise()
  let [state, dispatch] = useReducer(asyncDataReducer, (
    cachePromise && cachePromise.settledValue
      ? {
        status: 'loaded',
        args,
        data: cachePromise.settledValue,
        error: null,
      }
      : {
        status: promiseCreatorShouldRun ? 'loading' : 'inactive',
        args,
        data: null,
        error: null,
      }
  ))
  const argsSingleDep = useDeepComparisonDeps(promiseCreatorArgs)

  useLayoutEffect(() => {
    if (!promiseCreatorShouldRun) return

    dispatch({
      type: 'loading',
      args,
      persistData: persistDataWhenLoading,
    })

    setTimeout(() => {
      if (isFresh) {
        dispatch({ type: 'loading-long' })
      }
    }, window.TICKNOVATE_CONFIG.timeouts.LOADING_DISPLAY_WAIT_MILLISECONDS)

    // We must query the cache via `getCachePromise()` inside this
    // `useLayoutEffect` instead of top-level, or multiple instances of this
    // hook will both call the `promiseCreator` and attempt to fill the cache.
    const cachePromiseFresh = getCachePromise()
    const promise = cachePromiseFresh || promiseCreator(...args)

    if (cacheKey && !cachePromiseFresh) {
      set(cache, cachePath, promise)
    }

    promise
      .then(data => {
        if (isFresh) {
          dispatch({ type: 'loaded', data, args })
          // Save the settled value on the promise object so we can synchronously
          // return cached values, instead of retuning a loading state from this
          // hook on the first pass.
          promise.settledValue = data
        }
      })
      .catch(error => {
        console.error(error)
        if (isFresh) {
          dispatch({ type: 'error', error, args })
          unset(cache, cachePath)
        }
      })
  }, [argsSingleDep, promiseCreatorShouldRun])

  useLayoutEffect(() => {
    return () => {
      isFresh = false
    }
  }, [argsSingleDep])

  useEffect(() => {
    if (trigger.shouldRun) {
      setTrigger({ shouldRun: false })
    }
  }, [trigger.shouldRun])

  const abort = () => {
    dispatch({ type: 'abort' })
  }

  return expandState(setTrigger, abort, state)
}

/**
 * Utility to get the combined state of multiple `useAsyncData` return values.
 *
 * @param {(Object|null)[]} asyncDataSources
 */
export const getCombinedAsyncStatus = asyncDataSources => {
  const activeSources = asyncDataSources.filter(source => source != null)

  return {
    error: activeSources.find(({ error }) => error != null) || null,
    ready: activeSources.every(({ ready, inactive }) => ready || inactive),
    loadingLong: activeSources.some(({ loadingLong }) => loadingLong),
    NotReadyComponent: activeSources.reduce((found, { NotReadyComponent }) => {
      return NotReadyComponent || found
    }, null),
  }
}

export default useAsyncData
