import React from 'react'
import ReactDOM from 'react-dom'
import './app.scss'
import App from './App'

import getStore from './store'

import isBrowserSupported from '@ticknovate/frontend-shared/libs/isBrowserSupported'
import BrowserOutdatedWarning from '@ticknovate/frontend-shared/components/molecules/BrowserOutdatedWarning'
import { clearStorageIfScriptChanged } from '@ticknovate/frontend-shared/libs/asyncStorage'

import { trigger, APP_RENDERED } from '@ticknovate/frontend-shared/libs/events'

if (isBrowserSupported()) {
  initApp()
} else {
  initFallbackWarning()
}

async function initApp () {
  await clearStorageIfScriptChanged()

  const params = {
    locale: null,
    app: 'b2c',
    embed: false,
    context: null,
  }

  const searchParams = new URLSearchParams(document.location.search)

  searchParams.forEach((value, key) => {
    if (params.hasOwnProperty(key.toLocaleLowerCase())) params[key.toLocaleLowerCase()] = value
  })

  const store = await getStore()

  ReactDOM.render(<App params={params} store={store} />, document.getElementById('root'), () => {
    trigger(APP_RENDERED)
  })
}

function initFallbackWarning () {
  ReactDOM.render(
    <BrowserOutdatedWarning />,
    document.getElementById('root'),
  )
}
