import React from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import Calendar, { Holder as CalendarHolder } from '_/templates/CalendarSelector'
import Ticket, { Holder as TicketHolder } from '_/templates/TicketSelector'
import TicketMulti, { Holder as TicketMultiHolder } from '_/templates/TicketMultiLegSelector'

import {
  stampToDateISO,
} from '_/libs/date'

import checkMultiUI from '_/libs/CheckMultiUI'

const TicketSelectionAmend = ({
  data,
  current,
  options,
  layout,
  update,
  close,
  initial,
  dateField,
}) => {
  const { t } = useTranslation()

  const revert = useRevert(current, update, true)

  const wrappedClose = () => {
    revert()
    close()
  }

  const isMulti = checkMultiUI(current, layout)

  const TicketHolderComponent = isMulti ? TicketMultiHolder : TicketHolder
  const TicketComponent = isMulti ? TicketMulti : Ticket

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        compact
        finalStep={1}
        initial={initial}
        routes={[
          {
            title: t(`ticket.model-title-${dateField}-date`),
            icon: 'calendar',
            valid: current[`${dateField}_date`] !== null,
            view: ({ ...viewProps }) => (
              <CalendarHolder {...viewProps}>
                <Calendar
                  field={dateField}
                  current={current}
                  startLimitDate={null}
                  initial={current[`${dateField}_date`]}
                  update={update}
                  options={options}
                />
              </CalendarHolder>
            ),
          },
          {
            title: t(`ticket.model-title-${dateField}-ticket`),
            icon: 'ticket',
            valid: current[`${dateField}_date`] !== null,
            view: ({ ...viewProps }) => (
              <TicketHolderComponent {...viewProps}>
                <TicketComponent
                  field={dateField}
                  data={data}
                  current={current}
                  startLimitDate={stampToDateISO(new Date())}
                  update={update}
                  options={options}
                  layout={layout}
                />
              </TicketHolderComponent>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default TicketSelectionAmend
