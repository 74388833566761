import { createSchema } from 'morphism'

import {
  mapDefaults,
} from '_/libs/mapToSchema'

import {
  notEmptyOrNull,
} from '_/libs/validityChecks'

const getSchema = () => {
  return createSchema(
    {
      'customer': {
        'email': 'customer.email',
        'telephone': 'customer.telephone',
        'telephone_country_code': 'customer.telephone_country_code',
        'title': 'billing_address.title',
        'firstname': 'customer.firstname',
        'lastname': 'customer.lastname',
        'subscribed_to_newsletter': 'customer.subscribed_to_newsletter',
        'license_plate': 'customer.license_plate',
      },
      'delivery_method': 'delivery_method',
      'meta': {
        'email_confirm': 'meta.email_confirm',
        'keep': 'meta.keep',
        'gdpr': 'meta.gdpr',
        'terms': 'meta.terms',
      },
      'booking_note': 'booking_note',
    },
    mapDefaults({
      'customer.firstname': '',
      'customer.lastname': '',
      'customer.telephone_country_code': '',
      'customer.telephone': '',
      'customer.email': '',
      'customer.subscribed_to_newsletter': false,
      'customer.license_plate': '',
      'meta.email_confirm': '',
      'delivery_method': 'email',
      'meta.keep': false,
      'meta.gdpr': false,
      'meta.terms': false,
      'booking_note': '',
    }),
  )
}

const emailIsEmail = (value) => /\S+@\S+\.\S+/.test(value) // Not 100% happy with this, but at least we can check that it is emailish
const phoneIsPhone = (value) => /^\+?[0-9() -]+$/.test(value)
const validEmail = (record, value) => {
  const notEmpty = notEmptyOrNull(record, value)
  return notEmpty && emailIsEmail(value)
}

const validPhone = (record, value) => {
  return phoneIsPhone(value)
}

const valid_spec = {
  'customer.firstname': notEmptyOrNull,
  'customer.lastname': notEmptyOrNull,
  'customer.telephone': validPhone,
  'customer.email': validEmail,
  'customer.subscribed_to_newsletter': notEmptyOrNull,
  'meta.email_confirm': (record, value) => {
    return validEmail(record, record.customer.email) && value === record.customer.email
  },
  'meta.terms': (record, value) => {
    return value !== false
  },
}

export {
  getSchema,
  valid_spec,
}
