import React from 'react'
import styles from './button.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const TicketButton = ({
  change = null,
  selected,
  disabled,
  children,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            onClick={disabled ? null : change}
            className={styleCombine(styles.layout, selected && styles.selected, disabled && styles.disabled)}
            style={styling}
          >
            {children}
          </div>
        )
      }}
    />
  )
}

export default TicketButton
