import React, { Component } from 'react'

import styles from './select.scss'

import Container from '../../containers/Container'

import TextIconRow from '../../atoms/TextIconRow'
import UpIcon from '../../../icons/Up'
import DownIcon from '../../../icons/Down'

import ScrollList from '../../atoms/ScrollList'
import { formValid } from '../../HOC/formValidator'

import shallowEqual from '../../../libs/shallowEqual'

const compareVar = (value, comparison) => {
  if (value === Object(value) && comparison === Object(comparison)) {
    return shallowEqual(value, comparison)
  } else {
    return value === comparison
  }
}

class Select extends Component {
  constructor (props) {
    super(props)

    this.state = {
      active: false,
    }
  }

  toggle = () => {
    const active = !this.state.active

    this.setState({
      active,
    })
  }

  select = (index) => {
    const {
      options,
      value,
      canEmpty = false,
    } = this.props

    const selected = options.findIndex(item => compareVar(item.value, value))

    let output = index !== null ? this.props.options[index].value : null

    if (canEmpty && selected !== -1 && index === selected) {
      output = null
    }

    this.props.onSelect && this.props.onSelect(index, output)

    this.setState({
      active: false,
    })
  }

  getList = () => {
    const {
      options,
      value,
      small,
    } = this.props

    const Children = options.map((item, index) => {
      return (
        <span
          key={index}
          className={styles.label}
        >
          {item.title}
        </span>
      )
    })

    const selected = value !== null ? options.findIndex(item => compareVar(item.value, value)) : null

    return (
      <ScrollList
        select={this.select}
        selected={selected}
        styling={styles.list}
        small={small}
        rowSelect
        fill
      >
        {Children}
      </ScrollList>
    )
  }

  getTitle = () => {
    const {
      options,
      value,
    } = this.props

    let list = options || []

    const index = list.findIndex(item => compareVar(item.value, value))

    return index
  }

  onDocumentClick = ({ target }) => {
    if (this.state.active && !this.container.contains(target)) {
      this.setState({ active: false })
    }
  }

  componentDidMount () {
    document.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.onDocumentClick)
  }

  render () {
    const {
      placeholder,
      options,
      small,
      styling,
    } = this.props

    const Icon = this.state.active ? UpIcon : DownIcon

    const found = this.getTitle()

    const title = found !== -1 ? options[found].title : placeholder

    const style = small ? styles.button_small : styles.button

    return (
      <Container
        styling={[styles.layout, styling].join(' ')}
        getRef={el => { this.container = el }}
      >
        <TextIconRow
          text={title}
          icon={Icon}
          onClick={this.toggle}
          styling={style}
          iconSize={10}
        />
        {this.state.active && this.getList()}
      </Container>
    )
  }
}

export const ValidSelect = formValid(({
  status,
  isFormValid,
  onClick,
  color,
  ...rest
}) => {
  return (
    <Select
      {...rest}
      disabled={false}
      color={status ? color : 'disabled'}
      onClick={(...args) => {
        if (isFormValid({ displayAllErrors: true })) {
          onClick && onClick(...args)
        }
      }}
    />
  )
})

export default Select
