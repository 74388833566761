
import sumBy from 'lodash/sumBy'
import IconFood from '../icons/Food'
import IconReceipt from '../icons/Receipt'
import IconSeating from '../icons/Seating'
import IconGift from '../icons/Gift'

const badgeMap = {
  default: () => null,
  menu: IconFood,
  patron: IconReceipt,
  seating: IconSeating,
  ancillary: IconFood,
  promo: IconGift,
}

const createDataMapper = (type) => (item) => ({
  label: item.title,
  ...item,
  type,
  Badge: badgeMap[type] || badgeMap.default,
  // Let component consuming this data decide the color if no special color defined:
  style: type === 'commission' ? 'promo' : type,
  total: type === 'promo' && item.amount ? -item.amount : item.subtotal,
})

/**
 * Return a flat array of all of the components that make up
 * an item in the cart.
 *
 * @param {Object} cartItem
 */
const getItemComponents = (cartItem) => {
  const {
    seating = [],
    menu = [],
    ancillary = [],
    promos: promo = [],
    voucher_external_product_code,
  } = cartItem

  const isSeatingFree = sumBy(seating, 'total') === 0
  const hideSeating = cartItem.product.type === 'rental' && isSeatingFree

  const patron = cartItem.ticket_types
    .map(type => ({
      id: type.id,
      label: type.title,
      qty: type.qty,
      total: type.pricing.total,
      subtotal: type.pricing.subtotal,
    }))

  if (
    typeof cartItem.pricing.booking_fee === 'number' &&
    cartItem.pricing.booking_fee !== 0
  ) {
    patron.push({
      id: 'booking_fee',
      label: 'booking_fee',
      labelKey: cartItem.product.booking_fee_refundable ? 'basket.booking-fee' : 'basket.booking-fee-non-refundable',
      total: cartItem.pricing.booking_fee,
      subtotal: cartItem.pricing.booking_fee,
    })
  }

  return [
    ...patron.map(createDataMapper('patron')),
    ...(
      hideSeating
        ? []
        : seating.map(createDataMapper('seating'))
    ),
    ...menu.map(createDataMapper('menu')),
    ...ancillary.map(createDataMapper('ancillary')),
    ...promo.filter(({ applied }) => applied).map(createDataMapper('promo')),
    voucher_external_product_code && createDataMapper('promo')({
      label: `Voucher: ${voucher_external_product_code}`,
      applied: true,
    }),
  ].filter(Boolean)
}

export default getItemComponents
