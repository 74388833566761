import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ResponsiveView as View } from '_/components/layout/View'

import Area from '_/components/layout/Area'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'
import InputSelect from '_/components/input/InputSelect'
import ActionCard from '_/components/action/ActionCard'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'
import ModalRouter from '_/components/navigation/ModalRouter'

import StepLayout from '_/templates/StepLayout'
import Summary from '_/templates/Summary'

import ActionBasket from '_/components/action/ActionBasket'

import FooterWrapper from '_/components/layout/FooterWrapper'

import useOverView from '_/hooks/useOverview'

import NavFlow from '_/components/navigation/NavFlow'
import Basket from '_/modals/Basket'
import useCart from '_/hooks/useCart'

import { triggerEndSession } from '_/hooks/useSessionTimeout'
import { triggerToastMessage } from '_/components/notification/Toaster'
import EmbedTransport from '_/libs/EmbedTransport'

import * as cartApi from '@ticknovate/frontend-shared/api/cart'

const options = [
  {
    value: 'PT2H',
    label: '2 hours',
  },
  {
    value: 'PT24H',
    label: '1 day',
  },
  {
    value: 'PT72H',
    label: '3 days',
  },
]

const PayLater = ({
  history,
  match,
}) => {
  const { t } = useTranslation()

  const data = useOverView()
  const cart = useCart()

  const {
    email,
  } = cart.data.customer.email

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  const [
    payLaterExpiry,
    setPayLaterExpiry,
  ] = useState(options[1].value)

  const handleSubmit = async () => {
    try {
      const extendedCart = await cartApi.payLater(cart.data, {
        time_to_pay: payLaterExpiry,
      })

      console.log('Cart expiry extended ', extendedCart)

      triggerEndSession()

      window.TICKNOVATE_CONFIG.env.embeddedMode &&
        EmbedTransport.send({
          type: 'BOOKING_CONFIRMATION',
          value: cart.id,
        })

      history.push('/confirmation', cart.data)
    } catch (error) {
      console.error(error)
      triggerToastMessage({
        status: 'error',
        message: t(
          'billing.errors.UNABLE-TO-SAVE.long',
          'There was an error while trying to save your details.',
        ),
      })
    }
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <ModalRouter
            mobile={mobile}
            routes={{
              basket: {
                desktop: {},
                mobile: {
                  height: 'calc(100vh - 2rem)',
                  bottom: 0,
                },
                view: ({
                  ...props
                }) => {
                  return (
                    <Basket {...props} data={data} />
                  )
                },
              },
            }}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <NavFlow mobile={mobile} match={match} area={'nav'} mount={mount}>
                    {app.basketInMenu && (
                      <ActionBasket
                        type={'menu'}
                        change={() => mount('basket')}
                        area={'child'}
                        gridAlign={'stretch'}
                      />
                    )}
                  </NavFlow>
                  <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
                    <Area
                      areas={mobile ? ['title', 'message', 'time', 'submit'] : ['title', 'message submit', 'time submit']}
                      rowgap={1}
                      columns={2}
                    >
                      <Heading level={1} title={'Pay via email'} area={'title'} />
                      <TextBox area={'message'}>{`An email will be sent to ${email} for the customer to complete the payment in their browser.`}</TextBox>
                      <InputSelect
                        area={'time'}
                        field={'expiry'}
                        title={'Time to complete payment'}
                        options={options}
                        value={payLaterExpiry}
                        change={(field, value) => setPayLaterExpiry(value)}
                      />
                      <ActionCard
                        area={'submit'}
                        label={'Send email'}
                        change={handleSubmit}
                        gridAlign={'auto'}
                        margin={mobile ? '2.5rem 0' : '0'}
                        cta
                      />
                    </Area>
                  </View>
                  {!mobile && (
                    <Summary title area={'basket'} />
                  )}
                  {mobile && !app.basketInMenu && (
                    <FooterWrapper>
                      <ActionBasket
                        change={() => mount('basket')}
                      />
                    </FooterWrapper>
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

export default PayLater
