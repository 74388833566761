const FileReader = window.FileReader

const blobToDataURL = (blob) => new Promise((resolve, reject) => {
  let reader = new FileReader()
  reader.onerror = reject
  reader.onload = (e) => resolve(reader.result)
  reader.readAsDataURL(blob)
})

export default blobToDataURL
