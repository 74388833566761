
// EVERYTHING IN THIS FILE IS LEGACY. TODO: DELETE
const getBaseServiceMeta = data => {
  // console.log('SERVICE META', data)
  const {
    booked_unit,
    product,
  } = data

  if (booked_unit.type === 'time') {
    return {
      id: product.id,
      type: 'service',
      upsellUIType: 'none',
      patronUIType: 'unpriced',
      startTimeUIType: 'datetime',
      seatingUIType: 'default',
    }
  }

  return {
    id: product.id,
    type: 'pass',
    upsellUIType: 'default',
    patronUIType: 'priced',
    startTimeUIType: 'date',
    // seatingUIType: 'none',
    // getDetails: serviceApi.getTravelPassData,
    // getDetailsArgs: [id, voucher_id],
    // getInstances: params => serviceApi.getTravelPassInstances({ ...params, id })
  }

  // throw new Error('Unrecognised product')
}

const getServiceMeta = data => {
  const meta = getBaseServiceMeta(data)

  return {
    ...meta,
    // Decorate with an identifier that can be used on the frontend to easily
    // tell two products apart.
    productIdentifier: `${meta.type}:${meta.id}`,
  }
}

export const isSameProduct = (p1, p2) => {
  if (!p1 || !p2) return false

  return getServiceMeta(p1).productIdentifier === getServiceMeta(p2).productIdentifier
}

export default getServiceMeta
