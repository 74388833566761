import React from 'react'
import classnames from 'classnames'

import styles from './row.scss'

const allowed = [
  'spaced',
  'stretch',
  'start',
  'end',
  'centered',
  'collapse',
]

const Row = (props) => {
  const {
    children,
    type,
    styling,
    spread,
    getRef,
    reverse,
    ...rest
  } = props

  const className = type && allowed.includes(type) ? styles[type] : styles.default

  const elementSpace = spread ? styles.spread : null

  return (
    <div
      className={classnames(className, elementSpace, styling, {
        [styles.reverse]: reverse,
      })}
      ref={getRef}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Row
