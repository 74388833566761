import React from 'react'

import Area from '_/components/layout/Area'

const mobileLayout = [
  'nav',
  'form',
]

const desktopLayout = [
  'nav basket',
  'form basket',
]

const StepLayout = ({
  mobile,
  children,
}) => {
  return (
    <Area
      areas={mobile ? mobileLayout : desktopLayout}
      columns={mobile ? 1 : ['1fr', '20rem']}
      rows={mobile ? ['6.25rem', '1fr'] : ['5rem', '1fr']}
      colgap={0}
      rowgap={0}
      height={'var(--app-viewport)'}
      fullFlex
    >
      {children}
    </Area>
  )
}

export default StepLayout
