const shallowEqual = (A, B) => {
  let result = false

  const keys = Object.keys(A)

  for (let x = 0; x < keys.length; x++) {
    const key = keys[x]

    if (B[key] && B[key] === A[key]) {
      result = true
    } else {
      result = false

      break
    }
  }

  return result
}

export default shallowEqual
