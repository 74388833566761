import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import { ResponsiveView as View } from '_/components/layout/View'
import FooterWrapper from '_/components/layout/FooterWrapper'
import Icon from '_/components/element/Icon'
import InlineTextBox from '_/components/layout/InlineTextBox'

import NavFlow from '_/components/navigation/NavFlow'
import ActionBasket from '_/components/action/ActionBasket'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import StepLayout from '_/templates/StepLayout'
import EditorModal from '_/templates/EditorModal'
import Summary from '_/templates/Summary'

import ActionCard from '_/components/action/ActionCard'

import useCart from '_/hooks/useCart'
import useBasket from '_/hooks/useBasket'

import { syncBasketToCart } from '_/models/cart'

import DetailLegs from './DetailLegs'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

const Journey = ({
  match,
}) => {
  const {
    app,
    theme,
  } = window.TICKNOVATE_CONFIG

  const { t } = useTranslation()
  const history = useHistory()

  const [
    loading,
    setLoading,
  ] = useState(false)

  const {
    state: basket,
    editBasket,
  } = useBasket()

  const {
    data,
    update,
  } = useCart(basket)

  const sendCart = async () => {
    const payload = syncBasketToCart(basket, data)

    setLoading(true)

    await update(payload)

    history.push({
      pathname: '/details',
      state: {
        flow: 'journey',
      },
    })
  }

  const handleChange = (field) => (item) => {
    const {
      pick_id,
      product_id,
      instance_id,
      start_date,
      start_time,
      pricing,
      legs,
    } = item

    const basket_item = {
      pick_id,
      product_id,
      combo_id: null, // Will need to poke this back in at some point
      instance_id,
      start_date,
      start_time,
      ticket: basket.items[field].ticket, // Grab from current basket
      location: basket.items[field].location,
      end_location: basket.items[field].end_location,
      pricing,
      legs,
    }

    editBasket(basket_item, field)
  }

  let selection = null

  if (data.items[0]) {
    const {
      booked_unit,
      product,
      location,
      end_location,
      ticket_types,
    } = data.items[0]

    const {
      instance_id,
      start_date,
      start_time,
    } = booked_unit

    selection = [
      {
        product: {
          id: product.id,
        },
        booked_unit: {
          instance_id,
          start_date,
          start_time,
        },
        location: {
          id: location.id,
        },
        end_location: {
          id: end_location.id,
        },
        ticket_types: ticket_types
          .filter(({ qty }) => qty > 0)
          .map(({ id, qty }) => ({
            id,
            qty,
          })),
      },
    ]
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <EditorModal
            mobile={mobile}
            modalProps={{}}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <NavFlow
                    mobile={mobile}
                    match={match}
                    area={'nav'}
                    mount={mount}
                    flow={'journey'}
                  >
                    {app.basketInMenu && (
                      <ActionBasket
                        type={'menu'}
                        change={() => mount('basket')}
                        area={'child'}
                        gridAlign={'stretch'}
                      />
                    )}
                  </NavFlow>
                  <View mobile={mobile} area={'form'} gridAlign={'stretch'} background={'journey_background'}>
                    <Area
                      area={[
                        'content',
                        'total',
                        'submit',
                      ]}
                      columns={1}
                      rowgap={1.25}
                    >
                      <Container area={'content'}>
                        {data.items.map((item, index, items) => {
                          const title = t(index === 0 ? 'ticket.title-outbound' : 'ticket.title-inbound')

                          return (
                            <DetailLegs
                              key={index}
                              mobile={mobile}
                              title={title}
                              data={item}
                              change={handleChange(index === 0 ? 'outbound' : 'inbound')}
                              separator={index < items.length - 1}
                              selection={index > 0 ? selection : null}
                              showChooser={items.length === 1}
                            />
                          )
                        })}
                      </Container>
                      <Row
                        area={'total'}
                        padding={'0.5rem 0.75rem'}
                        rawStyle={{
                          borderTop: '1px solid RGB(var(--theme_journey_detail_text))',
                          borderBottom: '1px solid RGB(var(--theme_journey_detail_text))',
                        }}
                      >
                        <Icon name={'basket'} size={32} fill={theme['journey_detail_text']} />
                        <InlineTextBox size={1.25} fullFlex strong color={'journey_detail_text'}>{t('meta.total')}</InlineTextBox>
                        {data.pricing && (
                          <InlineTextBox size={1.25} pushRight strong noFlex color={'journey_detail_text'}>{formatCurrency(data.pricing.total, { currencyCode: data.currency })}</InlineTextBox>
                        )}
                      </Row>
                      <ActionCard
                        area={'submit'}
                        label={t('meta.done')}
                        change={sendCart}
                        margin={'0 0 6rem 0'}
                        disabled={basket.items.outbound === null || loading}
                        loading={loading}
                        cta
                      />
                    </Area>
                    {mobile && !app.basketInMenu && (
                      <FooterWrapper>
                        <ActionBasket
                          change={() => mount('basket')}
                        />
                      </FooterWrapper>
                    )}
                  </View>
                  {!mobile && (
                    <Summary
                      area={'basket'}
                      title
                    />
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

export default Journey
