// 'history' is a dependency of 'react-router'
//
// By creating our own instance of browser history instead of
// having this done for us automatically by react-router, we
// have the freedom to use it outside of react components.
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default history
