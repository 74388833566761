import { translate, getLanguage } from '@ticknovate/frontend-shared/locales'

const formatCurrency = (value, {
  small = false,
  useFreeText = false,
  freeTextString = 'Free',
  currencyCode = 'GBP', // TODO: Remove this default. It makes no sense.
} = {}) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  try {
    const locale = getLanguage()

    if (typeof value !== 'number' || Number.isNaN(value)) {
      throw new Error(`Value ${value} is not a number`)
    }

    if (useFreeText && value === 0) {
      return translate('common.free', 'Free')
    }

    // Backend rounds the DKK and SEK values.
    // See the code in the Product Service.
    if (currencyCode === 'DKK' || currencyCode === 'SEK') {
      if (value % 100 === 0) {
        small = true
      } else {
        // This is an error case. The backend should round these values.
        // In this case, we display the non-rounded value to avoid showing inaccurate info.
        console.error(`formatCurrency received a price of ${value} that is not a multiple of 100. Cannot display small format as expected.`)
      }
    }

    if (small) {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode || app.currencyCode || 'GBP',
        currencyDisplay: app.currencyDisplay || 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value / 100)
    }

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode || app.currencyCode || 'GBP',
      currencyDisplay: app.currencyDisplay || 'symbol',
    }).format(value / 100)
  } catch (err) {
    console.error({ value })
    console.error(err)
    return 'Invalid Price'
  }
}

const calculateBasketTotal = (items) => {
  const total = items
    .reduce((amount, data) => {
      return amount + data.pricing.total
    }, 0)

  return total
}

export {
  calculateBasketTotal,
  formatCurrency,
}
