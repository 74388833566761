import React, { Component, cloneElement } from 'react'
import Container from '../../containers/Container'
import TabSelect from '../TabSelect'

/**
 * A simple tab navigation component.
 *
 * Its only children should be <Pane /> components, only one of which
 * is displayed at a time, depending on which tab is selected.
 *
 * @param {Object} props
 * @param {Function} props.action
 * @param {Pane[]} props.children
 * @param {*} props.selected
 * @param {String} props.styling
 */
export const TabNav = ({
  action,
  children,
  selected,
  styling,
  tabSelectStyling,
  fadeOutColor,
  useIds,
  ...rest
}) => (
  <Container styling={styling}>
    <TabSelect
      useIndex={!useIds}
      styling={tabSelectStyling}
      fadeOutColor={fadeOutColor}
      {...rest}
      selected={selected}
      action={action}
      tabs={React.Children.map(children, child => {
        const { id, label, disabled } = cloneElement(child).props
        return { id, label, disabled }
      })}
    />
    {useIds
      ? children.find(({ props }) => props.id === selected)
      : children[selected]
    }
  </Container>
)

export class StatefulTabNav extends Component {
  state = {
    selected: 0,
  }

  updateSelected = (selected) => {
    this.setState({ selected })
  }

  render () {
    return <TabNav
      {...this.props}
      action={this.updateSelected}
      selected={this.state.selected}
    />
  }
}

/**
 * A wrapper for TabNav content.
 *
 * Define a corresponding label to appear in the navigation via the `label` prop.
 *
 * @param {Object} props
 * @param {React.Node} props.label
 * @param {React.Children} props.children
 */
export const Pane = ({ children }) => children
