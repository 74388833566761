import React from 'react'

const SeatingIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 28.8'
    >
      <polygon
        fill={fill}
        points={'3.2 24 3.2 28.8 8 28.8 8 24 24 24 24 28.8 28.8 28.8 28.8 19.2 3.2 19.2 3.2 24'}
      />
      <rect
        fill={fill}
        x={'27.2'}
        y={'11.2'}
        width={'4.8'}
        height={'4.8'}
      />
      <rect
        fill={fill}
        y={'11.2'}
        width={'4.8'}
        height={'4.8'}
      />
      <path
        fill={fill}
        d={'M24,16H8V3.2A3.21,3.21,0,0,1,11.2,0h9.6A3.21,3.21,0,0,1,24,3.2Z'}
      />
    </svg>
  )
}

export default SeatingIcon
