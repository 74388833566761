const duration_index = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']

const localiseDuration = (value, t) => {
  return duration_index
    .reduce((acc, cur) => {
      if (value[cur] > 0) acc.push(`${value[cur]} ${t(`time.${cur.slice(0, -1)}`, { count: value[cur] })}`)

      return acc
    }, [])
    .join(', ')
}

export default localiseDuration
