import React from 'react'

import Row from '_/components/layout/Row'
import Column from '_/components/layout/Column'
import InlineTextBox from '_/components/layout/InlineTextBox'

const Card = ({
  getNumber,
  children,
}) => {
  return (
    <Row type={'start'} margin={'0 0 2rem 0'} background={'background_module'} shadow spread={false}>
      <Column width={'2rem'} padding={'1rem 0 0 0'} background={'button'} noFlex>
        <InlineTextBox strong color={'text_light'}>{getNumber ? getNumber() : ''}</InlineTextBox>
      </Column>
      <Column padding={'1.5rem'} crossAxis={'flex-start'}>
        {children}
      </Column>
    </Row>
  )
}

export default Card
