import React from 'react'

import CardButton from '_/components/element/CardButton'

import InfoBlock from '_/components/layout/InfoBlock'

const ActionSelector = ({
  label,
  value,
  change,
  cta = false,
  warning = false,
  offer = false,
  disabled = false,
  withBorder = true,
  icon = 'travel',
  ...style
}) => {
  let title_color = 'card_default_title'
  let text_color = 'card_default_text'
  let icon_color = 'card_default_icon'

  if (cta) {
    icon_color = 'card_cta_icon'
    title_color = 'card_cta_title'
    text_color = 'card_cta_text'
  }

  if (warning) {
    icon_color = 'card_warning_icon'
    title_color = 'card_warning_title'
    text_color = 'card_warning_text'
  }

  if (disabled) {
    icon_color = 'card_disabled_icon'
    title_color = 'card_disabled_title'
    text_color = 'card_disabled_text'
  }

  return (
    <CardButton
      change={change}
      minHeight={'3.125rem'}
      padding={'0.5rem 0.5rem 1rem 0.5rem'}
      cta={cta}
      disabled={disabled}
      {...style}
      warning={warning}
      offer={offer}
      withBorder={withBorder}
    >
      <InfoBlock
        icon={icon}
        label={label}
        titleColor={title_color}
        textColor={offer ? title_color : text_color}
        fill={icon_color}
        value={value}
      />
    </CardButton>
  )
}

ActionSelector.styleable = true

export default ActionSelector
