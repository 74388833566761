
import React, { Fragment, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from '../../../../organisms/Form-Billing/formbilling.scss'
import { getPaymentCtaText, paymentMethods } from '../../../../../libs/constants'

import Container from '../../../../containers/Container'
import { triggerToastMessage } from '../../../../molecules/Toaster'
import ModalButton from '../../../../atoms/ModalButton'
import * as cartApi from '../../../../../api/cart'

import { v4 as uuidV4 } from 'uuid'

const getPaymentsArray = (paymentMethod, cart, uuid) => {
  switch (paymentMethod) {
    case paymentMethods.CREDIT:
      return [{
        method: paymentMethods.CREDIT,
        uuid: uuidV4(),
        details: {
          currency: cart.currency,
          value: cart.payable,
          timestamp: new Date().toISOString(),
        },
      }]
    case paymentMethods.NONE:
      return []
    default:
      throw new Error(`Unsupported payment method "${paymentMethod}"`)
  }
}

/**
 * A form for completing an order where no payment is required.
 * For example, with "none" or "invoice" payment methods.
 */
const GenericFreePaymentForm = props => {
  const {
    paymentMethod,
    draftCart,
    redirectToConfirmation,
    termsAndConditions,
    pendingRefunds,
    disabled,
  } = props

  const {
    t,
  } = useTranslation()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const paymentCtaText = getPaymentCtaText(paymentMethod)

  const payCompleteOrderAndRedirect = async (payments) => {
    const paymentsResponse = await cartApi.addPayment(
      draftCart,
      { method: 'mixed', payments },
    )
    console.log('Payments completed', paymentsResponse)

    const order = await cartApi.placeOrder(
      draftCart,
      draftCart.suppress_emails,
    )

    redirectToConfirmation(order)
  }

  const createOrder = async () => {
    setIsSubmitting(true)
    try {
      switch (paymentMethod) {
        case paymentMethods.REFUND: {
          await payCompleteOrderAndRedirect(
            pendingRefunds
              .filter(({ details }) => {
                return details.value !== 0
              })
              .map((payment) => {
                const { uuid, method, details, related_transaction } = payment
                const { value, description } = details

                // This method actually triggers an external refund.
                // Therefore, it does not need a timestamp as it is an instruction,
                // rather than a historic record of something already occurred.
                if (method === paymentMethods.CARD) {
                  return {
                    method,
                    details: {
                      paymentId: details.reference_transaction_id,
                      currency: draftCart.currency,
                      value: -Math.abs(value),
                    },
                  }
                }

                if (method === paymentMethods.NETS_EASY) {
                  return {
                    method,
                    details: {
                      paymentId: related_transaction.details.paymentId,
                      currency: draftCart.currency,
                      value: -Math.abs(value),
                    },
                  }
                }

                if (method === paymentMethods.WORLDPAY_ONLINE) {
                  return {
                    method,
                    details: {
                      paymentId: related_transaction.details.orderCode,
                      currency: draftCart.currency,
                      value: -Math.abs(value),
                    },
                  }
                }

                // These payment methods simply record entries in database tables.
                // They do not have side-effects, like reaching out to 3rd party APIs
                // and processing payments.
                return {
                  uuid,
                  method,
                  details: {
                    type: method === paymentMethods.GENERIC
                      ? 'reservations_external_refund'
                      : undefined,
                    description: description || undefined,
                    value: -Math.abs(value),
                    currency: draftCart.currency,
                    timestamp: new Date().toISOString(),
                  },
                }
              }),
          )
          break
        }

        default: {
          await payCompleteOrderAndRedirect(
            getPaymentsArray(paymentMethod, draftCart),
          )
        }
      }
    } catch (error) {
      console.error(error)
      triggerToastMessage({
        status: 'error',
        message: t('billing.errors.GENERIC-ORDER-COMPLETION.long', 'Something went wrong with completing the order'),
      })
    }
    setIsSubmitting(false)
  }

  return (
    <Fragment>
      {!!termsAndConditions && (
        <Container>
          {termsAndConditions}
        </Container>
      )}
      <Container styling={styles.paddingTop}>
        <ModalButton
          id={'complete-payment-button'}
          content={t(`billing.${paymentCtaText.translationKey}`, paymentCtaText.text)}
          color={disabled ? 'disabled' : 'green'}
          onClick={createOrder}
          loading={isSubmitting}
          disabled={disabled || isSubmitting}
          data-test-handle={'btn-checkout-complete'}
        />
      </Container>
    </Fragment>
  )
}

export default withRouter(GenericFreePaymentForm)
