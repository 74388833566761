import React from 'react'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import AsteriskIcon from '_/icons/Asterisk'

const WarningText = ({
  content,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Row crossAxis={'flex-start'}>
      <AsteriskIcon
        size={16}
        fill={theme.option_warning_icon}
      />
      <InlineTextBox>{content}</InlineTextBox>
    </Row>
  )
}

export default WarningText
