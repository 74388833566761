import React from 'react'

import styles from './scroll.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const Scroll = ({ children, ...style }) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.content} style={styling}>
            {children}
          </div>
        )
      }}
    />
  )
}

Scroll.styleable = true

export default Scroll
