import { useState, useCallback } from 'react'

const useFocus = ({ onBlur, onFocus } = {}) => {
  const [isFocused, setIsFocused] = useState(false)

  return {
    focusProps: {
      onFocus: useCallback(event => {
        setIsFocused(true)

        if (onFocus) onFocus(event)
      }, [setIsFocused, onFocus]),

      onBlur: useCallback(event => {
        setIsFocused(false)

        if (onBlur) onBlur(event)
      }, [setIsFocused, onBlur]),
    },
    isFocused,
    focusClass: isFocused ? 'focused' : '',
  }
}

export default useFocus
