import createApiCaller from '../libs/createApiCaller'
import jwtDecode from 'jwt-decode'

/**
 * Authless API-calling abstraction
 */
const callApi = createApiCaller()

const decorateLoginResponse = async response => {
  if (response.step !== 'LOGGED_IN') {
    return response
  }

  const idTokenData = response.idToken ? jwtDecode(response.idToken) : null
  const tradePartnerId = idTokenData && idTokenData['custom:trade_partner_id']
  // Avoid calling app's main `callApi` that is connected to redux since:
  // 1. data is not yet in redux at login
  // 2. It creates circular dependencies when imported into login redux actions
  const callSecureApi = createApiCaller({
    getRequestAuth: () => Promise.resolve(response.accessToken),
  })

  const tpPromise = tradePartnerId != null
    ? callSecureApi('me/trade-partner')
    : Promise.resolve(null)
  const [profile, tradePartner] = await Promise.all([
    callSecureApi('me/profile'),
    tpPromise,
  ])

  return {
    ...response,
    tradePartner,
    idTokenData,
    permissions: profile.userPermissions ? profile.userPermissions.split(',') : [],
  }
}

export const externalAuth = (token) => callApi('/users/authorize', { method: 'POST', headers: { 'Authorization': `Bearer ${token}` } })
  .then(decorateLoginResponse)

export const externalMe = (token) => callApi('/me/profile', { method: 'GET', headers: { 'Authorization': token } })
  .then(decorateLoginResponse)

export const login = body => callApi('users/login', { method: 'POST', body })
  .then(decorateLoginResponse)

export const challenge = body => callApi('users/challenge', { method: 'POST', body })
  .then(decorateLoginResponse)

export const forgot = body => callApi('users/forgot/start', { method: 'POST', body, responseFormat: 'plain' })

export const forgotConfirm = body => callApi('users/forgot/confirm', { method: 'POST', body, responseFormat: 'plain' })

export const getProfiles = () => callApi('users/profiles')

export const createProfile = body => callApi('users/profiles', { method: 'POST', body })
