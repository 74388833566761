import React from 'react'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'

import InputText from '_/components/input/InputText'
import InputFilter from '_/components/input/InputFilter'
import InputSelect from '_/components/input/InputSelect'

import { getCodesAsOptions } from '_/libs/countryCodes'

// import countriesByCode from '_/libs/countriesByCode'
import stateCodes from '@ticknovate/frontend-shared/libs/stateCodesUS'

// const layout = [
//   'firstName/2 lastName/2',
//   'phoneNumber/3 .',
//   'email/3 .',
//   'emailConfirm/3 .'
// ]

const countryCodes = getCodesAsOptions()

const baseLayout = [
  'line_1',
  'line_2',
  'city',
  'post_code',
  'country',
  'state',
]

const layout = {
  mobile: baseLayout,
  tablet: baseLayout,
  desktop: baseLayout,
}

const BillingForm = ({
  breakpoint,
  current,
  valid,
  change,
}) => {
  const { t } = useTranslation()

  return (
    <Area
      area={'form'}
      areas={layout[breakpoint]}
      colgap={1.5}
      rowgap={1.5}
      columns={4}
      margin={'1rem 0 0 0'}
    >
      <InputText
        area={'line_1'}
        field={'line_1'}
        title={t('details.form-field-address-1')}
        placeholder={t('details.form-field-address-1')}
        value={current.line_1}
        change={change}
        status={valid['line_1'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'address-line1'}
      />
      <InputText
        area={'line_2'}
        field={'line_2'}
        title={t('details.form-field-address-2')}
        placeholder={t('details.form-field-address-2')}
        value={current.line_2}
        change={change}
        autocomplete={'address-line2'}
      />
      <InputText
        area={'city'}
        field={'city'}
        title={t('billing.sections.details.label-city')}
        placeholder={t('billing.sections.details.label-city')}
        value={current.city}
        change={change}
        status={valid['city'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'address-level2'}
      />
      <InputText
        area={'post_code'}
        field={'post_code'}
        title={t('details.form-field-post-code')}
        placeholder={t('details.form-field-post-code')}
        value={current.post_code}
        change={change}
        status={valid['post_code'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'postal-code'}
      />
      <InputSelect
        area={'country'}
        field={'country'}
        title={t('details.form-field-country')}
        placeholder={t('details.form-field-country')}
        options={countryCodes}
        value={current.country}
        change={change}
        status={valid['country'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
      />
      {current.country === 'US' && (
        <InputFilter
          area={'state'}
          field={'state'}
          title={'State'}
          placeholder={'State'}
          options={stateCodes}
          value={current.state}
          change={change}
          status={valid['state'] ? 'VALID' : 'INVALID'}
          required
          requiredText={t('details.field-required')}
          autocomplete={'address-level1'}
        />
      )}
    </Area>
  )
}

export default BillingForm
