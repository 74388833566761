import React from 'react'

import styles from './tick.scss'

import TickIcon from '_/icons/Tick'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const Tick = ({
  active,
  change,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            className={styleCombine(styles.layout, active && styles.selected)}
            onClick={change && change}
          >
            {active && (
              <TickIcon size={10} fill={theme.text_light} />
            )}
          </div>
        )
      }}
    />
  )
}

export default Tick
