import React, { Fragment, useEffect } from 'react'

import { useQueryClient } from 'react-query'

import {
  useDispatch,
  useSelector,
} from 'react-redux'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'
import { ResponsiveView as View } from '_/components/layout/View'

import NavTitle from '_/components/navigation/NavTitle'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'

import Container from '_/components/layout/Container'

import ActionCard from '_/components/action/ActionCard'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import { triggerEndSession } from '_/hooks/useSessionTimeout'

const mobileLayout = [
  'title',
  'message',
  'login',
  'home',
]

const desktopLayout = [
  'title',
  'message',
  'login/2 .',
  'home/2 .',
]

const Expired = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const reduxDispatch = useDispatch()

  queryClient.removeQueries('cart')
  queryClient.removeQueries('customer')

  useEffect(() => {
    triggerEndSession()

    reduxDispatch({
      type: 'END_SESSION',
    })
  }, [reduxDispatch])

  const user = useSelector(({ user }) => {
    return user
  })

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Fragment>
            <NavTitle mobile={mobile} title={t('expired.section-title')} />
            <View mobile={mobile} fullFlex>
              <Area
                areas={mobile ? mobileLayout : desktopLayout}
                columns={mobile ? 1 : 4}
                rowgap={1}
                baseColUnit={1}
              >
                <Heading level={1} title={t('expired.message-title')} area={'title'} noMargin />
                <Container area={'message'}>
                  <Heading level={3} title={t('expired.message-sub-title')} />
                  <TextBox>{t('expired.message-text')}</TextBox>
                </Container>
                {app.showConfirmLinks && (
                  <Fragment>
                    {!user.authenticated && app.showLogin && (
                      <ActionCard
                        area={'login'}
                        cta
                        label={t('confirm.action-login')}
                        change={() => { }}
                        anchor={t('urls.external-login')}
                      />
                    )}
                    <ActionCard
                      area={'home'}
                      label={t('confirm.action-home')}
                      change={() => { }}
                      anchor={t('urls.external-homepage')}
                    />
                  </Fragment>
                )}
              </Area>
            </View>
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

export default Expired
