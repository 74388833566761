import React from 'react'

import ModalRouter from '_/components/navigation/ModalRouter'

import Location from '_/modals/Location'
import Travel from '_/modals/Travel'
import TravelSimple from '_/modals/TravelSimple'
import Travellers from '_/modals/Travellers'
import TicketSelectionAmend from '_/modals/TicketSelectionAmend'
import TicketSelectionReturn from '_/modals/TicketSelectionReturn'
import TicketSelectionTime from '_/modals/TicketSelectionTime'
import Basket from '_/modals/Basket'

const EditorModal = ({
  mobile,
  modalProps,
  render,
}) => {
  return (
    <ModalRouter
      mobile={mobile}
      routes={{
        location: {
          desktop: {
            top: '5rem',
            left: '15vw',
            width: '25rem',
          },
          mobile: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
          view: ({
            ...props
          }) => {
            return (
              <Location {...props} {...modalProps} />
            )
          },
        },
        travel: {
          desktop: {
            top: '5rem',
            left: '15vw',
            width: '45rem',
          },
          mobile: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
          view: ({
            ...props
          }) => {
            return (
              <Travel {...props} {...modalProps} />
            )
          },
        },
        travelSimple: {
          desktop: {
            top: '5rem',
            left: '15vw',
            width: '45rem',
          },
          mobile: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
          view: ({
            ...props
          }) => {
            return (
              <TravelSimple {...props} {...modalProps} />
            )
          },
        },
        travellers: {
          desktop: {
            top: '5rem',
            left: '50vw',
            width: '25rem',
          },
          mobile: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
          view: ({
            ...props
          }) => {
            return (
              <Travellers {...props} {...modalProps} />
            )
          },
        },
        ticketSelectionTime: {
          desktop: {
            top: '3rem',
            left: '3rem',
            width: '70vw',
            bottom: '1rem',
          },
          mobile: {
            bottom: '0rem',
          },
          view: ({
            ...props
          }) => {
            return (
              <TicketSelectionTime {...props} {...modalProps} />
            )
          },
        },
        ticketSelectionAmend: {
          desktop: {
            top: '3rem',
            left: '3rem',
            width: '70vw',
            bottom: '1rem',
          },
          mobile: {
            bottom: '0rem',
          },
          view: ({
            ...props
          }) => {
            return (
              <TicketSelectionAmend {...props} {...modalProps} />
            )
          },
        },
        ticketSelectionReturn: {
          desktop: {
            top: '3rem',
            left: '3rem',
            width: '70vw',
            bottom: '1rem',
          },
          mobile: {
            bottom: '0rem',
          },
          view: ({
            ...props
          }) => {
            return (
              <TicketSelectionReturn {...props} {...modalProps} />
            )
          },
        },
        basket: {
          desktop: {},
          mobile: {
            height: 'calc(100vh - 2rem)',
            bottom: 0,
          },
          view: ({
            ...props
          }) => {
            return (
              <Basket {...props} {...modalProps} />
            )
          },
        },
      }}
      render={render}
    />
  )
}

export default EditorModal
