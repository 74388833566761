import React from 'react'
import classnames from 'classnames'

import styles from './textbox.scss'

const TextBox = (props) => {
  const {
    children,
    styling,
    last,
    noMargin,
    center,
    ...rest
  } = props

  return (
    <p {...rest} className={classnames(styles.layout, styling, {
      [styles.last]: last,
      [styles.center]: center,
      [styles.noMargin]: noMargin,
    })}>
      {children}
    </p>
  )
}

export default TextBox
