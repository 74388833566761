import { useRef } from 'react'

import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

const defaults = {
  isLoaded: false,
  market: null,
  locations: [],
  location_map: {},
  groups: [],
  group_map: {},
  tickets: [],
  tickets_map: {},
  tickets_group_map: {},
  products: [],
  combos: [],
  combo_map: {},
}

const useOverviewFilter = ({
  from,
  to,
}) => {
  const {
    env,
    app,
  } = window.TICKNOVATE_CONFIG

  const errorSent = useRef(false)
  const getOverview = apiCaller(process.env.REACT_APP_OVERVIEW_OVERWRITE || `markets/${env.market}/overview?start-date=${from}&end-date=${to}`, app.auth)

  const {
    isFetching,
    isSuccess,
    isError,
    data,
  } = useQuery(['product_overview_filter', from, to], getOverview, {
    retry: 0,
    staleTime: 1000 * 60 * 10, // Keep me at 10 minutes
  })

  if (isSuccess) {
    const {
      market,
      locations,
      ticket_type_groups,
      ticket_types,
      products,
      combos,
    } = data

    const group_map = ticket_type_groups
      .reduce((list, group) => {
        list[group.entity_id] = group.title

        return list
      }, {})

    return {
      ...defaults,
      isFetching,
      isLoaded: isSuccess,
      market,
      locations_raw: locations,
      locations: locations
        .map(location => ({
          value: location.id,
          label: `${location.country}, ${location.title}`,
        })),
      location_map: locations
        .reduce((list, location) => {
          list[location.id] = `${location.country}, ${location.title}`

          return list
        }, {}),
      groups: ticket_type_groups
        .map(group => ({
          external_id: group.id,
          entity_id: group.entity_id,
          icon: group.icon,
          value: group.entity_id,
          label: group.title,
        })),
      group_map,
      tickets: ticket_types
        .map(ticket => ({
          group: ticket.ticket_type_group.entity_id,
          value: ticket.id,
          label: ticket.title,
          max: ticket.mandatory_companions_max_free_selection,
          raw_id: ticket.entity_id,
          short_description: ticket.short_description,
        })),
      tickets_map: ticket_types
        .reduce((list, ticket) => {
          list[ticket.id] = `${group_map[ticket.ticket_type_group.entity_id]}, ${ticket.title}`

          return list
        }, {}),
      tickets_group_map: ticket_types
        .reduce((list, ticket) => {
          const group = ticket_type_groups.find(group => group.entity_id === ticket.ticket_type_group.entity_id)

          if (group) {
            list[ticket.id] = {
              id: group.id,
              entity_id: group.entity_id,
            }
          }

          return list
        }, {}),
      tickets_by_group: ticket_types
        .reduce((list, ticket) => {
          const group = ticket_type_groups.find(group => group.entity_id === ticket.ticket_type_group.entity_id)

          if (group) {
            if (list[group.id]) {
              list[group.id].push({
                ...ticket,
              })
            } else {
              list[group.id] = [{
                ...ticket,
              }]
            }
          }

          return list
        }, {}),
      products,
      product_map: products
        .reduce((list, product) => {
          list[product.id] = product.title

          return list
        }, {}),
      product_info_map: products
        .reduce((list, product) => {
          list[product.id] = product

          return list
        }, {}),
      combos,
      combo_map: combos
        .reduce((list, product) => {
          list[product.id] = product.title

          return list
        }, {}),
      combo_info_map: combos
        .reduce((list, product) => {
          list[product.id] = product

          return list
        }, {}),
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isLoaded: isError,
      isFetching,
      ...defaults,
    }
  }

  return {
    isFetching,
    ...defaults,
  }
}

export default useOverviewFilter
