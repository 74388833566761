import useAsyncData from './useAsyncData'
import { loadPaymentScripts } from '../../libs/loadPaymentScripts'

const useLoadPaymentScripts = enabledPaymentMethods => useAsyncData({
  promiseCreator: loadPaymentScripts,
  promiseCreatorArgs: [enabledPaymentMethods],
  promiseCreatorShouldRun: enabledPaymentMethods != null,
})

export default useLoadPaymentScripts
