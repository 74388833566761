import React from 'react'

const WarningIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path
        fill={fill}
        d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'
      />
    </svg>
  )
}

export default WarningIcon
