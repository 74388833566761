import React from 'react'
import { useTranslation } from 'react-i18next'
import { getStatusMeta } from '../../../libs/statuses'

import styles from './toast.scss'

import Row from '../../containers/Row'
import NudeButton from '../../atoms/NudeButton'
import Notification from '../../molecules/Notification'
import IconClose from '../../../icons/Close'

const Toast = ({ status, children, close, onMouseEnter }) => {
  const {
    t,
  } = useTranslation()

  return (
    <Row
      style={{ background: getStatusMeta(status).color }}
      styling={styles.toast}
      onMouseEnter={onMouseEnter}
    >
      <Notification
        status={status}
        color={window.TICKNOVATE_CONFIG.theme.text_light}
        role={'alert'}
        useAltIcon
      >
        {children}
      </Notification>
      <NudeButton onClick={close} title={t('common.dismiss', 'Dismiss')}>
        <IconClose fill={window.TICKNOVATE_CONFIG.theme.text_light} size={24} />
      </NudeButton>
    </Row>
  )
}

export default Toast
