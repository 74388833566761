import React from 'react'

import { useTranslation } from 'react-i18next'

import InlineTextBox from '_/components/layout/InlineTextBox'
import NavWrapper from '_/components/layout/NavWrapper'
import Link from '_/components/element/Link'
import Logo from '_/components/element/Logo'

const desktopStyle = {
  flexGrow: 0,
  flexShrink: 0,
  height: '3.75rem',
  padding: '0 2rem',
}

const mobileStyle = {
  flexGrow: 0,
  flexShrink: 0,
  height: '3.75rem',
  padding: '0 1rem',
}

const NavTitle = ({
  mobile,
}) => {
  const { t } = useTranslation()

  return (
    <NavWrapper rawStyle={mobile ? mobileStyle : desktopStyle} type={'spaced'} background={'background_tenant_nav'}>
      <Logo width={'10rem'} height={'3rem'} rawStyle={{ objectFit: 'contain', objectPosition: 'left 50%' }} />
      <Link url={t('urls.external-homepage')}>
        <InlineTextBox strong color={'nav_link'}>{t('confirm.action-home')}</InlineTextBox>
      </Link>
    </NavWrapper>
  )
}

export default NavTitle
