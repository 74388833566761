import React from 'react'
import View from '../containers/View'
import TextBox from '../containers/TextBox'

const BrowserOutdatedWarning = () => {
  return (
    <View style={{ textAlign: 'center' }}>
      <TextBox>Your web browser is outdated and does not support features required by this site.</TextBox>
      <TextBox>
        {'Consider downloading a modern browser, such as '}
        <a href={'https://www.google.com/chrome/'}>Google Chrome</a>,{' '}
        <a href={'https://www.mozilla.org/firefox'}>Firefox</a>,{' '}
        <a href={'https://www.opera.com'}>Opera</a>{' or '}
        <a href={'https://www.microsoft.com/windows/microsoft-edge'}>Microsoft Edge</a>.
      </TextBox>
    </View>
  )
}

export default BrowserOutdatedWarning
