import { getChannel } from '../reducers/globalSelectors'
import getStore from '_/store'
import getResourceURLs from '../libs/getResourceURLs'
import createApiCaller from '../libs/createApiCaller'

import getFreshAuthToken from '../api/getFreshAuthToken'

export const callApi = createApiCaller({
  getRequestChannel: async () => {
    const store = await getStore()
    return getChannel(store.getState())
  },
  getRequestAuth: getFreshAuthToken,
})

export const decorateWithServerOffsetTime = ({ response, data }) => {
  if (data instanceof Array) {
    return data.map(item => decorateWithServerOffsetTime({ data: item, response }))
  }

  const serverDate = response.headers.get('date')

  if (!serverDate) {
    throw new Error(
      'Expected "Date" header on response.\n' +
      'Ensure it is being sent, along with a "Access-Control-Expose-Headers" header if using CORS.',
    )
  }

  return {
    ...data,
    server_time_offset: new Date(serverDate).getTime() - Date.now(),
  }
}

/**
 * Retrieve resource from asset API
 * Currently, we calculate this locally to avoid unnecessary round trips
 * to the server. This is equivalent to calling `${API_HOST}/assets/${id}`
 */
export const getResource = (id) =>
  Promise.resolve(getResourceURLs(id))
