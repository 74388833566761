// This file contains helpers for an Affiliate Futures integration.
// See http://www.affiliatefuture.co.uk/
import cookies from 'js-cookie'
import { listen, PURCHASE } from '../events'
import { AFFC_URL_PARAM, AFFC_COOKIE_NAME } from './afConstants'

export const getAffiliateFuturesSession = () => {
  const { affiliateFuturesMerchantId } = window.ticknovateServiceGroupMeta || {}

  if (!affiliateFuturesMerchantId) {
    console.debug('No affiliateFuturesMerchantId found')
    return null
  }

  const affc = cookies.get(AFFC_COOKIE_NAME)

  if (!affc) {
    console.debug('No Affiliate Futures "affc" cookie found')
    return null
  }

  return JSON.stringify({
    affc,
    merchant: affiliateFuturesMerchantId,
  })
}

export const initAffiliateFutures = () => {
  const searchParams = new URLSearchParams(document.location.search)

  // Set cookie. Ordering backend is on the domain allowed by the cookie, so server can fire... // TODO: Finish train of thought
  if (searchParams.has(AFFC_URL_PARAM)) {
    const value = searchParams.get(AFFC_URL_PARAM)
    console.log(`${AFFC_URL_PARAM} param found in URL. Creating cookie with value ${value}.`)
    cookies.set(AFFC_COOKIE_NAME, value, {
      expires: 2, // days
    })
  }

  // Listen to purchase event. Delete cookie when this occurs.
  listen(PURCHASE, () => {
    cookies.remove(AFFC_COOKIE_NAME)
  })
}
