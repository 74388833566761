import cloneDeep from 'lodash/cloneDeep'

const initial = {
  id: null,
  token: null,
  persist: {
    outbound: null,
    inbound: null,
  },
  items: {
    outbound: null,
    inbound: null,
  },
  promo_code: null,
}

const reducer = (state = cloneDeep(initial), action) => {
  const store = { ...state }

  const {
    type,
    field,
    data,
  } = action

  switch (type) {
    case 'BASKET_SET_PROMO':
      store.promo_code = data

      return store
    case 'BASKET_PERSIST_ID':
      store.persist = data

      return store
    case 'BASKET_EDIT':
      store.items[field] = data

      return store
    case 'BASKET_REMOVE':
      store.items[field] = null

      return store
    case 'BASKET_EMPTY':
      return {
        ...cloneDeep(initial),
        id: store.id,
        token: store.token,
        persist: store.persist,
      }
    case 'APP_CLEAR':
      return cloneDeep(initial)
    case 'END_SESSION':
      return cloneDeep(initial)
    case 'BASKET_SET':
      return {
        ...cloneDeep(initial),
        ...data,
      }
    default:
      return store
  }
}

export default reducer
