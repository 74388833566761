import React, { useRef, useCallback } from 'react'

import styles from './dropdown.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

import useListNavigation from '_/hooks/useListNavigation'

const getIndex = (options, value) => {
  const index = options.findIndex(item => item.value === value)
  return index === -1 ? 0 : index
}

const DropDown = ({
  options,
  change,
  value,
  cancel,
  focusOnMount = true,
  ...style
}) => {
  const container = useRef(null)

  const keyChange = useCallback((focused) => {
    change(options[focused].value)
  }, [change, options])

  const {
    focused,
    handleKey,
  } = useListNavigation(container, getIndex(options, value), options, keyChange, cancel, focusOnMount)

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            ref={container}
            className={styles.drop}
            style={styling}
            onKeyDown={handleKey}
            tabIndex={0}
          >
            {options.map((option, index) => {
              const isFocused = index === focused
              const isSelected = option.value === value

              const mod = isSelected ? styles.selected : isFocused ? styles.focused : null

              return (
                <div
                  key={option.value}
                  className={styleCombine(styles.row, mod)}
                  onMouseDown={event => {
                    event.stopPropagation()

                    change(option.value)
                  }}
                >
                  <span>{option.label}</span>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

const Uncontrolled = (props) => {
  const {
    options,
    change,
    value,
    cancel,
    focused,
    getRef,
    ...style
  } = props

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div
            ref={getRef}
            className={styles.drop}
            style={styling}
          >
            {options.map((option, index) => {
              const isFocused = index === focused
              const isSelected = option.value === value

              const mod = isSelected ? styles.selected : isFocused ? styles.focused : null

              return (
                <div
                  key={option.value}
                  className={styleCombine(styles.row, mod)}
                  onMouseDown={event => {
                    event.stopPropagation()

                    change(option.value)
                  }}
                >
                  <span>{option.label}</span>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default DropDown

export {
  Uncontrolled,
  getIndex,
}
