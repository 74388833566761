import { translate, getLanguage } from '../locales'

export const moneyNumberToString = (value) => {
  return `${(value / 100).toFixed(2)}`
}

export const formatCurrency = (value, {
  useFreeText = false,
  freeTextString = 'Free',
  currencyCode,
} = {}) => {
  try {
    const locale = getLanguage()

    if (typeof value !== 'number' || Number.isNaN(value)) {
      throw new Error(`Value ${value} is not a number`)
    }

    if (useFreeText && value === 0) {
      return translate('common.free', 'Free')
    }

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: window.TICKNOVATE_CONFIG.app.currencyDisplay || 'symbol',
    }).format(value / 100)
  } catch (err) {
    console.error({ value })
    console.error(err)
    return 'Invalid Price'
  }
}
