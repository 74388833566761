const getUniqueAreas = (areas) => {
  return areas.reduce((acc, row) => {
    row.forEach(area => {
      if (!acc.includes(area)) acc.push(area)
    })
    return acc
  }, [])
}

export default getUniqueAreas
