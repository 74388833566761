import React, { Fragment } from 'react'

import {
  useTranslation,
} from 'react-i18next'

import styles from './ticket.scss'

import TextBox from '_/components/layout/TextBox'
import InlineTextBox from '_/components/layout/InlineTextBox'

import Row from '_/components/layout/Row'

import Icon from '_/components/element/Icon'
import OpenInBrowserIcon from '_/icons/OpenOnBrowser'

import InfoModal from '_/templates/InfoModal'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const getTime = time => {
  const [
    hour,
    minute,
  ] = time.split(':')

  return `${hour}:${minute}`
}

const Ticket = ({
  selected,
  combo,
  cheapest,
  promo,
  showDetails = true,
  data,
  change,
  icon = 'arrow_right',
  showDiscount = false,
  showTime = false,
}) => {
  const { t } = useTranslation()

  const price = formatCurrency(data.pricing.total, { currencyCode: data.currency })

  const disabled = data.status !== 'available'

  return (
    <Fragment>
      {showTime && data.type === 'time' && (
        <TimeBar data={data} />
      )}
      <div onClick={disabled ? null : change} className={styleCombine(styles.layout, selected && styles.selected, disabled && styles.disabled)}>
        <TextBox noMargin color={selected ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : 'ticket_day_text_default'} strong size={1.25}>{price}</TextBox>
        {data.status === 'sold_out' && (
          <TextBox noMargin color={'text_warning'} size={0.75} strong>{t('ticket.no-capacity')}</TextBox>
        )}
        {(showDiscount || promo) && (
          <TextBox noMargin color={selected ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : 'ticket_day_text_default'} size={0.75}>{data.combo_saving ? data.combo_saving : promo || t('ticket.no-discount')}</TextBox>
        )}
        <TextBox margin={'0.25rem 0 0 0'} color={selected ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : null} strong>{combo ? data.combo_title : data.product_title}</TextBox>
        {data.short_description && (
          <TextBox margin={'0.25rem 0 0 0'} color={selected ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : null}>{data.short_description}</TextBox>
        )}
        {data.outbound_product && (
          <ProductIndicator
            selected={selected}
            combo={combo}
            icon={'arrow_right'}
            label={data.outbound_product_category || data.outbound_product}
            disabled={disabled}
          />
        )}
        <ProductIndicator
          selected={selected}
          combo={combo}
          icon={icon}
          label={data.category || data.product_title}
          disabled={disabled}
        />
        {data.category && (
          <ProductIndicator
            selected={selected}
            combo
            icon={icon}
            label={data.category}
            disabled={disabled}
          />
        )}
        {selected && showDetails && (
          <Modal
            combo={combo}
            data={data}
          />
        )}
      </div>
    </Fragment>
  )
}

const Modal = ({
  data,
  combo,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <InfoModal
      product={data.product_id}
      combo={combo ? data.id : null}
      render={mount => {
        return (
          <div className={styles.details} onClick={event => {
            event.stopPropagation()

            const bounds = event.nativeEvent.target.getBoundingClientRect()

            mount(bounds)
          }}>
            <OpenInBrowserIcon size={18} fill={theme.text_light} />
            <span>{t('meta.info')}</span>
          </div>
        )
      }}
    />
  )
}

const ProductIndicator = ({
  selected,
  disabled,
  combo,
  icon,
  label,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  let color = 'text_disabled'

  if (selected) color = 'text_light'

  if (disabled) color = 'ticket_tile_text_disabled'

  return (
    <Row margin={'0.25rem 0 0 0'}>
      <Icon
        name={icon}
        size={icon === 'return' ? 24 : 20}
        fill={selected ? theme.text_light : disabled ? theme.ticket_tile_text_disabled : theme.text_disabled}
      />
      <InlineTextBox color={color} strong size={0.8}>{label}</InlineTextBox>
    </Row>
  )
}

const TimeBar = ({
  data,
}) => {
  return (
    <TextBox
      color={'text_light'}
      background={data.status !== 'available' ? 'ticket_time_indicator_disabled' : 'ticket_time_indicator'}
      textAlign={'center'}
      uppercase
      strong
      margin={'0 0 0.25rem 0'}
      padding={'0.25rem 0'}
    >
      {getTime(data.start_time)}
    </TextBox>
  )
}

export default Ticket
