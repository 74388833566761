import React from 'react'

const RemoveIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M19 13H5v-2h14v2z' fill={fill} />
    </svg>
  )
}

export default RemoveIcon
