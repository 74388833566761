import React from 'react'

import styles from './modalcolumn.scss'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import IconTitle from '_/templates/IconTitle'
import Heading from '_/components/layout/Heading'

import ActionCard from '_/components/action/ActionCard'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

const ModalColumn = ({
  compact,
  icon,
  title,
  children,
  noSave,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Column height={'100%'} padding={'0.75rem'} spread={false} maxHeight={mobile ? '100vh' : '75vh'}>
            {compact && (
              <Heading level={3} title={title} noFlex textAlign={'left'} width={'100%'} margin={'0 0 0.5rem 0'} />
            )}
            {!compact && (
              <IconTitle icon={icon} title={title} size={40} noFlex margin={'0 0 0.5rem 0'} />
            )}
            <div className={styles.content} style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch' }}>
              {children}
            </div>
            {!noSave && (
              <Row type={'end'} noFlex className={styles.cta}>
                <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
              </Row>
            )}
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default ModalColumn
