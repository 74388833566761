import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import TabNav from '_/components/navigation/TabNav'
import Area from '_/components/layout/Area'
import Heading from '_/components/layout/Heading'
import ActionBack from '_/components/action/ActionBack'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import Tickets from './Tickets'
import Details from './Details'
import HistoryTab from './History'

import history from '@ticknovate/frontend-shared/libs/history'

import styles from './customerhistory.scss'

const TICKETS_TAB_INDEX = 'ticket'
const DETAILS_TAB_INDEX = 'details'
const HISTORY_TAB_INDEX = 'history'

const tabs = [
  TICKETS_TAB_INDEX,
  DETAILS_TAB_INDEX,
  HISTORY_TAB_INDEX,
].map(v => ({ id: v, label: v }))

const getTabContentComponent = (tabId) => {
  switch (tabId) {
    case TICKETS_TAB_INDEX:
      return Tickets
    case DETAILS_TAB_INDEX:
      return Details
    case HISTORY_TAB_INDEX:
      return HistoryTab
    default:
      return Tickets
  }
}

const CustomerHistory = ({
  location,
  match,
}) => {
  const initial = location.state.initial || tabs[0].id

  const id = match.params.id
  const [selectedTabId, setTabId] = useState(initial)

  const TabContent = getTabContentComponent(selectedTabId)

  return (
    <MediaQuery media={media.mobile}>
      {mobile => (
        <Area
          areas={[
            'nav',
            'content',
          ]}
          columns={1}
          rows={mobile ? ['7.5rem', '1fr'] : ['5rem', '1fr']}
          colgap={0}
          rowgap={0}
          minHeight={'100vh'}
          height={'100vh'}
          fullFlex
        >
          <Nav
            area={'nav'}
            id={id}
            selected={selectedTabId}
            change={setTabId}
            mobile={mobile}
          />
          <div className={styles.content} area={'content'}>
            <TabContent
              id={id}
            />
          </div>
        </Area>
      )}
    </MediaQuery>
  )
}

const mobileNav = [
  'back heading',
  'back tabs',
]

const desktopNav = [
  'back heading tabs',
]

const Nav = ({
  id,
  selected,
  change,
  mobile,
  ...style
}) => {
  const { t } = useTranslation()

  const translatedTabs = tabs.map(tab => {
    return {
      ...tab,
      label: t(`customer-history.tab-${tab.label}`),
    }
  })

  return (
    <Area
      areas={mobile ? mobileNav : desktopNav}
      columns={mobile ? ['2.5rem', '1fr'] : ['2.5rem', '1fr', '1fr']}
      rows={mobile ? ['4rem', '3.5rem'] : ['5rem']}
      colgap={1}
      rowgap={0}
      background={'background_nav'}
      height={mobile ? '7.5rem' : '5rem'}
      overflow={'hidden'}
    >
      <ActionBack
        area={'back'}
        change={() => history.replace('/orders')}
        color={'background_nav'}
        background={'button'}
      />
      <Heading
        area={'heading'}
        title={`${t('meta.booking')}: ${id}`}
        level={2}
        color={'nav_text'}
        gridAlign={'center'}
      />
      <TabNav
        area={'tabs'}
        tabs={translatedTabs}
        selected={selected}
        change={change}
        gridAlign={'end'}
        overflow={'scroll'}
      />
    </Area>
  )
}

Nav.styleable = true

export default CustomerHistory
