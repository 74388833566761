import React, { Fragment, useState, useRef } from 'react'

import Modal from '_/components/layout/Modal'

const ModalRouter = ({
  current,
  update,
  mobile,
  routes = {},
  render,
}) => {
  const initial_step = useRef(0)

  const [
    active,
    setActive,
  ] = useState(null)

  const [
    mounted,
    setMounted,
  ] = useState(false)

  const end = () => {
    setActive(null)
  }

  const mount = (slug, initial = 0) => {
    initial_step.current = initial

    setActive(slug)
    setMounted(true)
  }

  const close = () => {
    setMounted(false)
  }

  return (
    <Fragment>
      {render(mount)}
      {active !== null && routes[active] && (
        <Modal
          mounted={mounted}
          endHandler={end}
          rawStyle={!mobile ? routes[active].desktop : routes[active].mobile}
        >
          {routes[active].view({
            close,
            current,
            update,
            mount,
            initial: initial_step.current,
          })}
        </Modal>
      )}
    </Fragment>
  )
}

export default ModalRouter
