import React from 'react'

import StatefulWrapper from '_/components/layout/StatefulWrapper'
import Label from '_/components/layout/Label'
import Input from '_/components/element/Input'

import ValidityIndicator from '_/components/element/ValidityIndicator'

const InputText = ({
  placeholder,
  name,
  field,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  hideRequiredMessage,
  change,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  controlled = false,
  autocomplete,
  ...style
}) => {
  const handleChange = (event) => change(field, event.target.value)

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      hideRequiredMessage={hideRequiredMessage}
      minHeight={minHeight}
      disabled={disabled}
      indicator={state => (<ValidityIndicator status={status} margin={'0 0.25rem'} />)}
      controlled={controlled}
      {...style}
    >
      <Input
        name={name || field}
        type={'text'}
        placeholder={placeholder}
        value={value}
        change={handleChange}
        margin={margin}
        disabled={disabled}
        autoComplete={autocomplete ?? 'off'}
      />
    </StatefulWrapper>
  )
}

const InputTextWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputText {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputText {...props} />
  )
}

export default InputTextWrapped

export {
  Raw,
}
