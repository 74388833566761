import React from 'react'
import styles from './button.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const CardButton = (props) => {
  const {
    change,
    anchor = null,
    cta = false,
    warning = false,
    offer = false,
    disabled = false,
    withBorder = true,
    children,
    ...style
  } = props

  const {
    env,
  } = window.TICKNOVATE_CONFIG

  let type = null

  if (cta) type = styles.cta
  if (warning) type = styles.warning
  if (offer) type = styles.offer
  if (disabled) type = styles.disabled

  let target = {}

  if (env.embeddedMode) {
    target = {
      target: '_parent',
      rel: 'noreferrer',
    }
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        if (anchor) {
          return (
            <a
              style={styling}
              className={styleCombine(styles.button, type, withBorder && styles.withBorder)}
              disabled={disabled}
              href={anchor}
              {...target}
            >
              {children}
            </a>
          )
        } else {
          return (
            <button
              style={styling}
              className={styleCombine(styles.button, type, withBorder && styles.withBorder)}
              onClick={change}
              disabled={disabled}
            >
              {children}
            </button>
          )
        }
      }}
    />
  )
}

CardButton.styleable = true

export default CardButton
