import getTime from 'date-fns/get_time'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/start_of_month'
import getDaysInMonth from 'date-fns/get_days_in_month'
import isSameDay from 'date-fns/is_same_day'
import isAfter from 'date-fns/is_after'
import isBefore from 'date-fns/is_before'
import isValid from 'date-fns/is_valid'

import startOfHour from 'date-fns/start_of_hour'
import addHour from 'date-fns/add_hours'

/**
 * A wrapper around date-fns's isValid function that does not throw
 * an error when passing malformed date strings.
 *
 * @param {String|Number|Date} date
 * @returns {Boolean}
 */
const isValidDate = date => isValid(new Date(date))

const deconstructDate = (dateString) => {
  const [
    ISO,
    time,
  ] = dateString.slice(0, -8).split('T')

  return {
    ISO,
    time,
  }
}

const getStamp = (date) => getTime(parse(date))

const getToday = () => {
  return stampToDateISO(new Date())
}

const getTimeNow = (offset = 1, nearestHour = true) => {
  const stamp = addHour(new Date(), offset)

  if (nearestHour) {
    return format(startOfHour(stamp), 'HH:mm')
  } else {
    return format(new Date(), 'HH:mm')
  }
}

const getDateTimeNow = (nearestHour = true) => {
  const stamp = addHour(new Date(), 1)

  if (nearestHour) {
    const [
      date,
      time,
    ] = format(startOfHour(stamp), 'YYYY-MM-DD|HH:mm').split('|')

    return {
      date,
      time,
    }
  } else {
    const [
      date,
      time,
    ] = format(new Date(), 'YYYY-MM-DD|HH:mm').split('|')

    return {
      date,
      time,
    }
  }
}

const getMonthInfo = (date) => {
  date = parse(date)

  return {
    firstDay: startOfMonth(date).getDay(),
    length: getDaysInMonth(date),
    long: format(date, 'MMMM'),
    short: format(date, 'MMM'),
    year: format(date, 'YYYY'),
  }
}

const stampToDateISO = (stamp) => {
  stamp = parse(stamp)

  return format(stamp, 'YYYY-MM-DD')
}

const splitDate = (date, iso) => {
  const [
    day,
    month,
    year,
  ] = format(parse(date), 'D/M/YYYY').split('/')

  return {
    day: Number(day),
    month: iso ? Number(month) - 1 : Number(month),
    year: Number(year),
    clean: format(parse(date), 'D/M/YYYY'),
  }
}

const stampFromYM = (year, month) => getStamp(new Date(year, month, 1))

const isBetween = (start, end, date) => {
  return (isSameDay(start, date) || isAfter(date, start)) && (isSameDay(end, date) || isBefore(date, end))
}

const isBeforeOrSame = (end, date) => {
  return isSameDay(end, date) || isBefore(date, end)
}

const isDateBefore = (end, date) => {
  return isBefore(date, end)
}

const isAfterOrSame = (end, date) => {
  return isSameDay(end, date) || isAfter(date, end)
}

const stampToStrings = (date) => {
  date = parse(date)

  return {
    dateShort: format(date, 'D'),
    dateLong: format(date, 'DD'),
    dayOfWeek: Number(format(date, 'd')),
    dayShort: format(date, 'ddd'),
    dayLong: format(date, 'dddd'),
    dayOrd: format(date, 'Do'),
    monthShort: format(date, 'MMM'),
    monthLong: format(date, 'MMMM'),
    year: format(date, 'YYYY'),
    time: format(date, 'HH:mm'),
    ISO: format(date, 'YYYY-MM-DD'),
  }
}

export {
  getToday,
  getTimeNow,
  getDateTimeNow,
  stampToDateISO,
  splitDate,
  stampFromYM,
  getMonthInfo,
  isBetween,
  isDateBefore,
  isBeforeOrSame,
  isAfterOrSame,
  getStamp,
  stampToStrings,
  deconstructDate,
  isValidDate,
}
