import React, { useRef, useReducer, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { trigger, listen, TOASTER_MESSAGE } from '@ticknovate/frontend-shared/libs/events'

import styles from './toaster.scss'

import Toast from './Toast'

import WarningIcon from '_/icons/Warning'

let idCount = 0

const reducer = (state, action) => {
  const messages = [...state]

  switch (action.type) {
    case 'ADD':
      return [
        action.payload,
        ...messages,
      ]
    case 'REMOVE': {
      const index = messages.findIndex(item => item.id === action.id)

      messages.splice(index, 1)

      return messages
    }
    default:
      return state
  }
}

const Toaster = () => {
  const unListen = useRef(null)

  const [
    notifications,
    dispatch,
  ] = useReducer(reducer, [])

  useEffect(() => {
    if (unListen.current === null) unListen.current = listen(TOASTER_MESSAGE, handleMessageEvent)

    return () => {
      if (unListen.current !== null) unListen.current()
    }
  }, [dispatch])

  const handleMessageEvent = ({
    status,
    message,
    icon = null,
    persist = false,
  }) => {
    const id = idCount++

    const close = () => dispatch({ type: 'REMOVE', id })

    let timeout

    if (!persist) {
      timeout = setTimeout(
        close,
        window.TICKNOVATE_CONFIG.timeouts.TOAST_MESSAGE_DISPLAY_TIME_MILLISECONDS,
      )
    }

    const payload = {
      id,
      status,
      message,
      icon,
      close,
      pause: () => clearTimeout(timeout),
      active: true,
    }

    dispatch({ type: 'ADD', payload })
  }

  return (
    <Content notifications={notifications} />
  )
}

const Content = ({
  notifications,
}) => {
  const content = (
    <div className={styles.toaster}>
      {notifications.map(({ id, ...rest }) => {
        return (
          <Toast key={id} {...rest} />
        )
      })}
    </div>
  )

  return createPortal(
    content,
    document.getElementById('toast-messages'),
  )
}

const triggerToastMessage = ({
  icon,
  message,
  status,
  persist,
}) => {
  trigger(TOASTER_MESSAGE, {
    icon,
    message,
    status,
    persist,
  })
}

const triggerToastMessageForError = error => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  if (error.message) {
    triggerToastMessage({
      status: 'error',
      icon: (<WarningIcon fill={theme.text_light} size={24} />),
      message: error.message,
      persist: true,
    })
  } else {
    triggerGenericToastError()
  }
}

const triggerGenericToastError = () => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  triggerToastMessage({
    status: 'error',
    icon: (<WarningIcon fill={theme.text_light} size={24} />),
    message: 'Something went wrong...',
    persist: true,
  })
}

export default Toaster

export {
  triggerToastMessage,
  triggerToastMessageForError,
  triggerGenericToastError,
}
