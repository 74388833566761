import { morphism } from 'morphism'
import makeSerialID from '_/libs/makeSerialID'

const getBookingSearchSchema = (options) => {
  return morphism(
    {
      'types': 'type',
      'products': 'product_id',
      'booking': 'booking',
      'dates': {
        path: 'dates',
        fn: (value) => {
          if (!value) return []
          return value.join(',')
        },
      },
      'from': 'location',
      'to': 'end_location',
      'ticket-types': {
        path: 'ticket',
        fn: (value) => {
          if (!value) return []
          return value
            .map(({ id, qty }) => {
              return `${id}:${qty}`
            })
            .join(',')
        },
      },
      'market': 'market',
      'selection': 'selection',
      'time': 'time',
      'include': 'include',
    },
    options,
  )
}

const getBookingOptionSchema = (booking, meta, restrict_combo = null) => {
  return morphism(
    {
      options: {
        path: 'options',
        fn: (options, booking) => {
          if (!options) return []
          return options.map(option => morphism(optionSchema(booking, meta, restrict_combo), option))
        },
      },
    },
    booking,
  )
}

const optionSchema = (booking, meta, restrict_combo) => {
  return {
    type: 'type',
    pick_id: (option) => makeSerialID(option),
    currency: () => booking.currency,
    product_id: 'product_id',
    product_title: (option) => meta.product_map[option.product_id],
    instance_id: 'instance_id',
    start_date: 'start_date',
    start_time: 'start_time',
    status: 'status',
    pricing: 'pricing',
    pricing_entire_selection: 'pricing_entire_selection',
    pricing_potential_best: 'pricing_potential_best',
    combos: {
      path: 'combos',
      fn: (combos, option) => {
        if (!combos) return []
        return combos
          .filter(({ entire_combo_pricing, id }) => {
            if (restrict_combo !== null) {
              return option.pricing.combo_id !== null && option.pricing.combo_id === id && id === restrict_combo
            } else {
              return entire_combo_pricing === null || id === option.pricing_entire_selection.combo_id
            }
          })
          .map(combo => morphism(comboSchema(booking, option, meta), combo))
      },
    },
    legs: 'legs',
  }
}

const comboSchema = (booking, option, meta) => {
  return {
    combo_pick_id: 'id',
    combo_title: (combo) => booking.combos.find(({ id }) => id === combo.id)?.title,
    product_title: () => meta.product_map[option.product_id],
    combo_saving: (combo) => {
      const product = booking.combos.find(({ id }) => id === combo.id)

      const {
        combo_index,
      } = combo

      const actual_index = product.product_selection[combo_index + 1] ? combo_index + 1 : combo_index

      return product.product_selection[actual_index]?.discount_description
    },
    product_id: () => option.product_id,
    pricing: (combo) => {
      const {
        entire_combo_pricing,
        pricing,
      } = combo

      return entire_combo_pricing || pricing
    },
    currency: () => booking.currency,
    status: () => option.status,
    short_description: (combo) => booking.combos.find(({ id }) => id === combo.id)?.short_description,
    selection: () => ({
      pick_id: makeSerialID(option),
    }),
  }
}

export {
  getBookingSearchSchema,
  getBookingOptionSchema,
}
