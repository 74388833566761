import React from 'react'
import classnames from 'classnames'
import { getStatusMeta } from '../../../libs/statuses'

import styles from './notification.scss'

import Grid from '../../containers/Grid'
import GridCell from '../../containers/GridCell'
import Container from '../../containers/Container'
import TextBox from '../../containers/TextBox'
import withMediaQuery, { media } from '../../HOC/withMediaQuery'

/**
 * Render the component's `children` beside an icon.
 * The text color and icon depend on the `status` prop passed.
 *
 * @param {Object} props
 * @param {React.Node} [props.children]
 * @param {String} [props.status] 'error', 'success', or undefined for default state
 * @param {String} [props.size] 'small' or 'large'
 * @param {Boolean} [props.useAltIcon]
 * @param {String} [props.styling]
 */
const Notification = ({
  children,
  status,
  color,
  styling,
  useAltIcon,
  size = 'small',
  mediaQuery,
  center,
  centerVertical,
  inline,
  bold,
  role,
  onClick,
  icon: OverrideIcon,
  ...rest
}) => {
  const { Icon, IconAlternative, colorText: defaultColor } = getStatusMeta(status)
  const IconComponent = OverrideIcon || (
    useAltIcon
      ? IconAlternative
      : Icon
  )
  const iconSize = size === 'small'
    ? mediaQuery.mobile ? 18 : 24
    : mediaQuery.mobile ? 32 : 50
  color = color || defaultColor

  return (
    <Container
      styling={classnames(styling, {
        [styles.center]: center,
        [styles.centerVertical]: centerVertical,
        [styles.bold]: bold,
        [styles.inline]: inline || center,
        [styles.interactive]: onClick,
      })}
    >
      <Grid
        cols={2}
        gap={size === 'small' ? 'tiny' : 'small'}
        styling={styles.grid}
        onClick={onClick}
        {...rest}
      >
        <GridCell colspan={1} rowspan={1} styling={styles.iconCell}>
          <IconComponent fill={color} size={iconSize} />
        </GridCell>
        <GridCell colspan={1} rowspan={1}>
          <TextBox
            style={{ color }}
            styling={classnames(styles.text, {
              [styles.textLarge]: size === 'large',
            })}
            role={role}
          >
            {children}
          </TextBox>
        </GridCell>
      </Grid>
    </Container>
  )
}

export default withMediaQuery({
  mobile: media.mobile,
})(Notification)
