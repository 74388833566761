import { useRef } from 'react'

import { decorateWithServerOffsetTime } from '@ticknovate/frontend-shared/api/base'

import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

import orderDecorator from '_/libs/orderDecorator'

const getOrder = (id) => apiCaller(`orders/${id}`, true)({ includeResponse: true })
  .then(decorateWithServerOffsetTime)

const useSalesOrder = (id) => {
  const errorSent = useRef(false)

  const {
    isSuccess,
    isError,
    data,
  } = useQuery(['customer', 'order', id], () => getOrder(id), {
    retry: 0,
    staleTime: 1000 * 60 * 10, // Keep me at 10 minutes
  })

  if (isSuccess) {
    return {
      isLoaded: isSuccess,
      data: orderDecorator(data),
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isLoaded: isError,
      data: {},
    }
  }

  return {
    isLoaded: false,
    data: {},
  }
}

export default useSalesOrder
