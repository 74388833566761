import React from 'react'
import classnames from 'classnames'

import styles from './checkableinput.scss'
import utilStyles from '_/styles/util.scss'
import useFocus from '../../../components/hooks/useFocus'
import CheckIcon from '../../../icons/Check'

const CheckableInput = props => {
  const {
    children,
    name,
    value,
    checked,
    large,
    onChange,
    styling,
    style = 'default',
    type,
    disabled,
  } = props
  // JS used for focus detection for a bit more robust focus ring
  // that does not depend on the ordering of DOM nodes.
  const { focusProps, focusClass } = useFocus()

  return (
    <label
      className={classnames(
        styling,
        styles.layout,
        styles[`style_${style}`],
        focusClass,
        {
          [styles.active]: checked,
          [styles.large]: large,
          [styles.disabled]: disabled,
        },
      )}
      data-test-handle={props['data-test-handle']}
    >
      <input
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        className={utilStyles.visuallyHidden}
        onChange={onChange}
        checked={checked}
        {...focusProps}
      />
      {type === 'radio' && <span className={styles.radio} />}
      {type === 'checkbox' && (
        <span className={styles.checkbox}>
          {checked && <CheckIcon size={large ? 16 : 10} fill={window.TICKNOVATE_CONFIG.theme.text_light} />}
        </span>
      )}
      <span className={styles.label}>{children}</span>
    </label>
  )
}

export default CheckableInput
