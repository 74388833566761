import React, { Fragment, useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import Modal from '_/components/layout/Modal'
import ModalView from '_/components/layout/ModalView'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'

import ActionCard from '_/components/action/ActionCard'

import { listen } from '@ticknovate/frontend-shared/libs/events'
import { SESSION_TIMEOUT, SESSION_ENDED } from '_/hooks/useSessionTimeout'

const TimeoutModal = ({
  render = null,
}) => {
  const { t } = useTranslation()

  const [
    active,
    setActive,
  ] = useState(false)

  const [
    mounted,
    setMounted,
  ] = useState(false)

  useEffect(() => {
    const removeMount = listen(SESSION_TIMEOUT, mount)
    const removeClose = listen(SESSION_ENDED, close)

    return () => {
      removeMount()
      removeClose()
    }
  }, [])

  const end = () => {
    setActive(false)
  }

  const mount = (bounds) => {
    setActive(true)
    setMounted(true)
  }

  const close = () => {
    setMounted(false)
  }

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return (
    <Fragment>
      {render && render(mount)}
      {active && (
        <MediaQuery media={media.mobile}>
          {mobile => {
            return (
              <Modal
                mounted={mounted}
                endHandler={end}
                portal={'widget'}
                rawStyle={{
                  left: mobile ? 'calc((100vw - 20rem) / 2)' : 'calc((100vw - 20rem) / 2)',
                  top: 'calc((100vh - 20rem) / 2)',
                  width: '20rem',
                  bottom: 'auto',
                  minHeight: '10rem',
                }}
              >
                <ModalView type={'dialogue'}>
                  <Heading title={t('session.expired-heading')} level={2} margin={'0 0 1rem 0'} />
                  <TextBox margin={'0 0 2rem 0'}>{t('session.expired-message')}</TextBox>
                  <ActionCard
                    area={'login'}
                    cta
                    label={t('session.button-try-again')}
                    change={() => { }}
                    anchor={t(['urls.external-homepage', '/explore'])}
                    margin={'0 0 1rem 0'}
                  />
                  {app.showLogin && (
                    <ActionCard
                      area={'login'}
                      cta
                      label={t('confirm.action-login')}
                      change={() => { }}
                      anchor={t('urls.external-login')}
                    />
                  )}
                </ModalView>
              </Modal>
            )
          }}
        </MediaQuery>
      )}
    </Fragment>
  )
}

export default TimeoutModal
