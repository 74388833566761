import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { parse } from 'duration-fns'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Icon from '_/components/element/Icon'
import InfoModal from '_/templates/InfoModal'
import Button from '_/components/element/Button'

import TicketChooser from './TicketChooser'

import useOverview from '_/hooks/useOverview'

import { formatUTC } from '_/libs/dateFormatter'
import localiseDuration from '_/libs/localiseDuration'

import getPickId from './getPickId'

// Will need to add/replace these icons for forsea as they are a different style
import BoatIcon from '_/icons/Boat'
import DurationIcon from '_/icons/Duration'
import TransferEndIcon from '_/icons/TransferEnd'
import HasTransferIcon from '_/icons/HasTransfer'
import InfoIcon from '_/icons/Info'
import TicketIcon from '_/icons/Ticket'

import upperCaseFirst from '_/libs/upperCaseFirst'

const formatTime = (stamp) => { // I apologise Dan
  const [
    hours,
    minutes,
  ] = stamp.split('T')[1].split('+')[0].split(':')

  return `${Number(hours)}:${minutes}`
}

const DetailLegs = ({
  mobile,
  title,
  data,
  change,
  separator = false,
  selection,
  showChooser = true,
}) => {
  const [picked] = useState(getPickId(data))
  const meta = useOverview()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const {
    legs,
    product,
  } = data

  const location = meta.location_map[legs[0].location.id]
  const end_location = meta.location_map[legs[legs.length - 1].end_location.id]

  const details = [
    {
      Component: () => (
        <DetailRow
          label={formatUTC(data.booked_unit.start_date, 'dddd, D MMMM YYYY')}
          color={'journey_detail_text'}
          icon={() => (
            <Icon name={'calendar'} size={20} fill={theme['journey_detail_text']} />
          )}
        />
      ),
    },
    ...data.ticket_types
      .filter(({ qty }) => qty > 0)
      .map(({ id, qty }) => {
        const iconName = meta.tickets_group_map[id].icon

        return {
          Component: () => (
            <DetailRow
              label={`${qty} x ${meta.tickets_map[id].short}`}
              color={'journey_detail_text'}
              icon={() => (
                <Icon name={iconName} size={20} fill={theme['journey_detail_text']} />
              )}
            />
          ),
        }
      }),
  ]

  return (
    <Fragment>
      <Area
        areas={[
          'title',
          ...(showChooser ? ['chooser'] : []),
          'ticket',
          'legs',
        ]}
        columns={1}
        rowgap={1.5}
      >
        <Container area={'title'}>
          <Heading
            area={'title'}
            level={1}
            title={title}
            color={'journey_title'}
          />
          <TextBox
            area={'journey'}
            size={1}
            strong
            noMargin
            color={'journey_title'}
          >
            {`${location} - ${end_location}`}
          </TextBox>
        </Container>
        {showChooser && (
          <TicketChooser
            area={'chooser'}
            mobile={mobile}
            data={data}
            change={change}
            picked={picked}
            selection={selection}
          />
        )}
        <Container padding={'0 0.5rem'} area={'ticket'}>
          <InfoList options={details} />
        </Container>
        <Container area={'legs'}>
          {legs.map((leg) => {
            const {
              operator_type,
            } = leg

            const LegComponent = operator_type === 'transfer' ? TransferLeg : StandardLeg

            return (
              <LegComponent
                data={leg}
                meta={meta}
                product={product}
              />
            )
          })}
        </Container>
      </Area>
      {separator && (
        <Boundary />
      )}
    </Fragment>
  )
}

const TransferLeg = ({
  data,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const {
    duration,
    important_info,
  } = data

  return (
    <Container padding={'0 0.5rem'} area={'ticket'} margin={'1.5rem 0'}>
      <InfoList options={[
        {
          Component: () => (
            <DetailRow
              label={localiseDuration(parse(duration), t)}
              color={'journey_detail_text'}
              icon={() => (
                <HasTransferIcon size={20} fill={theme['journey_detail_text']} />
              )}
            />
          ),
        },
        {
          Component: () => (
            <DetailRow
              label={important_info}
              color={'journey_detail_text'}
              icon={() => (
                <Icon name={'car'} size={20} fill={theme['journey_detail_text']} />
              )}
            />
          ),
        },
      ]} />
    </Container>
  )
}

const StandardLeg = ({
  data,
  meta,
  product,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const {
    start,
    end,
    location,
    end_location,
    duration,
    operator_type,
    important_info,
  } = data

  return (
    <Container background={'card_default'} padding={'1rem 0.5rem'}>
      <Row spread={false}>
        <BoatIcon size={20} fill={theme['journey_leg_icon']} />
        <InlineTextBox margin={'0 0.75rem'} color={'journey_leg_icon'} strong size={1.25}>{formatTime(start)}</InlineTextBox>
        <InlineTextBox strong size={1.1}>{meta.location_map[location.id]}</InlineTextBox>
      </Row>
      <InfoList margin={'1rem 0'} barColor={'journey_leg_bar'} options={[
        ...(important_info === '' ? [] : [{
          Component: () => (
            <DetailRow
              label={important_info}
              color={'text'}
              icon={() => (
                <InfoIcon size={20} fill={theme['text']} />
              )}
            />
          ),
        }]),
        {
          Component: () => (
            <DetailRow
              label={`${t('meta.vehicle')}, ${operator_type === 'internal' ? t('ticket.label-internal-operator') : upperCaseFirst(operator_type)}`}
              color={'text'}
              icon={() => (
                <BoatIcon size={20} fill={theme['text']} />
              )}
            />
          ),
        },
        {
          Component: () => (
            <DetailRow
              label={localiseDuration(parse(duration), t)}
              color={'text'}
              icon={() => (
                <DurationIcon size={20} fill={theme['text']} />
              )}
            />
          ),
        },
        {
          Component: () => (
            <ModalRow
              product={product}
            />
          ),
        },
      ]} />
      <Row spread={false}>
        <TransferEndIcon size={20} fill={theme['journey_leg_icon']} />
        <InlineTextBox margin={'0 0.75rem'} color={'journey_leg_icon'} strong size={1.25}>{formatTime(end)}</InlineTextBox>
        <InlineTextBox strong size={1.1}>{meta.location_map[end_location.id]}</InlineTextBox>
      </Row>
    </Container>
  )
}

const Boundary = () => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Container
      rawStyle={{
        margin: '2rem 0 1rem 0',
        borderBottom: `1px dashed ${theme['journey_leg_bar']}`,
      }}
    />
  )
}

const Bar = ({
  background = 'journey_detail_bar',
  ...style
}) => {
  return (
    <Container
      background={background}
      {...style}
      margin={'0 auto'}
      rawStyle={{
        width: '0.5rem',
        borderRadius: '0.25rem',
        alignSelf: 'stretch',
      }}
    />
  )
}

Bar.styleable = true

const InfoList = ({
  barColor = 'journey_detail_bar',
  options,
  ...style
}) => {
  return (
    <Area
      areas={options.map((option, index) => {
        return `bar option_${index}`
      })}
      columns={['1.5rem', '1fr']}
      colgap={0.75}
      rowgap={0.25}
      {...style}
    >
      <Bar area={'bar'} background={barColor} />
      {options.map(({ Component }, index) => {
        return (
          <Component key={index} area={`option_${index}`} />
        )
      })}
    </Area>
  )
}

const DetailRow = ({
  icon,
  label,
  color = 'text',
}) => {
  return (
    <Row spread={false}>
      {icon()}
      <InlineTextBox margin={'0 0 0 1rem'} color={color}>{label}</InlineTextBox>
    </Row>
  )
}

const ModalRow = ({
  product,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <InfoModal
      product={product.id}
      render={mount => {
        return (
          <Row spread={false}>
            <TicketIcon size={20} fill={theme['text']} />
            <InlineTextBox margin={'0 0.25rem 0 1rem'}>{t('meta.ticket')}</InlineTextBox>
            <Button
              padding={'0'}
              change={event => {
                event.stopPropagation()

                const bounds = event.nativeEvent.target.getBoundingClientRect()

                mount(bounds)
              }}
            >
              <InlineTextBox color={'journey_leg_icon'}>{t('meta.read-more')}</InlineTextBox>
            </Button>
          </Row>
        )
      }}
    />
  )
}

export default DetailLegs
