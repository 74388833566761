import React from 'react'

const FoodIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
    >
      <path
        fill={fill}
        d='M24.81,32h2.42a2.42,2.42,0,0,0,2.37-2.13l2.4-24H24.73V0H21.86V5.89H14.63l.44,3.4a15,15,0,0,1,6.21,3.29c2.09,2.07,3.53,4.21,3.53,7.7Z'
      />
      <path
        fill={fill}
        d='M0,30.53V29.09H21.86v1.44A1.46,1.46,0,0,1,20.39,32H1.47A1.46,1.46,0,0,1,0,30.53Z'
      />
      <path
        fill={fill}
        d='M21.86,20.35C21.86,8.71,0,8.71,0,20.35Z'
      />
      <rect
        fill={fill}
        x='0.03'
        y='23.27'
        width='21.82'
        height='2.91'
      />
    </svg>
  )
}

export default FoodIcon
