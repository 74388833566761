import React from 'react'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'

import InputText from '_/components/input/InputText'

// const layout = [
//   'firstName/2 lastName/2',
//   'phoneNumber/3 .',
//   'email/3 .',
//   'emailConfirm/3 .'
// ]

const layout = {
  mobile: [
    'firstName/2 lastName/2',
    'phoneNumber',
    'email',
    'emailConfirm',
    'billing',
    'notes',
  ],
  tablet: [
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'emailConfirm',
    'billing',
    'notes',
  ],
  desktop: [
    'firstName/2 lastName/2',
    'phoneNumber/3 .',
    'email/3 .',
    'emailConfirm/3 .',
    'billing',
    'notes/3 .',
  ],
}

const ReducedForm = ({
  breakpoint,
  current,
  valid,
  change,
  children,
}) => {
  const { t } = useTranslation()

  const {
    details,
  } = window.TICKNOVATE_CONFIG

  return (
    <Area
      area={'form'}
      areas={layout[breakpoint]}
      colgap={1.5}
      rowgap={1.5}
      columns={4}
      margin={'1rem 0 0 0'}
    >
      <InputText
        area={'firstName'}
        field={'customer.firstname'}
        title={t('details.form-field-first-name')}
        placeholder={t('details.form-field-first-name')}
        value={current.customer.firstname}
        change={change}
        status={valid['customer.firstname'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'given-name'}
      />
      <InputText
        area={'lastName'}
        field={'customer.lastname'}
        title={t('details.form-field-last-name')}
        placeholder={t('details.form-field-last-name')}
        value={current.customer.lastname}
        change={change}
        status={valid['customer.lastname'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'family-name'}
      />
      <InputText
        area={'phoneNumber'}
        field={'customer.telephone'}
        title={t('details.form-field-phone')}
        placeholder={t('details.form-field-phone')}
        value={current.customer.telephone}
        change={change}
        status={valid['customer.telephone'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'tel'}
      />
      <InputText
        area={'email'}
        field={'customer.email'}
        title={t('details.form-field-email')}
        placeholder={t('details.form-field-email')}
        value={current.customer.email}
        change={change}
        status={valid['customer.email'] ? 'VALID' : 'INVALID'}
        required
        requiredText={t('details.field-required')}
        autocomplete={'email'}
      />
      <InputText
        area={'emailConfirm'}
        field={'meta.email_confirm'}
        title={t('details.form-field-email-confirm')}
        placeholder={t('details.form-field-email-confirm')}
        value={current.meta.email_confirm}
        change={change}
        status={valid['meta.email_confirm'] ? 'VALID' : 'INVALID'}
        required
        hideRequiredMessage
        autocomplete={'email'}
      />
      {children}
      {details.showLicensePlate && (
        <InputText
          area={'notes'}
          field={'customer.license_plate'}
          title={t('details.form-field-license-plate', 'License Plate')}
          placeholder={t('details.form-field-license-plate-placeholder', 'Enter your license plate number')}
          value={current.customer.license_plate}
          change={change}
        />
      )}
      {details.showNotes && (
        <InputText
          area={'notes'}
          field={'booking_note'}
          title={t('details.booking-notes', 'Booking Notes')}
          placeholder={t('details.booking-notes')}
          value={current.booking_note}
          change={change}
        />
      )}
    </Area>
  )
}

export default ReducedForm
