import React from 'react'

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import Explore from '_/views/Explore'
import Event from '_/views/Event'

import LoadIndicator from '_/templates/LoadIndicator'

import useOverview from '_/hooks/useOverview'

import routes from '_/config/routes'

const Routes = () => {
  const {
    isLoaded,
    products,
  } = useOverview()

  if (!isLoaded) {
    return (
      <LoadIndicator />
    )
  }

  let path = '/explore'
  let showExplore = false
  let showEvent = false

  const hasRoute = products.some(product => product.type === 'route')
  const hasEvent = products.some(product => product.type === 'event')

  if (hasRoute && hasEvent) {
    showExplore = true
    showEvent = true
  }

  if (!hasRoute && hasEvent) {
    path = '/event'

    showEvent = true

    const events = products.filter(product => product.type === 'event')

    if (events.length === 1) { // We only have one product
      showEvent = false

      path = `/ticket/event/${events[0].id}`
    }
  }

  if (hasRoute && !hasEvent) {
    showEvent = false

    path = '/ticket/route'
  }

  return (
    <Switch>
      {showExplore && (
        <Route
          path={'/explore'}
          component={Explore}
          exact
        />
      )}
      {showEvent && (
        <Route
          path={'/event'}
          component={Event}
          exact
        />
      )}
      {routes.map((route, index) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
            exact
          />
        )
      })}
      <Redirect to={path} />
    </Switch>
  )
}

export default Routes
