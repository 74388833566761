import React from 'react'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

import styles from './datecell.scss'

const DateCell = ({
  date,
  label,
  active,
  beetween = false,
  ghosted,
  selectable,
  change,
}) => {
  let style = null

  if (active) style = styles.active

  return (
    <div
      className={styleCombine(styles.layout, style, beetween && !ghosted && styles.between, ghosted && styles.ghosted)}
      onClick={selectable ? change : undefined}
    >
      <span className={styles.day}>
        {label}
      </span>
    </div>
  )
}

export default DateCell
