import React from 'react'

import styles from './fieldwrapper.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const FieldWrapper = ({
  children,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            {children}
          </div>
        )
      }}
    />
  )
}

export default FieldWrapper
