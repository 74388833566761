import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import styles from './iframe3dsecure.scss'
import TextBox from '../../../../../containers/TextBox'
import Container from '../../../../../containers/Container'
import Heading from '../../../../../atoms/Heading'
import SpinnerOverlay from '../../../../../molecules/SpinnerOverlay'

/**
 * Shared iframe component, used to inject the 3DS form for Worldpay and Opayo(Sagepay).
 */
const Iframe3DSecure = ({ initialHtml, loading }) => {
  const wrapperRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    wrapperRef.current.focus()
  }, [])

  return ReactDOM.createPortal(
    <Container styling={styles.layout} tabIndex={0} getRef={wrapperRef}>
      <Container styling={styles.layoutInner}>
        <Heading
          level={1}
          title={t([
            'billing.sections.iframe-3dsecure.title',
            'Nearly there..',
          ])}
        />
        <TextBox>
          {t([
            'billing.sections.iframe-3dsecure.authentication-required',
            'Your bank requires that you authenticate this transaction.',
          ])}
        </TextBox>
        <TextBox>
          {t([
            'billing.sections.iframe-3dsecure.please-complete',
            'Please complete the verification below to complete your order.',
          ])}
        </TextBox>
        <iframe
          width={Math.min(416, window.innerWidth)}
          height={416}
          frameBorder={'0'}
          src={`data:text/html;charset=utf-8,${encodeURI(initialHtml)}`}
        />
        {loading && <SpinnerOverlay />}
      </Container>
    </Container>,
    document.getElementById('portal'),
  )
}

export default Iframe3DSecure
