import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'

import InputCheckBox from '_/components/input/InputCheckBox'

import BookingCard from './BookingCard'

import styles from './orderspage.scss'
import TextBox from '_/components/layout/TextBox'

import history from '@ticknovate/frontend-shared/libs/history'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useCustomerOrders from '_/hooks/useCustomerOrders'

import LoadIndicator from '_/templates/LoadIndicator'

const getDetail = (section, id) => () => history.push({
  pathname: `/orders/${id}`,
  state: { initial: section },
})

const CustomerOrders = (props) => {
  const { t } = useTranslation()

  const [
    showCurrentBooking,
    setShowCurrentBooking,
  ] = useState(true)

  const {
    isLoaded,
    data,
  } = useCustomerOrders()

  if (!isLoaded) {
    return (
      <div className={styles.layout}>
        <LoadIndicator />
      </div>
    )
  }

  const orders = data

  const ordersWithCurrentBookings = orders.filter((order) => {
    return order.items.some(item => {
      return !item.is_past && !item.is_cancelled
    })
  })

  const ordersToShow = showCurrentBooking ? ordersWithCurrentBookings : orders

  console.log('ORDERS', ordersToShow)

  return (
    <MediaQuery media={media.mobile}>
      {mobile => (
        <div className={styles.layout}>
          <Row type={'spaced'} margin={'0 0 1.25rem 0'} rawStyle={mobile ? { display: 'block' } : {}}>
            <Heading
              level={2}
              title={t('customer-orders.section-title')}
            />
            <InputCheckBox
              title={t('customer-orders.option-current')}
              field={'showCurrentBooking'}
              value={showCurrentBooking}
              change={(field, value) => setShowCurrentBooking(value)}
            />
          </Row>
          {ordersToShow.length === 0 && (
            <TextBox>{t(showCurrentBooking ? 'customer-orders.message-no-current' : 'customer-orders.message-no-bookings')}</TextBox>
          )}
          {ordersToShow.length > 0 && ordersToShow.map(order => (
            <BookingCard
              key={order.id}
              order={order}
              getTickets={getDetail('ticket', order.id)}
              getDetails={getDetail('details', order.id)}
              mobile={mobile}
            />
          ))}
        </div>
      )}
    </MediaQuery>
  )
}

export default CustomerOrders
