import React from 'react'

const CheckIcon = ({ size, fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24'>
    <path d='M0 0h24v24H0z' fill='none' />
    <path fill={fill} d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' />
  </svg>
)

export default CheckIcon
