import { combineReducers } from 'redux'

// import defaultIcons from '@ticknovate/frontend-shared/reducers/defaultIcons'

// import cart from '@ticknovate/frontend-shared/reducers/session/cart'

import basket from './basket'
// import token_session from './session'

// import explore from '@ticknovate/frontend-shared/reducers/session/localState/explore'
// import localCartChanges from '@ticknovate/frontend-shared/reducers/session/localState/localCartChanges'
import sessionActive, { END_SESSION } from '@ticknovate/frontend-shared/reducers/session/localState/sessionActive'
// import sessionDate from '@ticknovate/frontend-shared/reducers/session/localState/sessionDate'
import sessionId from '@ticknovate/frontend-shared/reducers/session/localState/sessionId'

import userLocal from '@ticknovate/frontend-shared/reducers/userLocal'
import user from '@ticknovate/frontend-shared/reducers/user'
import ete from '@ticknovate/frontend-shared/reducers/ete'

const baseSessionReducer = combineReducers({
  localState: combineReducers({
    sessionId,
    sessionActive,
  }),
})

const session = (state, action) => {
  switch (action.type) {
    case END_SESSION:
      return baseSessionReducer(undefined, action)

    default:
      return baseSessionReducer(state, action)
  }
}

const reducer = combineReducers({
  session,
  ete,
  userLocal,
  user,
  basket,
})

const root = (state, action) => {
  if (action.type === 'APP_CLEAR') {
    state = {}
  }

  return reducer(state, action)
}

export default root
