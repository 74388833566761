import React from 'react'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import TicknovateLogo from '_/icons/TicknovateLogo'

const PoweredBy = () => {
  return (
    <Row rawStyle={{ position: 'absolute', bottom: 0, left: 0, right: 0, background: 'RGBA(255,255,255,0.5)', height: '2.25rem', padding: '0 1rem', pointerEvents: 'none' }}>
      <InlineTextBox size={'0.75rem'} rawStyle={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(126, 125, 125)' }}>{'Ticketing powered by'}</InlineTextBox>
      <TicknovateLogo size={20} />
    </Row>
  )
}

export default PoweredBy
