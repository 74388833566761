import React from 'react'
import classnames from 'classnames'

import styles from './modalbutton.scss'

import Button from '../../atoms/Button'
import IconSpinner from '../../../icons/Spinner'
import { formValid } from '../../HOC/formValidator'

const allowed = [
  'red',
  'blue',
  'green',
  'dark',
  'disabled',
  'create',
  'save',
  'cancel',
  'delete',
  'nude',
]

/**
 * @description
 * A button component that can be different colors
 *
 * @param {String} [color] One of 5 allowed colors: red, blue, green, disabled
 * @param {Any} [content] Any valid text string or react component for the label of the button
 * @param {Function} [onClick] Callback when button is clicked
 * @param {String} [styling]
 * @param {Boolean} [loading] Set to `true` to show a loading state
 * @param {String} [to] Pass a path to render an <a> element instead of <button>
 * @param {Boolean} [inline] Set to true for the button to flow inline
 * @param {Boolean} [small] Render the button with less padding
*/

const ModalButton = (props) => {
  const {
    id,
    color,
    content,
    onClick,
    styling,
    loading,
    fullWidth,
    to,
    inline,
    disabled,
    type,
    small,
    locked,
    ...rest
  } = props

  const style = color && allowed.includes(color) ? styles[`button_${color}`] : styles.button_blue
  const className = classnames(style, styling, styles.button, {
    [styles.button_full_width]: fullWidth,
    [styles.button_inline]: inline,
    [styles.button_small]: small,
  })
  const wrappedContent = loading
    ? (
      <span className={classnames(styles.buttonInner, styles.loading)}>
        <span className={styles.loadingText}>{content}</span>
        <span className={styles.loadingSpinner}>
          <IconSpinner size={19} fill={window.TICKNOVATE_CONFIG.theme.button_inner_text} />
        </span>
      </span>
    )
    : (
      <span className={styles.buttonInner}>
        {content}
      </span>
    )

  return (
    <Button
      {...rest}
      id={id}
      disabled={disabled !== undefined ? disabled : color === 'disabled'}
      styling={className}
      content={wrappedContent}
      onClick={onClick}
      type={type}
      locked={locked}
      to={to}
    />
  )
}

export const ValidModalButton = formValid(({
  status,
  isFormValid,
  onClick,
  color,
  disabled,
  ...rest
}) => {
  return (
    <ModalButton
      {...rest}
      disabled={false}
      aria-disabled={disabled}
      color={status ? color : 'disabled'}
      onClick={(...args) => {
        if (isFormValid({ displayAllErrors: true }) && !disabled) {
          onClick && onClick(...args)
        }
      }}
    />
  )
})

export default ModalButton
