import fromPairs from 'lodash/fromPairs'
import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/fp/pickBy'

/**
 * Where properties on an object are undefined, set a default value.
 * Useful for assigning a single default value to many potential properties.
 *
 * @example
 * ensureKeys(
 *   { foo: [22] },
 *   ['foo', 'bar', 'baz'],
 *   []
 * )
 * // returns { foo: [22], bar: [], 'baz': [] }
 *
 * @param {Object} object
 * @param {String[]} keys
 * @param {*} defaultValue
 */
export const ensureKeys = (object, keys, defaultValue) => ({
  ...fromPairs(keys.map(key => [key, defaultValue])),
  ...object,
})

/**
 * In some parts of the codebase, the convention for select list items is:
 * { label: 'Foo!', value: 'foo' }
 *
 * In other places, it's:
 * { title: 'Foo!', id: 'foo' }
 *
 * We should aim for consistency here, but this function can be used as a last
 * resort in order to share data between otherwise incompatible UI components.
 *
 * @param {Object} object
 * @returns {Object}
 */
export const normalizeSelectOption = ({ id, value, title, label, ...rest }) => ({
  id: id !== undefined ? id : value,
  value: value !== undefined ? value : id,
  title: title !== undefined ? title : label,
  label: label !== undefined ? label : title,
  ...rest,
})

/**
 * Recursively trim all string values of an object.
 *
 * @param {Object} obj
 * @return {Object}
 */
export const trimStringValues = value => {
  if (typeof value === 'string') {
    return value.trim()
  }

  if (isPlainObject(value)) {
    return mapValues(value, trimStringValues)
  }

  if (value instanceof Array) {
    return value.map(trimStringValues)
  }

  return value
}

export const pickNonEmptyProps = pickBy(value => value != null && value !== '')
