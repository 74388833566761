import { morphism } from 'morphism'

import isValid from 'date-fns/is_valid'
import isBefore from 'date-fns/is_before'
import startOfDay from 'date-fns/start_of_day'

const getAdjustedDate = (adjust_date, server_offset) => {
  const date = new Date(
    new Date(adjust_date).getTime() - server_offset,
  )

  if (!isValid(date)) {
    throw new Error('Invalid date when trying to apply offset')
  }

  return date
}

const cart_schema = (server_offset = 0) => morphism({
  data: (iteratee, source, destination) => {
    return iteratee
  },
  is_past: (iteratee, source, destination) => {
    const start = getAdjustedDate(iteratee.booked_unit.start_date, server_offset)

    const type = iteratee.booked_unit.type

    if (type === 'date') {
      return isBefore(start, startOfDay(new Date()))
    }

    return false
  },
  is_cancelled: {
    path: 'status',
    fn: (value, source) => {
      if (!value) return false

      return value === 'cancelled'
    },
  },
  is_cancelling: {
    path: 'status',
    fn: (value, source) => {
      if (!value) return false

      return value === 'cancelling'
    },
  },
  is_amending: {
    path: 'status',
    fn: (value, source) => {
      if (!value) return false

      return value === 'amending'
    },
  },
})

const order_schema = morphism({
  order: (iteratee, source, destination) => {
    const {
      items,
      ...rest
    } = iteratee

    return rest
  },
  is_cancelled: {
    path: 'status',
    fn: (value, source) => {
      if (!value) return false

      return value === 'cancelled'
    },
  },
  items: {
    path: 'items',
    fn: (value, source) => {
      if (!value) return []

      const morph = cart_schema(source.server_time_offset)

      return value.map(morph)
    },
  },
})

const orderDecorator = (data) => {
  const {
    order,
    items,
    ...rest
  } = order_schema(data) // Morphism is great but could do with a post process collector

  return {
    ...order,
    ...rest,
    items: items.map(item => {
      const {
        data,
        ...rest
      } = item

      return {
        ...data,
        ...rest,
      }
    }),
  }
}

export default orderDecorator
