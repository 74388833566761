// Material Icons library doesn't have a suitable spinner.
// This icon has been adapted from this codepen:
// https://codepen.io/aurer/pen/jEGbA

import React from 'react'

const Spinner = ({
  size = 16,
  fill = window.TICKNOVATE_CONFIG.theme.icon,
  styling,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 50 50'
    className={styling}
  >
    <path fill={fill} d='M21.97 6.308C11.8 8.037 4.954 17.684 6.683 27.858 8.41 38.028 18.058 44.874 28.23 43.145l-.682-4.01c-7.957 1.352-15.504-4.002-16.857-11.96-1.352-7.958 4.002-15.505 11.96-16.857l-.681-4.01z'>
      <animateTransform
        attributeName='transform'
        attributeType='xml'
        dur='0.6s'
        from='0 25 25'
        repeatCount='indefinite'
        to='360 25 25'
        type='rotate'
      />
    </path>
  </svg>
)

export default Spinner
