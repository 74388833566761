import {
  useLocation,
} from 'react-router-dom'

const useQuery = () => {
  const params = new URLSearchParams(useLocation().search)

  const output = {}

  params.forEach((value, key) => {
    output[key] = value
  })

  return output
}

export default useQuery
