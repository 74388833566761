import React from 'react'
import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const Container = ({
  children,
  className = null,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div style={styling} className={className}>
            {children}
          </div>
        )
      }}
    />
  )
}

Container.styleable = true

export default Container
