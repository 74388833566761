import React from 'react'

import { useTranslation } from 'react-i18next'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'
import InlineTextBox from '_/components/layout/InlineTextBox'
import OrderDetailsList from '../Details/OrderDetailsList'

import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import useOrderHistory from '_/hooks/useOrderHistory'

import LoadIndicator from '_/templates/LoadIndicator'

const top = {
  borderTop: '1px solid rgb(var(--theme_border_thick))',
}

const rowStyle = {
  ...top,
  padding: '2rem 0.25rem',
}

const History = ({
  id,
}) => {
  const { t } = useTranslation()

  const {
    isLoaded,
    data: history,
  } = useOrderHistory(id)

  if (!isLoaded) {
    return (
      <LoadIndicator />
    )
  }

  return (
    <Container>
      <Heading
        title={t('customer-history.section-history-title')}
        level={2}
      />
      {history.map(({ order, description, time, events }, index) => {
        return (
          <Row rawStyle={rowStyle} spread={false} key={index}>
            <Container margin={'0 2rem 0 0'} noFlex>
              <InlineTextBox strong size={1.5}>{index + 1}</InlineTextBox>
            </Container>
            <Container>
              <Heading
                level={3}
                title={description}
              />
              <TextBox strong>{formatLocal(time, 'DD/MM/YYYY - HH:mm')}</TextBox>
              <OrderDetailsList key={index} order={order} />
            </Container>
          </Row>
        )
      })}
    </Container>
  )
}

export default History
