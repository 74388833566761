import React from 'react'

import styles from './view.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const View = ({
  children,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} data-type={'form'} style={styling}>
            {children}
          </div>
        )
      }}
    />
  )
}

View.styleable = true

const desktopStyle = {
  padding: '3rem 3.75rem',
}

const mobileStyle = {
  padding: '2rem 1.5rem',
}

const ResponsiveView = ({
  mobile,
  children,
  ...style
}) => {
  return (
    <View {...style} rawStyle={mobile ? mobileStyle : desktopStyle}>
      {children}
    </View>
  )
}

ResponsiveView.styleable = true

export default View

export {
  ResponsiveView,
}
