import React from 'react'
import { useTranslation } from 'react-i18next'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import defs from '_/config/dates'

const dayKeys = {
  'Sun': 'sunday',
  'Mon': 'monday',
  'Tue': 'tuesday',
  'Wed': 'wednesday',
  'Thu': 'thursday',
  'Fri': 'friday',
  'Sat': 'saturday',
}

const DayBar = (props) => {
  const {
    startsOnMonday,
    small,
  } = props

  const {
    t,
  } = useTranslation()

  const days = startsOnMonday ? defs.daysAlt : defs.days

  return (
    <Row padding={'0.5rem 1rem'} background={'background_module'}>
      {days.map((day, index) => {
        return (
          <InlineTextBox
            key={index}
            color={'date_daybar'}
            width={'calc(100 % / 7)'}
            uppercase
            size={small ? 0.75 : 1.25}
            textAlign={'center'}
            strong
            rawStyle={{
              flexGrow: 1,
              flexShrink: 0,
              userSelect: 'none',
            }}
          >
            {t(`day.short.${dayKeys[day]}`, day)}
          </InlineTextBox>
        )
      })}
    </Row>
  )
}

export default DayBar
