/* eslint-disable camelcase */
import React from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import Calendar, { Holder as CalendarHolder } from '_/templates/CalendarSelector'
import Time, { Holder as TimeHolder } from '_/templates/TimeSelector'

import {
  stampToDateISO,
} from '_/libs/date'

/*
  data = [
    {
      date: '2021-04-20',
      meta: {
        ...
      }
    }
  ]
*/
const TicketSelectionTime = ({
  data,
  current,
  options,
  update,
  close,
  initial,
}) => {
  const { t } = useTranslation()

  const revert = useRevert(current, update, true)

  const wrappedClose = () => {
    revert()
    close()
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        compact
        finalStep={1}
        initial={initial}
        routes={[
          {
            title: t('ticket.model-title-outbound-date'),
            icon: 'calendar',
            valid: current.outbound_date !== null,
            view: ({ ...viewProps }) => (
              <CalendarHolder {...viewProps}>
                <Calendar
                  field={'outbound'}
                  current={current}
                  startLimitDate={null}
                  initial={current.outbound_date_selection}
                  update={update}
                  options={options}
                />
              </CalendarHolder>
            ),
          },
          {
            title: t('ticket.model-title-ticket'),
            icon: 'ticket',
            valid: current.outbound_time !== null,
            view: ({ ...viewProps }) => (
              <TimeHolder {...viewProps}>
                <Time
                  field={'outbound'}
                  data={data}
                  current={current}
                  startLimitDate={current.outbound_date}
                  update={update}
                  options={options}
                />
              </TimeHolder>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default TicketSelectionTime
