import React from 'react'

import styles from './modalview.scss'

import ActionCancel from '_/components/action/ActionCancel'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const ModalView = ({
  type = 'standard',
  close,
  children,
}) => {
  return (
    <div className={styleCombine(type === 'standard' ? styles.layout : styles.layout_dialogue)}>
      {close && (
        <ActionCancel
          change={close}
          iconColor={'modal_icon'}
          rawStyle={{
            position: 'absolute',
            top: '-1.25rem',
            right: type === 'standard' ? '0' : '-1rem',
          }}
        />
      )}
      {children}
    </div>
  )
}

export default ModalView
