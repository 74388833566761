import React, {Component} from 'react'
import styles from '../../../../../organisms/Form-Billing/formbilling.scss'

/**
 * A simple component that will hold the SagePay Iframe being loaded
 * when sagepayForm.form() is called in the SagePayForm component.
 */
class SagePayIframe extends Component {
  shouldComponentUpdate () {
    return false
  }
  render () {
    return <div id='sp-container' className={styles.paymentIFrameContainer} />
  }
}

export default SagePayIframe
