import Ticket from '_/views/Ticket'
import Journey from '_/views/Journey'
import Details from '_/views/Details'
import Payment from '_/views/Payment'
import PayLater from '_/views/PayLater'
import Confirmation from '_/views/Confirmation'
import CustomerHistory from '_/views/CustomerHistory'
import CustomerOrders from '_/views/CustomerOrders'

import Complete from '_/views/Complete'
import Expired from '_/views/Expired'

import Amend from '_/views/Amend'

const routes = [
  {
    step: 'ticket',
    path: '/ticket/:type?/:product_id?',
    component: Ticket,
  },
  {
    step: 'journey',
    path: '/journey',
    component: Journey,
  },
  {
    step: 'details',
    path: '/details',
    component: Details,
  },
  {
    step: 'payment',
    path: '/payment',
    component: Payment,
  },
  {
    step: 'payment',
    path: '/paylater',
    component: PayLater,
  },
  {
    step: 'confirmation',
    path: '/confirmation',
    component: Confirmation,
  },
  {
    step: 'history',
    path: '/orders/:id',
    component: CustomerHistory,
  },
  {
    step: 'orders',
    path: '/orders',
    component: CustomerOrders,
  },
  {
    step: 'complete',
    path: '/complete/:id',
    component: Complete,
  },
  {
    step: 'expired',
    path: '/expired',
    component: Expired,
  },
  {
    step: 'amend',
    path: '/amend/:id?/:item?',
    component: Amend,
  },
]

export default routes
