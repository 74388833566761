import React, { Component } from 'react'
import classnames from 'classnames'
import xor from 'lodash/xor'
import get from 'lodash/get'

import styles from './table.scss'

import TableCollapsed from '../../molecules/TableCollapsed'
import UpIcon from '../../../icons/KeyboardUp'
import DownIcon from '../../../icons/KeyboardDown'

const expandColumns = columns => columns
  .filter(column => column.display !== false)
  .map(column => {
    return typeof column === 'string'
      ? { key: column, label: column }
      : column
  })

const TableRow = (props) => {
  const {
    columns,
    row,
    rowClick,
    nextRow,
    rowIndex,
    shadeAlternateRows,
    detailed,
    isDetailedView,
    detailsColumn,
  } = props
  const onClick = rowClick || row.onClick
  const isDetailsActive = isDetailedView && row.details && detailed.includes(row.id)
  const sharedRowStyling = classnames({
    [styles.selectedRow]: row.isSelected,
    [styles.beforeSelectedRow]: nextRow && nextRow.isSelected,
    [styles.shadyRow]: shadeAlternateRows && rowIndex % 2 === 0,
  })

  return (
    <React.Fragment>
      <tr
        onClick={onClick}
        className={classnames(row.styling, sharedRowStyling, {
          [styles.noBorderBottom]: isDetailsActive,
          [styles.interactiveRow]: onClick,
        })}
        {...row.props}
      >
        {
          isDetailedView && (
            row.icon
              ? <td {...detailsColumn}>{row.icon}</td>
              : (
                <td {...detailsColumn}>
                  {row.details && (
                    isDetailsActive
                      ? <UpIcon size={24} fill={window.TICKNOVATE_CONFIG.theme.button_cta} />
                      : <DownIcon size={24} fill={window.TICKNOVATE_CONFIG.theme.button_cta} />
                  )}
                </td>
              )
          )
        }
        {columns.map((column, index) => {
          const colSpan = column.getColSpan ? column.getColSpan(row) : 1
          let content = null

          if (colSpan === 0) {
            return null
          }

          try {
            content = column.getter
              ? column.getter({ ...row, rowIndex })
              : get(row, column.key)
          } catch (error) {
            console.error('Failed to get data for table column', error)
          }

          return (
            <td
              key={column.key || index}
              style={column.style}
              colSpan={colSpan}
            >
              {content}
            </td>
          )
        })}
      </tr>
      {isDetailsActive && row.details && (
        typeof row.details === 'function'
          ? row.details({ rowProps: { className: sharedRowStyling } })
          : (
            <tr className={classnames(sharedRowStyling, row.detailsStyling)}>
              <td colSpan={columns.length + 1} className={styles.arrowCursor}>
                {row.details}
              </td>
            </tr>
          )
      )}
    </React.Fragment>
  )
}

/**
 * A simple table component.
 *
 * It takes columns props array of columns to be displayed and data prop of the data to be displayed.
 *
 * @param {Object} props
 * @param {(String|Object)[]} props.columns
 * @param {Object} props.data
 * @param {Boolean} props.shadeAlternateRows
 * @param {String} props.headerType - 'default', 'light', 'none'
 * @param {Boolean} props.noLines
 * @param {Function} props.rowClick
 * @param {String} [props.styling]
 */

const Table = (props) => {
  const {
    columns: rawColumns,
    data,
    rowClick,
    shadeAlternateRows,
    headerType = 'default',
    noLines,
    noRowPadding,
    lightLines,
    styling,
    detailed = null,
    isDetailedView = false,
    isCollapsedView = false,
    detailsColumn,
  } = props

  if (isCollapsedView) {
    return <TableCollapsed {...props} />
  }

  const columns = expandColumns(rawColumns)

  return (
    <table
      className={classnames(styles.layout, styling, styles[`headerStyle_${headerType}`], {
        [styles.shadeAlternateRows]: shadeAlternateRows,
        [styles.noLines]: noLines,
        [styles.noRowPadding]: noRowPadding,
        [styles.lightLines]: lightLines,
      })}
    >
      {headerType !== 'none' && (
        <thead>
          <tr className={classnames({
            [styles.beforeSelectedRow]: data && data[0] && data[0].isSelected,
          })}>
            {isDetailedView && <th className={styles['small-width']} key='iconColumn' />}
            {columns.map(({ key, label, style }, index) => (
              <th key={key || index} style={style}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data && data.map((row, i) => (
          <TableRow
            key={row.id || i}
            row={row}
            columns={columns}
            rowClick={rowClick && rowClick.bind(this, row)}
            nextRow={data[i + 1]}
            detailed={detailed}
            rowIndex={i}
            shadeAlternateRows={shadeAlternateRows}
            DetailedView={row.DetailedView}
            isDetailedView={isDetailedView}
            detailsColumn={detailsColumn}
          />
        ))}
      </tbody>
    </table>
  )
}

export class StatefulTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      detailed: props.initialDetailsSelection || [],
    }
  }

  render () {
    return (
      <Table
        {...this.props}
        detailed={this.state.detailed}
        data={this.props.data.map(row => {
          if (!row.details) {
            return row
          }

          return {
            ...row,
            // Using `row.onClick` instead of `props.rowClick` to avoid rows without details
            // from having interactive hover state as though they _do_ have details.
            onClick: () => {
              this.setState(({ detailed }) => ({
                detailed: xor(detailed, [row.id]),
              }))
            },
          }
        })}
      />
    )
  }
}

// Object to be used for <td> style attribute to make the cell shrink to content size.
// The table's table-layout style must be set to "auto".
export const tdMinWidthStyle = {
  width: '1%',
  whiteSpace: 'nowrap',
}

export default Table
