import React from 'react'
import classnames from 'classnames'

import styles from './texticonrow.scss'

import Row from '../../containers/Row'

/**
  * Displays a row containing an Icon next to a text label
  * @param {String} text Text to display with icon
  * @param {ReactElement} icon SVG icon component
  * @param {Number} iconSize size in pixels for the icon
  * @param {String} iconFill colour string for icon ('rgb(255,255,255)')
  * @param {Boolean} reverse whether to show the icon before the text
  * @param {String} textStyling pass through className for the text
  * @param {String} [gap] 'small', 'medium', or 'large'
*/

const TextIconRow = (props) => {
  const {
    text,
    icon: Icon,
    iconSize = 16,
    iconFill = window.TICKNOVATE_CONFIG.theme.icon,
    reverse,
    styling,
    textStyling,
    gap = 'small',
    ...rest
  } = props

  return (
    <Row
      type={reverse ? 'start' : 'spaced'}
      styling={classnames(styles.layout, styling)}
      {...rest}
    >
      <span className={classnames(textStyling, styles[`gap_${gap}`], {
        [styles.textReverse]: reverse,
        [styles.text]: !reverse,
      })}>
        {text}
      </span>
      <Icon size={iconSize} fill={iconFill} />
    </Row>
  )
}

export default TextIconRow
