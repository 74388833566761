
import { createSelector } from 'reselect'

const CLEAR_SESSION_USER = 'CLEAR_SESSION_USER'
const LOAD_SESSION_CUSTOMER = 'LOAD_SESSION_CUSTOMER'
export const LOAD_SESSION_TRADE_PARTNER = 'LOAD_SESSION_TRADE_PARTNER'

export const actions = {
  clearSessionUser: () => ({ type: CLEAR_SESSION_USER }),
  loadSessionCustomer: payload => ({ type: LOAD_SESSION_CUSTOMER, payload }),
  loadSessionTradePartner: payload => ({ type: LOAD_SESSION_TRADE_PARTNER, payload }),
}

// TODO: This state is prone to getting stale. It can perhaps be derived instead from the cart state.
const reducer = (state = null, action) => {
  switch (action.type) {
    case CLEAR_SESSION_USER:
      return null

    case LOAD_SESSION_CUSTOMER: {
      const { id, firstname, lastname } = action.payload
      return {
        id,
        type: 'customer',
        displayName: `${firstname} ${lastname}`,
        tradePartner: null,
        link: null,
      }
    }

    case LOAD_SESSION_TRADE_PARTNER: {
      const { tradePartner, tradePartnerPortalUserUser = false } = action.payload
      const { id, title } = tradePartner

      // Don't double-load trade partners. For Trade Partner Portal users, this
      // prevents the `tradePartnerPortalUserUser` flag from getting wiped out.
      if (state && state.id === id) return state

      return {
        id: id,
        type: 'trade-partner-contact',
        displayName: title,
        // displayName: `${tradePartner.title} - ${firstname} ${lastname}`,
        tradePartner,
        link: `/trade-partners/${id}`,
        tradePartnerPortalUserUser,
      }
    }

    default:
      return state
  }
}

export const getUserDisplayName = (state, newCustomer) => {
  return state && state.displayName ? state.displayName : newCustomer
}

export const getUserLink = state => state
  ? state.link
  : null

export const getTradePartner = state =>
  state && state.type === 'trade-partner-contact'
    ? state.tradePartner
    : null

export const getTradePartnerId = createSelector(
  getTradePartner,
  tradePartner => tradePartner ? tradePartner.id : null,
)

export default reducer
