import ordinal from 'ordinal'
import flow from 'lodash/flow'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import formatLocal from 'date-fns/format'

import { translate, getLanguage } from '@ticknovate/frontend-shared/locales'

// TODO: For i18n, we'll need these labels per language.
const labels = {
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthsLong: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
}

const translateKey = (key, defaultValue) => {
  // console.log('TRANS', key)
  // console.log('Current language for strings', getLanguage())
  if (translate) return translate(key, defaultValue)

  return defaultValue
}

const formatters = flow(
  // Turn object into an array and create regex
  map.convert({ cap: false })((getReplacement, pattern) => ({
    pattern,
    getReplacement,
    regex: new RegExp(pattern, 'g'),
  })),
  // Apply longest pattern first. Otherwise pattern "d" would execute
  // twice for pattern "dd".
  orderBy(['pattern.length'], ['desc']),
)({
  // Year
  YYYY: date => date.getUTCFullYear().toString().padStart(4, '0'),
  // Month
  MM: date => (date.getUTCMonth() + 1).toString().padStart(2, '0'),
  MMM: date => {
    const defaultValue = labels.monthsShort[date.getUTCMonth()]
    const key = labels.monthsLong[date.getUTCMonth()].toLowerCase()
    return translateKey(`month.short.${key}`, defaultValue)
  },
  MMMM: date => {
    const key = labels.monthsLong[date.getUTCMonth()]
    return translateKey(`month.long.${key.toLowerCase()}`, key)
  },
  // Day (of month)
  D: date => date.getUTCDate().toString(), // 1
  DD: date => date.getUTCDate().toString().padStart(2, '0'), // 01
  Do: date => ordinal(date.getUTCDate()), // 1st
  // Day (of week)
  d: date => date.getUTCDay(), // 0-6
  ddd: date => { // Mon
    const defaultValue = labels.daysShort[date.getUTCDay()]
    const key = labels.daysLong[date.getUTCDay()].toLowerCase()
    return translateKey(`day.short.${key}`, defaultValue)
  },
  dddd: date => { // Monday
    const key = labels.daysLong[date.getUTCDay()]
    return translateKey(`day.long.${key.toLowerCase()}`, key)
  },
  // Hour
  h: date => { // 1
    const hours = date.getUTCHours()

    // 12:00 stays as 12:00
    if (hours === 12) return 12

    // 00:00 -> 00:00
    // 13:00 -> 01:00
    // 23:00 -> 11:00
    return hours % 12
  },
  HH: date => date.getUTCHours().toString().padStart(2, '0'), // 01
  // am / pm
  a: date => date.getUTCHours() < 12 ? 'am' : 'pm',
  // Minute
  mm: date => date.getUTCMinutes().toString().padStart(2, '0'),
  // Seconds
  ss: date => date.getUTCSeconds().toString().padStart(2, '0'),
  // Milliseconds
  SSS: date => date.getUTCMilliseconds().toString().padStart(3, '0'),
})

// Basic mapping for built in date time
const intlOpts = {
  // Year
  YYYY: 'year.numeric',
  // Month
  MM: 'month.numeric',
  MMM: 'month.short',
  MMMM: 'month.long',
  // Day (of month)
  D: 'day.numeric', // 1
  DD: 'day.2-digit', // 01
  // Day (of week)
  ddd: 'weekday.short',
  dddd: 'weekday.long',
  // Hour
  h: 'hour.numeric',
  HH: 'hour.2-digit',
  // am / pm
  // a: date => date.getUTCHours() < 12 ? 'am' : 'pm', There isn't a concept for this in Intl
  // Minute
  mm: 'minute.2-digit',
  // Seconds
  ss: 'second.2-digit',
}

const sanitizeDateString = dateString => {
  if (typeof dateString !== 'string') {
    throw new TypeError('dateString must be a string')
  }

  // Treat dates in format "YYYY-MM-DD" as UTC date.
  // Not 100% sure how each browser would handle `new Date('2019-01-01')`,
  // so taking no chances here.
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return `${dateString}T00:00:00.000Z`
  }

  if (!/(Z|[+-]\d\d:\d\d)$/.test(dateString)) {
    throw new Error(`dateString ${dateString} does not have timezone information`)
  }

  return dateString
}

const formatUTCLocale = (dateString, format) => {
  const date = new Date(sanitizeDateString(dateString))

  const language = getLanguage()

  console.log('FORMATTING DATE', language, dateString, format)

  let output = format

  const options = output.replace(/[^dDmMyYhHmMsS]/g, '_')
    .split('_')
    .filter(item => item.length > 0 && intlOpts[item])
    .map(item => intlOpts[item])
    .reduce((acc, cur) => {
      const [
        key,
        value,
      ] = cur.split('.')

      acc[key] = value

      return acc
    }, {})

  return new Intl.DateTimeFormat(language, options).format(date)
}

/**
 * Base formatter. May throw errors.
 */
const formatUTCBase = (dateString, format) => {
  if (/[_\d]/.test(format)) {
    // `format` cannot contain values used for templating
    throw new Error(`format string ${format} contains _ or numbers`)
  }

  const date = new Date(sanitizeDateString(dateString))
  const replacementValues = []
  let output = format

  // Replace patterns with placeholders.
  // Placeholders are used, since the result of a replacement may
  // include values that include valid replacement patterns.
  //
  // This prevents this...
  // formatUTC('2019-12-02', 'D MMMM')
  //
  // ...from being formatted to "2 2ece0ber" instead of "2 December".
  formatters.forEach(({ regex, getReplacement }) => {
    // console.log('Replace', regex, output)
    output = output.replace(regex, (...args) => {
      replacementValues.push(getReplacement(date))
      // console.log('Result', getReplacement(date))
      return `_${replacementValues.length - 1}_`
    })
  })

  // console.log('Pre result', output, replacementValues)

  // Replace the placeholders
  return output.replace(
    /_(\d+)_/g,
    (match, index) => replacementValues[index],
  )
}

/**
 * Format a UTC time string, ignoring the timezone of the user's browser.
 *
 * @example
 * dateFns.format('2019-10-26T10:00:00.000Z', 'h:mma')
 * // Outputs maybe '10:00am', maybe '12:00am', or maybe '2:00pm'.
 * // We are at the mercy of the browser and its timezone.
 *
 * formatUTC('2019-10-26T10:00:00.000Z', 'h:mma')
 * // Outputs '10:00am', always.
 */
const formatUTC = (...args) => {
  try {
    return formatUTCBase(...args)
  } catch (err) {
    console.error('Invalid arguments passed to `formatUTC`', ...args)
    console.error(err)
    return 'Invalid Date'
  }
}

const formatDateLocale = (dateString) => {
  const date = new Date(sanitizeDateString(dateString))

  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

  const language = getLanguage()

  return new Intl.DateTimeFormat(language, options).format(date)
}

const formatDateTimeLocale = (dateString) => {
  const date = new Date(sanitizeDateString(dateString))

  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }

  const language = getLanguage()

  return new Intl.DateTimeFormat(language, options).format(date)
}

export {
  formatUTC,
  formatUTCLocale,
  /**
   * date-fns' `format` function, re-exported.
   *
   * This should be used instead of `format` directly to show intent
   * to display the time relative to the user's local timezone.
   */
  formatLocal,
  sanitizeDateString,
  formatDateLocale,
  formatDateTimeLocale,
}
