import React from 'react'

import { useTranslation } from 'react-i18next'

import Row from '_/components/layout/Row'
import TextBox from '_/components/layout/TextBox'
import Icon from '_/components/element/Icon'

const Message = () => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Row area={'message'}>
      <Icon name={'email'} size={48} fill={theme.card_default_icon} style={{ flexShrink: 0 }} />
      <TextBox margin={'0 0 0 0.5rem'}>{t('details.login-message')}</TextBox>
    </Row>
  )
}

export default Message
