import React, { Fragment, useState } from 'react'

import isToday from 'date-fns/is_today'

import DatePicker from '_/components/navigation/DatePicker'

import useBookingOptions from '_/hooks/useBookingOptions'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import {
  generateCalendarDates,
} from '_/libs/generateMonthBlock'

import {
  isDateBefore,
  isBeforeOrSame,
  stampToDateISO,
  getTimeNow,
} from '_/libs/date'

import useBasket from '_/hooks/useBasket'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'
import Scroll from '_/components/layout/Scroll'

import ActionCard from '_/components/action/ActionCard'

import LoadIndicator from '_/templates/LoadIndicator'

import {
  getBookingSearch,
} from '_/libs/dependantData'

const reduceAvailable = (options = []) => {
  return options
    .reduce((list, option) => {
      if (!list[option.start_date]) {
        list[option.start_date] = [
          {
            ...option,
          },
        ]
      } else {
        list[option.start_date].push(option)
      }

      return list
    }, {})
}

const Calendar = ({
  field,
  current,
  initial = null,
  update,
  options,
}) => {
  const {
    state: basket_state,
    removeFromBasket,
  } = useBasket()

  const booking = options[`${field}_date`].data

  const available_map = reduceAvailable(booking.options)

  const currentCombo = basket_state.items.outbound !== null ? basket_state.items.outbound.combo_id : null

  const selectedCombo = field === 'inbound' && currentCombo
    ? booking.combos.find(combo => combo.id === currentCombo) || null
    : null

  const calendar_map = Object.keys(available_map)
    .reduce((list, date) => {
      const {
        status = 'available',
        pricing_entire_selection,
        pricing_potential_best,
      } = available_map[date][0]

      const price = field === 'inbound' ? pricing_entire_selection.total : pricing_potential_best.total

      const hasCombos = available_map[date]
        .some(option => option.combos.length > 0)

      let isBetweenOutboundAndReturn = false
      let notSelectableBefore = false

      if (field === 'inbound') {
        isBetweenOutboundAndReturn = isBeforeOrSame(current.outbound_date, date)
        notSelectableBefore = isDateBefore(current.outbound_date, date)
      }

      // If this is an outbound view and there is an existing selection for inbound hide those dates
      if (field === 'outbound' && basket_state.items.inbound !== null) {
        notSelectableBefore = isDateBefore(date, basket_state.items.inbound.start_date)
      }

      list[date] = {
        price,
        currency: booking.currency,
        status: isBetweenOutboundAndReturn || notSelectableBefore ? 'is_past' : status,
        hasCombos: field === 'inbound' && hasCombos,
        hasSelectedCombo: !!selectedCombo && selectedCombo.id === pricing_entire_selection.combo_id,
        isOutbound: field === 'inbound' && current.outbound_date === date,
      }

      return list
    }, {})

  const isSelectedComboVisible = Object.values(calendar_map).some(meta => meta.hasSelectedCombo)

  // Clear fields and basket if date change
  const handleDateChange = (value) => {
    const datePayload = [
      {
        field: `${field}_date`,
        value,
      },
      {
        field: `${field}_date_selection`,
        value,
      },
      {
        field: `${field}_time_selection`,
        value: isToday(value) ? getTimeNow(0) : '09:00',
      },
    ]

    if (field === 'outbound') {
      update(
        [
          ...datePayload,
        ],
      )
    } else {
      removeFromBasket(field)

      update(
        [
          ...datePayload,
        ],
      )
    }
  }

  const handleSelectionChange = (value) => {
    update(
      [
        {
          field: `${field}_date_selection`,
          value,
        },
      ],
    )
  }

  return (
    <CalendarComponent
      meta={calendar_map}
      change={handleDateChange}
      selected={current[`${field}_date`]}
      changeDate={handleSelectionChange}
      initial={initial}
      tip={isSelectedComboVisible ? selectedCombo.title : null}
      loading={options[`${field}_date`].isFetching}
    />
  )
}

const CalendarAmend = ({
  current,
  startLimitDate = null,
  initial = null,
  update,
}) => {
  const {
    emptyBasket,
  } = useBasket()

  const [
    currentDate,
    setCurrentDate,
  ] = useState(startLimitDate || current.amend_date || stampToDateISO(new Date()))

  const {
    isFetching,
    data: booking,
  } = useBookingOptions(
    getBookingSearch({
      field: 'amend',
      data: current,
      dates: generateCalendarDates(currentDate),
    }),
    true,
  )

  const selectedCombo = booking.combos.find(combo => combo.id === current.amend_combo)

  console.log('RENDERING AMEND', current.amend_date)

  const available_map = reduceAvailable(booking.options)

  const calendar_map = Object.keys(available_map)
    .reduce((list, key) => {
      const {
        status = 'available',
        pricing,
      } = available_map[key][0]

      const price = pricing.total

      const hasCombos = available_map[key]
        .some(item => item.combos.length > 0)

      const notSelectableBefore = startLimitDate !== null ? isDateBefore(startLimitDate, key) : isDateBefore(stampToDateISO(new Date()), key)

      list[key] = {
        price,
        currency: booking.currency,
        status: notSelectableBefore ? 'is_past' : status,
        hasCombos: hasCombos,
        hasSelectedCombo: !!selectedCombo && selectedCombo.id === pricing.combo_id,
        isOutbound: current.amend_date === key,
      }

      return list
    }, {})

  const isSelectedComboVisible = Object.values(calendar_map).some(meta => meta.hasSelectedCombo)

  // Clear fields and basket if date change
  const handleDateChange = (value) => {
    emptyBasket()

    update(
      [
        {
          field: 'amend_date',
          value,
        },
      ],
      [
        'amend_time',
        'amend_combo',
      ],
    )
  }

  return (
    <CalendarComponent
      meta={calendar_map}
      title={null}
      change={handleDateChange}
      selected={current.amend_date}
      changeDate={setCurrentDate}
      initial={initial}
      tip={isSelectedComboVisible ? selectedCombo.title : null}
      loading={isFetching}
    />
  )
}

const CalendarComponent = ({
  meta,
  title,
  change,
  selected,
  changeDate,
  initial,
  tip,
  loading = false,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Fragment>
            <DatePicker
              margin={'0.75rem 0 0 0'}
              title={title}
              meta={meta}
              change={change}
              selected={selected}
              initial={initial}
              onChangeMonth={changeDate}
              small={mobile}
              showPrice={window.TICKNOVATE_CONFIG.explore.calendar.showPrices}
              tip={tip}
            />
            {loading && (
              <LoadIndicator />
            )}
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

const desktopLayout = {
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  pointerEvents: 'none',
  zIndex: 1,
}

const mobileLayout = {
  padding: '0.75rem 0 0 0.75rem',
}

const Holder = ({
  title,
  children,
  noSave,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.tablet}>
      {tablet => {
        return (
          <Column height={'100%'} spread={false}>
            <Heading
              level={3}
              title={title}
              noFlex
              textAlign={'left'}
              width={'100%'}
              rawStyle={tablet ? mobileLayout : desktopLayout}
            />
            <Scroll rawStyle={{ flex: '1 1', alignSelf: 'stretch' }}>
              {children}
            </Scroll>
            {!noSave && (
              <Row type={'end'} noFlex padding={'0 0 1rem 0'}>
                <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
              </Row>
            )}
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default Calendar

export {
  CalendarAmend,
  Holder,
}
