import { useRef } from 'react'
import arePropsEqual from '../../libs/arePropsEqual'

/**
 * React hooks, like `React.useEffect()`, take a `deps` array that controls
 * when the hook runs - a shallow comparison is made each time the hook is
 * called, and if the `deps` array contains changes, the hook callback runs.
 *
 * The comparison is only shallow.
 * This hook allows for a deep comparison.
 *
 * @example
 * // `data` will always be a fresh, new object if created like this:
 * const data = { ...someDefaultValue, ...someOtherData }
 *
 * // But we can avoid re-running the hook on every render:
 * useEffect(() => {
 *   console.log('The data has changed!')
 * }, [useDeepComparisonDeps(data)])
 *
 *
 * @param {*} deps
 */
const useDeepComparisonDeps = deps => {
  const state = useRef({ count: 0, deps })

  if (!arePropsEqual(deps, state.current.deps)) {
    state.current = { count: state.current.count + 1, deps }
  }

  return state.current.count
}

export default useDeepComparisonDeps
