import { useState } from 'react'

const useTouched = (initial = false) => {
  const [
    touched,
    setTouched,
  ] = useState(initial)

  const handleClick = () => {
    if (!touched) setTouched(true)
  }

  return {
    touched,
    click: handleClick,
  }
}

export default useTouched
