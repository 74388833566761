import React from 'react'

import FlexButton from '_/components/element/FlexButton'
import Icon from '_/components/element/Icon'

const ActionBack = ({
  change,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <FlexButton change={change} {...style}>
      <Icon name={'arrow_left'} size={32} fill={style.color ? theme[style.color] : theme.nav_text} />
    </FlexButton>
  )
}

ActionBack.styleable = true

export default ActionBack
