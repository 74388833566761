import { useRef } from 'react'

import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

const getTickets = (id) => apiCaller(`orders/${id}/tickets`, true)

const useTickets = (id) => {
  const errorSent = useRef(false)

  const {
    isSuccess,
    isError,
    data,
  } = useQuery(['customer', 'tickets', id], getTickets(id), {
    retry: 0,
    staleTime: 1000 * 60 * 10, // Keep me at 10 minutes
  })

  if (isSuccess) {
    return {
      isLoaded: isSuccess,
      data,
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isLoaded: isError,
      data: [],
    }
  }

  return {
    isLoaded: false,
    data: [],
  }
}

export default useTickets
