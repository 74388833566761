import React from 'react'

import styles from './actioncancel.scss'

import CancelIcon from '_/icons/Cancel'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const ActionCancel = ({
  change,
  iconColor = 'nav_icon',
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button className={styleCombine(styles.button)} style={styling} onClick={event => {
            event.stopPropagation()
            change()
          }}>
            <CancelIcon
              size={24}
              fill={theme[iconColor]}
            />
          </button>
        )
      }}
    />
  )
}

export default ActionCancel
