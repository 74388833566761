import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import EventBusyIcon from '_/icons/EventBusy'
import PersonRemoveIcon from '_/icons/PersonRemove'
import CircleRemoveIcon from '_/icons/CircleRemove'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

import { triggerToastMessage } from '_/components/notification/Toaster'

import styles from './datecell.scss'

// status = 'available' | 'sales_ended' | 'sold_out' | 'is_past'

const warnings = ['no_capacity', 'before_outbound', 'no_service', 'sales_ended', 'sold_out', 'is_past']

const DateCell = ({
  date,
  label,
  status = 'available',
  meta = {
    price: null,
    currency: 'GBP',
    hasCombos: false,
    hasSelectedCombo: false,
    isOutbound: false,
  },
  ghosted,
  selectable,
  change,
  showPrice,
}) => {
  const Info = !warnings.includes(status) ? meta.price !== null ? WithInfo : null : WithWarning

  const style = warnings.includes(status) ? styles.warning : styles[status]

  const showCombo = selectable && meta.hasSelectedCombo && status === 'available' && !meta.isOutbound

  return (
    <div
      className={styleCombine(styles.layout, style, showCombo && styles.combo, ghosted && styles.ghosted)}
      onClick={selectable ? change : null}
    >
      <span className={styles.day}>
        {label}
      </span>
      <Info
        status={status}
        meta={meta}
        showPrice={showPrice}
      />
    </div>
  )
}

const WithInfo = ({
  status,
  meta,
  showPrice,
}) => {
  const { t } = useTranslation()

  if (!showPrice) return null

  return (
    <div className={styles.info}>
      <span>{t('meta.from')}</span>
      <span className={styles.price}>{formatCurrency(meta.price, { currencyCode: meta.currency, small: false })}</span>
    </div>
  )
}

const warning_meta = {
  'no_capacity': {
    text: 'Not enough capacity',
    icon: PersonRemoveIcon,
  },
  'before_outbound': {
    text: 'Before outbound',
    icon: EventBusyIcon,
  },
  'is_past': {
    text: 'Before outbound',
    icon: EventBusyIcon,
  },
  'no_service': {
    text: 'No service',
    icon: CircleRemoveIcon,
  },
  'sales_ended': {
    text: 'No service',
    icon: CircleRemoveIcon,
  },
  'sold_out': {
    text: 'Not enough capacity',
    icon: PersonRemoveIcon,
  },
}

const WithWarning = ({
  status,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const Icon = warning_meta[status] ? warning_meta[status].icon : EventBusyIcon

  const triggerWarning = () => {
    warning_meta[status] && triggerToastMessage({
      icon: (<Icon fill={theme.text_light} size={24} />),
      message: warning_meta[status].text,
      status: 'notice',
    })
  }

  return (
    <div className={styles.info} onClick={triggerWarning}>
      <Icon
        fill={theme.date_info_inactive}
        size={22}
      />
    </div>
  )
}

export default DateCell
