import global from '../../../libs/global'

const START_SESSION = 'START_SESSION'
export const END_SESSION = 'END_SESSION'

const initialState = false

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Mark session as active.
    // Strictly speaking, there is always a session, and you can make changes
    // to it without calling this action. This action is used to update the UI
    // to reflect a session being active.
    case START_SESSION:
      return true

    // This action name is exported so it can be paired with session-clearing functionality.
    case END_SESSION:
      global.SESSION_CLEAR_COUNT++
      return false

    default:
      return state
  }
}

export default reducer
