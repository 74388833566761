import { morphism, createSchema } from 'morphism'

import {
  mapDefaults,
  mapToArraySubSchema,
} from '_/libs/mapToSchema'

import makeSerialID from '_/libs/makeSerialID'

/*
{
  market: '',
  items: {
    outbound: { // Maps to [0] when sending/merging
      id: '', // Cart Item ID
      pick_id: 'date,2021-11-26,00:00:00,Standardtrip', // Internal for selection state
      product_id: '',
      instance_id: '',
      start_date: '2021-11-25',
      start_time: '11:00',
      ticket: [ // Blended in from current
        {
          id: '',
          qty: 0
        },
        ...
      ],
      location: '',
      end_location: ''
    },
    inbound: { // Maps to [1] when sending/merging
      id: '', // Cart Item ID
      pick_id: 'date,2021-11-26,00:00:00,Standardtrip', // Internal for selection state
      product_id: '',
      instance_id: '',
      start_date: '2021-11-25',
      start_time: '11:00',
      ticket: [ // Blended in from current
        {
          id: '',
          qty: 0
        },
        ...
      ],
      location: '',
      end_location: ''
    }
  },
  promo_code: 'DAN90'
}
*/
const getCartSchema = (cart) => {
  return morphism(
    createSchema(
      {
        'market': {
          'id': 'market',
        },
        'items': {
          path: 'items',
          fn: mapToArraySubSchema(() => {
            return createSchema(
              {
                'id': 'id',
                'product': {
                  'id': 'product_id',
                },
                'booked_unit': {
                  'instance_id': 'instance_id',
                  'start_date': 'start_date',
                  'start_time': 'start_time',
                },
                'ticket_types': {
                  path: 'ticket',
                  fn: mapToArraySubSchema(() => {
                    return createSchema(
                      {
                        'id': 'id',
                        'qty': 'qty',
                      },
                      mapDefaults({
                        'qty': 0,
                      }),
                    )
                  }),
                },
                'location': {
                  'id': 'location',
                },
                'end_location': {
                  'id': 'end_location',
                },
              },
            )
          }),
        },
        'promo_code': 'promo_code',
      },
    ),
    cart,
  )
}

const basketItemSchema = {
  id: 'id',
  pick_id: ({ booked_unit, product }) => {
    return makeSerialID({
      ...booked_unit,
      product_id: product.id,
    })
  },
  product_id: 'product.id',
  combo_id: 'combos[0].id',
  instance_id: 'booked_unit.instance_id',
  start_date: 'booked_unit.start_date',
  start_time: 'booked_unit.start_time',
  location: 'location.id',
  end_location: 'end_location.id',
  ticket: {
    path: 'ticket_types',
    fn: (value) => value.map(({ id, qty }) => ({ id, qty })),
  },
  legs: 'legs',
}

const syncCartToBasket = (cart) => {
  return morphism(
    {
      id: 'id',
      token: 'token',
      items: {
        path: 'items',
        fn: (value) => {
          const [
            outbound,
            inbound,
          ] = value

          return {
            outbound: outbound ? morphism(basketItemSchema, outbound) : null,
            inbound: inbound ? morphism(basketItemSchema, inbound) : null,
          }
        },
      },
    },
    cart,
  )
}

const syncAmendCartToBasket = (cart) => {
  return {
    id: cart.id,
    token: cart.token,
    items: {
      outbound: null,
      inbound: null,
      amend: cart.item ? morphism(basketItemSchema, cart.item) : null,
    },
  }
}

const syncAmendBasketToCart = (basket) => {
  return morphism(
    {
      id: 'id',
      token: 'token',
      market: {
        id: () => window.TICKNOVATE_CONFIG.env.market || 'de',
      },
      items: {
        path: 'items',
        fn: (value) => {
          const {
            amend,
          } = value

          // Directly map amend for upload
          return [
            morphism(
              {
                id: 'id',
                product: {
                  id: 'product_id',
                },
                booked_unit: {
                  instance_id: 'instance_id',
                  start_date: 'start_date',
                  start_time: 'start_time',
                },
                ticket_types: 'ticket',
                location: {
                  id: 'location',
                },
                end_location: {
                  id: 'end_location',
                },
              },
              amend,
            ),
          ]
        },
      },
      promo_code: 'promo_code',
    },
    basket,
  )
}

const syncBasketToCart = (basket) => {
  return morphism(
    {
      id: 'id',
      token: 'token',
      market: {
        id: () => window.TICKNOVATE_CONFIG.env.market || 'de',
      },
      items: {
        path: 'items',
        fn: (value) => {
          const {
            outbound,
            inbound,
          } = value

          // Has to be positional to match the cart
          // If this is amend flow, only amend will be filled in
          const items = [outbound, inbound]
            .filter(item => Boolean(item))

          return items.map((item) => {
            if (!item) return null
            return morphism(
              {
                id: 'id',
                product: {
                  id: 'product_id',
                },
                booked_unit: {
                  instance_id: 'instance_id',
                  start_date: 'start_date',
                  start_time: 'start_time',
                },
                ticket_types: 'ticket',
                location: {
                  id: 'location',
                },
                end_location: {
                  id: 'end_location',
                },
              },
              item,
            )
          })
        },
      },
      promo_code: 'promo_code',
    },
    basket,
  )
}

export {
  getCartSchema,
  syncCartToBasket,
  syncAmendCartToBasket,
  syncBasketToCart,
  syncAmendBasketToCart,
}
