
import omit from 'lodash/omit'
import upperFirst from 'lodash/upperFirst'
import pick from 'lodash/pick'
import get from 'lodash/get'
import CartItem from './CartItem'
import isValid from 'date-fns/is_valid'

import { useTranslation } from 'react-i18next'

const normalizedProperties = [
  'billing_address',
  'shipping_address',
  'sales_order',
  'order_reference',
  'customer_email',
  'customer_name',
  'customer_lastname',
  'customer_telephone',
  'grand_total',
  'discount_amount',
  'subtotal',
  'paid',
  'payable',
  'last_paid',
  'cancellation_amendment_fee',
  'deposit',
]

/**
 * A wrapper for the objects returned by the endpoints:
 * /cart/*
 * /salesorder/*
 *
 * Provides a common interface between quotes (used for cart and order amendments) and orders.
 */
export default class Cart {
  constructor (order) {
    // console.log('CART ASSIGNED', order, this)
    Object.assign(
      this,
      // Remove any properties that we have getters for
      omit(order, normalizedProperties),
      {
        data: order,
        items: (order.items || [])
          // Sometimes when a cart operation fails, the item is returned form the API as `null`.
          // e.g. `{ ...cart, items: [null] }`. Filter that.
          .filter(Boolean)
          // Create instances of `CartItem` for easier consumption.
          .map(item => new CartItem(this, item)),
      },
    )
  }

  get grand_total () {
    return this.data.pricing.total
  }

  get deposit () {
    return this.data.pricing.deposit
  }

  get discount_amount () {
    return this.data.pricing.discount
  }

  get subtotal () {
    return this.data.pricing.subtotal
  }

  get paid () {
    return this.data.pricing.paid
  }

  get order_reference () {
    return this.data.id
  }

  get customer_email () {
    return this.data.customer.email
  }

  get customer_name () {
    return this.data.customer.firstname
  }

  get customer_lastname () {
    return this.data.customer.lastname
  }

  get customer_telephone () {
    return this.data.customer.telephone
  }

  get billing_address () {
    const {
      billing_address,
      customer,
    } = this.data

    return {
      ...billing_address,
      ...customer,
    }
  }

  get items_with_vouchers () {
    return this.items.filter(
      ({ voucher_id }) => voucher_id != null,
    )
  }

  get items_with_unverified_vouchers () {
    return this.items_with_vouchers.filter(
      ({ voucher_reference }) => voucher_reference == null,
    )
  }

  // get type () {
  //   return this.is_order ? 'order' : 'quote'
  // }

  get is_order () {
    return this.type === 'order'
  }

  get is_cart () {
    return !this.is_order
  }

  // /** This is a cart that is being used to track changes for editing an order. */
  get is_order_edit_cart () {
    return this.id != null
  }

  get is_basic_cart () {
    return (
      // Legacy behaviour
      this.status === null ||
      // Orders v2 status
      this.status === 'draft'
    )
  }

  get is_amending_order () {
    return this.status !== 'draft'
  }

  get is_cancelled () {
    return this.status === 'cancelled'
  }

  get is_amended () {
    return this.status === 'amended'
  }

  get is_past () {
    return this.items.some(({ is_past }) => is_past)
  }

  get status_display_text () {
    const {
      t,
    } = useTranslation()

    if (this.status === 'ordered') {
      return t('manage.status.ordered-label', 'Ordered booking')
    }
    if (this.is_cancelled) {
      return t('manage.status.cancelled-label', 'Cancelled booking')
    }
    if (this.is_basic_cart) {
      return t('manage.status.cart-label', 'Cart')
    }
    if (this.is_amending_order) {
      return t('manage.status.amending-label', 'Editing booking')
    }
    if (this.is_amended) {
      return t('manage.status.amended-label', 'Amended booking')
    }
    if (typeof this.status === 'string') {
      console.warn(`Unknown cart /order status obeserved "${this.status}"`)
      return upperFirst(this.status)
    }
    console.error(this.status)
    throw new Error('Invalid cart status')
  }

  get is_fee_free_period_almost_over () {
    return this.items.some(item => item.is_fee_free_period_almost_over)
  }

  get is_cancelling () {
    return this.items.every(({ is_cancelling }) => is_cancelling)
  }

  get is_default_state () {
    // A cart with no id is a local cart, not yet synced with backend.
    return this.id == null
  }

  get payable () {
    return this.pricing.payable
  }

  get last_paid () {
    return this.pricing.last_paid
  }

  get cancellation_amendment_fee () {
    return this.pricing.cancellation_amendment_fee
  }

  get has_payable_overwrite () {
    // `payable_overwrite` will be `null` only locally.
    //
    // On the server, a `payable_overwrite` of `0` is used to represent both
    // "no overwrite", or "the price was overwritten to be 0" - it depends on
    // what the original calculated price is. It's a bad system. We'll fix it.
    // For now, we jump through these hoops.
    if (this.data.pricing.payable_overwrite == null) {
      return false
    }

    // The same checks that the backend code does to determine if the price is overwritten.
    return (
      this.data.pricing.payable_overwrite === this.data.pricing.payable // && this.data.pricing.payable_overwrite !== this.data.payable TODO: Not too sure about this one Dan?
    )
  }

  /**
   * Price overwrite state is more complex than you'd hope.
   * See the comments on `this.has_payable_overwrite` for details.
   */
  get normalized_payable_overwrite () {
    if (!this.has_payable_overwrite) return null

    return this.data.pricing.payable_overwrite
  }

  get shipping_address () {
    return {
      // We currently only have 1 shipping method: email
      email: this.customer.email || null,
    }
  }

  get sales_order () {
    if (!this.data.id) return null // Hmm, not too sure about this one

    return new Cart(this.data)
  }

  get is_free () {
    return this.data.pricing.payable === 0
  }

  /**
   * When comparing this item to another, perhaps for `React.memo`, call this
   * method to get the core data for comparison.
   *
   * This is much more performant than letting lodash's `isEqual` function compare
   * the class instance as is, since it unnecessarily invokes all the getters.
   */
  getDataForMemoComparison () {
    return {
      parent: this.parent.getDataForMemoComparison(),
      data: this.data,
    }
  }

  toJSON () {
    return this.data
  }

  toNormalizedObject () {
    return {
      ...this.data,
      ...pick(this, [...normalizedProperties, 'is_cancelled']),
    }
  }

  /**
   * Get a date property that has been modified to account for a discrepancy
   * between the server time and the client device time.
   */
  getAdjustedDate (prop) {
    const date = new Date(
      new Date(get(this, prop)).getTime() - this.server_time_offset,
    )

    if (!isValid(date)) {
      throw new Error('Invalid date when trying to apply offset')
    }

    return date
  }
}
