import React from 'react'
import classnames from 'classnames'
import styles from './button.scss'
import NavLinkEnhanced from '../NavLinkEnhanced'
import LockedFeatureIcon from '../../molecules/LockedFeatureIcon'

const Button = (props) => {
  const {
    onClick,
    content,
    styling,
    brand,
    to,
    locked,
    ...rest
  } = props
  const className = classnames(styles.btn, styling, {
    [styles.brand]: brand,
  })

  return to
    ? (
      <NavLinkEnhanced {...rest} className={className} to={to}>
        {content}
      </NavLinkEnhanced>
    )
    : (
      <button
        {...rest}
        onClick={onClick}
        className={className}
      >
        {content}
        {locked && <LockedFeatureIcon position={{ top: '50%', right: 0 }} />}
      </button>
    )
}

export default Button
