import React from 'react'

import isSameMonth from 'date-fns/is_same_month'
import isPast from 'date-fns/is_past'
import endOfDay from 'date-fns/end_of_day'

import {
  generateMonthBlock,
} from '@ticknovate/frontend-shared/libs/generateCalendarRange'

import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import DateCell from './DateCell'

import styles from './calendar.scss'

const Calendar = ({
  from,
  to,
  earliest,
  date, // Current display month
  change,
  hideOtherMonths,
  small,
}) => {
  const dates = generateMonthBlock(date)

  const handleChange = (value) => () => change(value)

  return (
    <div className={styles.layout}>
      {
        dates.map(item => {
          const active = from === item.ISO || to === item.ISO
          const dateIsSameMonth = isSameMonth(item.ISO, date)

          const selectable = item.ISO >= earliest && !isPast(endOfDay(item.ISO))

          const beetween = item.ISO > from && item.ISO < to

          const ghosted = !beetween && (!dateIsSameMonth || !selectable)

          return (
            <DateCell
              key={item.ISO}
              date={item.ISO}
              label={formatLocal(item.date, 'D')}
              active={active}
              beetween={beetween}
              ghosted={ghosted}
              selectable={selectable}
              change={handleChange(item.ISO)}
            />
          )
        })
      }
    </div>
  )
}

export default Calendar
