/* eslint-disable no-extra-boolean-cast */
import React from 'react'

import Button from '_/components/element/Button'
import Tick from '_/components/element/Tick'
import InlineTextBox from '_/components/layout/InlineTextBox'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const InputCheckBox = ({
  title,
  field,
  value,
  change,
  ...style
}) => {
  const handleChange = (event) => {
    // Only register the change if the click was not on a link. This
    // allows us to put terms and conditions hyperlinks in the checkbox
    // label without funky behaviour.
    if (event.target.tagName !== 'A') {
      change(field, !value)
    }
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          // TODO: Change button to a checkbox. Current approach is bad for accessibility.
          <Button rawStyle={{ ...styling, justifyContent: 'flex-start' }} change={handleChange}>
            <Tick active={Boolean(value)} />
            <InlineTextBox margin={'0 0 0 1rem'} textAlign={'left'}>{title}</InlineTextBox>
          </Button>
        )
      }}
    />
  )
}

InputCheckBox.styleable = true

export default InputCheckBox
