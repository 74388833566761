import React, { Fragment } from 'react'

import sortBy from 'lodash/sortBy'

import { useTranslation } from 'react-i18next'

import addDays from 'date-fns/add_days'

import Container from '_/components/layout/Container'

import InlineTextBox from '_/components/layout/InlineTextBox'
import TextBox from '_/components/layout/TextBox'

import Button from '_/components/element/Button'

import ActionDateChanger from '_/components/action/ActionDateChanger'

import Scroll from '_/components/layout/Scroll'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import PersonRemoveIcon from '_/icons/PersonRemove'

import {
  stampToDateISO,
} from '_/libs/date'

import {
  sanitizeDateString,
} from '@ticknovate/frontend-shared/libs/dateFormatter'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

const getDateShift = (date, index) => {
  return stampToDateISO(addDays(sanitizeDateString(date), index))
}

const getTime = time => {
  const [
    hour,
    minute,
  ] = time.split(':')

  return `${hour}:${minute}`
}

const TimePicker = ({
  selected,
  displayDate,
  options = [],
  change,
  setDisplayDate,
}) => {
  const { t } = useTranslation()

  const changeDate = (next) => {
    setDisplayDate(getDateShift(displayDate, next ? 1 : -1))
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Fragment>
            <ActionDateChanger
              date={displayDate || stampToDateISO(new Date())}
              change={changeDate}
              rawStyle={{ flexGrow: 0, flexShrink: 0 }}
            />
            <Scroll rawStyle={{
              flexGrow: 1,
              flexShrink: 1,
              alignSelf: 'stretch',
              background: 'RGB(var(--theme_background_ui))',
            }}>
              <Container padding={'1rem'} background={'ticket_background'}>
                {sortBy(options, option => option.start_time).map(option => {
                  const price = formatCurrency(option.pricing.total, { currencyCode: option.currency })

                  return (
                    <Slot
                      key={option.pick_id}
                      change={() => change(option.pick_id)}
                      label={getTime(option.start_time)}
                      price={price}
                      active={option.pick_id === selected}
                      disabled={option.status !== 'available'}
                    />
                  )
                })}
                {options.length === 0 && (
                  <TextBox strong color={'text_warning'} textAlign={'center'} size={1}>{t('ticket.no-service')}</TextBox>
                )}
              </Container>
            </Scroll>
          </Fragment>
        )
      }}
    </MediaQuery>
  )
}

const Slot = ({
  active,
  label,
  price,
  disabled,
  change,
}) => {
  const {
    theme,
    explore,
  } = window.TICKNOVATE_CONFIG

  const style = {
    width: '100%',
    marginBottom: '1rem',
    borderTop: `0.125rem solid RGB(var(--theme_ticket_tile_background_border_${disabled ? 'disabled' : active ? 'active' : 'default'}))`,
    background: `RGB(var(--theme_ticket_tile_background_${disabled ? 'disabled' : active ? 'active' : 'default'}))`,
    borderBottomRightRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
  }

  return (
    <Button change={disabled ? null : change} rawStyle={style}>
      <InlineTextBox size={1.25} strong color={active ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : 'ticket_day_text_default'} margin={'0 2rem 0 0'}>{label}</InlineTextBox>
      {explore.ticket.showPrices && !disabled && (
        <InlineTextBox size={1.25} strong color={active ? 'ticket_day_text_active' : disabled ? 'ticket_tile_text_disabled' : 'ticket_day_text_default'} pushLeft>{price}</InlineTextBox>
      )}
      {disabled && (
        <PersonRemoveIcon
          fill={theme.date_info_inactive}
          size={22}
        />
      )}
    </Button>
  )
}

export default TimePicker
