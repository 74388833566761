/* eslint-disable camelcase */
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'

import StepRouter from '_/components/navigation/StepRouter'

import DateRangeSelector, { Holder } from '_/templates/DateRangeSelector'

const DateRangeSelection = ({
  earliest,
  current,
  update,
  close,
}) => {
  const { t } = useTranslation()

  const [
    range,
    setRange,
  ] = useState({
    ...current,
  })

  const handleChangeRange = (value) => {
    setRange(value)
  }

  const handleSubmit = () => {
    update(range)
    close()
  }

  const wrappedClose = () => {
    close()
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={handleSubmit}
        compact
        finalStep={0}
        routes={[
          {
            title: t('event.filter-title'),
            icon: 'calendar',
            valid: true,
            view: ({ ...viewProps }) => (
              <Holder {...viewProps}>
                <DateRangeSelector
                  current={range}
                  earliest={earliest}
                  update={handleChangeRange}
                />
              </Holder>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default DateRangeSelection
