import React, { Fragment } from 'react'

import styles from '_/components/layout/ModalColumn/modalcolumn.scss'

import TimePicker from '_/components/navigation/TimePicker'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useBasket from '_/hooks/useBasket'

import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import Heading from '_/components/layout/Heading'

import ActionCard from '_/components/action/ActionCard'

import differenceInDays from 'date-fns/difference_in_days'

import LoadIndicator from '_/templates/LoadIndicator'

const makeSerialID = (option) => {
  const {
    type,
    option_id,
    block_id,
    instance_id,
    start_date,
    start_time,
    product_id,
  } = option

  const keys = {
    type,
    option_id,
    block_id,
    instance_id,
    start_date,
    start_time,
    product_id,
  }

  return Object.keys(keys)
    .filter(key => Boolean(keys[key]))
    .map(key => keys[key])
    .join(',')
}

const Time = ({
  field,
  data,
  current,
  update,
  options,
}) => {
  const {
    state: basket,
    editBasket,
  } = useBasket()

  const booking = options[`${field}_time`].data

  const limitToDate = field === 'inbound' ? current.outbound_date : null

  const filtered = booking.options
    .filter(option => {
      if (limitToDate === null) return true

      return differenceInDays(option.start_date, limitToDate) > -1
    })
    .filter(option => option.type === 'time')
    .reduce((list, option) => {
      list.push({
        pick_id: makeSerialID(option),
        product: data.product_map[option.product_id],
        product_id: option.product_id,
        outbound_product: basket.items.outbound && field === 'inbound' ? data.product_map[basket.items.outbound.product_id] : null,
        currency: booking.currency,
        ...option, // If I am inbound don't show,
        pricing: field === 'outbound' ? option.pricing : {
          ...option.pricing,
          total: basket.items.outbound ? basket.items.outbound.pricing.total + option.pricing.total : option.pricing.total,
        },
      })

      return list
    }, [])

  const handleChange = (pick_id, combo_pick_id = null) => {
    const item = filtered.find(item => item.pick_id === pick_id)

    const {
      product_id,
      instance_id,
      start_date,
      start_time,
      pricing,
    } = item

    const basket_item = {
      pick_id,
      product_id,
      combo_id: combo_pick_id,
      instance_id,
      start_date,
      start_time,
      ticket: current.ticket,
      location: field === 'inbound' ? current.end_location : current.location,
      end_location: field === 'inbound' ? current.location : current.end_location,
      pricing,
    }

    editBasket(basket_item, field)

    if (field === 'inbound' && combo_pick_id === null) {
      const {
        combo_id,
        ...rest
      } = basket.items.outbound

      editBasket({
        ...rest,
        combo_id: null,
      }, 'outbound')
    }

    update([
      {
        field: `${field}_time`,
        value: start_time,
      },
      {
        field: `${field}_date_selection`,
        value: start_date,
      },
      {
        field: `${field}_date`,
        value: start_date,
      },
      {
        field: `${field}_combo`,
        value: combo_pick_id,
      },
    ])
  }

  const handleSelectionChange = (value) => {
    update(
      [
        {
          field: `${field}_date_selection`,
          value,
        },
      ],
    )
  }

  return (
    <Fragment>
      <TimePicker
        selected={basket.items[field] ? basket.items[field].pick_id : null}
        displayDate={current[`${field}_date_selection`]}
        options={filtered.filter(({ start_date }) => start_date === current[`${field}_date_selection`])}
        change={handleChange}
        setDisplayDate={handleSelectionChange}
      />
      {options[`${field}_time`].isFetching && (
        <LoadIndicator />
      )}
    </Fragment>
  )
}

const Holder = ({
  title,
  children,
  noSave,
  step,
  disabled,
  change,
}) => {
  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Column height={'100%'} spread={false}>
            <Heading
              level={3}
              title={title}
              noFlex
              textAlign={'left'}
              width={'100%'}
              margin={'0 0 0.5rem 0'}
              padding={'0.75rem'}
            />
            <Column rawStyle={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'hidden' }} margin={mobile ? null : '1rem 0 0 0'}>
              {children}
            </Column>
            {!noSave && (
              <Row type={'end'} noFlex className={styles.cta}>
                <ActionCard cta label={step} disabled={disabled} change={change} rawStyle={{ top: '-0.75rem' }} />
              </Row>
            )}
          </Column>
        )
      }}
    </MediaQuery>
  )
}

export default Time

export {
  Holder,
}
