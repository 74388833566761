/* eslint-disable camelcase */
import React, { useState } from 'react'

import sortBy from 'lodash/sortBy'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Area from '_/components/layout/Area'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'
import TextBox from '_/components/layout/TextBox'
import Column from '_/components/layout/Column'

import InputInteger from '_/components/input/InputInteger'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import useBasket from '_/hooks/useBasket'

import calcMaxPax from '_/libs/calcMaxPax'

import { getSingularAvailability } from '_/hooks/useBookingOptions'

import makeSerialID from '_/libs/makeSerialID'

const getNextDate = (meta, product) => {
  const {
    product_info_map,
  } = meta

  const next_service_date = product_info_map[product]?.next_service_date

  return next_service_date
}

const getMandatoryMap = (meta) => {
  return meta.tickets
    .reduce((list, ticket) => {
      const {
        value,
        mandatory_companions = [],
      } = ticket

      if (mandatory_companions.length > 0) {
        list[value] = mandatory_companions.map(getTicketID(meta))
      }

      return list
    }, {})
}

const getTicketID = (meta) => ({ entity_id }) => {
  return meta.tickets.find(({ raw_id }) => raw_id === entity_id)?.value
}

const addIndex = (value, index) => ({ index, ...value })

// Backend sends through 9999 if capacity is null, change to 1
const flattenValues = value => value === 9999 ? 1 : value

const validateMinMax = (product_id, meta, options) => {
  const valid = {
    minValid: true,
    maxValid: true,
    maxReached: false,
    minValue: 0,
    maxValue: 0,
  }

  if (!product_id) {
    return valid
  } else {
    const {
      qty_limit_per_booking_type,
      qty_limit_per_booking_min,
      qty_limit_per_booking_max,
      ticket_types,
    } = meta.product_info_map[product_id]

    valid.minValue = qty_limit_per_booking_min
    valid.maxValue = qty_limit_per_booking_max

    if (qty_limit_per_booking_type === 'none') return valid

    const total_quantity = options
      .reduce((amount, { value, qty }) => {
        if (qty_limit_per_booking_type === 'capacity_unit') {
          const exists = ticket_types.find(type => type.id === value)

          return amount + (exists ? qty * flattenValues(exists.capacity_units) : qty)
        } else {
          return amount + qty
        }
      }, 0)

    if (total_quantity < qty_limit_per_booking_min) valid.minValid = false
    if (total_quantity > qty_limit_per_booking_max) valid.maxValid = false
    if (total_quantity >= qty_limit_per_booking_max) valid.maxReached = true

    return valid
  }
}

const Travellers = ({
  data,
  current,
  update,
  close,
}) => {
  const { t } = useTranslation()

  const {
    editBasket,
  } = useBasket()

  const revert = useRevert(current, update, true)

  const [
    submitted,
    setSubmitted,
  ] = useState(false)

  const {
    explore,
    modalIcons,
  } = window.TICKNOVATE_CONFIG

  const layout = explore[current.type]

  const autoDate = getNextDate(data, current.product_id)

  const mandatory_companions = getMandatoryMap(data)

  let remaining = 999
  let options = sortBy(
    current.ticket
      .map(addIndex)
      .map(ticket => {
        const info = data.tickets.find(item => item.value === ticket.id)

        return {
          field: `ticket[${ticket.index}]qty`,
          qty: ticket.qty,
          label: info.label,
          value: ticket.id,
          order: ticket.order,
          description: info.short_description,
        }
      }),
    ['order'],
  )

  if (layout.selector === 'group') {
    options = sortBy(
      current.ticket
        .map(addIndex)
        .filter(ticket => {
          return data.tickets_group_map[ticket.id].id === layout.travellerGroup
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            field: `ticket[${ticket.index}]qty`,
            qty: ticket.qty,
            label: info.label,
            value: ticket.id,
            order: ticket.order,
            description: info.short_description,
          }
        }),
      ['order'],
    )

    remaining = calcMaxPax(data, current.ticket, options.map(option => option.value))
  }

  if (layout.selector === 'exact') {
    options = sortBy(
      current.ticket
        .map(addIndex)
        .filter(ticket => {
          return layout.exact.includes(ticket.id)
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            field: `ticket[${ticket.index}]qty`,
            qty: ticket.qty,
            label: info.label,
            value: ticket.id,
            order: ticket.order,
            description: info.short_description,
          }
        }),
      ['order'],
    )
  }

  const handleChange = async (field, value) => {
    update(
      [
        {
          field,
          value,
        },
      ],
    )
  }

  const wrappedClose = () => {
    revert()
    close()
  }

  const emptyTickets = current.ticket
    .filter(({ qty }) => qty === 0)
    .map(({ id }) => id)

  const mandatoryState = options.reduce((valid, option) => {
    const {
      value,
      qty,
    } = option

    if (mandatory_companions[value] && qty > 0) {
      const filtered = mandatory_companions[value].filter(id => emptyTickets.includes(id))

      if (filtered.length > 0) valid[value] = filtered.map(id => data.tickets_map[id]?.short)
    }

    return valid
  }, {})

  const mandatoryValid = Object.keys(mandatoryState).length === 0

  const {
    minValid,
    maxValid,
    minValue,
    maxValue,
    maxReached,
  } = validateMinMax(current.product_id, data, options)

  const remainingValid = remaining > 0

  // TODO: Move this to top level ticket logic
  const wrappedSave = async () => {
    if (autoDate && current.type === 'event' && layout.autoSelect) {
      const options = await getSingularAvailability({
        types: current.type,
        product_id: current.product_id,
        dates: [autoDate],
        ticket: current.ticket.filter(({ qty }) => qty > 0),
        ...(current.itemId && { booking: current.itemId }),
      })

      if (options.length > 0) {
        const {
          product_id,
          instance_id,
          start_date,
          start_time,
          pricing,
        } = options[0]

        const basket_item = {
          ...(current.itemId && { id: current.itemId }),
          pick_id: makeSerialID(options[0]),
          product_id,
          combo_id: null,
          instance_id,
          start_date,
          start_time,
          ticket: current.ticket
            .filter(ticket => ticket.qty > 0),
          location: null,
          end_location: null,
          pricing,
        }

        editBasket(basket_item, 'outbound')

        update([
          {
            field: 'outbound_time',
            value: start_time,
          },
          {
            field: 'outbound_date',
            value: start_date,
          },
        ])
      }
    }

    if (!mandatoryValid || !minValid || !maxValid || !remainingValid) {
      setSubmitted(true)
    } else {
      close()
    }
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={wrappedSave}
        routes={[
          {
            title: t('ticket.model-title-travellers'),
            icon: modalIcons.travellers || 'passenger',
            valid: true,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <Area
                  areas={[
                    'list',
                    'info',
                  ]}
                  columns={1}
                  rowgap={0.5}
                >
                  <Container area={'list'}>
                    {options.map(option => {
                      const hasDescription = option.description !== null && option.description !== '' && explore.showTravelDescription

                      return (
                        <Row spread={false} margin={'0 0 1rem 0'} key={option.value}>
                          <InputInteger
                            field={option.field}
                            value={option.qty}
                            change={handleChange}
                            disabled={!remainingValid || maxReached}
                            noFlex
                            gridAlign={'center'}
                            maxHeight={'2.5rem'}
                          />
                          <Column spread={false} type={'centered'} crossAxis={'flex-start'}>
                            <InlineTextBox margin={'0 0 0 1rem'} strong size={'1.125'}>{option.label}</InlineTextBox>
                            {submitted && mandatoryState[option.value] && (
                              <InlineTextBox margin={'0 0 0 1rem'} color={'text_error'}>{`${t('meta.requires')}: ${mandatoryState[option.value].join(', ')}`}</InlineTextBox>
                            )}
                            {(!submitted || !mandatoryState[option.value]) && hasDescription && (
                              <InlineTextBox margin={'0 0 0 1rem'} >{option.description}</InlineTextBox>
                            )}
                          </Column>
                        </Row>
                      )
                    })}
                  </Container>
                  <Container area={'info'} margin={'0 0 1rem 0'}>
                    {submitted && !minValid && (
                      <TextBox strong color={'text_error'} area={'info'} margin={'0 0 1rem 0'}>{t('ticket.warning-minimum', { count: minValue })}</TextBox>
                    )}
                    {maxReached && (
                      <TextBox strong color={'text_error'} area={'info'} margin={'0 0 1rem 0'}>{t('ticket.warning-maximum', { count: maxValue })}</TextBox>
                    )}
                    {submitted && !mandatoryValid && (
                      <TextBox strong color={'text_error'} area={'info'} margin={'0 0 1rem 0'}>{t('ticket.warning-mandatory')}</TextBox>
                    )}
                    {submitted && !remainingValid && (
                      <TextBox strong color={'text_error'} area={'info'} margin={'0 0 1rem 0'}>{t('ticket.warning-max-travellers')}</TextBox>
                    )}
                  </Container>
                </Area>
              </ModalColumn>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default Travellers
