import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import getRedemptionStatus from '@ticknovate/frontend-shared/libs/getRedemptionStatus'
import { formatUTCLocale } from '@ticknovate/frontend-shared/libs/dateFormatter'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import IconQrCodeScanner from '@ticknovate/frontend-shared/icons/QrCodeScanner'

import { convertMaybeTimezonelessToUtc } from '@ticknovate/frontend-shared/libs/maybeTimezonelessTimeToUtc'

const IconItem = ({ text, icon, fill }) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Row>
      <IconQrCodeScanner size={18} fill={theme[fill]} />
      <InlineTextBox color={fill}>{text}</InlineTextBox>
    </Row>
  )
}

const Redemption = ({ tickets, dateFormat }) => {
  const {
    t,
  } = useTranslation()

  if (tickets.length === 0) return null

  const {
    status,
    redemptions,
  } = getRedemptionStatus(tickets)

  return (
    <Fragment>
      {status === 'EXPIRED' && (
        <IconItem
          text={t('customer-history.label-expired')}
          fill={'text_error'}
        />
      )}
      {status === 'NOT_REDEEMED' && (
        <IconItem
          text={t('customer-history.label-not-redeemed')}
          fill={'text_success'}
        />
      )}
      {status === 'PARTIALLY_REDEEMED' && (
        <IconItem
          text={t('customer-history.label-partial-redeemed')}
          fill={'text_warning'}
        />
      )}
      {status === 'REDEEMED' && (
        <IconItem
          text={`${t('customer-history.label-redeemed')}: ${formatUTCLocale(convertMaybeTimezonelessToUtc(redemptions[redemptions.length - 1].scanned_at), dateFormat)}`}
          fill={'text_error'}
        />
      )}
    </Fragment>
  )
}

export default Redemption
