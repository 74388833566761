import React from 'react'

import { useTranslation } from 'react-i18next'

import InlineTextBox from '_/components/layout/InlineTextBox'
import FloatButton from '_/components/element/FloatButton'
import Button from '_/components/element/Button'
import Icon from '_/components/element/Icon'

import {
  formatCurrency,
} from '_/libs/basketCalculations'

import { createSchema } from 'morphism'

import mapToSchema, {
  mapToArraySubSchema,
} from '_/libs/mapToSchema'

import useBasket from '_/hooks/useBasket'
import useCart from '_/hooks/useCart'

// TODO: Completely remove this and use the model from /models/cart
const totals_default = {
  total: 0,
  discount: 0,
  sub_total: 0,
}

const getTicketSchema = () => {
  return createSchema(
    {
      'title': 'title',
      'qty': 'qty',
      'sub_total': 'pricing.total',
    },
  )
}

const getItemSchema = () => {
  return createSchema(
    {
      'id': 'booked_unit.instance_id',
      'title': (iteratee, source, destination) => {
        const {
          location,
          end_location,
        } = iteratee

        if ((location && location.title) && (end_location && end_location.title)) {
          return `${location.title} - ${end_location.title}`
        }

        return null
      },
      'product': 'product.title',
      'date': 'booked_unit.start_date',
      'sub_total': 'pricing.total',
      'tickets': {
        path: 'ticket_types',
        fn: mapToArraySubSchema(getTicketSchema),
      },
    },
  )
}

const getBasketSchema = () => {
  return createSchema(
    {
      'currency_code': 'currency',
      'totals': {
        path: 'items',
        fn: (value) => {
          if (!value) return { ...totals_default }
          return value.reduce((totals, item) => {
            const {
              total,
              subtotal,
              discount,
            } = item.pricing

            totals.total = totals.total + total
            totals.sub_total = totals.sub_total + subtotal
            totals.discount = totals.discount + discount

            return totals
          }, { ...totals_default })
        },
      },
      'items': {
        path: 'items',
        fn: mapToArraySubSchema(getItemSchema),
      },
    },
  )
}

const ActionBasket = ({
  change,
  basket,
  ...style
}) => {
  const {
    t,
  } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const mapped = mapToSchema(getBasketSchema)(basket.data)

  return (
    <FloatButton change={change} minHeight={'3.125rem'} background={'basket_button_background'} {...style}>
      <Icon name={'basket'} size={34} fill={theme.basket_button_text} />
      {mapped.items.length > 0 && (
        <InlineTextBox buttonLabel color={'basket_button_text'} margin={'0 0 0 0.5rem'}>
          {formatCurrency(mapped.totals.total, {
            currencyCode: mapped.currency_code,
          })}
        </InlineTextBox>
      )}
      {mapped.items.length === 0 && (
        <InlineTextBox buttonLabel color={'basket_button_text'} margin={'0 0 0 0.5rem'}>{t('meta.empty')}</InlineTextBox>
      )}
    </FloatButton>
  )
}

ActionBasket.styleable = true

const ActionMenuBasket = ({
  change,
  basket,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const mapped = mapToSchema(getBasketSchema)(basket.data)

  return (
    <Button change={change} mainAxis={'flex-end'} rawStyle={{ gap: '0.5rem' }} {...style}>
      {mapped.items.length > 0 && (
        <InlineTextBox size={'0.75rem'} strong color={'basket_button_text'} margin={'0 0 0 0.5rem'}>
          {formatCurrency(mapped.totals.total, {
            currencyCode: mapped.currency_code,
          })}
        </InlineTextBox>
      )}
      <Icon name={'basket'} size={24} fill={theme.basket_button_text} />
    </Button>
  )
}

ActionMenuBasket.styleable = true

const ActionBasketTicket = ({
  type = 'action',
  change,
  ...style
}) => {
  const { state } = useBasket()
  const calculations = useCart(state)

  const Action = type === 'action' ? ActionBasket : ActionMenuBasket

  return (
    <Action
      change={change}
      basket={calculations}
      {...style}
    />
  )
}

ActionBasketTicket.styleable = true

const ActionBasketCart = ({
  type = 'action',
  change,
  ...style
}) => {
  const basket = useCart()

  const Action = type === 'action' ? ActionBasket : ActionMenuBasket

  return (
    <Action
      change={change}
      basket={basket}
      {...style}
    />
  )
}

ActionBasketCart.styleable = true

export default ActionBasketTicket

export {
  ActionBasketCart,
}
