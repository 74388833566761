import React from 'react'

import { formatUTCLocale } from '@ticknovate/frontend-shared/libs/dateFormatter'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import Button from '_/components/element/Button'

import Icon from '_/components/element/Icon'

const DateChanger = ({
  date,
  format = 'ddd D MMM YYYY',
  change,
  minHeight = '2rem',
  ...style
}) => {
  const { theme } = window.TICKNOVATE_CONFIG

  return (
    <Row type={'spaced'} {...style} minHeight={minHeight} padding={'0 1rem'}>
      <Button
        change={() => change(false)}
        noFlex
      >
        <Icon
          name={'arrow_left'}
          fill={theme.text}
          size={24}
        />
      </Button>
      <DisplayDate date={date} format={format} minHeight={minHeight} />
      <Button
        change={() => change(true)}
        noFlex
      >
        <Icon
          name={'arrow_right'}
          fill={theme.text}
          size={24}
        />
      </Button>
    </Row>
  )
}

const DisplayDate = ({
  date,
  format = 'ddd D MMM YYYY',
  ...style
}) => {
  const { theme } = window.TICKNOVATE_CONFIG

  return (
    <Row type={'centered'} {...style}>
      <Icon
        name={'calendar'}
        fill={theme.text}
        size={24}
      />
      <InlineTextBox strong size={1}>
        {formatUTCLocale(new Date(date).toISOString(), format)}
      </InlineTextBox>
    </Row>
  )
}

export default DateChanger

export {
  DisplayDate,
}
