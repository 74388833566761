import useAsyncData from './useAsyncData'
import * as salesOrderApi from '../../api/salesOrder'
import { decorateWithCumulativeTotal } from '../../libs/transactionUtils'

const useSalesOrderPayments = orderId => useAsyncData({
  promiseCreator: (...args) => salesOrderApi.getPayments(...args).then(decorateWithCumulativeTotal),
  promiseCreatorArgs: [orderId],
  promiseCreatorShouldRun: orderId != null,
})

export default useSalesOrderPayments
