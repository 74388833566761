import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Summary from '_/templates/Summary'

import FixedWrapper from '_/components/layout/FixedWrapper'
import ActionCard from '_/components/action/ActionCardReverse'

import StepRouter from '_/components/navigation/StepRouter'

const Basket = ({
  close,
}) => {
  const { t } = useTranslation()
  return (
    <ModalView close={close}>
      <StepRouter
        save={null}
        routes={[
          {
            title: t('basket.section-title'),
            icon: 'basket',
            valid: true,
            view: ({ ...viewProps }) => {
              return (
                <Fragment>
                  <ModalColumn {...viewProps}>
                    <Summary />
                    <FixedWrapper padding={'0 0 5rem 0'} mainAxis={'flex-start'} rawStyle={{ left: 0 }}>
                      <ActionCard cta label={t('meta.back')} change={close} />
                    </FixedWrapper>
                  </ModalColumn>
                </Fragment>
              )
            },
          },
        ]}
        noSave
      />
    </ModalView>
  )
}

export default Basket
