import React from 'react'

import Row from '_/components/layout/Row'

const NavWrapper = ({
  children,
  ...style
}) => {
  return (
    <Row
      background={'background_nav'}
      {...style}
    >
      {children}
    </Row>
  )
}

export default NavWrapper
