import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

import {
  getBookingSearchSchema,
} from '_/models/bookingOptions'

import {
  generateCalendarDates,
  generatePaddedBlock,
} from '_/libs/generateMonthBlock'

import checkMultiUI from '_/libs/CheckMultiUI'

import useBasket from '_/hooks/useBasket'

const getOptions = (params) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return apiCaller('product-booking-options', app.auth)({ query: params })
}

const defaults = {
  isFetching: false,
  isLoaded: false,
  data: {
    currency: '',
    combos: [],
    options: [],
  },
}

const getSearchTimeByType = (current, field) => {
  if (current[`${field}_time_type`] === 'depart') {
    return current[`${field}_time_selection`]
  } else {
    const [
      hour,
    ] = current[`${field}_time_selection`].split(':')

    const altered = Number(hour) - 6

    return altered < 0 ? '00:00' : `${altered < 10 ? '0' : ''}${altered}:00`
  }
}

const getBookingSearch = ({
  field = 'outbound',
  current,
  layout,
  basket,
  isTicketSearch = false,
}) => {
  const {
    type,
    product_id,
    location,
    end_location,
    ticket,
    itemId,
  } = current

  const isMulti = checkMultiUI(current, layout)

  const dateValue = current[`${field}_date_selection`]

  const search = {
    type,
    product_id,
    dates: isTicketSearch ? isMulti ? [dateValue] : generatePaddedBlock(dateValue, 3, null) : generateCalendarDates(dateValue),
    location: field === 'inbound' ? end_location : location,
    end_location: field === 'inbound' ? location : end_location,
    ticket: ticket.filter(({ qty }) => qty > 0),
    ...(itemId && { booking: itemId }),
  }

  if (isTicketSearch && type !== 'event' && isMulti) search.time = getSearchTimeByType(current, field)

  if (!isTicketSearch) search.include = 'cheapest-per-day'

  if (field === 'inbound' && basket.items.outbound !== null) {
    const {
      instance_id,
      product_id,
      start_date,
      start_time,
    } = basket.items.outbound

    const selection = [
      {
        product: {
          id: product_id,
        },
        booked_unit: {
          instance_id,
          start_date,
          start_time,
        },
        location: {
          id: current.location,
        },
        end_location: {
          id: current.end_location,
        },
        ticket_types: current.ticket
          .filter(({ qty }) => qty > 0)
          .map(({ id, qty }) => ({
            id,
            qty,
          })),
      },
    ]

    search.selection = JSON.stringify(selection)
  }

  return search
}

const isEnabled = (field, current) => {
  const {
    type,
    location,
    outbound_date,
    ticket,
  } = current

  if (field === 'inbound') {
    if (type === 'event') return false

    return (
      location !== null &&
      outbound_date !== null &&
      ticket.some(({ qty }) => qty > 0)
    )
  } else {
    return (
      location !== null &&
      ticket.some(({ qty }) => qty > 0)
    )
  }
}

const useGetOptions = ({
  field = 'outbound',
  current,
  layout,
  isTicketSearch = false,
}) => {
  const {
    state: basket,
  } = useBasket()

  const { env } = window.TICKNOVATE_CONFIG

  const params = getBookingSearch({
    field,
    current,
    layout,
    basket,
    isTicketSearch,
  })

  const options = getBookingSearchSchema({
    ...params,
    market: env.market || 'de',
  })

  const {
    isFetching,
    isSuccess,
    isError,
    data,
  } = useQuery(['product-booking-options', options], () => getOptions(options), {
    retry: 0,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // Keep me at 30 minutes
    enabled: isEnabled(field, current),
  })

  if (isSuccess) {
    return {
      isFetching,
      isLoaded: isSuccess,
      data,
    }
  }

  if (isError) {
    console.log('Error in fetch')

    return {
      ...defaults,
      isFetching,
      isLoaded: isError,
    }
  }

  return {
    ...defaults,
    isLoaded: isSuccess,
    isFetching,
  }
}

const useBookings = ({
  current,
  layout,
}) => {
  const options_defaults = { current, layout }

  const outbound_date = useGetOptions({ ...options_defaults })
  const outbound_time = useGetOptions({ ...options_defaults, isTicketSearch: true })
  const inbound_date = useGetOptions({ field: 'inbound', ...options_defaults })
  const inbound_time = useGetOptions({ field: 'inbound', ...options_defaults, isTicketSearch: true })

  return {
    isFetching: outbound_date.isFetching || outbound_time.isFetching || inbound_date.isFetching || inbound_time.isFetching,
    outbound_date: { ...defaults, ...outbound_date },
    outbound_time: { ...defaults, ...outbound_time },
    inbound_date: { ...defaults, ...inbound_date },
    inbound_time: { ...defaults, ...inbound_time },
  }
}

export default useBookings
