import React, { useState } from 'react'

import styles from './datepicker.scss'

import {
  splitDate,
  stampToDateISO,
  stampFromYM,
  isValidDate,
} from '@ticknovate/frontend-shared/libs/date'
import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import Container from '_/components/layout/Container'

import ActionDateChanger from '_/components/action/ActionDateChanger'
import DayBar from '../DatePicker/calendar/DayBar'
import Calendar from './calendar/Calendar'

const getInitial = (selected) => {
  const date = selected !== null && isValidDate(selected)
    ? selected
    : formatLocal(Date.now(), 'YYYY-MM-DD')

  const { month, year } = splitDate(date)

  return {
    month,
    year,
  }
}

const DateRange = ({
  initial = null,
  from,
  to,
  earliest,
  hideOtherMonths = false,
  isFocused,
  focusProps,
  change,
  small,
  onChangeMonth,
  ...style
}) => {
  const [
    clicks,
    setClicks,
  ] = useState(0)

  const [
    display,
    setDisplayDate,
  ] = useState(getInitial(from || initial))

  const getMonthStamp = () => {
    const { month, year } = display
    return stampFromYM(year, month - 1)
  }

  // Set range:
  // If value = from or to set both = value
  // If value = before from, set to = from and from = value
  // If value = after from, set to = value
  const handleChange = (date) => {
    switch (clicks) {
      case 1:
        if (date === from || date === to) {
          change({
            from: date,
            to: date,
          })
        } else {
          if (date > from) {
            change({
              from,
              to: date,
            })
          }

          if (date < from) {
            change({
              from: date,
              to: from,
            })
          }
        }

        setClicks(0)

        break
      case 2: // Not really needed, but there in case we want to nullify
        change({
          from: null,
          to: null,
        })

        setClicks(0)

        break
      default:
        change({
          from: date,
          to: date,
        })

        setClicks(1)
    }
  }

  const changeMonth = (increase) => {
    let {
      month,
      year,
    } = display

    if (increase) {
      if ((month + 1) > 12) {
        month = 1
        year += 1
      } else {
        month += 1
      }
    } else {
      if ((month - 1) < 1) {
        month = 12
        year -= 1
      } else {
        month -= 1
      }
    }

    setDisplayDate({
      month,
      year,
    })

    onChangeMonth && onChangeMonth(stampToDateISO(stampFromYM(year, month - 1)))
  }

  const monthStamp = getMonthStamp()

  return (
    <Container {...style}>
      <ActionDateChanger
        date={monthStamp}
        format={'MMM YYYY'}
        change={changeMonth}
        minHeight={'1.5rem'}
        margin={'0 0 0 auto'}
        width={'50%'}
      />
      <DayBar
        startsOnMonday
        small={small}
      />
      <div className={styles.layout}>
        <Calendar
          date={stampToDateISO(monthStamp)}
          change={handleChange}
          changeMonth={changeMonth}
          from={from}
          to={to}
          earliest={earliest}
          hideOtherMonths={hideOtherMonths}
        />
      </div>
    </Container>
  )
}

export default DateRange
