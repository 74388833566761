import React from 'react'

const AsteriskIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
    >
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M15.9676+13.2096L15.8876+13.2096C16.4285+13.618+16.91+14.0995+17.3183+14.6403L19.6228+17.8186L15.8091+20.5578L13.5454+17.4202C13.1564+16.9062+12.8607+16.328+12.6716+15.7118C12.4118+16.299+12.0922+16.8579+11.7178+17.3795L9.37403+20.5578L5.60111+17.7779L7.9041+14.6358C8.29888+14.1087+8.76692+13.6406+9.29405+13.2459C8.62971+13.236+7.9717+13.1151+7.34722+12.8882L3.61354+11.6612L5.08347+7.2122L8.77791+8.40444C9.41617+8.61693+10.0186+8.92476+10.5648+9.31749C10.3781+8.67206+10.2846+8.00329+10.2871+7.33143L10.2871+3.43777L14.9746+3.43777L14.9746+7.32992C14.977+8.00178+14.8835+8.67055+14.6969+9.31598C15.2342+8.94339+15.8229+8.65107+16.4445+8.44821L20.1782+7.25597L21.6089+11.705L17.9537+12.8972C17.3119+13.1039+16.6418+13.2093+15.9676+13.2096Z' fill={fill} />
    </svg>
  )
}

export default AsteriskIcon
