import React from 'react'
import classnames from 'classnames'
import styles from './fakefield.scss'

/**
 * All of the padding of an input field, but with no actual <input />!
 */
const FakeField = ({ children, styling, ...rest }) => {
  return (
    <div
      {...rest}
      className={classnames(styles.wrapper, styling)}
    >
      {children}
    </div>
  )
}

export default FakeField
