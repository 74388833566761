import React from 'react'
import styles from './button.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const FloatButton = (props) => {
  const {
    change,
    disabled = false,
    children,
    ...style
  } = props

  let type = null

  if (disabled) type = styles.disabled

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styleCombine(styles.button, type)}
            onClick={change}
            disabled={disabled}
          >
            {children}
          </button>
        )
      }}
    />
  )
}

FloatButton.styleable = true

export default FloatButton
