const checkMultiUI = (current, layout) => {
  if (!layout) return false

  const {
    location,
    end_location,
  } = current

  let isMulti = false

  const locationsValid = location !== null && end_location !== null

  if (layout.variant && current.type === 'route') {
    if (layout.routesDisableMulti) {
      isMulti = locationsValid && layout.variant === 'multi' && !layout.routesDisableMulti
        .some(({ depart, arrive }) => depart === location && arrive === end_location)
    } else {
      isMulti = locationsValid && layout.variant === 'multi'
    }
  }

  return isMulti
}

export default checkMultiUI
