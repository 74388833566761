import { useRef } from 'react'

import { decorateWithServerOffsetTime } from '@ticknovate/frontend-shared/api/base'

import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

import orderDecorator from '_/libs/orderDecorator'

const getOrders = () => apiCaller('me/orders', true)({ includeResponse: true })
  .then(decorateWithServerOffsetTime)
  .then(orders => orders.reverse())

const useCustomerOrders = () => {
  const errorSent = useRef(false)

  const {
    isSuccess,
    isError,
    data,
  } = useQuery(['customer', 'orders'], getOrders, {
    retry: 0,
    staleTime: 1000 * 60 * 10, // Keep me at 10 minutes
  })

  if (isSuccess) {
    return {
      isLoaded: isSuccess,
      data: data.map(orderDecorator),
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isLoaded: isError,
      data: [],
    }
  }

  return {
    isLoaded: false,
    data: [],
  }
}

export default useCustomerOrders
