import React, { useState } from 'react'

import styles from './datepicker.scss'

import {
  splitDate,
  stampToDateISO,
  stampFromYM,
  isValidDate,
} from '@ticknovate/frontend-shared/libs/date'
import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import Container from '_/components/layout/Container'

import TextBox from '_/components/layout/TextBox'

import ActionDateChanger from '_/components/action/ActionDateChanger'
import DayBar from './calendar/DayBar'
import Calendar from './calendar/Calendar'
import Tip from './calendar/Tip'

const getInitial = (selected) => {
  const date = selected !== null && isValidDate(selected)
    ? selected
    : formatLocal(Date.now(), 'YYYY-MM-DD')

  const { month, year } = splitDate(date)

  return {
    month,
    year,
  }
}

const DatePicker = ({
  title = null,
  initial = null,
  selected,
  meta = [],
  change,
  small,
  tip = null,
  onChangeMonth,
  showPrice = true,
  ...style
}) => {
  const [
    display,
    setDisplayDate,
  ] = useState(getInitial(selected || initial))

  const getMonthStamp = () => {
    const { month, year } = display
    return stampFromYM(year, month - 1)
  }

  const handleChange = (date) => {
    change(date)
    setDisplayDate(getInitial(date))
  }

  const changeMonth = (increase) => {
    let {
      month,
      year,
    } = display

    if (increase) {
      if ((month + 1) > 12) {
        month = 1
        year += 1
      } else {
        month += 1
      }
    } else {
      if ((month - 1) < 1) {
        month = 12
        year -= 1
      } else {
        month -= 1
      }
    }

    setDisplayDate({
      month,
      year,
    })

    onChangeMonth && onChangeMonth(stampToDateISO(stampFromYM(year, month - 1)))
  }

  const monthStamp = getMonthStamp()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const titleStyle = {
    color: theme.text_light,
    backgroundColor: theme.background_nav,
    borderRadius: '0 0 0.5rem 0.5rem',
  }

  return (
    <Container {...style}>
      <ActionDateChanger
        date={monthStamp}
        format={'MMM YYYY'}
        change={changeMonth}
        minHeight={'1.5rem'}
        margin={small ? '0' : '0 0 0 auto'}
        width={small ? 'auto' : '50%'}
      />
      <DayBar
        startsOnMonday
        small={small}
      />
      <div className={styles.layout}>
        {title && (
          <TextBox textAlign={'center'} uppercase strong margin={'0 1rem'} padding={'0.5rem 0'} rawStyle={titleStyle}>{title}</TextBox>
        )}
        <Calendar
          date={stampToDateISO(monthStamp)}
          change={handleChange}
          changeMonth={changeMonth}
          selected={selected}
          meta={meta}
          showPrice={showPrice}
        />
        {tip && (
          <Tip label={tip} />
        )}
      </div>
    </Container>
  )
}

export default DatePicker
