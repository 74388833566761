import codes from './codes.json'

import sortBy from 'lodash/sortBy'

const getCodesAsOptions = (first = []) => {
  return sortBy(codes, ['name'])
    .map(({ name, alpha2Code }) => {
      return {
        label: name,
        value: alpha2Code,
      }
    })
}

export {
  getCodesAsOptions,
}
