import upperFirst from 'lodash/upperFirst'

export const formatPaymentMethodName = method => {
  let [, ...methodTypeParts] = method.split('_').map(upperFirst)

  return methodTypeParts.join(' ')
}

export const formatPaymentMethod = ({ method, card_last_4_digits, card_type, details }) => {
  const extras = method === 'internal_generic'
    ? [
      details.type,
    ].filter(Boolean)
    : [
      card_type,
      card_last_4_digits && `**${card_last_4_digits}`,
    ].filter(Boolean)

  return [
    formatPaymentMethodName(method),
    extras.length && `(${extras.join(' ')})`,
  ].filter(Boolean).join(' ')
}
