import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from '_/components/layout/Heading'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

import Container from '_/components/layout/Container'
import IconEvent from '@ticknovate/frontend-shared/icons/Event'

import { formatCurrency } from '@ticknovate/frontend-shared/libs/moneyFormatting'
import { formatUTCLocale } from '@ticknovate/frontend-shared/libs/dateFormatter'

import Redemption from '../../Redemption'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

const top = {
  borderTop: '1px solid rgb(var(--theme_border_thick))',
}

const bottom = {
  borderBottom: '1px solid rgb(var(--theme_border_thick))',
}

const rowStyle = {
  ...top,
  padding: '0.5rem 0.25rem',
}

const ItemDetailsSummary = ({
  time,
  item,
  tickets,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <MediaQuery media={media.mobile}>
      {mobile => (
        <Row type={'start'} spread={false} padding={'1rem 0'} rawStyle={mobile ? { display: 'block' } : {}}>
          <Row type={'start'} noFlex margin={mobile ? '0 1.5rem 0.5rem 0' : '0 1.5rem 0 0'}>
            <IconEvent size={18} fill={theme.text} />
            <InlineTextBox>{time}</InlineTextBox>
          </Row>
          <Redemption tickets={tickets} dateFormat={'D MMMM YYYY'} />
        </Row>
      )}
    </MediaQuery>
  )
}

const ItemDetailsTable = ({
  item,
  order,
  showPrice = true,
  showTotal = true,
}) => {
  return (
    <Container rawStyle={showTotal ? {} : bottom}>
      {item.ticket_types
        .map((ticket) => (
          <Row type={'spaced'} rawStyle={rowStyle} key={ticket.id}>
            <InlineTextBox>{`${ticket.qty ? `${ticket.qty}x` : '-'} ${ticket.title}`}</InlineTextBox>
            {showPrice && (
              <InlineTextBox>{ticket.pricing.total !== null ? formatCurrency(ticket.pricing.total, { currencyCode: order.currency }) : '-'}</InlineTextBox>
            )}
          </Row>
        ))
      }
      {showTotal && (
        <TotalRow item={item} order={order} />
      )}
    </Container>
  )
}

const TotalRow = ({
  item,
  order,
}) => {
  const { t } = useTranslation()

  return (
    <Row type={'spaced'} background={'row_highlight'} rawStyle={{ ...rowStyle, ...bottom }}>
      <InlineTextBox strong>{t('meta.total')}</InlineTextBox>

      <InlineTextBox strong>{formatCurrency(item.pricing.total, { currencyCode: order.currency })}</InlineTextBox>
    </Row>
  )
}

const ItemDetails = ({
  index,
  item,
  order,
  tickets,
}) => {
  const { t } = useTranslation()

  return (
    <Container margin={'0 0 1.5rem 0'} padding={'1.5rem'} background={'background_module'} shadow>
      <Heading
        title={item.product.type === 'event' ? t(`meta.${item.product.type}`) : index === 0 ? t('customer-history.outbound-journey') : t('customer-history.inbound-journey')}
        level={4}
      />
      <BasketItem
        item={item}
        order={order}
        tickets={tickets}
      />
    </Container>
  )
}

const BasketItem = ({
  order,
  item,
  tickets,
}) => {
  const { t } = useTranslation()

  if (item.product.type === 'route') {
    const legs = item.legs.filter(leg => leg.operator_type !== 'transfer')

    return (
      <Fragment>
        {legs.map((leg, index) => {
          const title = `${t('customer-history.leg')} ${index + 1}: ${leg.location.title} - ${leg.end_location.title}`

          const [
            hour,
            minute,
          ] = leg.start_time.split(':')

          const time = `${formatUTCLocale(leg.start, 'D MMMM YYYY')}, ${hour}:${minute}`

          return (
            <Container margin={'0 0 1rem 0'} key={index}>
              <Heading
                title={title}
                level={2}
              />
              <Heading
                title={item.combos.length > 0 ? `${item.combos[0].title}, ${item.title}` : item.title}
                level={3}
              />
              {item.is_cancelled && (
                <InlineTextBox color={'text_error'} strong>
                  {t('meta.cancelled').toUpperCase()}
                </InlineTextBox>
              )}
              {item.is_amending && (
                <InlineTextBox color={'text_error'} strong>
                  {`${t('meta.edited').toUpperCase()} ${formatCurrency(item.pricing.payable, { currencyCode: order.currency })}`}
                </InlineTextBox>
              )}
              <ItemDetailsSummary time={time} item={item} tickets={tickets} />
              <ItemDetailsTable item={item} tickets={tickets} order={order} showPrice={false} showTotal={false} />
            </Container>
          )
        })}
        <TotalRow item={item} order={order} />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Heading
        title={item.combos.length > 0 ? `${item.combos[0].title}, ${item.title}` : item.title}
        level={2}
      />
      {item.is_cancelled && (
        <InlineTextBox color={'text_error'} strong>
          {t('meta.cancelled').toUpperCase()}
        </InlineTextBox>
      )}
      {item.is_amending && (
        <InlineTextBox color={'text_error'} strong>
          {`${t('meta.edited').toUpperCase()} ${formatCurrency(item.pricing.payable, { currencyCode: order.currency })}`}
        </InlineTextBox>
      )}
      <ItemDetailsSummary time={formatUTCLocale(item.booked_unit.start_date, 'D MMMM YYYY')} item={item} tickets={tickets} />
      <ItemDetailsTable item={item} tickets={tickets} order={order} />
    </Fragment>
  )
}

export default ItemDetails
