import React, { Component } from 'react'

import sumBy from 'lodash/sumBy'
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import { isValidDate } from '../../libs/date'

export const FormContext = React.createContext({
  shouldDisplayAllErrors: false,
  isFormValid: () => false,
  valid: {},
  errorMessages: {},
  validateFrom: Date.now(),
})

export const withValidator = () => {
  // TODO: There is no reason to wrap this class in the "withValidator" function. Tidy this.
  class Validator extends Component {
    state = {
      shouldDisplayAllErrors: false,
      validateFrom: Date.now(),
    }

    checkCurrentValidity = () => {
      const { record, spec } = this.props
      return mapValues(spec, (validationRuleFn, key) => {
        const value = get(record, key)
        return validationRuleFn(record, value)
      })
    }

    isFormValid = ({ displayAllErrors = false } = {}) => {
      const validity = this.checkCurrentValidity()

      if (displayAllErrors) {
        this.setState({ shouldDisplayAllErrors: true })
      }
      return values(validity).every(item => item === true)
    }

    reset = () => {
      this.setState({
        shouldDisplayAllErrors: false,
        validateFrom: Date.now(),
      })
    }

    render () {
      const { shouldDisplayAllErrors, validateFrom } = this.state
      const validityState = this.checkCurrentValidity()
      // Value may be `true` (no error), `false` (error), or a string error message (also an error).
      // Explicity check that the value is `true`, not just truthy.
      const valid = mapValues(validityState, value => value === true)
      const errorMessages = mapValues(validityState, value => {
        // Custom error message defined
        if (typeof value === 'string') {
          return value
        }

        // Value is not `true`. Return generic error message
        if (!value) {
          return 'Field is required'
        }

        return null
      })

      return (
        <FormContext.Provider
          value={{
            valid,
            validateFrom,
            errorMessages,
            shouldDisplayAllErrors,
            isFormValid: this.isFormValid,
          }}
        >
          {this.props.children}
        </FormContext.Provider>
      )
    }
  }

  return Validator
}

// TODO: Tidy this. Wrapper function is unnecessary.
export const Validator = withValidator()

export const validate = (Wrapped) => {
  const ValidateField = (props) => {
    return (
      <FormContext.Consumer>
        {validity => {
          const {
            valid,
            validateFrom,
            errorMessages,
            shouldDisplayAllErrors,
          } = validity
          const { field, requiresValidFields = [], ...rest } = props
          let statusValid

          if (valid && field && valid.hasOwnProperty(field)) {
            statusValid = valid[field]
          }

          if (requiresValidFields.some(field => valid[field] === false)) {
            statusValid = false
          }

          const status = statusValid === true || statusValid === undefined
            ? 'validated'
            : 'error'

          return (
            <Wrapped
              required
              requiredText={errorMessages[field]}
              {...rest}
              field={field}
              status={status}
              validateFrom={validateFrom}
              shouldDisplayAllErrors={shouldDisplayAllErrors}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  return ValidateField
}

export const formValid = (Wrapped) => {
  const ValidateFull = (props) => {
    return (
      <FormContext.Consumer>
        {validity => {
          const { isFormValid, valid } = validity
          const status = isFormValid()

          return (
            <Wrapped
              {...props}
              status={status}
              disabled={!status || props.disabled}
              isFormValid={isFormValid}
              valid={valid}
            />
          )
        }}
      </FormContext.Consumer>
    )
  }

  return ValidateFull
}

// Helpers - use these in validation rules
export const composeRules = (...rules) => (...args) =>
  rules.reduce((isValid, rule) => {
    if (isValid !== true) {
      return isValid // `false`, or a string with error message
    }

    return rule(...args)
  }, true)
export const applyRuleIf = (rule, predicateFn) =>
  (record, value) =>
    predicateFn(record, value)
      ? rule(record, value)
      : true

export const overwriteError = (rule, message) => (...args) =>
  rule(...args) ? true : message
export const notEmpty = (record, value) => {
  if (typeof value === 'string') {
    return value.trim() !== ''
  }

  return value != null
}
export const isTrue = (record, value) => value === true
export const requiredNumber = (record, value) => typeof value === 'number'
export const minimumLength = minLength => (record, value) => value && value.length >= minLength
export const maximumLength = maxLength => (record, value) => value && value.length <= maxLength
export const minimumSumBy = (key, sum) => (record, value) => sumBy(value, key) >= sum
export const validDate = (record, value) => isValidDate(value)
export const validEmail = (record, value) => {
  const input = document.createElement('input')
  input.type = 'email'
  input.value = value
  return input.checkValidity()
}
/**
 * Loosely validate phone numbers.
 *
 * We're not too restrictive here as international phone numbers have varying
 * length and format varies depending on country.
 *
 * We just validate that there are roughly the right amount of digits in the
 * field.
 */
export const validPhoneNumber = (record, value) => {
  const valueNumbers = value.replace(/[^\d]/g, '')
  return valueNumbers.length >= 7 && valueNumbers.length < 16
}
export const validPassword = (record, value) => {
  const passShape = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/
  return passShape.test(value)
}
export const every = fn => (record, value) => value.every((item) => fn(record, item))
