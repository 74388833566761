import React from 'react'
import classnames from 'classnames'

import './gridcell.scss'

const GridCell = (props) => {
  const {
    children,
    colspan = 1,
    rowspan = 1,
    styling,
    noChildSpacing,
    getRef,
    align = 'stretch',
    justify = 'stretch',
    start = 'auto',
    style,
    ...rest
  } = props

  const cellStyle = {
    justifySelf: justify,
    alignSelf: align,
    gridColumnStart: start,
    ...style,
  }

  return (
    <div
      {...rest}
      style={cellStyle}
      className={classnames(
        'gridCell',
        `colspan-${colspan}_rowspan-${rowspan}`,
        styling,
        { noChildSpacing },
      )}
      ref={getRef}
    >
      {children}
    </div>
  )
}

export default GridCell
