import React from 'react'
import classnames from 'classnames'

import './grid.scss'

/**
 * @param {Number} cols - number of columns (1 - 12, or 'auto')
 * @param {Number} rows - number of rows (1 - 12, or 'auto')
 * @param {String} gap - 'none', 'tiny', 'small', 'medium', 'large' or 'huge'
 * @param {String} flow - 'row', 'column' or 'dense'
 * @param {String} colcell - whether all cols are same size, 'fixed' or 'auto'
 * @param {String} rowcell - whether all rows are same size, 'fixed' or 'auto'
 * @param {String} styling
 * @param {React.Node} children
**/

const Grid = ({
  children,
  cols,
  rows,
  flow = 'row',
  colcell = 'auto',
  rowcell = 'auto',
  gap = 'large',
  styling,
  getRef,
  onClick,
}) => (
  <div
    className={classnames(
      'grid',
      `grid-gap-${gap}`,
      `flow-${flow}`,
      styling,
      {
        [`col-${cols}_cell-${colcell}`]: cols != null,
        [`row-${rows}_cell-${rowcell}`]: rows != null,
      },
    )}
    onClick={onClick}
    ref={getRef}
  >
    {children}
  </div>
)

export default Grid
