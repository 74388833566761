import React from 'react'

import Button from '_/components/element/Button'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Waiting from '_/components/element/Waiting'

import CancelIcon from '_/icons/CancelNeg'

const ActionDiscard = ({
  label,
  change,
  iconColor = 'button_stop',
  loading = false,
  ...styling
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Button change={change} color={iconColor} padding={'0'} {...styling}>
      <Row>
        <CancelIcon
          size={24}
          fill={theme[iconColor]}
        />
        <InlineTextBox strong color={iconColor}>{label}</InlineTextBox>
      </Row>
      {loading && (
        <Row type={'centered'} cover>
          <Waiting color={'card_warning_text'} />
        </Row>
      )}
    </Button>
  )
}

export default ActionDiscard
