import React, { Fragment } from 'react'

import convert from './stylingHandler'

/**
 * Styling wrapper using render props
 * @param {Function} render what to render using styling output
 * @param {String} color colour value for text content
 * @param {String} background colour value for containers background
 * @param {Boolean} strong font weight bold
 * @param {Boolean} em font style italic
 * @param {Boolean} block force display block
 * @param {String} margin set the elements margin
 * @param {String} minHeight set the elements relative height
*/

const StyleWrapper = ({ render, ...style }) => {
  const styling = convert(style)

  return (
    <Fragment>
      {render(styling)}
    </Fragment>
  )
}

export default StyleWrapper
