const makeSerialID = (option) => {
  const {
    type,
    option_id,
    block_id,
    instance_id,
    start_date,
    start_time,
    product_id,
  } = option

  const keys = {
    type,
    option_id,
    block_id,
    instance_id,
    start_date,
    start_time,
    product_id,
  }

  return Object.keys(keys)
    .filter(key => Boolean(keys[key]))
    .map(key => keys[key])
    .join(',')
}

export default makeSerialID
