
import React, { Fragment, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import set from 'lodash/fp/set'
import sortBy from 'lodash/sortBy'
import classnames from 'classnames'
import { v4 as uuidV4 } from 'uuid'
import { formatCurrency } from '../../../../../../libs/moneyFormatting'
import styles from './refundform.scss'
import utilStyles from '_/styles/util.scss'

import Table from '../../../../../molecules/Table'
import { formatPaymentMethod, formatPaymentMethodName } from '../../../../../../libs/paymentUtils'
import CheckBox from '../../../../../molecules/CheckBox'
import CurrencyField from '../../../../../molecules/CurrencyField'
import keyBy from 'lodash/keyBy'
import sumBy from 'lodash/sumBy'
import FakeField from '../../../../../molecules/FakeField'
import AddCircleIcon from '../../../../../../icons/AddCircle'
import TextIconRow from '../../../../../atoms/TextIconRow'
import { keyboardClickableProps } from '../../../../../../libs/accessibilityHelpers'
import { paymentMethods } from '../../../../../../libs/constants'
import Select from '../../../../../atoms/Select'
import Area from '../../../../../atoms/Area'
import StatefulContainer from '../../../../../atoms/StatefulContainer'
// import { validate, FormContext } from '../../../../../HOC/formValidator'
import Notification from '../../../../../molecules/Notification'
import Grid from '../../../../../containers/Grid'
import GridCell from '../../../../../containers/GridCell'
import useAllowedPaymentMethods from '../../../../../hooks/useAllowedPaymentMethods'
import useSalesOrderPayments from '../../../../../hooks/useSalesOrderPayments'

import TextBox from '../../../../../containers/TextBox'
import Heading from '../../../../../atoms/Heading'

import GenericFreePaymentForm from '../GenericFreePaymentForm'

// const ValidCurrencyField = validate(CurrencyField)

const methodMeta = {
  /** "Free" payment via credit. For trade partner's being billed via invoice. */
  [paymentMethods.CREDIT]: {
    automatic: true,
    description: false,
  },
  [paymentMethods.CASH]: {
    automatic: false,
    description: true,
  },
  [paymentMethods.GENERIC]: {
    automatic: false,
    description: true,
  },
  [paymentMethods.CARD]: {
    automatic: true,
    description: false,
  },
  [paymentMethods.NETS_EASY]: {
    automatic: true,
    description: false,
  },
}

const getPaidAmountForMethod = (method, transactions) => sumBy(
  transactions.filter(transaction => {
    return transaction.method === method
  }),
  'cleared_value',
)

const getRefundOptions = (transactions, pendingRefunds) => {
  const pendingRefundsById = keyBy(pendingRefunds, 'id')
  const cardOptions = transactions
    .filter(({ refundable_value, method }) => {
      return refundable_value > 0 && [
        paymentMethods.CARD,
        paymentMethods.NETS_EASY,
        paymentMethods.WORLDPAY_ONLINE,
      ].includes(method)
    })
    .map(transaction => ({
      id: `${transaction.method}-${transaction.uuid}`,
      method: transaction.method,
      related_transaction: transaction,
      reference_transaction_id: transaction.uuid,
      max_value: transaction.refundable_value,
      initial_value: transaction.refundable_value,
    }))

  const creditValue = getPaidAmountForMethod(paymentMethods.CREDIT, transactions)
  const creditOptions = creditValue > 0
    ? [{
      id: paymentMethods.CREDIT,
      method: paymentMethods.CREDIT,
      initial_value: creditValue,
    }]
    : []

  const options = [
    ...cardOptions,
    ...creditOptions,
  ]

  const extraOptions = pendingRefunds
    .filter(({ id }) => {
      return !options.find(option => option.id === id)
    })
    .map(({ id }) => {
      return {
        id,
        method: id,
        isMethodChangeable: true,
      }
    })

  options.push(...extraOptions)

  return options.map(option => {
    const pending = pendingRefundsById[option.id]
    return {
      ...option,
      checked: !!pending,
      refund: pending || {
        method: option.method,
        details: { value: 0 },
      },
    }
  })
}

// COPY IN GENERIC PAYMENT FORM IN HERE TO GET REFUND FUNC AND SWITCH TO USE STATE FOR PENDING REFUNDS
const RefundForm = props => {
  const {
    draftCart,
    pendingRefunds,
    setPendingRefunds,
  } = props
  // const { valid } = useContext(FormContext)

  const { t } = useTranslation()

  const allowedPaymentMethodsReq = useAllowedPaymentMethods(draftCart)
  const transactions = useSalesOrderPayments(draftCart.id)
  const allowedPaymentMethods = allowedPaymentMethodsReq.data || []

  if (allowedPaymentMethodsReq.NotReadyComponent) {
    return <allowedPaymentMethodsReq.NotReadyComponent />
  }

  if (transactions.NotReadyComponent) {
    return <transactions.NotReadyComponent />
  }

  const toRefund = Math.abs(draftCart.pricing.payable)
  const refunding = sumBy(pendingRefunds, 'details.value')
  const remainingRefundable = toRefund - refunding

  const tableData = getRefundOptions(transactions.data, pendingRefunds).map(option => {
    const disabled = !option.checked && remainingRefundable <= 0
    return {
      ...option,
      styling: classnames({
        [styles.alignTop]: methodMeta[option.method] && (methodMeta[option.method].description || !methodMeta[option.method].automatic),
        [styles.disabledRow]: disabled,
      }),
      disabled,
    }
  })

  const extraRefundOptions = [
    paymentMethods.CREDIT,
    paymentMethods.CASH,
    paymentMethods.GENERIC,
  ].filter(method => {
    return (
      // Can we use the payment method?
      allowedPaymentMethods.includes(method) &&
      // Has it already been selected elsewhere?
      !tableData.find(option => option.method === method)
    )
  })

  const updatePendingRefunds = newPendingRefunds => {
    setPendingRefunds(newPendingRefunds)
  }

  const updatePendingRefundById = (id, updateFn) => {
    updatePendingRefunds(pendingRefunds.map(option => {
      if (option.id === id) {
        return updateFn(option)
      } else {
        return option
      }
    }))
  }

  const addPendingRefund = method => {
    updatePendingRefunds([
      ...pendingRefunds,
      {
        id: method,
        method,
        uuid: uuidV4(),
        details: {
          value: remainingRefundable,
          description: '',
        },
      },
    ])
  }

  const columns = [
    {
      key: 'method',
      label: t('refund.method-label', 'Payment Method'),
      style: { width: '25%' },
      getter: (option) => {
        const {
          id,
          checked,
          disabled,
          method,
          initial_value,
          related_transaction,
          reference_transaction_id,
          isMethodChangeable,
          max_value,
        } = option

        return (
          <Fragment>
            <CheckBox
              data-test-handle={'checkbox-refund-method'}
              styling={classnames({
                [styles.inlineFlex]: isMethodChangeable,
              })}
              checked={checked}
              disabled={disabled}
              onChange={event => {
                const { checked } = event.currentTarget
                const filtered = pendingRefunds.filter(pending => pending.id !== id)
                updatePendingRefunds(
                  checked
                    ? [...filtered, {
                      related_transaction,
                      id,
                      uuid: uuidV4(),
                      method,
                      max_value, // Bit of a hack - store max_value for easy validation
                      details: {
                        value: Math.min(
                          initial_value != null ? initial_value : remainingRefundable,
                          remainingRefundable,
                        ),
                        reference_transaction_id,
                      },
                    }]
                    : filtered,
                )
              }}
            >
              <span className={classnames({
                [utilStyles.visuallyHidden]: isMethodChangeable,
              })}>
                {
                  related_transaction
                    ? formatPaymentMethod(related_transaction)
                    : formatPaymentMethod(option.refund)
                }
              </span>
            </CheckBox>
            {isMethodChangeable && (
              <Select
                styling={styles.methodSelect}
                options={sortBy([
                  { value: id, title: formatPaymentMethodName(method) },
                  ...extraRefundOptions.map(method => {
                    return {
                      value: method,
                      title: formatPaymentMethodName(method),
                    }
                  }),
                ], 'title')}
                value={id}
                onSelect={(index, method) => {
                  updatePendingRefundById(id, option => ({
                    ...option,
                    id: method,
                    method,
                  }))
                }}
              />
            )}
          </Fragment>
        )
      },
    },
    {
      getter: ({ refund, related_transaction, method, id }) => {
        const nodes = []

        if (related_transaction) {
          nodes.push(<span>{related_transaction.uuid}</span>)
        }

        // A method with `isMethodChangeable` is just a generic internal payment method.
        // Each of these have a "description" field that can be populated. We show that here, instead of an ID.
        if (methodMeta[method].description) {
          nodes.push(
            <StatefulContainer>
              <Area
                styling={styles.descriptionField}
                value={refund.details.description}
                placeholder={t('refund.description-placeholder', 'Describe this transaction')}
                change={(event) => {
                  updatePendingRefundById(
                    id,
                    set('details.description', event.target.value),
                  )
                }}
              />
            </StatefulContainer>,
          )
        }

        if (!methodMeta[method].automatic) {
          nodes.push(
            <Notification status={'warning'}>
              {t('refund.method-warning-body', 'A refund will not be automatically processed for this payment method. This is only a way to record a payment that has been processed outside of this system.')}
            </Notification>,
          )
        }

        if (!nodes.length) return null

        return (
          <Grid colspan={1} gap={'small'}>
            {nodes.map(node => <GridCell>{node}</GridCell>)}
          </Grid>
        )
      },
      label: t('refund.transaction-label', 'Transaction ID'),
    },
    {
      style: { width: '15%' },
      label: t('refund.how-label', 'How'),
      getter: ({ method }) => methodMeta[method].automatic
        ? t('refund.auto-label', 'Automatic')
        : t('refund.manual-label', 'Manual External'),
    },
    {
      style: { width: '10%' },
      label: t('refund.value-label', 'Value'),
      getter: ({ initial_value }) => initial_value != null ? formatCurrency(initial_value, { currencyCode: draftCart.currency }) : null,
    },
    {
      style: { width: '10%', textAlign: 'right' },
      label: t('refund.refunding-label', 'Refunding'),
      getter: ({ id, checked, refund }) => {
        return checked
          ? (
            <CurrencyField
              currency={draftCart.currency}
              messagePosition={'below'}
              subtleValidation
              field={id}
              styling={styles.refundAmountField}
              value={refund.details.value}
              noNegative
              data-test-handle={'input-refund-amount'}
              change={(field, value) => {
                updatePendingRefundById(
                  field,
                  set('details.value', value),
                )
              }}
            />
          )
          : (
            <FakeField styling={styles.refundAmountField}>
              {t('refund.none-placeholder', 'None')}
            </FakeField>
          )
      },
    },
  ]
  const addMoreDisabled = !extraRefundOptions.length

  return (
    <Fragment>
      <Grid cols={1} gap={'large'}>
        <GridCell>
          <Table
            headerType={'light'}
            columns={columns}
            data={tableData}
            styling={styles.refundsTable}
          />
          <div
            className={classnames(styles.addMethodBtn, {
              [styles.addMethodBtnDisabled]: addMoreDisabled,
            })}
          >
            <TextIconRow
              reverse
              icon={AddCircleIcon}
              iconSize={24}
              iconFill={window.TICKNOVATE_CONFIG.theme.button_cta}
              text={t('refund.add-method-button', 'Add a different refund method')}
              styling={styles.addMethodBtnTextIconRow}
              textStyling={styles.success}
              aria-disabled={addMoreDisabled}
              {...keyboardClickableProps}
              onClick={() => {
                if (!addMoreDisabled) {
                  addPendingRefund(extraRefundOptions[0])
                }
              }}
            />
          </div>
        </GridCell>
        <GridCell>
          <table className={styles.summaryTable}>
            <tbody>
              <tr>
                <th>{`${t('refund.total-title', 'Total to refund')}:`}</th>
                <td>{formatCurrency(toRefund, { currencyCode: draftCart.currency })}</td>
              </tr>
              <tr>
                <th>{`${t('refund.refund-title', 'Refunding')}:`}</th>
                <td>{formatCurrency(refunding, { currencyCode: draftCart.currency })}</td>
              </tr>
            </tbody>
          </table>
        </GridCell>
        {toRefund !== refunding && (
          <GridCell>
            <Notification status={'error'}>
              <Trans
                i18nKey={'refund.errors.INVALID-PAYMENT AMOUNT'}
                defaults={'Choose payment methods from the list above.<br />The total value (currently {{refunding}}) must equal the calculated amount to refund ({{refund}}).'}
                values={{ refunding: formatCurrency(refunding, { currencyCode: draftCart.currency }), refund: formatCurrency(toRefund, { currencyCode: draftCart.currency }) }}
              />
            </Notification>
          </GridCell>
        )}
      </Grid>
      <GenericFreePaymentForm {...props} disabled={toRefund !== refunding} paymentMethod={paymentMethods.REFUND} pendingRefunds={pendingRefunds} />
    </Fragment>
  )
}

const RefundInfo = (props) => {
  const { draftCart, redirectToConfirmation } = props

  const [pendingRefunds, setPendingRefunds] = useState([])

  // const refundButton = (
  //   <GenericFreePaymentForm {...props} paymentMethod={paymentMethods.REFUND} pendingRefunds={pendingRefunds} />
  // )

  const { t } = useTranslation()

  return (
    <Grid cols={1} gap={'large'}>
      <GridCell>
        <Heading
          level={2}
          title={t('refund-process.title', 'Refund process')}
          styling={styles.heading}
        />
        <TextBox styling={styles.bodyText}>
          <Trans
            i18nKey={'refund-process.a-body'}
            defaults={
              '<strong>Select the payment methods</strong> received to refund via those transactions.'
            }
          />
        </TextBox>
        <TextBox styling={styles.bodyText}>
          <Trans
            i18nKey={'refund-process.b-body'}
            defaults={
              '<strong>Multiple methods</strong> may be needed for refund values higher than any one payment method.'
            }
          />
        </TextBox>
        <TextBox styling={styles.bodyText}>
          <Trans
            i18nKey={'refund-process.c-body'}
            defaults={
              '<strong>Email(s) will be sent</strong> to the email addresses submitted when making payments.'
            }
          />
        </TextBox>
        <TextBox styling={styles.bodyText}>
          <Trans
            i18nKey={'refund-process.d-body'}
            defaults={
              '<strong>For card payments done on this system</strong> the refund will be done into the payment account in 3 - 5 working days.'
            }
          />
        </TextBox>
        <TextBox styling={styles.bodyText}>
          <Trans
            i18nKey={'refund-process.e-body'}
            defaults={
              '<strong>Payment methods outside of this reservations system</strong> will have to be handled manually via other methods. Check with your manager regarding the method for each payment.'
            }
          />
        </TextBox>
      </GridCell>
      <GridCell>
        <fieldset>
          <legend>
            <Heading
              level={2}
              title={t(
                'refund-process.select-heading',
                'Select refund methods',
              )}
              styling={styles.heading}
            />
          </legend>
          <RefundForm
            draftCart={draftCart}
            pendingRefunds={pendingRefunds}
            setPendingRefunds={setPendingRefunds}
            redirectToConfirmation={redirectToConfirmation}
          />
        </fieldset>
      </GridCell>
    </Grid>
  )
}

export default RefundInfo
