import React from 'react'

import Row from '_/components/layout/Row'
import Waiting from '_/components/element/Waiting'

const LoadIndicator = () => {
  return (
    <Row type={'centered'} cover>
      <Waiting color={'text_error'} />
    </Row>
  )
}

export default LoadIndicator
