import React, { Fragment } from 'react'
import * as duration from 'duration-fns'
import { useTranslation } from 'react-i18next'
import sortBy from 'lodash/sortBy'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import Column from '_/components/layout/Column'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'
import TextBox from '_/components/layout/TextBox'

import GroupedTicket from '_/components/navigation/GroupedTicket'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useOverview from '_/hooks/useOverview'

import localiseDuration from '_/libs/localiseDuration'

import ArrowForwardIcon from '_/icons/ArrowForward'
import DurationIcon from '_/icons/Duration'
import HasTransferIcon from '_/icons/HasTransfer'
import InfoIcon from '_/icons/Info'

import { Raw as InputInlineSelect } from '_/components/input/InputInlineSelect'

/*
  Ticket structure
  {
    currency: '',
    date: '',
    start_time: '',
    end_time: '',
    duration: '',
    hasTransfers: false,
    tickets: [
      {
        pick_id: '',
        title: '',
        description: '',
        price: '5000',
        status: 'available'
      }
    ]
  }
*/

const getLegsInfo = (legs) => {
  const journeyDuration = duration.between(legs[0].start, legs[legs.length - 1].end)
  const normalizedDuration = duration.normalize(journeyDuration)

  return {
    hash: `${legs[0].start},${legs[legs.length - 1].end}`,
    journeyDuration: normalizedDuration,
  }
}

const filterBefore = (legs, before) => {
  const end = legs[legs.length - 1].end

  const [
    hours,
    minutes,
  ] = end.split('T')[1].split('+')[0].split(':')

  return `${hours}:${minutes}` < before
}

const getTimedTicketGroups = ({
  options = [],
  meta,
  before = '23:59',
  comboOnly = false,
}) => {
  const output = options
    .filter(({ type }) => type === 'time')
    .filter(({ legs }) => filterBefore(legs, before))
    .reduce((acc, cur) => {
      const {
        currency,
        pick_id,
        start_date,
        pricing,
        product_id,
        product_title,
        status,
        legs,
        combos,
      } = cur

      const {
        hash,
        journeyDuration,
      } = getLegsInfo(legs)

      const curProduct = meta.product_info_map[product_id]

      if (!acc[hash]) {
        acc[hash] = {
          date: start_date,
          duration: journeyDuration,
          legs,
          tickets: [
            ...(comboOnly ? [] : [
              {
                currency,
                pick_id,
                combo_pick_id: null,
                combo_title: null,
                title: product_title,
                description: curProduct ? curProduct.short_description || curProduct.description : '',
                price: pricing.total,
                status,
              },
            ]),
            ...combos.map(combo => {
              return {
                currency,
                pick_id,
                combo_pick_id: combo.combo_pick_id,
                combo_title: combo.combo_title,
                title: product_title,
                description: combo.short_description || combo.description,
                price: combo.pricing.total,
                status,
              }
            }),
          ],
        }
      } else {
        // TODO: Remove this when we have a stable non duplicate response from the backend
        const exists = acc[hash].tickets.find(ticket => ticket.pick_id === pick_id)

        if (!exists) {
          if (!comboOnly) {
            acc[hash].tickets.push({
              currency,
              pick_id,
              combo_pick_id: null,
              combo_title: null,
              title: product_title,
              description: curProduct ? curProduct.short_description || curProduct.description : '',
              price: pricing.total,
              status,
            })
          }

          combos.forEach(combo => {
            acc[hash].tickets.push({
              currency,
              pick_id,
              combo_pick_id: combo.combo_pick_id,
              combo_title: combo.combo_title,
              title: product_title,
              description: combo.short_description || combo.description,
              price: combo.pricing.total,
              status,
            })
          })
        }
      }

      return acc
    }, {})

  return sortBy(
    Object.keys(output)
      .map(time => ({ time, ...output[time] })),
    ['time'])
}

const getDateTicketGroups = ({
  options = [],
  meta,
  comboOnly = false,
}) => {
  return options
    .filter(({ type }) => type === 'date')
    .reduce((structure, option, index) => {
      const {
        currency,
        pick_id,
        start_date,
        pricing,
        product_id,
        product_title,
        status,
        combos,
      } = option

      if (index === 0) {
        structure = {
          date: start_date,
          duration: null,
          ...structure,
        }
      }

      const curProduct = meta.product_info_map[product_id]

      if (!comboOnly) {
        structure.tickets.push({
          currency,
          pick_id,
          combo_title: null,
          title: product_title,
          description: curProduct ? curProduct.short_description || curProduct.description : '',
          price: pricing.total,
          status,
          combos,
        })
      }

      combos.forEach(combo => {
        structure.tickets.push({
          currency,
          pick_id,
          combo_pick_id: combo.combo_pick_id,
          combo_title: combo.combo_title,
          title: `${combo.combo_title}, ${product_title}`,
          description: combo.short_description || combo.description,
          price: combo.pricing.total,
          status,
        })
      })

      return structure
    }, {
      tickets: [],
    })
}

const generateTimes = (end = 24) => {
  const options = []

  for (let hour = 0; hour < end; hour++) {
    const stringHour = hour < 10 ? `0${hour}` : hour
    options.push(`${stringHour}:00`)
  }

  return options
    .map(value => ({
      value,
      label: value,
    }))
}

/**
 * For an array of legs, return the number of transfers.
 *
 * Naively, we could assume that it is just this:
 * `legs.filter(leg => leg.type === 'transfer').length`
 *
 * But that does not work for implicit transfers, such as
 * a 2 leg journey between A->C (leg 1: A->B, leg 2: B->C)
 * where there is no explicit "transfer" leg between the
 * two legs, since they are at the same location.
 */

const getRealTransferCount = (legs) => {
  const filteredLegs = legs.filter((leg) => leg.operator_type !== 'transfer')
  return Math.max(filteredLegs.length - 1, 0)
}

const TicketList = ({
  selected,
  comboOnly,
  comboPicked = null,
  change,
  options,
  displayTime,
  displayTimeType,
  setDisplayTime,
  showTypeOption,
  isFetching,
  ...style
}) => {
  const { t } = useTranslation()

  const meta = useOverview()

  const hasDates = options.filter(({ type }) => type === 'date').length > 0

  const dateTickets = getDateTicketGroups({
    options,
    meta,
    comboOnly,
  })

  const before = displayTimeType === 'arrive' ? displayTime : '23:59'

  const timeTickets = getTimedTicketGroups({
    options,
    meta,
    before,
    comboOnly,
  })

  const selectedHash = `${selected}|${comboPicked}`

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Container {...style}>
            {hasDates && (
              <GroupedTicket
                mobile={mobile}
                options={dateTickets.tickets}
                selected={selected}
                change={change}
              >
                <DayInfo mobile={mobile} />
              </GroupedTicket>
            )}
            <TimeSelector
              mobile={mobile}
              value={displayTime}
              type={displayTimeType}
              change={setDisplayTime}
              showTypeOption={showTypeOption}
            />
            {!hasDates && timeTickets.length === 0 && !isFetching && (
              <TextBox strong color={'text_warning'} textAlign={'center'} size={1}>{t('ticket.no-service')}</TextBox>
            )}
            {timeTickets.length > 0 && (
              <Fragment>
                {timeTickets.map(option => {
                  const {
                    time,
                    duration,
                  } = option

                  const [
                    start,
                    end,
                  ] = time.split(',')

                  return (
                    <GroupedTicket
                      key={time}
                      mobile={mobile}
                      options={option.tickets}
                      selected={selectedHash}
                      change={change}
                    >
                      <TimeInfo
                        start={start}
                        end={end}
                        duration={duration}
                        mobile={mobile}
                        transfersCount={getRealTransferCount(option.legs)}
                      />
                    </GroupedTicket>
                  )
                })}
              </Fragment>
            )}

          </Container>
        )
      }}
    </MediaQuery>
  )
}

const TimeSelector = ({
  mobile,
  value,
  type,
  change,
  showTypeOption,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Area
      areas={mobile ? ['chooser', 'message'] : ['chooser message .']}
      margin={'0 0 0.5rem 0'}
      columns={mobile ? 1 : 3}
      colgap={0.5}
      rowgap={0.25}
    >
      <Row area={'chooser'}>
        {!showTypeOption && (
          <InlineTextBox
            rawStyle={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            strong
            padding={'0 1rem'}
            minHeight={'2.5rem'}
          >
            {t('ticket.label-multi-depart')}
          </InlineTextBox>
        )}
        {showTypeOption && (
          <InputInlineSelect
            field={'type'}
            options={[
              {
                label: t('ticket.label-multi-arrive'),
                value: 'arrive',
              },
              {
                label: t('ticket.label-multi-depart'),
                value: 'depart',
              },
            ]}
            value={type}
            change={change}
            placeholder={'Choose time'}
            background={'ticket_option_time_select_background'}
            color={'text'}
          />
        )}
        <InputInlineSelect
          field={'selection'}
          options={generateTimes()}
          value={value}
          change={change}
          placeholder={'Choose time'}
          background={'ticket_option_time_select_background'}
          color={'text'}
        />
      </Row>
      <Row spread={false} area={'message'}>
        <InfoIcon size={20} fill={theme.ticket_option_text_default} />
        <InlineTextBox
          rawStyle={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          strong
          padding={'0 0.25rem'}
          minHeight={'2.5rem'}
          color={'ticket_option_text_default'}
        >
          {t('ticket.message-multi-ticket')}
        </InlineTextBox>
      </Row>
    </Area>
  )
}

const DayInfo = () => {
  return (
    <Column crossAxis={'flex-start'}>
      <InlineTextBox strong size={1}>{'All day tickets'}</InlineTextBox>
    </Column>
  )
}

const formatTime = (stamp) => { // I apologise Dan
  const [
    hours,
    minutes,
  ] = stamp.split('T')[1].split('+')[0].split(':')

  return `${Number(hours)}:${minutes}`
}

const TimeInfo = ({
  start,
  end,
  duration,
  transfersCount = 0,
  mobile,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Column crossAxis={'flex-start'} rawStyle={{ flexDirection: mobile ? 'row' : 'column' }} >
      <Row pushRight>
        <InlineTextBox strong size={1}>{formatTime(start)}</InlineTextBox>
        <ArrowForwardIcon fill={theme.card_default_icon} size={16} />
        <InlineTextBox strong size={1}>{formatTime(end)}</InlineTextBox>
      </Row>
      <Row noFlex>
        <DurationIcon fill={theme.card_default_icon} size={16} />
        <InlineTextBox strong size={1}>{localiseDuration(duration, t)}</InlineTextBox>
      </Row>
      {transfersCount > 0 && (
        <Row>
          <Fragment>
            <HasTransferIcon fill={theme.card_default_icon} size={16} />
            <InlineTextBox strong size={1}>
              {`${transfersCount} ${transfersCount > 1 ? t('ticket.label-multi-transfer_plural') : t('ticket.label-multi-transfer')}`}
            </InlineTextBox>
          </Fragment>
        </Row>
      )}
    </Column>
  )
}

export default TicketList
