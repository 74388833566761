import React from 'react'

import InlineTextBox from '_/components/layout/InlineTextBox'
import CardButton from '_/components/element/CardButton'
import Row from '_/components/layout/Row'
import Container from '_/components/layout/Container'
import Icon from '_/components/element/Icon'
import Waiting from '_/components/element/Waiting'

const ActionCard = ({
  label,
  sub = null,
  change,
  anchor = null,
  cta = false,
  warning = false,
  disabled = false,
  loading = false,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  let title_color = null
  let text_color = null
  let icon_color = 'card_default_icon'

  if (cta) {
    icon_color = 'card_cta_icon'
    title_color = 'card_cta_title'
    text_color = 'card_cta_text'
  }

  if (warning) {
    icon_color = 'card_warning_icon'
    title_color = 'card_warning_title'
    text_color = 'card_warning_text'
  }

  if (disabled) {
    icon_color = 'card_disabled_icon'
    title_color = 'card_disabled_title'
    text_color = 'card_disabled_text'
  }

  return (
    <CardButton change={change} anchor={anchor} minHeight={'3.125rem'} cta={cta} warning={warning} disabled={disabled} {...style}>
      <Container flex={'1 0'}>
        <Row margin={sub !== null ? '0.5rem 0 0 0' : null}>
          <InlineTextBox buttonLabel pushRight color={title_color}>{label}</InlineTextBox>
          <Icon name={'arrow_right'} size={34} fill={theme[icon_color]} />
        </Row>
        {sub !== null && (
          <Sub content={sub} color={text_color} />
        )}
      </Container>
      {loading && (
        <Row type={'centered'} cover>
          <Waiting color={'card_warning_text'} />
        </Row>
      )}
    </CardButton>
  )
}

const Sub = ({
  content,
  color,
}) => {
  if (Array.isArray(content)) {
    return (
      <Row type={'spaced'} margin={'0 0 0.75rem 0'}>
        {content.map((item, index) => {
          return (
            <InlineTextBox key={index} color={color} size={0.75}>{item}</InlineTextBox>
          )
        })}
      </Row>
    )
  } else {
    return (
      <InlineTextBox block textAlign={'left'} color={color} size={0.75} margin={'0 0 0.75rem 0'}>{content}</InlineTextBox>
    )
  }
}

ActionCard.styleable = true

export default ActionCard
