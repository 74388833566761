import {
  createStore,
  applyMiddleware,
} from 'redux'
import debounce from 'lodash/debounce'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from '_/reducers'
import { thunk } from './reduxMiddleware'
import { isSessionStale } from '../reducers/session/localState/sessionDate'
import sessionActions from '../reducers/session/localState/sessionActiveActions'

/**
 * Create the redux store for an app, and handle synchronizing
 * with some storage (like localStorage) via `load` and `save`
 * functions.
 */
const setUpStore = async ({ load, save }) => {
  let hasUnsavedChanges = false
  const store = createStore(
    rootReducer,
    await load(),
    composeWithDevTools(
      applyMiddleware(thunk),
    ),
  )

  const syncState = () => {
    save(store)
    hasUnsavedChanges = false
  }
  const debouncedSyncState = debounce(syncState, 1000)
  const initialState = store.getState()

  // Sync storage as changes happen
  store.subscribe(() => {
    hasUnsavedChanges = true
    debouncedSyncState()
  })

  // Sync state on navigate away from page
  window.addEventListener('beforeunload', () => {
    // Only update if user made some change to the state while using this tab.
    // Allows us to not lose state when closing another tab.
    if (hasUnsavedChanges) {
      syncState()
    }
  })

  // Wipe state if it is old
  if (isSessionStale(initialState.session.localState.sessionDate)) {
    store.dispatch(sessionActions.endSession())
  }

  return store
}

export default setUpStore
