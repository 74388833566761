import React from 'react'

import { useTranslation } from 'react-i18next'

import Container from '_/components/layout/Container'
import Heading from '_/components/layout/Heading'

import OrderDetailsList from './OrderDetailsList'
import BookingSummary from './BookingSummary'
import ItemDetails from './ItemDetails'

import Area from '_/components/layout/Area'
import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useSalesOrder from '_/hooks/useSalesOrder'
import useTickets from '_/hooks/useTickets'

import LoadIndicator from '_/templates/LoadIndicator'

const mobileLayout = [
  'details',
  'summary',
  'items',
]

const desktopLayout = [
  'summary/2 details',
  'items/2 details',
]

const Details = ({
  id,
}) => {
  const {
    t,
  } = useTranslation()

  const {
    isLoaded: order_loaded,
    data: order_details,
  } = useSalesOrder(id)

  const {
    isLoaded: tickets_loaded,
    data: order_tickets,
  } = useTickets(id)

  if (!order_loaded || !tickets_loaded) {
    return (
      <LoadIndicator />
    )
  }

  console.log('Order', order_details, order_tickets)

  return (
    <MediaQuery media={media.mobile}>
      {mobile => (
        <Container>
          <Area
            areas={mobile ? mobileLayout : desktopLayout}
            columns={6}
            rowgap={1.5}
            baseColUnit={1}
            minHeight={'100vh'}
          >
            <Container area={'details'}>
              <Heading
                level={2}
                title={t('customer-history.section-details-title')}
                margin={'0 0 1.25rem 0'}
              />
              <OrderDetailsList order={order_details} />
            </Container>

            <Container area={'summary'}>
              <Heading
                level={2}
                title={t('customer-history.section-summary-title')}
                margin={'0 0 1.25rem 0'}
              />
              <BookingSummary order={order_details} />
            </Container>

            <Container area={'items'}>
              <Heading
                level={2}
                title={t('customer-history.section-items-title')}
                margin={'0 0 1.25rem 0'}
              />
              {order_details.items.map((item, index) => (
                <ItemDetails
                  key={item.id}
                  index={index}
                  item={item}
                  order={order_details}
                  tickets={order_tickets.filter(ticket => ticket.booking.id === item.id)}
                />
              ))}
            </Container>
          </Area>
        </Container>
      )}
    </MediaQuery>
  )
}

export default Details
