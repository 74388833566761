import React from 'react'

import CheckableInput from '../../atoms/CheckableInput'

const CheckBox = (props) => {
  return (
    <CheckableInput
      {...props}
      type={'checkbox'}
    />
  )
}

export default CheckBox
