import React from 'react'

import { useTranslation } from 'react-i18next'

import Row from '_/components/layout/Row'
import { sanitizeDateString } from '@ticknovate/frontend-shared/libs/dateFormatter'

import isSameDay from 'date-fns/is_same_day'
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'

import styles from './day.scss'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const formatDateTitle = (date, t) => {
  const today = new Date()

  if (isSameDay(date, today)) return t('meta.today')
  if (isSameDay(date, addDays(today, 1))) return t('meta.tomorrow')

  const [
    num,
    day,
  ] = format(sanitizeDateString(date), 'D_dddd').split('_')

  return `${num} ${t(`day.short.${day.toLowerCase()}`, day)}`

  // return formatUTCLocale(date, 'ddd, D').replace(/^(\D+)(.+)$/, '$2 $1') // Don't like this, replace with correct formatter at a later date
}

const Day = props => {
  const {
    date,
    selected,
    disabled,
  } = props

  const {
    t,
  } = useTranslation()

  return (
    <Row className={styleCombine(styles.layout, selected && styles.selected, disabled && styles.disabled)}>
      {formatDateTitle(date, t)}
    </Row>
  )
}

export default Day
