import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { formatLocal } from '@ticknovate/frontend-shared/libs/dateFormatter'

import styles from './orderdetailslist.scss'

const OrderDetailsList = ({
  order,
}) => {
  const { t } = useTranslation()

  const entries = [
    () => [
      t('customer-history.label-name'),
      [
        order.customer.firstname,
        order.customer.lastname,
      ].filter(Boolean).join(' '),
    ],
    () => [t('customer-history.label-email'), order.customer.email
      ? (
        <Fragment>
          {`${order.customer.email} `}
        </Fragment>
      )
      : null,
    ],
    () => [
      t('customer-history.label-phone'), [
        order.customer.telephone_country_code
          ? `+${order.customer.telephone_country_code.split(':')[1]}`
          : null,
        `${order.customer.telephone}`,
      ].filter(Boolean).join(' '),
    ],
    () => [t('customer-history.label-date'), formatLocal(order.created_at, 'DD/MM/YYYY - HH:mm')],
  ]
    .map(getter => {
      try {
        return getter()
      } catch (err) {
        console.error('Error when trying to display order details:')
        console.error(err)
        return null
      }
    })
    .filter(Boolean)

  return (
    <ul className={styles.list}>
      {entries.map(([key, value]) => (
        <li key={key}>
          <span className={styles.key}>{key}</span>
          <span className={styles.value}>{value}</span>
        </li>
      ))}
    </ul>
  )
}

export default OrderDetailsList
