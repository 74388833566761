import { dateFnsOptions } from '_/config/dates.json'
import { moveToEndAtIndex } from './arrayHelpers'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import getTime from 'date-fns/get_time'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'
import addWeeks from 'date-fns/add_weeks'
import startOfWeek from 'date-fns/start_of_week'
import endOfWeek from 'date-fns/end_of_week'
import differenceInMonths from 'date-fns/difference_in_months'
import addMonths from 'date-fns/add_months'
import addDays from 'date-fns/add_days'
import eachDay from 'date-fns/each_day'
import min from 'date-fns/min'
import max from 'date-fns/max'
import isValid from 'date-fns/is_valid'
import setDay from 'date-fns/set_day'
import isBefore from 'date-fns/is_before'
import isAfter from 'date-fns/is_after'
import isSameDay from 'date-fns/is_same_day'
import { formatLocal, formatUTC } from './dateFormatter'

const ALL_DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6]

const getToday = () => formatLocal(new Date(), 'YYYY-MM-DD')

const stampFromYM = (year, month) => getTime(new Date(year, month, 1))

const splitDate = (date, iso) => {
  const [
    year,
    month,
    day,
  ] = new Date(date).toISOString().split('-')

  return {
    day: Number(day),
    month: iso ? Number(month) - 1 : Number(month),
    year: Number(year),
    clean: `${Number(day)}/${Number(month)}/${Number(year)}`,
  }
}

const padDays = (baseStamp, padding = 0) => {
  const start = addDays(baseStamp, padding * -1)
  const end = addDays(baseStamp, padding)
  return eachDay(start, end)
}

const getDatesInWeek = (baseStamp, offset = 0) => {
  const offsetDate = addWeeks(baseStamp, offset)
  const start = startOfWeek(offsetDate, dateFnsOptions)
  const end = endOfWeek(offsetDate, dateFnsOptions)
  return eachDay(start, end)
}

const getDatesLeftInWeek = start => eachDay(
  start,
  endOfWeek(start, dateFnsOptions),
)

const getDatesInMonth = (baseStamp, offset = 0) => {
  const offsetDate = addMonths(baseStamp, offset)
  const start = startOfMonth(offsetDate, dateFnsOptions)
  const end = endOfMonth(offsetDate, dateFnsOptions)
  return eachDay(start, end)
}

const distanceBetween = (first, last) => {
  const months = differenceInMonths(parse(last), parse(first))

  return months
}

const getRangeInDates = dates => {
  const start = min(...dates)
  const end = max(...dates)

  return { start, end }
}

const fillGapsInDates = (dates) => {
  const { start, end } = getRangeInDates(dates)
  return eachDay(start, end)
}

const stampToDateISO = (stamp) => formatLocal(stamp, 'YYYY-MM-DD')

const stampToUTCDateISO = (stamp) => formatUTC(stamp, 'YYYY-MM-DD')

const stripLocalTimeZone = date => formatLocal(date, 'YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'

const sortDates = dates =>
  dates.sort((a, b) => getTime(a) - getTime(b))

/**
 * A wrapper around date-fns's isValid function that does not throw
 * an error when passing malformed date strings.
 *
 * @param {String|Number|Date} date
 * @returns {Boolean}
 */
const isValidDate = date => isValid(new Date(date))

/**
 * Order and de-dupe an array of day indexes.
 *
 * @example
 * sanitizeDaysOfWeek([1, 5, 0, 1]) // [0, 1, 5]
 *
 * @param {Number[]} daysOfWeek
 * @returns {Number[]}
 */
const sanitizeDaysOfWeek = daysOfWeek =>
  uniq(daysOfWeek).sort()

/**
 * Order an array of day indexes, starting with the relevant day
 * as configured for this app.
 *
 * @example
 * sanitizeDaysOfWeek([0, 1, 2])
 * // [1, 2, 0] for UK (Monday first)
 * // [0, 1, 2] for US (Sunday first)
 *
 * @param {Number[]} daysOfWeek
 * @param {Number} offset
 * @returns {Number[]}
 */
const getLocaleDayOfWeeks = (daysOfWeek, offset = dateFnsOptions.weekStartsOn) => {
  const sanitized = sanitizeDaysOfWeek(daysOfWeek)

  let highestRelevantIndex = 0
  let i = daysOfWeek.length

  while (i--) {
    if (sanitized[i] >= offset) {
      highestRelevantIndex = i
    }
  }

  return moveToEndAtIndex(sanitized, highestRelevantIndex)
}

/**
 * Get the human-readable string representation of a day index.
 * It's not locale-specific - 0 is ALWAYS Sunday, as is standard in JS.
 *
 * @example
 * getFormattedDay(0, 'ddd') // 'Sun'
 * getFormattedDay(2, 'dddd') // 'Tuesday'
 *
 * @param {Number} dayIndex
 * @param {String} dayFormat
 * @returns {String}
 */
const getFormattedDay = (dayIndex, dayFormat) => {
  const date = setDay(new Date(), dayIndex)
  return formatLocal(date, dayFormat)
}

/**
 * For an array of day indexes, get a human-readable string representation.
 *
 * @example
 * getHumanDaySpan({ daysOfWeek: [0, 1] })
 * // 'Mon Sun'
 *
 * @param {Number[]} daysOfWeek
 * @returns {String}
 */
const getHumanDaySpan = ({
  daysOfWeek,
  weekendText = 'Weekend',
  wholeWeekText = 'All week',
  dayFormat = 'ddd',
  join = array => array.join(' '),
}) => {
  const sanitized = sanitizeDaysOfWeek(daysOfWeek)

  if (isEqual(sanitized, ALL_DAYS_OF_WEEK)) {
    return wholeWeekText
  }

  if (isEqual(sanitized, [0, 6])) {
    return weekendText
  }

  const days = getLocaleDayOfWeeks(daysOfWeek, dateFnsOptions.weekStartsOn)
    .map(day => getFormattedDay(day, dayFormat))

  return join(days)
}

const getHumanDuration = (rawSeconds, t) => {
  const seconds = Number(rawSeconds)

  if (Number.isNaN(seconds)) {
    if (typeof rawSeconds === 'string') {
      return rawSeconds
    } else {
      return ''
    }
  }

  const minutesTotal = Math.floor(seconds / 60)
  const minutes = minutesTotal % 60
  const hours = Math.floor((minutesTotal - minutes) / 60)

  const data = [
    {
      labelSingular: t('common.hour', 'hour'),
      labelPlural: t('common.hours', 'hours'),
      value: hours,
    },
    {
      labelSingular: t('common.minute', 'minute'),
      labelPlural: t('common.minutes', 'minutes'),
      value: minutes,
    },
  ]

  return data
    .filter(({ value }) => value !== 0)
    .map(({ value, labelSingular, labelPlural }) => `${value} ${value === 1 ? labelSingular : labelPlural}`)
    .join(' ')
}

/**
 * Returns a date which is the mix of two dates, taking the "date" value (e.g. 2019-12-02)
 * of one, and the "time" value of another (e.g. 10:00).
 */
const getSameTimeForDifferentDate = (timeDate, dateDate) => {
  const [date] = dateDate.split('T')
  const [, time] = timeDate.split('T')

  return `${date}T${time}`
}

const isBeforeOrSame = (end, date) => {
  return isSameDay(end, date) || isBefore(date, end)
}

const isAfterOrSame = (end, date) => {
  return isSameDay(end, date) || isAfter(date, end)
}

export {
  ALL_DAYS_OF_WEEK,
  padDays,
  getToday,
  stampFromYM,
  splitDate,
  getDatesInWeek,
  getDatesLeftInWeek,
  getDatesInMonth,
  distanceBetween,
  fillGapsInDates,
  stampToDateISO,
  stampToUTCDateISO,
  stripLocalTimeZone,
  sortDates,
  isValidDate,
  getHumanDaySpan,
  getHumanDuration,
  getSameTimeForDifferentDate,
  isBeforeOrSame,
  isAfterOrSame,
}
