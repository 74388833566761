import React, { useState, useEffect } from 'react'
import {
  Provider,
  useSelector,
} from 'react-redux'
import { Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import history from '@ticknovate/frontend-shared/libs/history'
import TimeoutModal from '_/templates/TimeoutModal'

import Routes from './Routes'

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { ReactQueryDevtools } from 'react-query/devtools'
import Toaster from '_/components/notification/Toaster'

import useSessionTimeout from '_/hooks/useSessionTimeout'

import NavTenant from '_/components/navigation/NavTenant'

import {
  initLocales,
  setLocalesContext,
  getContext,
  changeLanguage,
} from '@ticknovate/frontend-shared/locales'

import {
  getInitialMarketLocale,
} from '_/hooks/useOverview'

import Config from '_/libs/Config'
import EmbedTransport from '_/libs/EmbedTransport'
import getStore from '_/store'

import PoweredBy from '_/templates/PoweredBy'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
})

const setInitialLanguage = async () => {
  const marketLocale = await getInitialMarketLocale()

  changeLanguage(marketLocale)
}

const EmbedLayer = () => {
  useSessionTimeout()

  const {
    env,
    app,
  } = window.TICKNOVATE_CONFIG

  const embedReady = useSelector(state => state.ete.ready)

  useEffect(() => {
    if (!env.embeddedMode) {
      document.querySelector(':root').style.setProperty('--app-viewport', 'calc(100vh - 3.75rem)') // Thanks for that grid 🤬
    }
  }, [env.embeddedMode])

  if (!embedReady) {
    return null
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        {!env.embeddedMode && (
          <NavTenant title={'home'} />
        )}
        <Routes />
      </Router>
      {app.showPoweredBy && (
        <PoweredBy />
      )}
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster />
      <TimeoutModal />
    </QueryClientProvider>
  )
}

const initApp = async ({ locale, app, embed, context }, callback) => {
  Config.init({ app, context })

  await Config.loadConfig(embed)
  console.log('APP: post load config', Config.config)

  const initialLocale = locale || Config.app.defaultLocale

  await initLocales(`${Config.env.endpoint}/${Config.env.tenant}`, 'fe_next')
  console.log('APP: Locales loaded ready for context')
  await setLocalesContext()
  console.log('APP: Context set for locales')
  console.log('APP: Locales loaded starting app', locale, initialLocale)

  // Now everything is loaded, see if we need to await EmbedTransport
  if (Config.env.embeddedMode) {
    await EmbedTransport.init(window.parent)
  } else {
    const store = await getStore()

    await setInitialLanguage()

    await store.dispatch({
      type: 'EMBED_READY',
      ready: true,
    })
  }

  callback()
}

const App = ({
  params, // This will come from the initial query string, usually with an embed request
  store,
  ...props
}) => {
  const [
    loaded,
    setLoaded,
  ] = useState(false)

  useEffect(() => {
    initApp(params, () => setLoaded(true))
  }, [params])

  if (!loaded) {
    return null
  }

  return (
    <I18nextProvider i18n={getContext()}>
      <Provider store={store}>
        <EmbedLayer />
      </Provider>
    </I18nextProvider>
  )
}

export default App
