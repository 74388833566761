import React from 'react'

import styles from './tip.scss'

import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

const Tip = ({
  label,
}) => {
  return (
    <Row type={'start'} padding={'0 0rem 1rem 1rem'} background={'background_ui'}>
      <div className={styles.chip} />
      <InlineTextBox>{label}</InlineTextBox>
    </Row>
  )
}

export default Tip
