import { getToday } from '../../../libs/date'

const reducer = (state = getToday()) => state

/**
 * Return `true` if the current localState session has a past date.
 *
 * This is useful since the current session likely has dates selected
 * that are no longer valid, which could lead to unpredictable behaviour.
 */
export const isSessionStale = state => {
  return state !== getToday()
}

export default reducer
