import React from 'react'

import Button from '_/components/element/Button'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

const ActionIcon = ({
  label,
  change,
  icon: Icon,
  ...rest
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  return (
    <Button change={change}>
      <Row>
        <Icon size={16} fill={theme.button} /><InlineTextBox strong color={'button'}>{label}</InlineTextBox>
      </Row>
    </Button>
  )
}

export default ActionIcon
