import isPast from 'date-fns/is_past'

import * as sagePayApi from '../../../../../../api/sagepay'

export default class SagePaySessionKey {
  _data = null
  _populateDataPromise = null

  async _populateDataRAW (market) {
    const { expiry, merchantSessionKey } = await sagePayApi.getMerchantSession(market)
    const expiryWithBuffer = new Date(expiry).getTime() - window.TICKNOVATE_CONFIG.timeouts.SAGEPAY_TOKEN_EXPIRY_BUFFER_MILLISECONDS

    this._data = {
      expiry: expiryWithBuffer,
      key: merchantSessionKey,
    }
  }

  async _populateData (market) {
    // Store promise for current request so we only have one running at a time
    if (!this._populateDataPromise) {
      this._populateDataPromise = this._populateDataRAW(market)
    }

    await this._populateDataPromise
    this._populateDataPromise = null
  }

  _isValid () {
    return !!(this._data && !isPast(this._data.expiry))
  }

  invalidate () {
    this._data = null
    this._populateData()
  }

  async getKey (market) {
    if (!this._isValid()) {
      await this._populateData(market)
    }

    return this._data.key
  }

  getLastGeneratedKey () {
    return this._data.key
  }
}
