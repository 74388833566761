import {
  useSelector,
  useDispatch,
} from 'react-redux'

/*
{
  market,
  items: [
    {
      product_id,
      instance_id,
      start_date,
      start_time,
      ticket: [
        {
          id,
          qty
        }
      ],
      location,
      end_location
    }
  ],
  promo_code
}
*/

const useBasket = () => {
  const reduxDispatch = useDispatch()

  const state = useSelector((state) => state.basket)

  const setPromo = (data) => {
    reduxDispatch({
      type: 'BASKET_SET_PROMO',
      data,
    })
  }

  const setPersistID = (data) => {
    reduxDispatch({
      type: 'BASKET_PERSIST_ID',
      data,
    })
  }

  const editBasket = (data, field) => {
    const payload = {
      ...data,
    }

    if (state.persist[field] !== null) {
      payload.id = state.persist[field]
    }

    reduxDispatch({
      type: 'BASKET_EDIT',
      field,
      data: payload,
    })
  }

  const removeFromBasket = (field) => {
    reduxDispatch({
      type: 'BASKET_REMOVE',
      field,
    })
  }

  const emptyBasket = (data) => {
    reduxDispatch({
      type: 'BASKET_EMPTY',
    })
  }

  const resetBasket = (data) => {
    reduxDispatch({
      type: 'BASKET_SET',
      data,
    })
  }

  return {
    state,
    setPromo,
    editBasket,
    removeFromBasket,
    emptyBasket,
    resetBasket,
    setPersistID,
  }
}

export default useBasket
