import objectToQueryString from './objectToQueryString'
import merge from 'lodash/merge'

import blobToDataURL from './blobToDataURL'

import { getLanguage } from '../locales'

const fetch = window.fetch

const expandOptions = async options => {
  const {
    body,
    ...fetchOptions
  } = options

  const defaults = {
    method: 'GET',
    headers: { 'Accept': 'application/json' },
  }

  if (body !== undefined) {
    if (typeof body === 'string') {
      defaults.body = body
    } else {
      defaults.body = JSON.stringify(body)
      defaults.headers['Content-Type'] = 'application/json'
    }
  }

  return merge(defaults, fetchOptions)
}

const expandPathname = pathname => {
  const pathnameParts = typeof pathname === 'string' ? [pathname] : pathname
  let output = ''

  // Endpoint defined as an array of parts.
  // The pathname is constructed up until the first optional parameter is missing.
  for (const part of pathnameParts) {
    if (part == null || part === '') {
      break
    }
    output += `/${part}`
  }

  return output
}

const createApiCaller = ({
  // Dependency injection.
  // These getters rely on the redux store, which can cause some nasty circular
  // dependencies when trying to call an API endpoint from within a redux action.
  // This base class can be called with custom getters here to break the circular
  // dependency.
  getRequestChannel,
  getRequestAuth,
} = {}) => async (
  endpoint,
  {
    urlBase = window.TICKNOVATE_CONFIG.app.gatewayURL,
    responseFormat = 'json',
    query: rawQuery = {},
    ...options
  } = {},
) => {
  // await waitForInternetConnection()
  const query = { ...rawQuery }

  console.log('CALLING', window.TICKNOVATE_CONFIG.app.gatewayURL, urlBase)

  // if (query.channel == null && getRequestChannel) {
  //   query.channel = await getRequestChannel()
  // }

  const queryString = objectToQueryString(query)
  const url = `${urlBase}${expandPathname(endpoint)}${queryString}`
  const fetchOptions = await expandOptions(options)

  // If no auth header is specified, get from redux store
  if (!fetchOptions.headers.Authorization && getRequestAuth) {
    fetchOptions.headers.Authorization = await getRequestAuth()
  }

  // General language header is used to display product info and other
  // data from the backend in the correct language.
  //
  // Carts / orders internally store a reference to their own locale, so
  // backend operations for those objects may use that instead this header.
  const language = getLanguage()
  if (language) {
    fetchOptions.headers['Accept-Language'] = language
  }

  const response = await fetch(url, fetchOptions)

  if (responseFormat === 'blob') {
    const data = await response.blob()

    if (options.asUrl) {
      return blobToDataURL(data)
    }

    return data
  }

  const data = responseFormat === 'json'
    ? await response.json()
    : await response.text()

  if (!response.ok) {
    throw data
  }

  if (data.hasOwnProperty('code') && Number(data.code) >= 400) {
    throw data
  }

  if (options.includeResponse) {
    return {
      data,
      response,
    }
  }

  return data
}

export default createApiCaller

// TODO: Dave's mac running Chrome was able to reproduce this bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=678075
// It causes navigator.onLine to be `false`, even when the device
// was clearly online and able to access other websites.
// Maybe put this functionality back, but based off fetch failure.
// const waitForInternetConnection = () => {
//   const promise = new Promise(resolve => {
//     if (navigator.onLine !== false) {
//       return void resolve()
//     }

//     triggerToastMessage({
//       status: 'error',
//       message: 'Your device is not connected to the internet.',
//       uniqueId: 'ERROR-NO-INTERNET-CONNECTION',
//       getDismissPromise: () => promise
//     })

//     // Resolve when connected to internet, after a delay.
//     // The delay helps in "waiting for the dust to settle", since the user is likely
//     // to drop the connection again, perhaps when their system is automatically connecting
//     // to a VPN service.
//     const callback = debounce(() => {
//       if (navigator.onLine === false) return

//       window.removeEventListener('online', callback, false)
//       window.removeEventListener('offline', callback, false)
//       resolve()
//     }, 1000)

//     window.addEventListener('online', callback, false)
//     window.addEventListener('offline', callback, false)
//   })

//   return promise
// }
