import { callApi as rawCallApi, decorateWithServerOffsetTime } from './base'
import pick from 'lodash/pick'
import addMilliseconds from 'date-fns/add_milliseconds'
import { trimStringValues } from '../libs/objectUtils'
import getStore from '_/store'
import { trigger, ADD_TO_CART, PURCHASE, REMOVE_FROM_CART } from '../libs/events'
import { getAffiliateFuturesSession } from '../libs/affiliateFutures'

const callApi = async (url, { token, ...options } = {}) => {
  // A custom "token" value can be passed to work on a cart no longer in the
  // redux store.
  // TODO: Refactor - remove reliance on redux store. Maybe pass a single cart object to all cart API functions
  if (!token) {
    const store = await getStore()
    const state = store.getState()
    if (state.token_session) {
      // New, for up-to-date booking flow.
      token = state.token_session.token
    } else if (state.session && state.session.cart) {
      // Legacy state for reservations portal. TODO: Use one approach
      token = state.session.cart.token
    }
  }

  const { data, response } = await rawCallApi(url, {
    ...options,
    includeResponse: true,
    headers: {
      ...(token && { 'Cart-Token': token }),
      ...options.headers,
    },
  })

  if (typeof data !== 'string' && !Array.isArray(data)) {
    return decorateWithServerOffsetTime({ data, response })
  }

  return data
}

// Internal util functions
// ------------------------------------------------------------------

const markCartAsUntouched = cart => ({
  ...cart,
  // When calling /order/:id/settoamend, the "existing" property will be `true`
  // if the quote existed before the user triggered the action. It may have changes.
  untouched: !cart.existing,
})

const calculateExpiryIfPresent = cart => {
  if (cart.expiry) {
    return {
      ...cart,
      meta: {
        expires_at: addMilliseconds(Date.now(), cart.expiry).toISOString(),
      },
    }
  }

  return cart
}

// Internal API functions
// ------------------------------------------------------------------

const addToNewCart = items => callApi('cart', {
  method: 'POST',
  body: items,
  query: {
    channel: items[0].channel,
  },
})

const updateCart = (cartId, items, action) =>
  callApi(`cart/${cartId}`, {
    method: 'PUT',
    body: items.map(item => ({
      ...item,
      id: cartId,
    })),
    query: {
      channel: items[0].channel,
      action,
    },
  })

// Public API functions
// ------------------------------------------------------------------

export const getCart = (cartId, options) => callApi(`cart/${cartId}`, options)
/**
 * Delete the cart on the backend, releasing inventory held.
 *
 * @param {Number} cartId
 * @param {Object} [store] - optional redux state
 */
export const deleteCart = (cartId, token) => callApi(`cart/${cartId}`, { method: 'DELETE', token })
export const deleteCartItem = (cartId, item) =>
  callApi(`cart/${cartId}/items/${item.item_id}`, { method: 'DELETE' })
    .then(response => {
      trigger(REMOVE_FROM_CART, { removedItems: [item] })
      return response
    })

export const overwriteCartPayable = (cartId, amount, reason = null) =>
  callApi(`cart/${cartId}/overwrite/amount/${amount}`, {
    method: 'PUT',
    body: { note: reason },
  })

/**
 * Add items to the cart.
 *
 * Pass a `null` value for `cartId` to generate a new cart.
 *
 * If any of the `items` being pushed to the cart have an `item_id` property defined,
 * the respective item _already_ in the cart will be updated.
 */
export const addToCart = async (cartId, items, action = 'create') => {
  const response = cartId != null
    ? await updateCart(cartId, items, action)
    : await addToNewCart(items)
  const cart = response.then(calculateExpiryIfPresent)
  const cartItems = cart.items
  const addedItems = cartItems.slice(cartItems.length - items.length, cartItems.length)

  trigger(ADD_TO_CART, {
    cart,
    addedItems,
  })

  return cart
}

export const getCartItem = (cartId, itemId) => callApi(`cart/${cartId}/items/${itemId}`)

export const cancelCart = (cartId, feeOverride) =>
  callApi(`cart/${cartId}/cancel`, {
    method: 'PUT',
    body: {
      feeOverride,
    },
  })

export const uncancelCart = cartId =>
  callApi(`cart/${cartId}/uncancel`, { method: 'PUT' })

export const uncancelCartItem = (cartId, itemId) =>
  callApi(`cart/${cartId}/items/${itemId}/uncancel`, { method: 'PUT' })

export const updateCartItemNotes = (cartId, itemId, { supplier_note, booking_note }) =>
  callApi(`cart/${cartId}/item/${itemId}`, {
    method: 'PUT',
    responseFormat: 'plain',
    body: {
      supplier_note: supplier_note || '',
      booking_note: booking_note || '',
    },
  })

export const updateCartItemReference = (cartId, itemId, reference_a) =>
  callApi(`cart/${cartId}/item/${itemId}/reference`, {
    method: 'PUT',
    responseFormat: 'plain',
    body: {
      customer_reference: reference_a || '',
    },
  })

// Promotional code operations
export const addPromoToCart = (cartId, promoCode) =>
  callApi(`cart/${cartId}/applypromo/${promoCode}`, { method: 'POST' })

// Cart delivery method details operations
export const setCartDetails = (cartId, details) => callApi(`cart/${cartId}/details`, {
  method: 'POST',
  body: trimStringValues({
    ...details,
    // Strip unexpected properties to prevent errors being thrown.
    billing_address: pick(details.billing_address, [
      'title',
      'firstname',
      'lastname',
      'country',
      'is_billing',
      'is_shipping',
      'post_code',
      'company',
      'line_1',
      'line_2',
      'line_3',
      'town',
      'county',
      'telephone',
      'telephone_country_code',
      'mobile',
      'note',
      'email',
      'collection_point',
    ]),
  }),
  responseFormat: 'plain',
})

export const addPayment = (cart, body) => {
  return callApi(`cart/${cart.id}/payments`, {
    token: cart.token,
    method: 'POST',
    body,
  })
}

export const payLater = (cart, body) => {
  return callApi(`cart/${cart.id}/pay-later`, {
    token: cart.token,
    method: 'POST',
    body,
  })
}

// Create a payment using the "nets easy" payment method.
// This returns a paymentId that can be used to initialize the checkout iframe.
// Payment is provisional and only completed when the form in the iframe is
// completed with card details.
export const initNetsPayment = (cart) => callApi(`cart/${cart.id}/nets-easy/payments?checkout_url=${document.location.href}`, {
  token: cart.token,
  method: 'POST',
})

/** Place an order directly from a cart */
export const placeOrder = async (cart, suppress_emails = false) => {
  const order = await callApi(`cart/${cart.id}/order`, {
    token: cart.token,
    method: 'PUT',
    query: {
      suppress_emails,
      affiliate_futures_session: getAffiliateFuturesSession(),
    },
  })

  if (order.status === 'ordered') {
    trigger(PURCHASE, order)
  }

  return order
}

export const sendQuoteEmail = (cartId, params) =>
  callApi(`cart/${cartId}/sendQuote`, {
    method: 'POST',
    body: pick(params, [
      'customer_name',
      'customer_lastname',
      'customer_email',
    ]),
  })

export const reset = cartId =>
  callApi(`cart/${cartId}/reset`, { method: 'POST' })

    .then(calculateExpiryIfPresent)
    .then(markCartAsUntouched)

export const getAllowedPaymentMethods = async (cart) =>
  callApi(`cart/${cart.id}/allowed-payment-methods`, { token: cart.token, method: 'GET' })
