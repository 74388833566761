import React from 'react'

import styles from './container.scss'

const Container = (props) => {
  const {
    styling,
    children,
    getRef,
    ...rest
  } = props

  return (
    <div
      className={[styles.layout, styling].join(' ')}
      ref={getRef}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Container
