import { useRef, useCallback, useMemo } from 'react'

import cloneDeep from 'lodash/cloneDeep'

import useBasket from '_/hooks/useBasket'

import useCart from '_/hooks/useCart'

/*
  store = {
    ticket: ...
    other: ...
  }
*/
const useRevert = (store, update, holdBasket = false) => {
  const {
    state: basket,
    resetBasket,
  } = useBasket()

  const cart = useCart()

  const revertRef = useRef(cloneDeep(store))

  const staleBasket = useMemo(() => {
    return cloneDeep(basket)
  }, [])

  const revert = useCallback(() => {
    const output = Object.keys(revertRef.current)
      .map(field => {
        return {
          field,
          value: revertRef.current[field],
        }
      })

    update(output)

    if (holdBasket) {
      resetBasket(staleBasket)
      cart.reset() // Reset the cart to sync with remade state
    }
  }, [holdBasket, resetBasket, update])

  return revert
}

export default useRevert
