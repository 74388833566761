import React from 'react'
import classnames from 'classnames'
import Container from '../../containers/Container'
import Notification from '../../molecules/Notification'
import styles from './errorbanner.scss'

const ErrorBanner = ({ size = 'small', message }) => (
  <Container styling={classnames(styles.layout, styles[size])}>
    <Container styling={styles.notificationWrapper}>
      <Notification status={'error'} size={size} role={'alert'}>
        {message || 'Something went wrong'}
      </Notification>
    </Container>
  </Container>
)

export default ErrorBanner
