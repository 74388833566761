import React from 'react'

const SmartphoneGeo = ({
  width = '38.684',
  height = '50.436',
  fill,
}) => (
  <svg id="smartphone" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 38.684 50.436">
    <path id="Path_6896" data-name="Path 6896" d="M90.476,35.682a.738.738,0,0,0-.738.738v8.918a3.626,3.626,0,0,1-3.622,3.622H64.8a3.626,3.626,0,0,1-3.622-3.622V5.1A3.626,3.626,0,0,1,64.8,1.476H86.115A3.626,3.626,0,0,1,89.737,5.1V9.9a.738.738,0,1,0,1.476,0V5.1a5.1,5.1,0,0,0-5.1-5.1H64.8a5.1,5.1,0,0,0-5.1,5.1v40.24a5.1,5.1,0,0,0,5.1,5.1H86.115a5.1,5.1,0,0,0,5.1-5.1V36.42a.738.738,0,0,0-.738-.738Z" transform="translate(-59.701 0)" fill={fill}/>
    <path id="Path_6897" data-name="Path 6897" d="M218.45,37.665a.738.738,0,0,0,.738-.738V32.146A2.148,2.148,0,0,0,217.043,30h-2.426a1.046,1.046,0,0,0-.981.689l-.119.331a1.2,1.2,0,0,1-1.128.792H205.66a.738.738,0,0,0,0,1.476h6.728a2.682,2.682,0,0,0,2.517-1.768l.016-.044h2.122a.67.67,0,0,1,.669.669v4.782A.738.738,0,0,0,218.45,37.665Z" transform="translate(-190.628 -27.047)" fill={fill}/>
    <path id="Path_6898" data-name="Path 6898" d="M269.48,122.05a9.419,9.419,0,0,0-5.523,1.774.738.738,0,0,0,.861,1.2,8.009,8.009,0,0,1,12.671,6.511c0,3.02-4.125,7.958-7.986,11.572a.032.032,0,0,1-.045,0c-3.861-3.614-7.986-8.552-7.986-11.572a7.97,7.97,0,0,1,1.342-4.439.738.738,0,0,0-1.228-.82A9.441,9.441,0,0,0,260,131.535c0,1.926,1.2,4.413,3.561,7.391a51.025,51.025,0,0,0,4.892,5.258,1.513,1.513,0,0,0,2.063,0,51.054,51.054,0,0,0,4.892-5.258c2.363-2.979,3.561-5.466,3.561-7.391A9.5,9.5,0,0,0,269.48,122.05Z" transform="translate(-240.281 -110.037)" fill={fill}/>
    <path id="Path_6899" data-name="Path 6899" d="M313.092,169.845a5.3,5.3,0,1,0-5.3,5.3A5.308,5.308,0,0,0,313.092,169.845Zm-9.128,0a3.826,3.826,0,1,1,3.826,3.826A3.83,3.83,0,0,1,303.964,169.845Z" transform="translate(-278.591 -148.347)" fill={fill}/>
    <path id="Path_6900" data-name="Path 6900" d="M107.6,55.159a.738.738,0,0,0-1.044-.016l-5.228,5.072a.835.835,0,0,0,.02,1.217l12.407,11.273a.67.67,0,0,1-.587.349h-2.688L99.5,63.079a.836.836,0,0,0-1.142.018L91.179,70.06V66.632l5.53-5.365a.738.738,0,1,0-1.028-1.06l-4.5,4.368V32.146a.67.67,0,0,1,.669-.669h2.22l.016.044A2.682,2.682,0,0,0,96.6,33.29h2.035a.738.738,0,0,0,0-1.476H96.6a1.2,1.2,0,0,1-1.128-.792l-.119-.331A1.046,1.046,0,0,0,94.373,30H91.848A2.148,2.148,0,0,0,89.7,32.146v40.24a2.148,2.148,0,0,0,2.146,2.146h21.316a2.148,2.148,0,0,0,2.146-2.146V63.555a.738.738,0,1,0-1.476,0v7.231L102.843,60.8l4.737-4.6A.738.738,0,0,0,107.6,55.159ZM91.179,72.386v-.269l7.771-7.54,9.33,8.478H91.848A.67.67,0,0,1,91.179,72.386Z" transform="translate(-86.75 -27.048)" fill={fill}/>
    <path id="Path_6901" data-name="Path 6901" d="M177.932,247.593,171.326,254a.738.738,0,0,0,1.028,1.06l6.606-6.41a.738.738,0,1,0-1.028-1.06Z" transform="translate(-160.137 -223.035)" fill={fill}/>
    <path id="Path_6902" data-name="Path 6902" d="M142.177,134.054a.738.738,0,0,0,.738-.738v-3.555a3.2,3.2,0,1,0-1.476,0v3.555A.738.738,0,0,0,142.177,134.054Zm-1.721-7.4a1.721,1.721,0,1,1,1.721,1.721A1.722,1.722,0,0,1,140.456,126.651Z" transform="translate(-131.177 -111.303)" fill={fill}/>
  </svg>

)

export default SmartphoneGeo
