// This module exposes some async wrappers around window.localStorage.
// The API is async so that it can be swapped out if need be in future.

const detectStorage = () => {
  let storage

  try {
    storage = window.localStorage
    storage.testString = 'success'

    console.log('LOCAL STORAGE AVAILABLE')
  } catch (error) {
    console.log('LOCAL STORAGE UNAVAILABLE', error)
    console.log('RETURNING SINGLETON')

    storage = {}
  }

  return storage
}

const storage = detectStorage()
const APP_KEY = 'TICKNOVATE'

const getAppStore = () => {
  const state = storage[APP_KEY]

  if (state !== undefined) {
    return JSON.parse(state)
  }

  return {}
}

const store = {
  async get (key) {
    return getAppStore()[key]
  },
  async getAll () {
    return getAppStore()
  },
  async set (key, value) {
    storage[APP_KEY] = JSON.stringify({
      ...getAppStore(),
      [key]: value,
    })
  },
  async merge (value) {
    storage[APP_KEY] = JSON.stringify({
      ...getAppStore(),
      ...value,
    })
  },
  async clear () {
    storage.clear()
  },
}

/**
 * Clear the storage if the JS bundle filename has changed.
 *
 * This prevents a whole class of subtle bugs where the latest app expects a
 * certain shape to the state that could be different to what is already
 * stored in the user's browser.
 *
 * For local development, the script src doesn't change:
 * /static/js/bundle.js
 *
 * For production, the hash of the file is present in the src:
 * /static/js/main.74dceab7.js
 */
export const clearStorageIfScriptChanged = async () => {
  const SRC_KEY = 'scriptSrc'
  const scripts = document.getElementsByTagName('script')
  const { src } = (document.currentScript || scripts[scripts.length - 1])
  const previousSrc = await store.get(SRC_KEY)

  if (previousSrc && previousSrc !== src) {
    await store.clear()
  }

  await store.set(SRC_KEY, src)
}

export default store
