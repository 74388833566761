const parseValidity = (value) => {
  if (typeof value === 'undefined') return 'VALID'
  return value ? 'VALID' : 'INVALID'
}

const notEmptyOrNull = (record, value) => {
  return value !== null && value !== ''
}

const isUnique = (key) => (record, value, unique = {}) => {
  const hasValue = value !== null && value !== ''

  return unique[key] ? !unique[key].includes(value) && hasValue : hasValue
}

export {
  parseValidity,
  notEmptyOrNull,
  isUnique,
}
