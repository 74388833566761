const rgb = /(\d+),\s*(\d+),\s*(\d+)/

// Calculates the darkness of a color in RGB
const colorLightOrDark = (color) => {
  const colors = color.match(rgb)

  const r = colors[1]
  const g = colors[2]
  const b = colors[3]

  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  )

  return hsp > 127.5 ? 'light' : 'dark'
}

export default colorLightOrDark
