import React from 'react'
import { useTranslation } from 'react-i18next'

import LockIcon from '../../../icons/Lock'
import styles from './lockedfeatureicon.scss'

const LockedFeatureIcon = ({
  position = { top: 0, right: 0 },
  translateX,
  translateY,
  ...rest
}) => {
  translateX = translateX || (position.right === 0 ? '50%' : '-50%')
  translateY = translateY || (position.bottom === 0 ? '50%' : '-50%')

  const {
    t,
  } = useTranslation()

  return (
    <div
      {...rest}
      title={t('common.permissions-lock', 'You do not have the user permissions required to use this feature.')}
      className={styles.wrapper}
      style={{
        ...position,
        transform: `translate(${translateX}, ${translateY})`,
      }}
    >
      <LockIcon size={20} fill={window.TICKNOVATE_CONFIG.theme.button_inner_text} />
    </div>
  )
}

export default LockedFeatureIcon
