import React from 'react'

import { ResponsiveView as View } from '_/components/layout/View'

import Heading from '_/components/layout/Heading'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'
import ModalRouter from '_/components/navigation/ModalRouter'

import StepLayout from '_/templates/StepLayout'
import Summary from '_/templates/Summary'
import PaymentSection from '@ticknovate/frontend-shared/components/templates/PaymentPage/modules/PaymentSection'

import ActionBasket from '_/components/action/ActionBasket'

import FooterWrapper from '_/components/layout/FooterWrapper'

import useOverView from '_/hooks/useOverview'

import NavFlow from '_/components/navigation/NavFlow'
import Basket from '_/modals/Basket'
import useCart from '_/hooks/useCart'
import { useTranslation } from 'react-i18next'
import { triggerToastMessage } from '_/components/notification/Toaster'
import EmbedTransport from '_/libs/EmbedTransport'

import { triggerEndSession } from '_/hooks/useSessionTimeout'
import { usePageView } from '_/hooks/usePageView'

const flowSteps = [
  {
    title: 'Your details',
    trans: 'details.flow-title',
    path: ['/complete/:id'],
  },
  {
    title: 'Make payment',
    trans: 'payment.flow-title',
    path: ['/payment', '/paylater'],
  },
]

const Payment = ({
  history,
  match,
  location,
}) => {
  const { t } = useTranslation()
  const data = useOverView()
  const cart = useCart()
  const {
    billing = {},
    complete = false,
    flow = null,
  } = location.state

  usePageView('PAYMENT_DETAILS', { cart: cart.data })

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <ModalRouter
            mobile={mobile}
            routes={{
              basket: {
                desktop: {},
                mobile: {
                  height: 'calc(100vh - 2rem)',
                  bottom: 0,
                },
                view: ({
                  ...props
                }) => {
                  return (
                    <Basket {...props} data={data} />
                  )
                },
              },
            }}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  {!complete && (
                    <NavFlow mobile={mobile} match={match} area={'nav'} mount={mount} flow={flow}>
                      {app.basketInMenu && (
                        <ActionBasket
                          type={'menu'}
                          change={() => mount('basket')}
                          area={'child'}
                          gridAlign={'stretch'}
                        />
                      )}
                    </NavFlow>
                  )}
                  {complete && (
                    <NavFlow
                      mobile={mobile}
                      match={match}
                      area={'nav'}
                      options={flowSteps}
                      title={t('paylater.section-title')}
                      back={false}
                    >
                      {app.basketInMenu && (
                        <ActionBasket
                          type={'menu'}
                          change={() => mount('basket')}
                          area={'child'}
                          gridAlign={'stretch'}
                        />
                      )}
                    </NavFlow>
                  )}
                  <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
                    <Heading
                      level={2}
                      title={t('payment.section-title')}
                      area={'title'}
                    />
                    <div style={{ maxWidth: '600px', marginBottom: mobile ? '6rem' : '0' }}>
                      <PaymentSection
                        draftCart={cart.data}
                        billingAddress={billing}
                        updateLocalCart={(...args) => {
                          console.log('updateLocalCart called', args)
                        }}
                        redirectToConfirmation={async (order) => {
                          try {
                            triggerEndSession()

                            EmbedTransport.send({
                              type: 'BOOKING_CONFIRMATION',
                              value: order,
                            })

                            history.push('/confirmation', order)
                          } catch (error) {
                            console.error(error)
                            triggerToastMessage({
                              status: 'error',
                              message: t(
                                'billing.errors.UNABLE-TO-SAVE.long',
                                'There was an error while trying to save your details.',
                              ),
                            })
                          }
                        }}
                      />
                    </div>
                  </View>
                  {!mobile && (
                    <Summary title area={'basket'} />
                  )}
                  {mobile && !app.basketInMenu && (
                    <FooterWrapper>
                      <ActionBasket
                        change={() => mount('basket')}
                      />
                    </FooterWrapper>
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

export default Payment
