import React from 'react'

import styles from './column.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const justifyMap = {
  centered: 'center',
  spaced: 'space-between',
  start: 'flex-start',
  end: 'flex-end',
}

const Column = ({
  children,
  type = 'start',
  spread = true,
  className = null,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        const layout = {
          ...styling,
          justifyContent: justifyMap[type],
        }

        return (
          <div className={styleCombine(styles.layout, spread && styles.spread, className && className)} style={layout}>
            {children}
          </div>
        )
      }}
    />
  )
}

Column.styleable = true

export default Column
