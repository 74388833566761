import React from 'react'

import Area from '_/components/layout/Area'
import Column from '_/components/layout/Column'
import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'
import InlineTextBox from '_/components/layout/InlineTextBox'

const DescriptionBlock = ({
  label = '',
  value,
  color = 'text',
  area,
}) => {
  const {
    transforms,
  } = window.TICKNOVATE_CONFIG

  return (
    <Area
      area={area}
      areas={[
        'title',
        'value',
      ]}
      columns={1}
      baseColUnit={1}
      colgap={0}
      rowgap={0.25}
      textAlign={'left'}
      margin={'0 0 0.5rem 0'}
    >
      <Heading level={'section'} title={label} area={'title'} textTransform={transforms.heading_section || 'normal'} />
      {Array.isArray(value) && (
        <Column area={'value'} spread={false} crossAxis={'flex-start'}>
          {value.map((label, index) => {
            return (
              <InlineTextBox color={color} block key={index}>{label}</InlineTextBox>
            )
          })}
        </Column>
      )}
      {!Array.isArray(value) && (
        <TextBox color={color} noMargin area={'value'}>{value || '...'}</TextBox>
      )}
    </Area>
  )
}

export default DescriptionBlock
