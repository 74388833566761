/* eslint-disable no-new */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as cartApi from '../../../../../api/cart'
import Container from '../../../../containers/Container'
import useEventListener from '../../../../hooks/useEventListener'
import Notification from '../../../../molecules/Notification'
import SpinnerOverlay from '../../../../molecules/SpinnerOverlay'
import styles from '../../../../organisms/Form-Billing/formbilling.scss'
import Iframe3DSecure from './Iframe3DSecure'

export default function WorldpayAccessForm ({
  cart,
  redirectToConfirmation,
  termsAndConditions,
  config,
}) {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const isWorldpayUnavailable = !window.Worldpay
  const [dataFor3DSecureRedirect, setDataFor3DSecureRedirect] = useState(null)
  const [is3DSecureSubmitting, setIs3DSecureSubmitting] = useState(false)

  const completeOrderAndRedirect = async () => {
    const order = await cartApi.placeOrder(cart)
    redirectToConfirmation(order)
  }

  // Listen to messages from the 3D secure iframe
  useEventListener(
    window,
    'message',
    useCallback(async (event) => {
      if (!event.data) return

      switch (event.data.type) {
        case 'ticknovate-payment-loading':
          setIs3DSecureSubmitting(true)
          break
        case 'ticknovate-payment-completed':
          completeOrderAndRedirect()
          break
        case 'ticknovate-payment-error':
          setError(event.data.value)
          setIs3DSecureSubmitting(false)
          setDataFor3DSecureRedirect(null)
          break
      }
    }),
  )

  const NewTemplateForm = window.Worldpay.useTemplateForm

  useEffect(() => {
    // https://developer.worldpay.com/docs/wpop/template-form#include-and-initialize-worldpay-js-in-your-page
    new NewTemplateForm({
      clientKey: config.clientKey,
      form: 'wpForm',
      paymentSection: 'wpPaymentSection',
      display: 'inline',
      reusable: false,
      callback: async (res) => {
        setLoading(true)
        try {
          if (res && res.token) {
            setError(null)
            const payment = await cartApi.addPayment(cart, {
              method: 'worldpay_online',
              token: res.token,
              // Pass the cardholder name to the payment call
              // as it's required for the 3DS flow by Worldpay.
              cardholderName:
                res.paymentMethod && res.paymentMethod.name
                  ? res.paymentMethod.name
                  : '',
            })

            const is3DSecureNeeded =
              payment.status === '3d-secure-authentication-required'

            if (is3DSecureNeeded) {
              setDataFor3DSecureRedirect(payment)
            } else {
              // Place the order and redirect to confirmation
              await completeOrderAndRedirect()
            }

            setLoading(false)
          } else {
            // Display an error anyway, in case we didn't receive a token back from form submission.
            console.error(res)
            setError(res)
            setLoading(false)
          }
        } catch (err) {
          console.error(err)
          setError(err)
          setLoading(false)
        }
      },
    })
  }, [])

  if (isWorldpayUnavailable) {
    return (
      <Notification status={'error'}>
        {t([
          // Show generic message
          'billing.errors.CANT-LOAD-PAYMENT-FORM.long',
          // Last resort fallback value
          'The payment form could not load.',
        ])}
      </Notification>
    )
  }

  return (
    <Container>
      {loading && <SpinnerOverlay />}

      {dataFor3DSecureRedirect && (
        <Iframe3DSecure
          initialHtml={dataFor3DSecureRedirect.redirectIframeHTML}
          loading={is3DSecureSubmitting}
        />
      )}

      {termsAndConditions && (
        <Container styling={styles.paddingTop}>{termsAndConditions}</Container>
      )}

      <div id={'wpPaymentSection'} className={styles.wpPaymentForm} />

      {error && (
        <Notification status={'error'}>
          {t([
            // Do we human-readable text for the error message code?
            `server-errors.${error.message}`,
            // Fallback message, if above fails.
            'The payment failed, please try again.',
          ])}
        </Notification>
      )}
    </Container>
  )
}
