import objectToQueryString from '@ticknovate/frontend-shared/libs/objectToQueryString'
import merge from 'lodash/merge'

import blobToDataURL from '@ticknovate/frontend-shared/libs/blobToDataURL'

import { getLanguage } from '@ticknovate/frontend-shared/locales'

import getStore from '_/store'

const fetch = window.fetch

const expandOptions = async options => {
  const {
    body,
    ...fetchOptions
  } = options

  const defaults = {
    method: 'GET',
    headers: { 'Accept': 'application/json' },
  }

  if (body !== undefined) {
    if (typeof body === 'string') {
      defaults.body = body
    } else {
      defaults.body = JSON.stringify(body)
      defaults.headers['Content-Type'] = 'application/json'
    }
  }

  return merge(defaults, fetchOptions)
}

const expandPathname = pathname => {
  const pathnameParts = typeof pathname === 'string' ? [pathname] : pathname
  let output = ''

  // Endpoint defined as an array of parts.
  // The pathname is constructed up until the first optional parameter is missing.
  for (const part of pathnameParts) {
    if (part == null || part === '') {
      break
    }
    output += `/${part}`
  }

  return output
}

const apiCaller = (endpoint, auth = false) => async (
  {
    urlBase = window.TICKNOVATE_CONFIG.app.gatewayURL,
    responseFormat = 'json',
    query: rawQuery = {},
    ...options
  } = {},
) => {
  const query = { ...rawQuery }

  if (window.TICKNOVATE_CONFIG.env.context) {
    query['context'] = window.TICKNOVATE_CONFIG.env.context
  }

  const queryString = objectToQueryString(query)
  const url = `${urlBase}${expandPathname(endpoint)}${queryString}`
  const fetchOptions = await expandOptions(options)

  // General language header is used to display product info and other
  // data from the backend in the correct language.
  //
  // Carts / orders internally store a reference to their own locale, so
  // backend operations for those objects may use that instead this header.
  const language = getLanguage()
  if (language) {
    fetchOptions.headers['Accept-Language'] = language
  }

  if (auth) {
    const store = await getStore()

    fetchOptions.headers.Authorization = store.getState().user.accessToken
  }

  const response = await fetch(url, fetchOptions)

  if (responseFormat === 'blob') {
    const data = await response.blob()

    if (options.asUrl) {
      return blobToDataURL(data)
    }

    return data
  }

  const data = responseFormat === 'json'
    ? await response.json()
    : await response.text()

  if (!response.ok) {
    throw data
  }

  if (data.hasOwnProperty('code') && Number(data.code) >= 400) {
    throw data
  }

  if (options.includeResponse) {
    return {
      data,
      response,
    }
  }

  return data
}

export default apiCaller
