import React from 'react'

import styles from './toaster.scss'

import CancelIcon from '_/icons/Cancel'

import Row from '_/components/layout/Row'
import Button from '_/components/element/Button'

import InlineTextBox from '_/components/layout/InlineTextBox'

const status_types = {
  notice: {
    color: 'notification_default',
  },
  warning: {
    color: 'notification_warning',
  },
  error: {
    color: 'notification_error',
  },
}

const Toast = ({
  message,
  icon: Icon,
  status,
  close,
  pause,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const background = status_types?.[status]?.color || status_types.notice.color

  return (
    <div className={styles.toast} onMouseEnter={pause}>
      <Row type={'start'} background={background} padding={'1rem'}>
        {Icon}
        <InlineTextBox color={'text_light'} pushRight>{message}</InlineTextBox>
        <Button change={close}>
          <CancelIcon
            size={24}
            fill={theme.text_light}
          />
        </Button>
      </Row>
    </div>
  )
}

export default Toast
