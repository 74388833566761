// import fromPairs from 'lodash/fromPairs'
// import keyBy from 'lodash/keyBy'
// // import * as duration from 'duration-fns'
// import history from '@ticknovate/frontend-shared/libs/history'
// import global from '@ticknovate/frontend-shared/libs/global'

// export const TICKET_URL_PARAM = 'ticket'
// const TICKET_PROPERTY_DELIMITER = '_'
// const TICKET_DELIMITER = '__'
// const COLLECTION_PROPERTY_DELIMITER = ':'
// const COLLECTION_ITEM_DELIMITER = ','

const param_map = {
  'from': {
    fields: ['location'],
    parse: (value, context) => {
      return {
        location: value,
      }
    },
    stringify: ({ location = null }) => location || null,
    flatten: (value) => value || null,
  },
  'to': {
    fields: ['end_location'],
    parse: (value, context) => {
      return {
        end_location: value,
      }
    },
    stringify: ({ end_location = null }) => end_location || null,
    flatten: (value) => value || null,
  },
  'outbound': {
    fields: ['outbound_date'],
    parse: (value, context) => {
      const [
        date,
      ] = value.replace('Z', '').split('T')

      return {
        outbound_date: date,
      }
    },
    stringify: ({ outbound_date = null }) => outbound_date || null,
    flatten: (value) => value || null,
  },
  'return': {
    fields: ['inbound_date'],
    parse: (value, context) => {
      const [
        date,
      ] = value.replace('Z', '').split('T')

      return {
        inbound_date: date,
      }
    },
    stringify: ({ inbound_date = null }) => inbound_date || null,
    flatten: (value) => value || null,
  },
  'ticket': {
    fields: ['ticket'],
    parse: (value, context) => {
      return {
        ticket: value
          .split(',')
          .map(item => {
            const [
              id,
              qty,
            ] = item.split(':')

            return {
              id,
              qty: Number(qty),
            }
          }),
      }
    },
    stringify: ({ ticket = [] }) => {
      if (ticket.length === 0) return null

      return ticket
        .map(({ id, qty }) => {
          return `${id}:${qty}`
        })
        .join(',')
    },
    flatten: (ticket = []) => {
      if (ticket.length === 0) return null

      return ticket
        .map(({ id, qty }) => {
          return `${id}:${qty}`
        })
        .join(',')
    },
  },
}

const parseTicketsFromURL = (search = '') => {
  const searchParams = new URLSearchParams(search)

  const context = {}

  searchParams.forEach((value, key) => {
    if (param_map[key]) {
      Object.assign(context, param_map[key].parse(value, context))
    }
  })

  return context
}

const parseTicketsFromQuery = (query) => {
  const context = {}

  for (const property in query) {
    if (param_map[property]) {
      Object.assign(context, param_map[property].parse(query[property], context))
    }
  }

  return context
}

const parseTicketsFromObject = (context) => {
  const searchParams = new URLSearchParams('')

  Object.keys(param_map)
    .forEach(key => {
      const {
        flatten,
      } = param_map[key]

      if (!context.hasOwnProperty(key)) return

      const queryValue = flatten(context[key])

      if (queryValue !== null) {
        searchParams.set(key, queryValue)
      }
    })

  searchParams.set('reset', 'true')

  return searchParams.toString()
}

const getFieldsFromContext = (fields, context) => {
  return fields
    .filter(field => context[field] && context[field] !== null)
    .reduce((shape, field) => {
      if (context[field]) shape[field] = context[field]
      return shape
    }, {})
}

const appendTicketsToURL = (context, search = '') => {
  const searchParams = new URLSearchParams(search)

  Object.keys(param_map)
    .forEach(key => {
      const {
        fields,
        stringify,
      } = param_map[key]

      const values = getFieldsFromContext(fields, context)

      const queryValue = stringify(values)

      if (queryValue !== null) {
        searchParams.set(key, queryValue)
      }
    })

  return searchParams.toString()
}

export {
  parseTicketsFromURL,
  parseTicketsFromObject,
  appendTicketsToURL,
  parseTicketsFromQuery,
}
