import React from 'react'

import Heading from '_/components/layout/Heading'
import NavWrapper from '_/components/layout/NavWrapper'

import ActionBack from '_/components/action/ActionBack'

import Area from '_/components/layout/Area'

import history from '@ticknovate/frontend-shared/libs/history'

const desktopStyle = {
  flexGrow: 0,
  flexShrink: 0,
  height: '5rem',
  padding: '0',
}

const mobileStyle = {
  flexGrow: 0,
  flexShrink: 0,
  height: '3.75rem',
  padding: '0',
}

const NavTitle = ({
  mobile,
  title,
  headingStyle = {},
  back = false,
}) => {
  const {
    transforms,
  } = window.TICKNOVATE_CONFIG

  return (
    <NavWrapper rawStyle={mobile ? mobileStyle : desktopStyle}>
      {back && (
        <Area
          areas={[
            'back title',
          ]}
          columns={['2rem', '1fr']}
          colgap={0.5}
          margin={mobile ? '0 0 0 0.5rem' : '0 0 0 3rem'}
        >
          <ActionBack change={() => history.goBack()} area={'back'} color={'nav_icon'} />
          <Heading level={'1'} title={title} color={'nav_title'} area={'title'} rawStyle={headingStyle} textTransform={transforms.nav_title || 'normal'} />
        </Area>
      )}
      {!back && (
        <Heading level={'1'} title={title} color={'nav_title'} margin={mobile ? '0 0 0 1.25rem' : '0 0 0 3.75rem'} rawStyle={headingStyle} />
      )}
    </NavWrapper>
  )
}

export default NavTitle
