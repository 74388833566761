import React from 'react'

import StatefulWrapper from '_/components/layout/StatefulWrapper'
import Label from '_/components/layout/Label'
import Select from '_/components/element/Select'

import VerticalArrowIndicator from '_/components/element/VerticalArrowIndicator'

const InputInlineSelect = ({
  field,
  placeholder,
  status,
  value,
  disabled = false,
  required,
  requiredText,
  change,
  options = [],
  minHeight = '2.5rem',
  controlled = false,
  lockToWindow = false,
  ...style
}) => {
  const handleChange = (value) => {
    change(field, value)
  }

  return (
    <StatefulWrapper
      status={status}
      required={required}
      requiredText={requiredText}
      minHeight={minHeight}
      disabled={disabled}
      controlled={controlled}
      {...style}
    >
      <VerticalArrowIndicator
        rawStyle={{
          position: 'absolute',
          right: required ? '2rem' : '1rem',
        }}
      />
      <Select
        placeholder={placeholder}
        options={options}
        change={handleChange}
        value={value}
        disabled={disabled}
        width={'100%'}
        padding={'0 1rem'}
      />
    </StatefulWrapper>
  )
}

const InputSelectWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputInlineSelect {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputInlineSelect {...props} />
  )
}

export default InputSelectWrapped

export {
  Raw,
}
