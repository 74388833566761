import React from 'react'

const KeyboardDown = ({ size, fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24 24'>
    <path fill={fill} d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
    <path fill='none' d='M0 0h24v24H0V0z' />
  </svg>
)

export default KeyboardDown
