import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next'

const StepRouter = ({
  save,
  noSave = false,
  routes = [],
  finalStep = routes.length,
  initial = 0,
  compact = false,
}) => {
  const [
    step,
    setActive,
  ] = useState(initial)

  const handleNext = () => {
    if ((step + 1) > finalStep || (step + 1) > (routes.length - 1)) {
      save()
    } else {
      setActive(step + 1)
    }
  }

  const {
    t,
  } = useTranslation()

  const {
    icon,
    title,
    view,
    valid,
  } = routes[step]

  return (
    <Fragment>
      {view({
        setActive,
        compact,
        icon,
        title,
        noSave,
        step: step === finalStep || step === routes.length - 1 ? t('meta.done') : t('meta.next'),
        disabled: !valid,
        change: handleNext,
      })}
    </Fragment>
  )
}

export default StepRouter
