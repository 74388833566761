import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { useQueryClient } from 'react-query'

import {
  useDispatch,
} from 'react-redux'

import { useTranslation } from 'react-i18next'

import Area from '_/components/layout/Area'
import Row from '_/components/layout/Row'
import Icon from '_/components/element/Icon'
import InlineTextBox from '_/components/layout/InlineTextBox'

import { ResponsiveView as View } from '_/components/layout/View'

import ModalRouter from '_/components/navigation/ModalRouter'

import NavTitle from '_/components/navigation/NavTitle'

import ActionLink from '_/components/action/ActionLink'
import ActionCard from '_/components/action/ActionCard'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import useOverview from '_/hooks/useOverview'
import useOverviewFilter from '_/hooks/useOverviewFilter'

import DateRangeSelection from '_/modals/DateRangeSelection'

import LoadIndicator from '_/templates/LoadIndicator'

import {
  stampToDateISO,
} from '@ticknovate/frontend-shared/libs/date'

import { formatUTC } from '_/libs/dateFormatter'

import isSameDay from 'date-fns/is_same_day'
import addDays from 'date-fns/add_days'

const mobileLayout = [
  'title',
  'chooser',
]

const desktopLayout = [
  'title',
  'chooser/2 .',
]

const formatDate = (date, t) => {
  const today = new Date()

  if (isSameDay(date, today)) return t('meta.today')
  if (isSameDay(date, addDays(today, 1))) return t('meta.tomorrow')

  return formatUTC(date, 'ddd Do MMM')
}

const getLabel = ({ from, to }, t) => {
  if (from === to) {
    return formatDate(from, t)
  } else {
    return `${formatDate(from, t)} - ${formatDate(to, t)}`
  }
}

const Event = ({
  history,
  earliest,
}) => {
  const queryClient = useQueryClient()
  const reduxDispatch = useDispatch()

  queryClient.removeQueries('cart')
  queryClient.removeQueries('customer')

  const { t } = useTranslation()

  useEffect(() => {
    reduxDispatch({
      type: 'END_SESSION',
    })
  }, [reduxDispatch])

  const [
    range,
    setRange,
  ] = useState({
    from: earliest,
    to: stampToDateISO(addDays(earliest, 30)),
  })

  const {
    isFetching,
    products,
    location_map,
  } = useOverviewFilter(range)

  const handleChangeRange = (value) => {
    setRange(value)
  }

  const events = products
    .filter(product => product.type === 'event')
    .map(({ title, id, locations, next_service_date = null }) => ({
      label: title,
      value: id,
      location: locations.length === 1 ? location_map[locations[0]] : null,
      next_service_date,
    }))

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <ModalRouter
            mobile={mobile}
            routes={{
              dateRange: {
                desktop: {
                  top: '3rem',
                  left: '3rem',
                  width: '70vw',
                  bottom: '1rem',
                },
                mobile: {
                  bottom: '0rem',
                },
                view: ({
                  ...props
                }) => {
                  return (
                    <DateRangeSelection {...props} earliest={earliest} current={range} update={handleChangeRange} />
                  )
                },
              },
            }}
            render={mount => {
              return (
                <Fragment>
                  <NavTitle mobile={mobile} headingStyle={mobile ? { fontSize: '1.5rem' } : {}} title={t('event.section-title')} back />
                  <View mobile={mobile} fullFlex>
                    <Area
                      areas={mobile ? mobileLayout : desktopLayout}
                      columns={6}
                      rowgap={1}
                      baseColUnit={1}
                    >
                      <ActionLink
                        area={'title'}
                        label={getLabel(range, t)}
                        icon={'calendar'}
                        change={() => mount('dateRange')}
                      />
                      {events.length === 0 && !isFetching && (
                        <Empty
                          area={'chooser'}
                          change={handleChangeRange}
                        />
                      )}
                      {events.length > 0 && (
                        <Area
                          area={'chooser'}
                          columns={1}
                          rowgap={1}
                          baseColUnit={1}
                        >
                          {events.map(({ label, value, location, next_service_date }) => {
                            const sub = next_service_date ? [location, `${t('meta.next')}: ${formatDate(next_service_date, t)}`] : location

                            return (
                              <ActionCard
                                key={value}
                                label={label}
                                sub={sub}
                                change={() => history.push(`/ticket/event/${value}`)}
                              />
                            )
                          })}
                        </Area>
                      )}
                    </Area>
                    {isFetching && (
                      <LoadIndicator />
                    )}
                  </View>
                </Fragment>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

const Empty = ({
  change,
}) => {
  const { t } = useTranslation()

  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const handleReset = (days) => {
    change({
      from: stampToDateISO(addDays(new Date(), 1)),
      to: stampToDateISO(addDays(new Date(), days)),
    })
  }

  return (
    <div>
      <Row>
        <Icon name={'warning'} size={24} fill={theme.text_error} />
        <InlineTextBox strong color={'text_error'}>{t('event.warning-unavailable')}</InlineTextBox>
      </Row>
      <ActionLink
        label={t('event.next-two-weeks')}
        icon={'calendar'}
        change={() => handleReset(14)}
      />
      <ActionLink
        label={t('event.next-four-weeks')}
        icon={'calendar'}
        change={() => handleReset(28)}
      />
    </div>
  )
}

const Loader = (props) => {
  const {
    products,
  } = useOverview()

  const testDate = stampToDateISO(new Date())

  const earliest = useMemo(() => {
    const first = products
      .filter(({ type }) => type === 'event')
      .map(({ next_service_date }) => next_service_date)
      .sort()

    return first[0] || testDate
  }, [products, testDate])

  return (
    <Event
      {...props}
      earliest={earliest}
    />
  )
}

export default Loader
