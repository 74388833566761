import React, { useCallback, useState, useRef } from 'react'

import Label from '_/components/layout/Label'
import Input from '_/components/element/Input'

import ValidityIndicator from '_/components/element/ValidityIndicator'

import StatefulWrapper from '_/components/layout/StatefulWrapper'

import DropDown from '_/components/element/DropDown'

import useOnClickOutside from '_/hooks/useOnClickOutside'
import useParentDistance from '_/hooks/useParentDistance'

const getLabel = (found, showValue) => {
  return showValue ? `${found.label} (${found.value})` : found.label
}

const InputFilter = ({
  field,
  placeholder,
  value,
  change,
  options = [],
  status,
  required,
  requiredText,
  minHeight = '2.5rem',
  margin = '0 0.25rem 0 0.5rem',
  disabled = false,
  controlled = false,
  lockToWindow = false,
  showValue = true,
  ...style
}) => {
  const container = useRef(null)

  const [
    filter,
    setFilter,
  ] = useState('')

  const [
    focused,
    setFocus,
  ] = useState(false)

  const [
    open,
    setOpen,
  ] = useState(false)

  const forceClose = useCallback(event => {
    setOpen(false)
  }, [])

  useOnClickOutside(container, forceClose)

  const {
    maxHeight,
    calcMaxHeight,
  } = useParentDistance(container, lockToWindow)

  const handleChange = useCallback((value) => {
    change(field, value)

    setOpen(false)
  }, [change, field])

  const handleOpen = useCallback((event) => {
    event.preventDefault()

    calcMaxHeight()

    setOpen(true)
    setFocus(true)
  }, [calcMaxHeight])

  const handleBlur = useCallback(() => {
    setFocus(false)
  }, [setFocus])

  const handleFilter = (event) => setFilter(event.target.value)

  const filtered = options.filter(item => item.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1)

  const found = options.find(option => option.value === value)

  return (
    <StatefulWrapper
      focused={open}
      status={status}
      required={required}
      requiredText={requiredText}
      getRef={container}
      minHeight={minHeight}
      disabled={disabled}
      indicator={state => (<ValidityIndicator status={status} margin={'0 0.25rem'} />)}
      controlled={controlled}
      {...style}
    >
      <Input
        name={field}
        type={'text'}
        placeholder={placeholder}
        value={focused ? filter : found ? getLabel(found, showValue) : ''}
        change={handleFilter}
        margin={margin}
        disabled={disabled}
        focus={handleOpen}
        blur={handleBlur}
      />
      {open && (
        <DropDown
          options={filtered}
          change={handleChange}
          value={value}
          cancel={forceClose}
          focusOnMount={false}
          maxHeight={maxHeight}
        />
      )}
    </StatefulWrapper>
  )
}

const InputFilterWrapped = ({ title, margin, ...rest }) => {
  return (
    <Label title={title} margin={margin}>
      <InputFilter {...rest} />
    </Label>
  )
}

const Raw = (props) => {
  return (
    <InputFilter {...props} />
  )
}

export default InputFilterWrapped

export {
  Raw,
}
