import React from 'react'

import styles from './validityindicator.scss'

import TickIcon from '_/icons/Tick'
import WarningIcon from '_/icons/Warning'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const ValidityIndicator = ({ status, ...style }) => {
  if (!status) return null
  const Config = window.TICKNOVATE_CONFIG
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styles.layout} style={styling}>
            {status === 'VALID' && (
              <TickIcon
                size={24}
                fill={Config.theme.text_success}
              />
            )}
            {status === 'INVALID' && (
              <WarningIcon
                size={24}
                fill={Config.theme.text_error}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default ValidityIndicator
