import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '@ticknovate/frontend-shared/libs/moneyFormatting'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'

const top = {
  borderTop: '2px solid rgb(var(--theme_border_thick))',
}

const bottom = {
  borderBottom: '2px solid rgb(var(--theme_border_thick))',
}

const rowStyle = {
  ...top,
  padding: '0.5rem 0',
}

const PriceRow = ({
  label,
  price,
  currency,
  highlight = false,
}) => (
  <Row type={'spaced'} rawStyle={rowStyle}>
    <InlineTextBox strong size={highlight ? 1.25 : 0.875}>{label}</InlineTextBox>
    <InlineTextBox strong size={highlight ? 1.25 : 0.875}>{formatCurrency(price, { currencyCode: currency })}</InlineTextBox>
  </Row>
)

const BookingSummary = ({
  order,
}) => {
  const {
    pricing,
    currency,
  } = order

  const {
    t,
  } = useTranslation()

  return (
    <Container rawStyle={bottom}>
      <PriceRow label={t('meta.total')} price={pricing.total} currency={currency} highlight />
      <PriceRow label={t('meta.savings')} price={pricing.discount} currency={currency} />
      <PriceRow label={t('meta.sub-total')} price={pricing.subtotal} currency={currency} />
    </Container>
  )
}

export default BookingSummary
