import { Component } from 'react'
import mapValues from 'lodash/mapValues'
import values from 'lodash/values'
export { default as media } from 'styles/mediaQueries.scss'

const registerMediaQuery = (query, listener) => {
  const mediaQuery = window.matchMedia(query)
  const { matches } = mediaQuery
  const unlisten = () => mediaQuery.removeListener(listener)

  mediaQuery.addListener(listener)

  return { matches, unlisten }
}

/**
 * A utility component for working with responsive layouts.
 *
 * @param {Object} props
 * @param {Object|String} props.media
 *
 * Basic example:
 * ```jsx
 *  <MediaQuery media={'(min-width: 800px)'}>
 *    <p>This text shows on devices over 800px wide.</p>
 *  </MediaQuery>
 * ```
 *
 * For reusability, store the query in "styles/mediaQueries.scss".
 * This can be used in CSS, and also in JS like this:
 * ```jsx
 *  import MediaQuery, { media } from '../../../components/renderProp/MediaQuery'
 *
 *  <MediaQuery media={media.wide}>
 *    <p>This text shows on devices over 800px wide.</p>
 *  </MediaQuery>
 * ```
 *
 * If you pass a function as the component's children, the current matching
 * status will be passed:
 * ```jsx
 *   <MediaQuery media={media.wide}>
 *     {wide => (
 *       <p>The content is {wide ? 'wide' : 'narrow'}.</p>
 *     )}
 *   </MediaQuery>
 * ```
 *
 * If you pass an object as the `media` prop, all queries are passed:
 * ```jsx
 *   <MediaQuery media={{ mobile: media.mobile, tablet: media.tablet }}>
 *     {({ mobile, tablet }) => (
 *       <p>
 *         It is {!mobile && 'not'} a mobile size.
 *         It is {!tablet && 'not'} a tablet size.
 *         It is an unimaginative code example.
 *       </p>
 *     )}
 *   </MediaQuery>
 * ```
 *
 * For components with very specific breakpoints, follow the same pattern
 * as in mediaQueries.scss and export the breakpoint variables from your
 * component's SCSS file.
 */

class MediaQuery extends Component {
  constructor (props) {
    super(props)

    const { media } = props
    this.unlistenFns = []

    if (typeof media === 'string') {
      // There is only 1 media query to track.
      // Component state shape will be { matches: Boolean }
      const { matches, unlisten } = registerMediaQuery(media, event => {
        this.setState({ matches: event.matches })
      })
      this.unlistenFns.push(unlisten)
      this.state = { matches }
    } else {
      // There are multiple media queries to track.
      // Component state shape will be { matches: { foo: boolean, bar: boolean } }
      this.state = {
        matches: mapValues(media, (query, key) => {
          const { matches, unlisten } = registerMediaQuery(query, event => {
            this.setState(prevState => ({
              matches: {
                ...prevState.matches,
                [key]: event.matches,
              },
            }))
          })

          this.unlistenFns.push(unlisten)
          return matches
        }),
      }
    }
  }

  componentWillUnmount () {
    this.unlistenFns.forEach(fn => fn())
  }

  allQueriesMatch () {
    const { matches } = this.state

    if (typeof matches === 'boolean') {
      return matches
    }

    return values(matches).every(value => value === true)
  }

  render () {
    const { children } = this.props
    const { matches } = this.state

    // Render prop style
    if (typeof children === 'function') {
      return children(matches)
    }

    // Regular child element style
    if (this.allQueriesMatch()) {
      return children
    }

    // Regular child element style, with media query that doesn't match
    return null
  }
}

export default MediaQuery
