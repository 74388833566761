/* eslint-disable camelcase */
import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Area from '_/components/layout/Area'

import WarningText from '_/templates/WarningText'

import OptionList from '_/components/element/OptionList'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import useBasket from '_/hooks/useBasket'

import useCart from '_/hooks/useCart'

const ModalLocation = ({
  data,
  current,
  update,
  close,
  initial,
}) => {
  const { t } = useTranslation()

  const revert = useRevert(current, update, true)

  const wrappedClose = () => {
    revert()
    close()
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        initial={initial}
        routes={[
          {
            title: t('ticket.model-title-depart'),
            icon: 'location',
            valid: current.location !== null,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <Location
                  data={data}
                  close={close}
                  current={current}
                  update={update}
                />
              </ModalColumn>
            ),
          },
          {
            title: t('ticket.model-title-arrive'),
            icon: 'location',
            valid: current.end_location !== null,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <EndLocation
                  data={data}
                  close={close}
                  current={current}
                  update={update}
                />
              </ModalColumn>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

const Location = ({
  data,
  current,
  update,
}) => {
  const {
    emptyBasket,
  } = useBasket()

  const cart = useCart()

  const handleChange = (value) => {
    emptyBasket()

    cart.reset()

    update(
      [
        {
          field: 'location',
          value,
        },
      ],
      [
        'end_location',
        'outbound_date',
        'inbound_date',
        'outbound_time',
        'inbound_time',
        'ticket',
      ],
    )
  }

  const {
    route_segments,
  } = data

  return (
    <Fragment>
      <Area
        areas={[
          'list',
          'info',
        ]}
        columns={1}
        rowgap={1.5}
      >
        <OptionList
          area={'list'}
          value={current.location}
          options={route_segments}
          change={handleChange}
        />
        {data.locations.some(item => item.highlight) && (
          <WarningText
            area={'info'}
            content={'These destinations are handled through a different booking system.'}
          />
        )}
      </Area>
    </Fragment>
  )
}

const EndLocation = ({
  data,
  current,
  update,
}) => {
  const {
    emptyBasket,
  } = useBasket()

  const cart = useCart()

  const {
    route_segments_map,
  } = data

  const options = route_segments_map[current.location] || []

  const handleChange = (value) => {
    emptyBasket()

    cart.reset()

    update(
      [
        {
          field: 'end_location',
          value,
        },
      ],
      [
        'outbound_date',
        'inbound_date',
        'outbound_time',
        'inbound_time',
        'ticket',
      ],
    )
  }

  return (
    <Fragment>
      <Area
        areas={[
          'list',
          'info',
        ]}
        columns={1}
        rowgap={1.5}
      >
        <OptionList
          area={'list'}
          value={current.end_location}
          options={options}
          change={handleChange}
        />
        {data.locations.some(item => item.highlight) && (
          <WarningText
            area={'info'}
            content={'These destinations are handled through a different booking system.'}
          />
        )}
      </Area>
    </Fragment>
  )
}

export default ModalLocation
