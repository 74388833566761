import React, { useRef } from 'react'
import ReactDOM from 'react-dom'

import styles from './modal.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'
import styleCombine from '@ticknovate/frontend-shared/style/styleCombine'

const Modal = ({
  children,
  mounted,
  endHandler = null,
  portal = 'portal',
  ...style
}) => {
  const openRef = useRef(false)

  const handleEnd = (event) => {
    event.stopPropagation()

    if (openRef.current === true) {
      openRef.current = false
      endHandler && endHandler()
    } else {
      openRef.current = true
    }
  }

  const content = (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <div className={styleCombine(styles.layout)}>
            <div className={styleCombine(styles.content, mounted ? styles.in : styles.out)} onAnimationEnd={handleEnd}>
              <div className={styleCombine(styles.caddy)} style={styling}>
                {children}
              </div>
            </div>
          </div>
        )
      }}
    />
  )

  return ReactDOM.createPortal(
    content,
    document.getElementById(portal),
  )
}

export default Modal
