import React, { Component } from 'react'

import styles from './scrolllist.scss'

import Container from '../../containers/Container'

import HeaderRow from '../../atoms/HeaderRow'

class ScrollList extends Component {
  handleRowSelect = (e, index) => {
    e.stopPropagation()

    const {
      selected,
      deselectable,
    } = this.props

    this.props.select && this.props.select((index === selected && deselectable ? null : index))
  }

  renderList = () => {
    const {
      children,
      selected,
      rowSelect,
      small,
      fill,
    } = this.props

    const styleBase = small ? 'row_small' : 'row'

    return React.Children.map(children, (child, index) => {
      const selectedRow = selected !== null && index === selected && rowSelect

      const styleString = selectedRow ? (fill ? `${styleBase}_selected_fill` : `${styleBase}_selected`) : styleBase

      return (
        <li
          key={index}
          className={styles[styleString]}
          onClick={e => this.handleRowSelect(e, index)}
        >
          {React.cloneElement(child, {selected: selectedRow})}
        </li>
      )
    })
  }

  getHeader = () => {
    if (this.props.header) {
      return (
        <HeaderRow
          headings={this.props.header}
          styling={styles.header}
        />
      )
    }

    return null
  }

  render () {
    return (
      <Container
        styling={[styles.layout, this.props.styling].join(' ')}
      >
        {this.getHeader()}
        <ul className={styles.list}>
          {this.renderList()}
        </ul>
      </Container>
    )
  }
}

export default ScrollList
