import React from 'react'

import InlineTextBox from '_/components/layout/InlineTextBox'
import CardButton from '_/components/element/CardButton'
import Row from '_/components/layout/Row'
import Container from '_/components/layout/Container'
import Icon from '_/components/element/Icon'

const ActionCard = ({
  label,
  sub = null,
  change,
  anchor = null,
  cta = false,
  warning = false,
  disabled = false,
  ...style
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  let text_color = null
  let icon_color = 'card_default_icon'

  if (cta) {
    icon_color = 'card_cta_icon'
    text_color = 'text_light'
  }

  if (warning) {
    icon_color = 'text_error'
    text_color = 'text_error'
  }

  if (disabled) {
    icon_color = 'card_disabled_icon'
    text_color = 'text_disabled'
  }

  return (
    <CardButton change={change} anchor={anchor} minHeight={'3.125rem'} cta={cta} warning={warning} disabled={disabled} {...style}>
      <Container flex={'1 0'}>
        <Row margin={sub !== null ? '0.5rem 0 0 0' : null}>
          <Icon name={'arrow_left'} size={34} fill={theme[icon_color]} />
          <InlineTextBox buttonLabel pushLeft color={text_color}>{label}</InlineTextBox>
        </Row>
        {sub !== null && (
          <InlineTextBox block textAlign={'left'} color={text_color} size={0.75} margin={'0 0 0.75rem 0'}>{sub}</InlineTextBox>
        )}
      </Container>
    </CardButton>
  )
}

ActionCard.styleable = true

export default ActionCard
