import React, { useState } from 'react'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

import colorLightOrDark from '@ticknovate/frontend-shared/libs/colorLightOrDark'

const Logo = ({
  ...style
}) => {
  const {
    endpoint,
    tenant,
  } = window.TICKNOVATE_CONFIG.env

  const [
    loaded,
    setLoaded,
  ] = useState(false)

  const useAlt = (colorLightOrDark(window.TICKNOVATE_CONFIG.theme['background_nav']) !== 'light')

  const logoUrl = `${endpoint}/${tenant}/logo${useAlt ? '-alt' : ''}.svg`

  const handleError = (event) => {
    console.log('LOGO LOAD ERROR', event)
  }

  const handleLoad = (event) => {
    setLoaded(true)
  }

  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <img
            style={{ display: loaded ? 'block' : 'none', ...styling }}
            onError={handleError}
            onLoad={handleLoad}
            src={logoUrl}
            alt={`${tenant} site logo`}
          />
        )
      }}
    />
  )
}

export default Logo
