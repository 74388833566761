import { callApi, decorateWithServerOffsetTime } from './base'

export const getMyOrders = () =>
  callApi('me/orders', {
    includeResponse: true,
  })
    .then(decorateWithServerOffsetTime)
    // Little hack. Currently the latest order is at the bottom.
    .then(orders => orders.reverse())

export const getSalesOrderHistory = salesOrderId =>
  callApi(`orders/${salesOrderId}/history`, {
    query: {
      api: 1,
    },
  })

export const getSalesOrder = salesOrderId =>
  callApi(`orders/${salesOrderId}`, {
    includeResponse: true,
  })
    .then(decorateWithServerOffsetTime)

export const getSalesOrderTickets = salesOrderId =>
  callApi(`orders/${salesOrderId}/tickets`, { query: { revision: 'all' } })

export const findSalesOrder = ({
  ref,
  name,
  email,
  location_id,
  markets,
  products,
  services,
  service_types,
  channels,
  source,
  booking_date_start,
  booking_date_end,
  booking_time_start,
  booking_time_end,
  ordered_date_start,
  ordered_date_end,
  ordered_time_start,
  ordered_time_end,
  state,
}) =>
  callApi('orders', {
    includeResponse: true,
    query: {
      ref,
      name,
      email,
      'location-id': location_id,
      'ordered-date-start': ordered_date_start,
      'ordered-date-end': ordered_date_end,
      'ordered-time-start': ordered_time_start,
      'ordered-time-end': ordered_time_end,
      'booking-date-start': booking_date_start,
      'booking-date-end': booking_date_end,
      'booking-time-start': booking_time_start,
      'booking-time-end': booking_time_end,
      'service-types': service_types,
      markets,
      source,
      products,
      services,
      channels,
      state,
    },
  })
    .then(decorateWithServerOffsetTime)

export const findOwnTradePartnerSalesOrder = ({ ref, name, email, address }) =>
  callApi('me/trade-partner/orders', {
    includeResponse: true,
    query: {
      ref, name, email, address,
    },
  })
    .then(decorateWithServerOffsetTime)

export const sendConfirmation = salesOrderId =>
  callApi(`salesorder/${salesOrderId}/sendConfirmationEmail`)

export const getPayments = salesOrderId =>
  callApi(`salesorder/${salesOrderId}/payments`)

export const updateNotes = ({ orderId, notes }) =>
  callApi(`orders/${orderId}`, {
    method: 'POST',
    body: {
      items: [
        {
          ...notes,
        },
      ],
    },
  })
