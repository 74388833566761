import React from 'react'

import { useTranslation } from 'react-i18next'

import Heading from '_/components/layout/Heading'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Row from '_/components/layout/Row'
import QrCodeIcon from '@ticknovate/frontend-shared/icons/QrCode'
import OpenInBrowserIcon from '@ticknovate/frontend-shared/icons/OpenInBrowser'
import { formatUTCLocale } from '@ticknovate/frontend-shared/libs/dateFormatter'
import ActionIcon from '_/components/action/ActionIcon'

import styles from './bookingcard.scss'

const BookingCard = ({
  order,
  getTickets,
  getDetails,
  mobile,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.layout}>
      <Row margin={'0 0 0.5rem 0'} rawStyle={mobile ? { display: 'block' } : {}}>
        <Heading
          title={`${t('meta.booking')}: ${order.id}`}
          level={3}
        />
        <Row type={'end'}>
          <ActionIcon
            label={t('customer-orders.action-tickets')}
            icon={QrCodeIcon}
            change={getTickets}
          />
          <ActionIcon
            label={t('customer-orders.action-details')}
            icon={OpenInBrowserIcon}
            change={getDetails}
          />
        </Row>
      </Row>
      {order.items.map(item => (
        <Row key={item.id} type={'spaced'} margin={'0 0 0.25rem 0'}>
          <InlineTextBox>{item.title}</InlineTextBox>
          <InlineTextBox>{formatUTCLocale(item.booked_unit.start_date, 'D MMM YYYY')}</InlineTextBox>
        </Row>
      ))}
    </div>
  )
}

export default BookingCard
