import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import Container from '_/components/layout/Container'
import InputCheckBox from '_/components/input/InputCheckBox'

const Options = ({
  current,
  change,
}) => {
  const { t } = useTranslation()
  const newsletterText = t('details.checkbox-label-newsletter', 'NULL')

  return (
    <Fragment>
      <Container margin={'1.5rem 0 0 0'}>
        <InputCheckBox
          title={<span dangerouslySetInnerHTML={{ __html: t('details.checkbox-label-terms') }} />}
          field={'meta.terms'}
          value={current.meta.terms}
          change={change}
        />
      </Container>
      {newsletterText !== 'NULL' && (
        <Container margin={'1.5rem 0 0 0'}>
          <InputCheckBox
            title={<span dangerouslySetInnerHTML={{ __html: newsletterText }} />}
            field={'customer.subscribed_to_newsletter'}
            value={current.customer.subscribed_to_newsletter}
            change={change}
          />
        </Container>
      )}
    </Fragment>
  )
}

export default Options
