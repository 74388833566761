import storage from '../libs/asyncStorage'
import history from '../libs/history'
import sessionActiveActions from '../reducers/session/localState/sessionActiveActions'

export const userLogin = payload => dispatch => {
  dispatch({ type: 'USER_LOGIN', payload })

  if (payload.tradePartner) {
    dispatch({
      type: 'LOAD_SESSION_TRADE_PARTNER',
      payload: {
        tradePartner: payload.tradePartner,
        tradePartnerPortalUserUser: true,
        fillAddress: true,
      },
    })
  }
}

export const logout = () =>
  async dispatch => {
    // End session, which cleans up cart
    await dispatch(sessionActiveActions.endSession())
    // Clear data stored in browser
    await storage.clear()
    // Clear data stored in memory (redux store)
    dispatch({ type: 'APP_CLEAR' })
  }

export const handleUserResponse = response => dispatch => {
  const { step, challenge } = response

  switch (step) {
    case 'CHALLENGE':
      switch (challenge) {
        case 'NEW_PASSWORD_REQUIRED':
          history.push('/login/change', response)
          break

        default:
          throw new Error(`Unknown challenge ${challenge}`)
      }
      break

    case 'FORCE_CHANGE_PASSWORD':
      history.push('/login/forgot/confirm', response)
      break

    case 'LOGGED_IN':
      dispatch(userLogin(response))
      break

    default:
      throw new Error(`Unknown login step "${step}"`)
  }
}
