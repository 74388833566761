import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import { ResponsiveView as View } from '_/components/layout/View'

import Area from '_/components/layout/Area'
import NavTitle from '_/components/navigation/NavTitle'

import Heading from '_/components/layout/Heading'
import IconTitle from '_/templates/IconTitle'

import ActionSelector from '_/components/action/ActionSelector'
import ActionBasket from '_/components/action/ActionBasket'
import ActionCard from '_/components/action/ActionCard'
import ActionDiscard from '_/components/action/ActionDiscard'

import DescriptionBlock from '_/components/layout/DescriptionBlock'

import FooterWrapper from '_/components/layout/FooterWrapper'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import ActionLocation from '_/components/action/ActionLocation'

import {
  filterTravellers,
  filterNonTravellers,
  getTravellerValue,
  getMetaFromProduct,
} from '_/libs/dependantData'

import useBasket from '_/hooks/useBasket'

import { formatUTC } from '_/libs/dateFormatter'

const getTime = time => {
  const [
    hour,
    minute,
  ] = time.split(':')

  return `${hour}:${minute}`
}

const getProductTitle = (meta, id, time) => {
  const info = meta.product_info_map[id]

  if (!info) return 'undefined'

  return info.booking_unit.type === 'time' && time !== null ? `${info.title} ${getTime(time)}` : info.title
}

const getTicketTitle = (meta, item) => {
  if (!item) return null

  const title = getProductTitle(meta, item.product_id, item.start_time)

  return item.combo_id ? `${meta.combo_map[item.combo_id]}, ${title}` : title
}

const fields = {
  info: ({
    current,
    data,
  }) => {
    const meta_value = getMetaFromProduct(current.product_id, data)

    return (
      <DescriptionBlock
        area={'info'}
        label={meta_value.title}
        value={meta_value.description}
      />
    )
  },
  heading_how: ({
    t,
  }) => {
    return (
      <Heading level={2} title={t('ticket.title-how')} area={'heading_how'} />
    )
  },
  heading_route: ({
    t,
  }) => {
    return (
      <Heading level={2} title={t('ticket.title-route')} area={'heading_route'} />
    )
  },
  heading_amend: ({
    t,
    dateField,
  }) => {
    return (
      <IconTitle icon={'arrow_right'} title={t(`ticket.title-${dateField}`)} area={'heading_amend'} />
    )
  },
  heading_outbound: ({
    t,
  }) => {
    return (
      <IconTitle icon={'arrow_right'} title={t('ticket.title-outbound')} area={'heading_outbound'} />
    )
  },
  heading_return: ({
    t,
  }) => {
    return (
      <IconTitle icon={'arrow_left'} title={t('ticket.title-inbound')} area={'heading_return'} />
    )
  },
  location: ({ // Location is always disabled in amends
    t,
    mount,
    valid,
    current,
    data,
    layout,
    colgap,
  }) => {
    if (layout.locationSplit) {
      return (
        <Area
          area={'location'}
          areas={[
            'from to',
          ]}
          columns={2}
          colgap={colgap}
        >
          <ActionSelector
            area={'from'}
            icon={'location'}
            label={t('ticket.label-depart')}
            warning={!valid.location}
            value={current.location ? data.location_map[current.location] : null}
            disabled
          />
          <ActionSelector
            area={'to'}
            icon={'location'}
            label={t('ticket.label-arrive')}
            warning={!valid.end_location}
            value={current.end_location ? data.location_map[current.end_location] : null}
            change={() => mount('location', 1)}
            disabled
          />
        </Area>
      )
    }

    return (
      <ActionLocation
        area={'location'}
        change={() => mount('location')}
        warning={!valid.location || !valid.end_location}
        from={current.location ? data.location_map[current.location] : null}
        to={current.end_location ? data.location_map[current.end_location] : null}
        disabled
      />
    )
  },
  travel: ({ // Only used for group mode
    t,
    mount,
    valid,
    current,
    data,
    layout,
  }) => {
    const non_travellers = filterNonTravellers(data, current.ticket, layout)

    const travel_value = non_travellers
      .filter(ticket => ticket.qty === 1)

    return (
      <ActionSelector
        area={'travel'}
        icon={'travel'}
        label={t('ticket.label-travel')}
        warning={!valid.travel}
        value={valid.travel ? data.tickets_map[travel_value[0].id].long : null}
        change={() => mount('travel')}
        disabled={current.type === 'route' && (!valid.location || !valid.end_location)}
      />
    )
  },
  travellers: ({ // Only runs when not in group mode
    t,
    mount,
    valid,
    current,
    data,
  }) => {
    const value = current.ticket
      .filter(ticket => ticket.qty > 0)
      .map(ticket => {
        const info = data.tickets.find(item => item.value === ticket.id)

        return {
          ...ticket,
          label: info ? info.label : 'undefined',
        }
      })
      .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      .map(ticket => {
        return `${ticket.qty} x ${ticket.label}`
      })

    return (
      <ActionSelector
        area={'travellers'}
        icon={'passenger'}
        label={t('ticket.label-travellers')}
        warning={!valid.ticket}
        value={value.length > 0 ? value : null}
        change={() => mount('travellers')}
      />
    )
  },
  travellers_group: ({ // Only runs in group mode
    t,
    mount,
    valid,
    current,
    data,
    layout,
  }) => {
    const travellers = filterTravellers(data, current.ticket, layout)
      .filter(({ qty }) => qty > 0)

    return (
      <ActionSelector
        area={'travellers_group'}
        icon={'passenger'}
        label={t('ticket.label-travellers')}
        warning={!valid.travellers_group}
        value={travellers.length > 0 ? getTravellerValue(data, current.ticket, layout) : null}
        change={() => mount('travellers')}
        disabled={current.type === 'route' && (!valid.location || !valid.end_location)}
      />
    )
  },
  date: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
    dateField,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    return (
      <Area
        area={'date'}
        areas={[
          'date',
          'ticket',
        ]}
        columns={1}
        rowgap={0.25}
      >
        <ActionSelector
          area={'date'}
          icon={'calendar'}
          label={t('ticket.label-date')}
          warning={!valid[`${dateField}_date`]}
          disabled={!travellers_valid}
          change={() => mount('ticketSelectionAmend')}
          value={current[`${dateField}_date`] !== null ? formatUTC(current[`${dateField}_date`], 'D MMMM YYYY') : null}
          withBorder={false}
        />
        <ActionSelector
          area={'ticket'}
          icon={'ticket'}
          label={t('ticket.label-ticket')}
          warning={valid[`${dateField}_date`] && !basket.items[dateField]}
          disabled={!valid[`${dateField}_date`]}
          change={() => mount('ticketSelectionAmend', 1)}
          value={getTicketTitle(data, basket.items[dateField])}
        />
      </Area>
    )
  },
  time: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    return (
      <Area
        area={'time'}
        areas={[
          'date',
          'ticket',
        ]}
        columns={1}
        rowgap={0.25}
      >
        <ActionSelector
          area={'date'}
          icon={'calendar'}
          label={t('ticket.label-date')}
          warning={!valid.outbound_date}
          disabled={!travellers_valid}
          change={() => mount('ticketSelectionTime')}
          value={current.outbound_date !== null ? formatUTC(current.outbound_date, 'D MMMM YYYY') : null}
          withBorder={false}
        />
        <ActionSelector
          area={'ticket'}
          icon={'time'}
          label={t('ticket.label-ticket')}
          warning={valid.outbound_date && !basket.items.outbound}
          disabled={!valid.outbound_date}
          change={() => mount('ticketSelectionTime', 1)}
          value={getTicketTitle(data, basket.items.outbound)}
        />
      </Area>
    )
  },
  submit: ({
    t,
    submit,
    discard,
    basket,
    dateField,
  }) => {
    return (
      <Area
        area={'submit'}
        areas={[
          'accept',
          'discard',
        ]}
        columns={1}
        rowgap={1}
      >
        <ActionCard
          disabled={basket.items[dateField] === null}
          label={t('meta.done')}
          change={submit}
          area={'accept'}
          cta
        />
        <ActionDiscard
          label={'Discard edits and exit'}
          change={discard}
          area={'discard'}
        />
      </Area>
    )
  },
  discard: ({
    discard,
  }) => {
    return (
      <ActionDiscard
        label={'Discard changes'}
        change={discard}
        area={'discard'}
        margin={'0 0 6rem 0'}
      />
    )
  },
}

const Form = ({
  current,
  valid,
  mobile,
  mount,
  data,
  layout,
  submit,
  discard,
  dateField,
}) => {
  const { t } = useTranslation()

  const {
    state: basket,
  } = useBasket()

  return (
    <Fragment>
      <NavTitle mobile={mobile} title={'Edit Journey'} area={'nav'} />
      <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
        <MediaQuery media={media.tablet}>
          {tablet => {
            const options = layout[tablet ? 'mobile' : 'desktop']
              .reduce((acc, cur) => {
                cur
                  .split(' ')
                  .filter(field => field !== '.')
                  .map(field => field.replace(/\/[0-9]+/, ''))
                  .forEach(field => !acc.includes(field) && acc.push(field))

                return acc
              }, [])

            return (
              <Area
                areas={layout[tablet ? 'mobile' : 'desktop']}
                columns={tablet ? 2 : 4}
                colgap={tablet ? 0.5 : 1.5}
                rowgap={tablet ? 1 : 1.5}
              >
                {options.map((option, index) => {
                  const Module = fields[option]

                  return (
                    <Module
                      key={`${option}_${index}`}
                      {...{
                        t,
                        mount,
                        valid,
                        current,
                        data,
                        submit,
                        basket,
                        layout,
                        discard,
                        index: current.index,
                        dateField,
                      }}
                    />
                  )
                })}
              </Area>
            )
          }}
        </MediaQuery>
        {mobile && (
          <FooterWrapper>
            <ActionBasket
              change={() => mount('basket')}
            />
          </FooterWrapper>
        )}
      </View>
    </Fragment>
  )
}

export default Form
