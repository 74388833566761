import React, { Fragment, Component } from 'react'
import classnames from 'classnames'

import Row from '../../containers/Row'
import InlineTextBox from '../../containers/InlineTextBox'
import Container from '../../containers/Container'

import styles from './statefulcontainer.scss'

import CheckIcon from '../../../icons/Check'

const allowed = [
  'interacting',
  'validated',
  'warning',
  'error',
  'disabled',
]

class StatefulContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      focused: false,
      touched: null,
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.touched !== null && state.touched < props.validateFrom) {
      return {
        touched: null,
      }
    }

    return null
  }

  handleFocus = () => {
    this.setState({
      focused: true,
      touched: this.props.noTouchValidation ? null : Date.now(),
    })
  }

  handleBlur = () => {
    this.setState({
      focused: null,
    })
  }

  render () {
    const {
      children,
      status,
      styling,
      required,
      requiredText,
      subtleValidation,
      shouldDisplayAllErrors,
      getRef,
      noFocusStyling,
      nude,
      messagePosition = 'absolute', // | 'below'
    } = this.props

    let style

    let alteredStatus = status

    if (this.state.touched === null && !shouldDisplayAllErrors) {
      if (status !== 'validated') {
        alteredStatus = null
      }
    }

    if (this.state.focused && !noFocusStyling) {
      alteredStatus = 'interacting'
    }

    // For subtle validation, we only show default state (null), interaction, or error.
    if (subtleValidation && !['interacting', 'error'].includes(alteredStatus)) {
      alteredStatus = null
    }

    style = allowed.includes(alteredStatus) ? styles[alteredStatus] : styles.default

    const showRequiredMessage = required && alteredStatus === 'error' && !this.state.focused && (this.state.touched !== null || shouldDisplayAllErrors)
    const showRequiredIndicator = !subtleValidation && required && !nude
    const messages = (
      <Fragment>
        {showRequiredMessage && <RequiredMessage label={requiredText} status={alteredStatus} />}
        {showRequiredIndicator && <RequiredIndicator status={alteredStatus} />}
      </Fragment>
    )

    return (
      <Fragment>
        <Row
          type={'start'}
          styling={classnames(
            styles.layout,
            style,
            styling,
            {
              'stateful-container-with-indicator': showRequiredIndicator,
              [styles.nude]: nude,
            },
          )}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          getRef={getRef}
        >
          {children}
          {messagePosition === 'absolute' && messages}
        </Row>
        {messagePosition === 'below' && messages}
      </Fragment>
    )
  }
}

const RequiredIndicator = (props) => {
  const {
    status,
  } = props

  const styling = [styles.indicator]

  styling.push(allowed.includes(status) ? styles[status] : styles.default)

  const Icon = status === 'validated' ? <CheckIcon size={10} fill={window.TICKNOVATE_CONFIG.theme.button_inner_text} /> : '*'

  return (
    <Container
      styling={styling.join(' ')}
    >
      {Icon}
    </Container>
  )
}

const RequiredMessage = (props) => {
  const {
    label,
    status,
  } = props

  const styling = status === 'error' ? styles.error : null

  return (
    <InlineTextBox
      styling={[styles.message, styling].join(' ')}
    >
      {label}
    </InlineTextBox>
  )
}

export default StatefulContainer
