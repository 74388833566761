const memoize = require('lodash/memoize')

const paymentMethodScripts = {
  // Scripts for "Nets Easy" embedded checkout iframe
  nets_easy: {
    test: 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1',
    live: 'https://checkout.dibspayment.eu/v1/checkout.js?v=1',
  },
  // Scripts for "Sagepay card" embedded checkout iframe
  sagepay_card: {
    test: 'https://pi-test.sagepay.com/api/v1/js/sagepay-dropin.js',
    live: 'https://pi-live.sagepay.com/api/v1/js/sagepay-dropin.js',
  },
  // Scripts for "Worldpay Online" embedded checkout iframe
  // TODO: Double-check that this is the correct live script. Can't see any mention in docs atm.
  worldpay_online: {
    test: 'https://cdn.worldpay.com/v1/worldpay.js',
    live: 'https://cdn.worldpay.com/v1/worldpay.js',
  },
}

const loadScript = memoize((src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
  })
})

/**
 * Load checkout scripts for all enabled payment methods.
 */
export const loadPaymentScripts = async (enabledPaymentMethods) => {
  await Promise.all(
    enabledPaymentMethods.map((method) => {
      if (paymentMethodScripts[method]) {
        const env = window.TICKNOVATE_CONFIG.meta.envType
        const urls = paymentMethodScripts[method]
        if (!urls) {
          return
        }

        if (!urls[env]) {
          console.error(
            `Links found for "${method}" payment method, but not for environment "${env}"`,
            urls,
          )
          return
        }

        return loadScript(urls[env])
      }

      return Promise.resolve()
    }),
  )
}
