import React from 'react'
import styles from './spinnerplaceholder.scss'
import IconSpinner from '../../../icons/Spinner'

const SpinnerPlaceholder = ({ fill = window.TICKNOVATE_CONFIG.theme.background_ui, size = 32, style }) =>
  <div className={styles.layout} style={style}>
    <IconSpinner fill={fill} size={size} />
  </div>

export default SpinnerPlaceholder
