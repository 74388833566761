import React from 'react'

const DownIcon = (props) => {
  const {
    size,
    fill,
  } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 20.01'
    >
      <polygon
        fill={fill}
        points='4 0.01 0 4.01 16 20.01 32 4.01 28 0 16 12.01 4 0.01'
      />
    </svg>
  )
}

export default DownIcon
